import React, { useEffect, useContext, useState } from 'react';
import { Breadcrumbs, TableInquiry, TablePaging } from '../../components';
import { usePagination } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import { useTranslation } from 'react-i18next';

export const MypageInquiryListPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(1, 10);

  useEffect(() => {
    console.log('page effect', page);
    if (page) {
      requestNewList();
    }
  }, [page]);

  const requestNewList = () => {
    API.User.GetInquiry({
      page: page,
      limit: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        let result = res.data;
        console.log('result', result);
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    console.log('tableRowClick target_seq', target_seq);
    history.push('/mypage/inquiry_detail/' + target_seq);
  };

  return (
    <article className='basic-page'>
      <Breadcrumbs content='credit' title={'1:1 상담 내역'}></Breadcrumbs>
      <article className='content__wrapper'>
        <div style={{ height: 80 }}></div>
        <TableInquiry data={list} rowClick={tableRowClick}></TableInquiry>
        <div style={{ height: 40 }}></div>
        <TablePaging
          page={page}
          totalPage={totalPage}
          onChange={onPageChange}
          customButton={{
            label: t('CONTACT_US'),
            style: {
              width: '240px',
            },
            onClick: () => {
              history.push('/mypage/inquiry_add');
            },
          }}
        ></TablePaging>
      </article>
    </article>
  );
};
