import React from 'react';
import Handler from '../../Handler';
import { Button } from '../../components';

export const TableMethodology = (props) => {
  const { data, rowClick, callback } = props;

  return (
    <article className='table-methodology'>
      <ul>
        {data && data.length === 0 && (
          <li className='empty'>
            <span>데이터가 없습니다.</span>
          </li>
        )}
        {data &&
          data.map((item, index) => {
            const {
              area,
              attachedFile,
              createdAt,
              expiredYear,
              groupSeq,
              id,
              introOne,
              introTwo,
              isValid,
              mainImage,
              seq,
              startDate,
              subArea,
              title,
              version,
              versionNumber,
            } = item;
            return (
              <li>
                <section className='img__box'>
                  <img src={mainImage}></img>
                </section>
                <section className='caption__box'>
                  <div className='title'>
                    <h2>{title}</h2>
                    <div className='version'>
                      {versionNumber === 0 && <span className='new'>신규</span>} <span>{version}</span>
                    </div>
                  </div>
                  <div className='intro'>
                    <p>{introOne}</p>
                    <p>{introTwo}</p>
                  </div>
                  <div className='attribute'>
                    <header>
                      <span>분야</span>
                    </header>
                    <span className='value'>
                      {Handler.ENUM.AREA(area)} {'>'} {Handler.ENUM.SUB_AREA(subArea)}
                    </span>
                  </div>
                  <div className='attribute'>
                    <header>
                      <span>고유번호</span>
                    </header>
                    <span className='value'>{id}</span>
                  </div>
                  <div className='attribute'>
                    <header>
                      <span>유효 시작일</span>
                    </header>
                    <span className='value'>{Handler.dbDateToYYYYMMDDComma(startDate)}</span>
                  </div>
                  <div className='attribute'>
                    <header>
                      <span>적용 사업 유효기간</span>
                    </header>
                    <span className='value'>{expiredYear}년</span>
                  </div>
                </section>
                <section className='button__box'>
                  {versionNumber > 0 && (
                    <Button
                      style={{ width: '144px', height: '38px', marginRight: 12 }}
                      type='secondary-square'
                      label='개정이력'
                      onClick={(e) => {
                        e.stopPropagation();
                        callback('selected_slug', item.seq);
                      }}
                    ></Button>
                  )}
                  {attachedFile && (
                    <Button
                      onClick={() => {
                        window.open(attachedFile, '_blank');
                      }}
                      style={{ width: '144px', height: '38px' }}
                      type='square'
                      label='파일 다운로드'
                    ></Button>
                  )}
                </section>
              </li>
            );
          })}
      </ul>
    </article>
  );
};
