import React from 'react';
import { UserProvider, DialogProvider, SnackbarProvider, MarginTopProvider, ContentProvider, LangProvider } from './providers';

const Provider = (props) => {
  const { children } = props;

  return (
    <LangProvider>
    <SnackbarProvider>
      <DialogProvider>
        <UserProvider>
          <MarginTopProvider>
            <ContentProvider>{children}</ContentProvider>
          </MarginTopProvider>
        </UserProvider>
      </DialogProvider>
    </SnackbarProvider>
    </LangProvider>
  );
};

export default Provider;
