import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressSearch } from '../form';
import { Button } from '..';
import _ from 'lodash';
import Handler from '../../Handler';

export const AddressLocation = (props) => {
  const { value, onChange, meta } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span>*</span>;
      }
    }
  };

  const handleSearch = (address) => {
    let new_value = _.clone(Handler.jsonParser(value));
    new_value.addr1 = address;
    onChange(new_value, meta.key);
    setOpen(false);
  };

  /* 검색해서 설정한 주소 */
  const addressOne = useMemo(() => {
    if (value) {
      const addr = Handler.jsonParser(value);
      if (addr.addr1) return addr?.addr1.formatted_address;
      if (addr.daum) return addr?.daum.roadAddress;
      return '';
    }
    return '';
  }, [value]);

  /* 상세 주소 */
  const addressTwo = useMemo(() => {
    if (value) {
      const addr = Handler.jsonParser(value);
      if (addr.addr2) return addr?.addr2;
      if (addr.daum) return addr?.daum.detail;
      return '';
    }
    return '';
  }, [value]);

  /* 우편번호 */
  const zoneCode = useMemo(() => {
    if (value) {
      const addr = Handler.jsonParser(value);
      if (addr.addr1) return Handler.findZonecode(addr.addr1?.address_components);
      if (addr.daum) return addr?.daum.zonecode;
      return '';
    }
    return;
  }, [value]);

  return (
    <article
      className={Handler.BuildArticleClassname('form-address', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>{meta.label}</span>
          {renderRequired()}
        </header>
      )}
      <section className='address-value__box'>
        {meta && meta.type === 'content' && (
          <Button
            disabled={meta.disabled}
            style={{ width: '96px', height: '50px', marginRight: 8 }}
            onClick={() => {
              if (!meta.readOnly) {
                setOpen(true);
              }
            }}
            label={t('SEARCH')}
            type='secondary-square'
          ></Button>
        )}

        <div className='zonecode'>
          <input readOnly value={zoneCode}></input>
          {meta && meta.type === 'mypage' && (
            <Button
              disabled={meta.disabled}
              style={{ width: '80px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                setOpen(true);
              }}
              label={t('SEARCH')}
              type='info-square'
            ></Button>
          )}
        </div>
        {open && (
          <div className='search-address'>
            <article>
              <div>
                <h2>주소검색</h2>
                <p>도로명주소 또는 지번을 입력하세요.</p>
              </div>
              <div className='ico close' onClick={() => setOpen(false)}></div>
            </article>
            <AddressSearch onSearch={handleSearch} />
          </div>
        )}
        <div className='address'>
          <input readOnly value={addressOne}></input>
        </div>
        <div className='detail'>
          <input
            disabled={meta.disabled}
            value={addressTwo}
            placeholder={t('ADDRESS_DETAIL')}
            onChange={(e) => {
              let new_value = _.clone(Handler.jsonParser(value));
              new_value.addr2 = e.target.value;
              onChange(new_value, meta.key);
            }}
          ></input>
        </div>
      </section>
    </article>
  );
};
