import React, { useState, useEffect } from 'react';
import { media } from '../../styles/style';
import styled from '@emotion/styled';

const ImageWrapper = styled.div`
  &.image__box {
    filter: ${(props) => (props.blur === 'true' ? 'blur(30px)' : '')};
    transition: 0.5s all;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100%;
    min-height: 780px;
    overflow: hidden;
    filter: brightness(0.8);
    @media only screen and (max-width: ${media.$lg}) {
      height: 100vh;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 10%;
      @media only screen and (max-width: ${media.$lg}) {
        width: auto;
        height: 100%;
      }
    }
  }
`;

export const LazyLoadImage = (props) => {
  const { preview, image, box_style, img_style } = props;
  const [currentImage, setCurrentImage] = useState(preview);
  const [loading, setLoading] = useState(true);

  const fetchImage = (src) => {
    const loadingImage = new Image();
    loadingImage.src = src;
    loadingImage.onload = () => {
      setCurrentImage(loadingImage.src);
      setLoading(false);
    };
  };

  useEffect(() => {
    fetchImage(image);
  }, []);

  return (
    <ImageWrapper className='image__box' style={box_style}>
      <img src={currentImage} alt='' blur={`${loading}`} style={img_style} />
    </ImageWrapper>
  );
};
