import React from 'react';
import styled from '@emotion/styled';
import PurpleArrow from '../../styles/icon/purple_arrow.svg';
import WhiteArrow from '../../styles/icon/white_right_arrow.svg';

const OutlineButtonWrapper = styled.div`
  border: 1px solid ${(prop) => prop.color};
  border-radius: 30px;
  display: flex;
  max-width: 200px;
  margin: auto;
  /* display: flex;
  justify-content: center; */
  padding: 10px 30px;
  span {
    color: ${(prop) => prop.color};
    font-weight: 800;
  }
  button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: none;
    .arrow {
      width: 40px;
      img {
        width: 100%;
      }
    }
  }
`;

export const OutLineButton = (props) => {
  const { label, onClick, color, type, loading, disabled, aos } = props;
  return (
    <OutlineButtonWrapper color={color} data-aos={aos}>
      <button disabled={disabled} onClick={onClick}>
        <span>{label}</span>
        <div className='arrow'>
          <img src={color === '#5d3be5' ? PurpleArrow : WhiteArrow} alt='오른쪽화살표' />
        </div>
      </button>
    </OutlineButtonWrapper>
  );
};
