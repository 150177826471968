import React, { useEffect } from 'react';
import { Button } from '../../components';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import API from '../../api';

export const UserSignUpCompletePage = (props) => {
  const history = useHistory();
  const searchObject = queryString.parse(history.location.search, { decode: false });

  console.log('searchObject', searchObject);
  useEffect(() => {
    if (searchObject && searchObject.key) {
      API.User.PutSignupCertification({ key: searchObject.key }).then(
        (res) => {
          console.log('res', res);
        },
        (err) => {
          console.log('err', err);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='user-email__container'>
          <section className='mail__box'>
            <div className='image'></div>
            <h1>회원가입이 완료되었습니다.</h1>
          </section>
          <section className='guide__box'>
            <Button
              style={{ width: '180px' }}
              onClick={() => {
                history.push('/user/login');
              }}
              label='로그인'
            ></Button>
          </section>
        </section>
      </article>
    </article>
  );
};
