import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, SelectBox, Button, TablePaging, MetaTag, TextInput } from '../../components';
import { usePagination } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { UserContext, SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import queryString from 'query-string';
import { genMypageRoutes } from '../../constants';
import { MypageNavList } from './components/MypageNavList';

export const ForwardPurchaseListPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const searchObject = queryString.parse(history.location.search);
  const { userInfo, isPersonal } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 4);
  const [search, setSearch] = useState('');
  const [calculateStatus, setCalculateStatus] = useState('SELECT_ALL');
  const [lastSearchBody, setLastSearchBody] = useState(null);
  const [userMenu, setUserMenu] = useState(null);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });
  useEffect(() => {
    setMetaObject({
      title: '마이페이지 - 선도구매목록',
      description: '크레딧 선도구매목록',
      keywords: '팝플이란, 팝플',
      image: '',
      canonical: '',
      url: window.location.href,
    });
  }, []);
  console.log('searchObject', searchObject);

  useEffect(() => {
    requestNewList(0);
  }, []);

  useEffect(() => {
    if (lastSearchBody) {
      requestNewList();
    }
  }, [page]);

  useEffect(() => {
    if (userInfo) {
      setUserMenu(genMypageRoutes(isPersonal));
    }
  }, [userInfo, isPersonal]);

  //event
  const requsetSearch = () => {
    requestNewList(0);
  };

  const requestNewList = (init_page) => {
    let params = null;

    if (init_page === 0) {
      params = {
        page: init_page ? init_page : page,
        limit: Handler.VARIABLES.PAGE_LIMIT,
        searchType: 'Title',
        searchValue: search,
      };
      if (calculateStatus !== 'SELECT_ALL') {
        params.calculateStatus = calculateStatus;
      }

      setLastSearchBody({ ...params });
    } else {
      params = {
        ...lastSearchBody,
        page: init_page ? init_page : page,
        limit: Handler.VARIABLES.PAGE_LIMIT,
      };
    }
    console.log('requestNewList params', params);

    API.User.GetForwardPaymentList(params).then(
      (res) => {
        let result = res.data;
        setList(result.content);
        setTotalPage(result.numberOfElements >= 0 ? result.totalPages : 0);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  if (!userInfo) {
    return null;
  }
  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <div className='content__wrapper'>
          <MypageNavList userMenu={userMenu} />
          <section className='headding-text__container'>
            <header>
              <h1>선도구매 목록</h1>
            </header>
            <div className='right__box'>
              <Breadcrumbs type={'small'}></Breadcrumbs>
            </div>
          </section>
          <section className='forward-purchase-list__container'>
            <div className='serach__box'>
              <TextInput
                value={search}
                onChange={(value, key) => {
                  setSearch(value);
                }}
                onEnter={() => {
                  requsetSearch();
                }}
                meta={{ style: { width: '446px' }, placeholder: '궁금하신 내용을 입력해 주세요.', className: 'round' }}
              ></TextInput>
              <Button
                style={{ width: '80px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  requsetSearch();
                }}
                label='검색'
              ></Button>
            </div>
            <div className='filter__box'>
              <div className='select__box'>
                <SelectBox
                  value={calculateStatus}
                  onChange={(value) => {
                    setCalculateStatus(value);
                  }}
                  meta={{
                    key: 'calculateStatus',
                    style: {
                      width: 165,
                    },
                    type: 'round',
                    menus: [
                      {
                        label: '정산상태 전체',
                        value: 'SELECT_ALL',
                      },
                      {
                        label: '정산완료',
                        value: 'CalculateComplete',
                      },
                      {
                        label: '정산대기',
                        value: 'CalculateWait',
                      },
                    ],
                  }}
                ></SelectBox>
              </div>
            </div>
            <div className='list__box'>
              <ul>
                {list && list.length === 0 && (
                  <div className='empty'>
                    <div className='ico no-data'></div>
                    <span>데이터가 없습니다.</span>
                  </div>
                )}
                {list &&
                  list.map((item, index) => {
                    return (
                      <li>
                        <section className='info__box'>
                          <div className='status__box'>
                            <div className='status'>
                              <span className={`status ${item.status}`}>
                                {t(Handler.buildPaymentStatusText(item.status))}
                              </span>
                              <div className='date'>{Handler.getYYYYMMDDHHMMByUnix(item.createdAt)}</div>
                            </div>
                            <div className='order'>
                              <span>주문번호 : {item.orderNumber}</span>
                            </div>
                          </div>
                          <div className='caption__box'>
                            <div className='img__box'>
                              {item.project.mainImages && item.project.mainImages.length > 0 && (
                                <img src={item.project.mainImages[0]} alt={item.project.title}></img>
                              )}
                            </div>
                            <div className='title__box'>
                              <span className='name'>{item.project.title}</span>
                              <span className='id'>{item.project.id}</span>
                              <div className='price'>
                                <span>{Handler.numberWithCommas(Math.floor(item.price / item.amount))}원</span>
                                <span>·</span>
                                <span>{Handler.numberWithCommas(item.amount)}VRC</span>
                              </div>
                              <div className='total-price'>
                                <span>{Handler.numberWithCommas(item.price)}원</span>
                              </div>
                            </div>
                            <div className='price__box'>
                              <span>{Handler.numberWithCommas(item.price)}원</span>
                            </div>
                          </div>
                        </section>
                        <section className='option__box'>
                          <div className='forward-status'>
                            <div className='ready'>
                              <span>{userInfo.userType === 'Personal' ? '정산대기' : '상쇄대기'} </span>
                              <b>{Handler.numberWithCommas(item.forwardReady)} VRC</b>
                            </div>
                            <div className='complete'>
                              <span>{userInfo.userType === 'Personal' ? '정산완료' : '상쇄완료'}</span>
                              <b>{Handler.numberWithCommas(item.forwardComplete)} VRC</b>
                            </div>
                          </div>
                          <div className='button__box'>
                            <div
                              className='button'
                              onClick={() => {
                                history.push('/mypage/forward_purchase_detail/' + item.seq);
                              }}
                            >
                              주문상세
                            </div>
                          </div>
                        </section>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </section>
          <div style={{ height: 36 }}></div>
          <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
        </div>
      </article>
    </>
  );
};
