import React, { useEffect, useContext } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from 'react-router-dom';
import { MainFooter, HeaderNav } from '../components';
import { MarginTopContext } from '../contexts';

export const MainLayout = (props) => {
  const { children } = props;
  const location = useLocation();
  const { transparent, updateTransparent } = useContext(MarginTopContext);
  useEffect(() => {
    AOS.init({
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 150, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1200, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: true,
      // once: false,
      mirror: true, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window sh
    });
  }, []);

  useEffect(() => {
    updateTransparent(location.pathname);
  }, [location]);

  return (
    <div className={transparent ? 'main-layout' : 'main-layout mt'}>
      <section className='header__box'>
        <HeaderNav></HeaderNav>
      </section>
      <section className='content__box'>{children}</section>
      <section className='footer__box'>
        <MainFooter></MainFooter>
      </section>
    </div>
  );
};
