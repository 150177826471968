import React from 'react';
import { Button } from '../components';

export const NotFoundPage = (props) => {
  return (
    <article className='basic-page'>
      <section className='button__container' style={{ marginTop: 200, marginBottom: 200 }}>
        <h1>페이지를 찾을 수 없습니다.</h1>
      </section>
      <section className='button__container'>
        <Button
          style={{ width: '240px' }}
          onClick={() => {
            window.location.href = '/';
          }}
          label='홈으로 가기'
        ></Button>
      </section>
    </article>
  );
};
