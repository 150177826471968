import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next';
import { PageTitle, MetaTag } from '../../components';
import {
  PRICE_TITLE_BG,
} from '../../constants';
import { ChartContainer, ChartHeader, ChartWrapper, TabBox } from '../../styles/ComponentStyle.js';
import CarbonPrice from './components/CarbonPrice';
import ChartCard from './components/ChartCard';
import API from '../../api';
import RenewablePrice from './components/RenewablePrice';
import { useLabel } from '../../hooks/useLabel.jsx';

export const PricePage = (props) => {
  const today = dayjs(new Date()).format('YYYY-MM-DD');
  const [mandatoryLoading, setMandatoryLoading] = useState(true);
  const [voluntaryLoading, setVoluntaryLoading] = useState(true);
  const [renewableLoading, setRenewableLoading] = useState(true);
  const [mandatory, setMandatory] = useState([null, null, null]);
  const [voluntary, setVoluntary] = useState([null, null]);

  const [rec, setRec] = useState([]);
  const [smp, setSmp] = useState([]);
  const [rps, setRps] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();
  const { CARBON_PRICE_DICT, RENEWABLE_PRICE_DICT } = useLabel();
  const TAB_LIST = CARBON_PRICE_DICT['TAB'];

  const [metaObject, setMetaObject] = useState({
    title: t('CARBON_PRICE'),
    description: t('CARBON_PRICE_DESCRIPTION'),
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });

  const getChartData = (chart) => {
    API.Chart.GetChart({
      chart: chart,
      date: today,
    }).then(
      (res) => {
        let result = res.data;
        if (chart === 'MANDATORY') {
          setTimeout(() => {
            setMandatory([...result.charts.map((el) => ({ ...el, price: el.data[el.data.length - 1].value }))]);
            setMandatoryLoading(false);
          }, 1000);
        }
        if (chart === 'VOLUNTARY') {
          setTimeout(() => {
            setVoluntary([...result.charts.map((el) => ({ ...el, price: el.data[el.data.length - 1].value }))]);
            setVoluntaryLoading(false);
          }, 1000);
        }
        if (chart === 'RENEWABLE') {
          setTimeout(() => {
            setRec(() => result.charts.filter((el) => el.config.tag === 'REC'));
            setSmp(() => result.charts.filter((el) => el.config.tag === 'SMP'));
            setRps(() => result.charts.filter((el) => el.config.tag === 'RPS'));
            setRenewableLoading(false);
          }, 1000);
        }
      },
      (err) => {},
    );
  };

  useEffect(() => {
    const promiseList = [getChartData('MANDATORY'), getChartData('VOLUNTARY'), getChartData('RENEWABLE')];
    Promise.all(promiseList);
  }, []);

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <PageTitle title={t('CARBON_PRICE')} description={t('CARBON_PRICE_DESCRIPTION')} image={PRICE_TITLE_BG} />
        <ChartContainer>
          <TabBox className='tab__box'>
            <ul>
              {TAB_LIST.map((item, index) => {
                return (
                  <li
                    className={tabIndex === index ? 'on' : ''}
                    key={index + 'tab'}
                    onClick={() => {
                      setTabIndex(index);
                    }}
                  >
                    <span>{item}</span>
                  </li>
                );
              })}
            </ul>
          </TabBox>
        </ChartContainer>
        <>
          {tabIndex === 0 && (
            <>
              <ChartContainer>
                <ChartHeader>
                  <h2 className='font-bold'>{CARBON_PRICE_DICT['SUMMARY']}</h2>
                </ChartHeader>
                <ChartWrapper>
                  {[...mandatory, ...voluntary].map((el, idx) => {
                    return (
                      <ChartCard
                        loading={mandatoryLoading && voluntaryLoading}
                        key={idx + 'chart'}
                        config={el ? el.config : null}
                        price={(el && el?.price) || ''}
                      />
                    );
                  })}
                </ChartWrapper>
              </ChartContainer>
              <CarbonPrice
                charts={mandatory}
                loading={mandatoryLoading}
                title={CARBON_PRICE_DICT.MANDATORY.TITLE}
                content={CARBON_PRICE_DICT.MANDATORY.CONTENT}
              />
              <CarbonPrice
                charts={voluntary}
                loading={voluntaryLoading}
                title={CARBON_PRICE_DICT.VOLUNTARY.TITLE}
                content={CARBON_PRICE_DICT.VOLUNTARY.CONTENT}
              />
            </>
          )}
          {tabIndex === 1 && (
            <ChartContainer>
              <RenewablePrice
                charts={rps}
                title={RENEWABLE_PRICE_DICT.TITLE}
                content={RENEWABLE_PRICE_DICT.CONTENT}
                chart_title={RENEWABLE_PRICE_DICT.RPS}
                loading={renewableLoading}
              />
              <RenewablePrice charts={smp} chart_title={RENEWABLE_PRICE_DICT.SMP} loading={renewableLoading} />
              <RenewablePrice charts={rec} chart_title={RENEWABLE_PRICE_DICT.REC} loading={renewableLoading} />
            </ChartContainer>
          )}
        </>
      </article>
    </>
  );
};
