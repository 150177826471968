import AboutTitleBg from '../styles/images/title/about_title_bg.png';
import LowAboutTitleBg from '../styles/images/title/low_about_title_bg.png';
import BrandTitleBg from '../styles/images/title/brand_title_bg.png';
import LowBrandTitleBg from '../styles/images/title/low_brand_title_bg.png';
import BizTitleImage from '../styles/images/title/biz_title_bg.png';
import LowBizTitleImage from '../styles/images/title/low_biz_title_bg.png';
import NewsTitleBg from '../styles/images/title/news_title_bg.png';
import LowNewsTitleBg from '../styles/images/title/low_news_title_bg.png';

import StandardTitleBg from '../styles/images/title/standard_title_bg.png';
import LowStandardTitleBg from '../styles/images/title/low_standard_title_bg.png';
import DigitalTitleBg from '../styles/images/title/digital_title_bg.png';
import LowDigitalTitleBg from '../styles/images/title/low_digital_title_bg.png';

import MarketplaceTitleBg from '../styles/images/title/market_title_bg.png';
import LowMarketplaceTitleBg from '../styles/images/title/low_market_title_bg.png';
import CreditTitleBg from '../styles/images/title/credit_title_bg.png';
import LowCreditTitleBg from '../styles/images/title/low_credit_title_bg.png';

export const PAGE_TOP = {
  ABOUT: {
    title: 'We create Net-Zero Culture',
    desc: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    image: AboutTitleBg,
    preview: LowAboutTitleBg,
  },
  BRAND: {
    title: '브랜드 아이덴티티',
    desc: '팝플은 지속 가능한 지구에 대한 생각과 가치를 상징합니다.',
    image: BrandTitleBg,
    preview: LowBrandTitleBg,
  },
  BIZ: {
    title: '사업소개',
    desc: '팝플은 탄소 중립 실현을 위한 다양한 서비스를 제공합니다.',
    image: BizTitleImage,
    preview: LowBizTitleImage,
  },
  // PROJECT: { title: '프로젝트 사례', desc: '', image: NewsTitleBg, preview: LowNewsTitleBg },
  NEWS: { title: '팝플 뉴스', desc: '팝플의 다양한 소식을 만나보세요.', image: NewsTitleBg, preview: LowNewsTitleBg },
  STANDARD: {
    title: '팝플 스탠다드',
    desc: '팝플은 신뢰할 수 있는 가치와 원칙을 준수합니다.',
    image: StandardTitleBg,
    preview: LowStandardTitleBg,
  },
  METHODOLOGY: {
    title: '방법론',
    desc: '팝플은 팝플스탠다드의  과학 기반의 정량적 방법론에 근거합니다.',
    image: '',
    preview: '',
  },
  SOLUTION: {
    title: '디지털 MRV',
    desc: '팝플의 혁신 기술로 감축 사업 데이터를 정확하게 측정·보고·검증합니다.',
    image: DigitalTitleBg,
    preview: LowDigitalTitleBg,
  },
  MARKET: {
    title: '마켓플레이스',
    desc: '마켓플레이스에서 지구를 위한 실천을 함께하세요.',
    image: MarketplaceTitleBg,
    preview: LowMarketplaceTitleBg,
  },
  CREDIT: {
    title: '크레딧 구매하기',
    desc: '탄소 감축, 제거 등 다양한 사업들을 만나보세요.',
    image: CreditTitleBg,
    preview: LowCreditTitleBg,
  },
  POPLE_CERT: { title: '팝플 인증서', desc: '인증서를 발급받고 선한 영향력을 전파해보세요.', image: '', preview: '' },
};
