import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import styled from '@emotion/styled';
import { color, font, fontSize, media } from '../../styles/style';
import { ContentContext } from '../../contexts';
import {
  PAGE_TOP,
  OUR_VALUE_DICT,
} from '../../constants';
import AboutPopleImage from '../../styles/images/about-pople.png';
import BrandBelief from './components/BrandBelief';
import BrandVisionCard from './components/BrandVisionCard';
import BrandValueCard from './components/BrandValueCard';
import Mission from '../../styles/images/mission.png';
import Vision from '../../styles/images/vision.png';
import { ButtonFooter, SectionTitle, PageTitle, MetaTag } from '../../components';

const AboutContainer = styled.article``;

const AboutPople = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 140px 0;
  white-space: pre-wrap;
  h3 {
    font-size: ${fontSize(font.$text_4xl)};
    font-weight: 800;
    line-height: 1.5;
    padding-top: 20px;
    span {
      font-size: inherit;
      margin-left: 0.5rem;
      border-bottom: 4px solid ${color.$main500};
    }
  }
  div {
    img {
      width: 100%;
    }
  }
  .mobile-about-image {
    display: none;
  }
  .pc-about-image {
    display: block;
  }
  @media only screen and (max-width: ${media.$sm}) {
    display: block;
    text-align: center;
    h3 {
      font-size: ${fontSize(font.$text_4xl, media.$sm)};
    }
    .mobile-about-image {
      display: block;
    }
    .pc-about-image {
      display: none;
    }
  }
`;

const ValueCardContainer = styled.section`
  background-color: ${color.$fill_300_light};
  width: 100%;
  padding: 10rem 0;
  text-align: center;
  h2 {
    padding-bottom: 60px;
  }
  div.flex-container {
    width: 100%;
    margin: auto;
    min-width: ${media.$wrapper_min_width};
    max-width: ${media.$wrapper_max_width};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    @media only screen and (max-width: ${media.$sm}) {
      display: block;
      min-width: unset;
      max-width: unset;
      padding: 0 24px;
    }
  }
`;

export const AboutPage = (props) => {
  const { t } = useTranslation()
  const { deviceWidth } = useContext(ContentContext);
  const [ourValues, setOurValues] = useState([]);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });

  useEffect(() => {
    setOurValues([...OUR_VALUE_DICT]);
    setMetaObject({
      title: PAGE_TOP.ABOUT.title,
      description: t('POPLE_NET_ZERO_CULTURE'),
      keywords: '팝플이란, 팝플',
      canonical: '',
      url: window.location.href,
    });
  }, []);
  return (
    <>
      <MetaTag props={metaObject} />{' '}
      <AboutContainer className='basic-page'>
        <PageTitle
          title={PAGE_TOP.ABOUT.title}
          description={t('POPLE_NET_ZERO_CULTURE')}
          image={PAGE_TOP.ABOUT.image}
          preview={PAGE_TOP.ABOUT.preview}
        />
        <section className='content__wrapper'>
          <AboutPople>
            <div className='mobile-about-image'>
              <img src={AboutPopleImage} alt='' />
            </div>
            <div>
              <SectionTitle title={t('POPLE_INTRO')}></SectionTitle>
              <h3>
                {t('POPLE_MEANING_DESCRIPTION_01')}
                <span>{t('POPLE_MEANING_DESCRIPTION_SPAN')}</span>
                {t('POPLE_MEANING_DESCRIPTION_02')}
              </h3>
            </div>
            <div className='pc-about-image'>
              <img src={AboutPopleImage} alt='' />
            </div>
          </AboutPople>
        </section>
        <section className='content__wrapper'>
          <SectionTitle title={t('BRAND_BELIEF')}></SectionTitle>
          <BrandBelief />
        </section>
        <section className='content__wrapper'>
          <BrandVisionCard image_left={false} title={t('BRAND_MISSION')} description={t('BRAND_MISSION_DESCRIPTION')} image={Mission} />
          <BrandVisionCard
            image_left={deviceWidth > 640}
            title={t('BRAND_VISION')}
            catchphrase={t('BRAND_VISION_PHRASE')}
            description={t('BRAND_VISION_DESCRIPTION')}
            image={Vision}
          />
        </section>
        <ValueCardContainer className='py-40'>
          <SectionTitle title={t('OUR_VALUE')}></SectionTitle>
          <div className='flex-container'>
            {ourValues &&
              ourValues.map((item, key) => {
                return (
                  <BrandValueCard
                    key={'value-item' + key}
                    title={item.title}
                    description={t(item.desc)}
                    image={item.image}
                  />
                );
              })}
          </div>
        </ValueCardContainer>
        <ButtonFooter></ButtonFooter>
      </AboutContainer>
    </>
  );
};
