import React from 'react';
import { useHistory } from 'react-router-dom';

const renderTypeText = (value) => {
  switch (value) {
    case 'Member': {
      return '회원 가입/탈퇴';
    }
    case 'Methodology': {
      return '방법론';
    }
    case 'Project': {
      return '사업 관리';
    }
    case 'ReductionCert': {
      return '감축량 인증';
    }
    case 'ZCCredit': {
      return 'ZC크레딧';
    }
    case 'Etc': {
      return '기타';
    }
    default: {
      return value;
    }
  }
};

const renderStatusText = (value) => {
  switch (value) {
    case 'Inquiry': {
      return '답변대기';
    }
    case 'Complete': {
      return '답변완료';
    }
    default: {
      return value;
    }
  }
};

export const BoardInquiry = (props) => {
  const history = useHistory();
  const { data } = props;
  if (!data) {
    return null;
  }

  console.log('data', data);

  return (
    <article className='board-inquiry'>
      <section className='title__box'>
        <div className='text__box'>
          <em>[{renderTypeText(data.type)}]</em>
          <span>{data.title}</span>
        </div>
        <div className='status__box'>
          <em>{renderStatusText(data.status)}</em>
          <span>{data.inquiryDate}</span>
        </div>
      </section>
      {data.files && data.files.length > 0 && (
        <section className='file__box'>
          <header>
            <span>첨부파일</span>
          </header>
          <div className='file-list__box'>
            <ul>
              {data.files.map((item, index) => {
                const fileName = item ? item.split('/')[item.split('/').length - 1] : '';
                return (
                  <li>
                    <span
                      onClick={() => {
                        window.open(item, '_blank');
                      }}
                    >
                      {fileName}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      )}

      <section className='content__box'>
        <p>{data.content}</p>
      </section>
      <section className='reply__box'>
        <header>
          <span>답변</span>
        </header>
        <div className='content__box'>
          <p>{data.answerContent ? data.answerContent : '답변대기'}</p>
        </div>
      </section>
    </article>
  );
};
