import React, { useContext, useState } from 'react';
import { TextInput, Button } from '../../components';
import Handler from '../../Handler';
import { useForm } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import API from '../../api';
import { useHistory } from 'react-router-dom';

export const UserResetPwPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange] = useForm(
    {
      password: '',
    },
    {
      password: {
        key: 'password',
        type: 'password',
        style: {},
        className: 'round',
        required: true,
        placeholder: '영문 대,소문자,숫자,특수문자를 포함한 8~12자리',
        regexp: Handler.REGEXP.PASSWORD,
      },
    },
  );
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const requsetRestPw = () => {
    if (formData.password !== passwordConfirm) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '비밀번호가 일치하지 않습니다.',
      });
      return;
    }
    let targetRegexp = new RegExp(Handler.REGEXP.PASSWORD, 'g');
    const isValidtion = targetRegexp.test(formData.password);
    console.log('isValidtion', isValidtion);

    if (!isValidtion) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '비밀번호를 확인해주세요.',
      });
      return;
    }

    let body = Handler.formToBody(formData, formMeta);
    console.log('requsetLogin', body);

    if (body) {
      console.log('body', body);

      API.User.ModifyPassword(body).then(
        (res) => {
          let result = res.data;
          console.log('User ModifyPassword result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '비밀번호가 변경되었습니다.',
          });
        },
        (err) => {
          console.log('err.data', err.data);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인해주세요.',
      });
    }
  };

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>비밀번호 변경</h1>
          </header>
        </section>
        <section className='m-headding-text__container'>
          <header>
            <h2>비밀번호 변경</h2>
          </header>
        </section>
        <section className='login-form__container'>
          <div className='guide__text'>
            <span>
              이전과는 다른 <em>새로운 비밀번호</em>를 입력해주세요.
            </span>
          </div>
          <TextInput value={formData.password} onChange={formChange} meta={formMeta.password}></TextInput>
          <TextInput
            value={passwordConfirm}
            onChange={(new_value) => {
              setPasswordConfirm(new_value);
            }}
            meta={{
              key: 'passwordConfirm',
              type: 'password',
              style: { marginTop: 12 },
              className: 'round',
              required: true,
              placeholder: '비밀번호 확인',
              equal: formData.password,
            }}
          ></TextInput>

          <Button
            style={{ width: '100%', marginTop: 28 }}
            onClick={() => {
              requsetRestPw();
            }}
            label='비밀번호 재설정'
          ></Button>
          <Button
            style={{ width: '100%', marginTop: 14 }}
            type='secondary'
            onClick={() => {
              history.push('/');
            }}
            label='메인으로 가기'
          ></Button>
        </section>
      </article>
    </article>
  );
};
