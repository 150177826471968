import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import Greenery from '../../styles/icon/greenery.svg'
import { ContentContext, LangContext } from '../../contexts';
import {IND_TERM_KO, IND_TERM_EN, COMPANY_TERM_KO, COMPANY_TERM_EN, INFO_TERM_KO, INFO_TERM_EN } from '../../constants/term'
import { TermService } from './components';
import { media } from '../../styles/style';
import {
  FOOTER_ROUTE_PATH,
  FAMILY_SITE_LIST,
  COMPANY_INFO,
} from '../../constants';
import Handler from '../../Handler';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  boxSizing: 'border-box',
};

const m_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  boxSizing: 'border-box',
};

const FooterNavContainer = styled.section`
  max-width: calc(1/2);
  width: 100%;
  ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    cursor: pointer;
    span {
      font-size: 18px;
      font-weight: 700;
    }
    @media only screen and (max-width: ${media.$sm}) {
      grid-template-columns: repeat(2, 1fr);
      li {
        margin-bottom: 20px;
      }
    }
  }
  .company-info {
    padding: 60px 0 40px;
    div {
      margin-bottom: 10px;
      span {
        font-size: 14px;
        line-height: 18px;
        color: #555;
        a {
          text-decoration: underline;
        }
      }
    }
    .divider {
      display: block;
      margin: 0 0.3rem;
      @media only screen and (max-width: ${media.$sm}) {
        display: none;
        margin: 0 0.2rem;
      } 
    }
  }
`;

const FooterNavItem = styled.li`
  ul.item-child {
    display: block;
    margin-top: 20px;
    li {
      margin: 10px 0;
      cursor: pointer;
    }
  }
`;

export const MainFooter = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const { deviceWidth } = useContext(ContentContext);
  const { isKorean } = useContext(LangContext);
  const [term, setTerm] = useState('');
  
  const NAV_LIST = FOOTER_ROUTE_PATH;
  const SITE_LIST = FAMILY_SITE_LIST;

  const openModal = (value) => {
    console.log('value ---->', value);
    if (value === 'term_person') setOpen(true);
    if (value === 'term_company') setOpen2(true);
    if (value === 'term_info') setOpen3(true);
  };

  useEffect(() => {
    if (open) isKorean ? setTerm(IND_TERM_KO) : setTerm(IND_TERM_EN)
    if (open2) isKorean ? setTerm(COMPANY_TERM_KO) : setTerm(COMPANY_TERM_EN)
    if (open3) isKorean ? setTerm(INFO_TERM_KO) : setTerm(INFO_TERM_EN)

  }, [open, open2, open3])

  return (
    <article className='main-footer'>
      <FooterNavContainer>
        <ul className=''>
          {NAV_LIST.map((item, index) => {
            return (
              <FooterNavItem key={'navlist' + index}>
                {item.type === 'outer' ? (
                  <span onClick={() => Handler.gotoOuterLink(item.path)}>{t(item.text)}</span>
                ) : (
                  <span>{t(item.text)}</span>
                )}

                <ul className='item-child'>
                  {item.child &&
                    item.child.map((li) => {
                      if (item.text === 'TERM') {
                        return <li key={`list+${li.text}`} onClick={() => openModal(li.value)}>{t(li.text)}</li>;
                      }
                      if (li.outer) {
                        return <li key={`list+${li.text}`} onClick={() => Handler.gotoOuterLink(li.path)}>{t(li.text)}</li>;
                      }
                      return (
                        <li key={`list+${li.text}`}>
                          <Link to={li.path}>{t(li.text)}</Link>
                        </li>
                      );
                    })}
                </ul>
              </FooterNavItem>
            );
          })}
        </ul>
        <div className='company-info'>
          <h3 style={{ marginBottom: '30px', }}>
            <img width={140} src={Greenery} alt="" />
          </h3>
          <div className="flex max-lg:block w-full">
            <p className='text-gray-500 text-sm'>{t('CEO')}: {t('YSH')}</p>
            <p className='text-gray-300 text-sm divider'>|</p>
            <a href='mailto:info@pople.earth' className='text-gray-500 block text-sm'>{t('EMAIL')}: info@pople.earth</a>
            <p className='text-gray-300 text-sm divider'>|</p>
            <a href='tel:02-6274-3600' className='text-gray-500 block text-sm'>{t('CONTACT_PHONE')}: 02-6274-3600</a>
          </div>
          <div className="flex flex-wrap max-lg:block w-full">
            <p className='text-gray-500 text-sm'>{t('BIZ_NUMBER')}: 401-88-02247</p>
            <p className='text-gray-300 text-sm divider'>|</p>
            <p className='text-gray-500 text-sm'>{t('BIZ_SELLER')}: {t('BIZ_SELLER_NUMBER')}</p>
            <p className='text-gray-300 text-sm divider'>|</p>
            <p className='text-gray-500 text-sm'>{t('ADDRESS')}: {t('COMPANY_ADDRESS')}</p>
          </div>
        </div>
      </FooterNavContainer>
      <section className='right__container'>
        <select
          value='none'
          onChange={(e) => {
            console.log('work', e.target.value);
            window.open(e.target.value, '_blank');
          }}
        >
          <option value='none'>{t('FAMILY_SITE')}</option>

          {SITE_LIST.map((item, index) => {
            return (
              <option key={'site-option-' + index} value={item.url}>
                {t(item.text)}
              </option>
            );
          })}
        </select>
      </section>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={deviceWidth > 1136 ? style : m_style}>
          <TermService
            title={term.title}
            term={term.content}
            onClose={() => {
              setOpen(false);
            }}
          />
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={() => {
          setOpen2(false);
        }}
      >
        <Box sx={deviceWidth > 1136 ? style : m_style}>
          <TermService
            title={term.title}
            term={term.content}
            onClose={() => {
              setOpen2(false);
            }}
          />
        </Box>
      </Modal>
      <Modal
        open={open3}
        onClose={() => {
          setOpen3(false);
        }}
      >
        <Box sx={deviceWidth > 1136 ? style : m_style}>
          <TermService
            title={term.title}
            term={term.content}
            onClose={() => {
              setOpen3(false);
            }}
          />
          {/* <TermInfo
            term={TERMS_INFO}
            onClose={() => {
              setOpen3(false);
            }}
          /> */}
        </Box>
      </Modal>
    </article>
  );
};
