import React, { useEffect, useContext, useState } from 'react';
import {
  DatePicker,
  BasicTab,
  Breadcrumbs,
  Button,
  TablePaging,
  MetaTag,
  CheckBox,
  DropdownInput,
  TableCalculate,
  TableWithdraw,
  SelectBox,
} from '../../components';
import { usePagination } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { UserContext, SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import { genMypageRoutes } from '../../constants';
import { MypageNavList } from './components/MypageNavList';

export const CalculatePage = (props) => {
  const history = useHistory();
  const { userInfo, isPersonal } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [stat, setStat] = useState({
    totalSale: 0,
    normalSale: 0,
    forwardSale: 0,
    totalInvest: 0,
    accumulateInvest: 0,
    expectInvest: 0,
    withdrawCash: 0,
  });
  const [list, setList] = useState(null);
  const [list2, setList2] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 4);
  const [totalValue, setTotalValue] = useState(null);
  const [won, setWon] = useState('');
  const [previewWon, setPreviewWon] = useState('');
  const [isAgree, setIsAgree] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [userMenu, setUserMenu] = useState(null);

  //invest filter
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState('Title');

  //withdraw filter
  const [searchDateType, setSearchDateType] = useState('WithdrawDate');
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });
  useEffect(() => {
    setMetaObject({
      title: '마이페이지 - 정산/출금',
      description: '크레딧 정산/출금',
      keywords: '팝플이란, 팝플',
      image: '',
      canonical: '',
      url: window.location.href,
    });
  }, []);
  console.log('userInfo', userInfo);
  console.log('list', list);

  useEffect(() => {
    if (userInfo) {
      setUserMenu(genMypageRoutes(isPersonal));
    }
  }, [userInfo]);

  useEffect(() => {
    setSearch('');
    setSearchType('Title');
    requestNewList(0);
  }, [tabIndex]);

  useEffect(() => {
    if (won) {
      console.log('won', won);
      setPreviewWon(Handler.numberWithCommas(won));
    } else if (won === 0) {
      setPreviewWon('');
    }
  }, [won]);

  useEffect(() => {
    console.log('page effect', page);
    requestNewList(page || 0);
  }, [page]);

  const requestSearch = () => {
    requestNewList(0);
  };

  const requestNewList = (init_page) => {
    API.User.GetCalculateStat().then(
      (res) => {
        let result = res.data;
        console.log('GetCalculateStat result', result);
        setStat(result);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );

    //list 분기처리
    if (tabIndex === 0) {
      let body = {
        page: init_page ? init_page : page,
        limit: 5,
        searchType: 'Title',
        searchValue: search,
      };

      if (startDate) {
        body.startDate = startDate;
      }
      if (endDate) {
        body.endDate = endDate;
      }
      API.User.GetCalculateList(body).then(
        (res) => {
          let result = res.data;
          console.log('GetCalculateList result', result);
          setList(result.content);
          setTotalPage(result.totalPages);
          setTotalCount(result.totalItems);
          setTotalValue(result.totalValue);
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else if (tabIndex === 1) {
      let body = {
        page: init_page ? init_page : page,
        limit: 5,
        searchDateType: searchDateType,
      };

      if (startDate) {
        body.startDate = startDate;
      }
      if (endDate) {
        body.endDate = endDate;
      }
      API.User.GetWithdrawList(body).then(
        (res) => {
          let result = res.data;
          console.log('GetWithdrawList result', result);
          setList2(result.content);
          setTotalPage(result.totalPages);
          setTotalCount(result.totalItems);
          setTotalValue(result.totalValue);
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    }
  };

  const requestWithdraw = () => {
    let message = '';
    if (!isAgree) message = '동의사항을 확인해주세요.';
    if (won <= 0) message = '출금금액을 확인해주세요.';
    if (message) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: message,
      });
      return;
    }

    API.User.PostWithdraw({
      withdraw: won,
    }).then(
      (res) => {
        let result = res.data;
        console.log('PostWithdraw result', result);
        requestNewList(0);
        updateSnackbar({
          type: 'success',
          open: true,
          message: '출금요청 성공',
        });
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  /*   if (!stat) {
    return null;
  } */

  const renderTabs = (stat) => {
    if (tabIndex === 0) {
      return (
        <>
          <section className='calculate-filter__box'>
            <div className='date__box'>
              <DatePicker
                value={startDate}
                meta={{
                  style: {
                    maxWidth: '180px',
                  },
                  className: 'round',
                }}
                onChange={(e) => {
                  console.log('e', e);
                  setStartDate(e);
                }}
              ></DatePicker>
              <span style={{ margin: '0px 5px' }}>~</span>
              <DatePicker
                value={endDate}
                meta={{
                  style: {
                    maxWidth: '180px',
                  },
                  className: 'round',
                }}
                onChange={(e) => {
                  console.log('e', e);
                  setEndDate(e);
                }}
              ></DatePicker>
            </div>

            <div className='input__box'>
              <DropdownInput
                value={search}
                menuValue={searchType}
                onChange={(value, key) => {
                  setSearch(value);
                }}
                onMenuChange={(value, key) => {
                  console.log('menu', value);
                  setSearchType(value);
                }}
                onEnter={() => {
                  requestSearch();
                }}
                meta={{
                  style: { width: '468px' },
                  placeholder: '검색어',
                  menus: [
                    {
                      value: 'Title',
                      label: '사업명',
                    },
                    {
                      value: 'Id',
                      label: '사업ID',
                    },
                    {
                      value: 'Name',
                      label: '판매자명',
                    },
                  ],
                }}
              ></DropdownInput>
              <Button
                style={{ width: '80px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  requestSearch();
                }}
                label='검색'
              ></Button>
            </div>
          </section>
          <section className='calculate-summary__box'>
            <span className='desc'>
              - 사업별 정산 금액이 궁금하신가요? ‘사업ID’로 검색하시면 총 건수와 금액을 확인할 수 있습니다.
            </span>
            <div className='summary'>
              <span>
                검색 결과 총 <em>{totalCount}</em> 건
              </span>
              <span>
                <em>{Handler.numberWithCommas(totalValue)}</em> 원
              </span>
            </div>
          </section>
          <TableCalculate data={list}></TableCalculate>
        </>
      );
    }
    return (
      <>
        <section className='calculate-filter__box'>
          <div className='date__box'>
            <SelectBox
              value={searchDateType}
              onChange={(value) => {
                setSearchDateType(value);
              }}
              meta={{
                key: 'searchDateType',
                style: {
                  width: 165,
                },
                type: 'round',
                menus: [
                  {
                    label: '출금요청일',
                    value: 'WithdrawDate',
                  },
                  {
                    label: '입금일',
                    value: 'DepositDate',
                  },
                ],
              }}
            ></SelectBox>

            <DatePicker
              value={startDate}
              meta={{
                style: {
                  maxWidth: '180px',
                },
                className: 'round',
              }}
              onChange={(e) => {
                console.log('e', e);
                setStartDate(e);
              }}
            ></DatePicker>
            <span style={{ margin: '0px 5px' }}>~</span>
            <DatePicker
              value={endDate}
              meta={{
                style: {
                  maxWidth: '180px',
                },
                className: 'round',
              }}
              onChange={(e) => {
                console.log('e', e);
                setEndDate(e);
              }}
            ></DatePicker>
          </div>

          <div className='input__box'>
            <SelectBox
              value={searchDateType}
              onChange={(value) => {
                setSearchDateType(value);
              }}
              meta={{
                key: 'searchDateType',
                style: {
                  width: 165,
                },
                type: 'round',
                menus: [
                  {
                    label: '출금요청일',
                    value: 'WithdrawDate',
                  },
                  {
                    label: '입금일',
                    value: 'DepositDate',
                  },
                ],
              }}
            ></SelectBox>
            <Button
              style={{ width: '80px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                requestSearch();
              }}
              label='검색'
            ></Button>
          </div>
        </section>
        <section className='calculate-summary__box'>
          <span className='desc'></span>
          <div className='summary'>
            <span>
              검색 결과 총 <em>{totalCount}</em> 건
            </span>
            <span>
              <em>{Handler.numberWithCommas(totalValue)}</em> 원
            </span>
          </div>
        </section>
        <TableWithdraw data={list2}></TableWithdraw>
      </>
    );
  };

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <article className='content__wrapper'>
          <MypageNavList userMenu={userMenu} />
          <section className='headding-text__container'>
            <header>
              <h1>{isPersonal ? '투자/출금' : '정산/출금'}</h1>
            </header>
            <div className='right__box'>
              <Breadcrumbs type={'small'}></Breadcrumbs>
            </div>
          </section>

          <section className='calculate-stat__container'>
            <div className='current__box'>
              <section className='total__box'>
                <header>
                  <h3>{isPersonal ? '총 투자 금액' : '총 정산 금액'}</h3>
                  <span>(누적)</span>
                </header>
                <div className='price'>
                  <h1>
                    {isPersonal
                      ? Handler.numberWithCommas(stat?.totalInvest || 0)
                      : Handler.numberWithCommas(stat?.totalSale || 0)}{' '}
                    원
                  </h1>
                </div>
              </section>
              <section className='accumulate__box'>
                <header>
                  {isPersonal ? (
                    <h3>
                      누적 수익 금액
                      <span>(원금+누적)</span>
                    </h3>
                  ) : (
                    <h3>일반거래</h3>
                  )}
                </header>
                <div className='price'>
                  <h3>
                    {isPersonal
                      ? Handler.numberWithCommas(stat?.accumulateInvest || 0)
                      : Handler.numberWithCommas(stat?.normalSale || 0)}{' '}
                    원
                  </h3>
                </div>
              </section>
              <section className='expect__box'>
                <header>
                  {isPersonal ? (
                    <h3>
                      향후 예상 수익
                      <span>(원금+누적)</span>
                    </h3>
                  ) : (
                    <h3>선도거래</h3>
                  )}
                </header>
                <div className='price'>
                  <h3>
                    {isPersonal
                      ? Handler.numberWithCommas(stat?.expectInvest || 0)
                      : Handler.numberWithCommas(stat?.forwardSale || 0)}{' '}
                    원
                  </h3>
                </div>
              </section>
              <section className='guide__box'>
                <header>안내사항</header>
                <span>
                  {isPersonal
                    ? '* 향후 예상 수익은 크레딧 판매 시 책정되는 금액입니다.'
                    : '- 정산금액은 사업 크레딧의 판매금액에서 POPLE 수수료(5%)가 차감된 금액입니다.'}
                </span>
              </section>
            </div>
            <div className='withdraw__box'>
              <section className='total__box'>
                <header>
                  <h3>출금 가능</h3>
                </header>
                <div className='price'>
                  <h1>{Handler.numberWithCommas(stat?.withdrawCash || 0)}원</h1>
                </div>
              </section>
              <section className='won__box'>
                <header>
                  <h3>출금 금액</h3>
                </header>
                <div className='input__box'>
                  <input
                    value={previewWon}
                    onChange={(e) => {
                      let new_value = e.target.value;
                      new_value = Number(new_value.replaceAll(',', ''));

                      console.log('new_value', new_value);
                      if (Number.isInteger(new_value)) {
                        setWon(new_value);
                      }
                    }}
                  ></input>
                  <em>원</em>
                </div>
              </section>
              <section className='caution__box'>
                <header>출금 시 주의사항</header>
                <span>- 출금 신청 후 다음 영업일 15시부터 순차적으로 처리됩니다.</span>
                <span>- 10,000원 이상부터 출금 요청이 가능합니다. (출금 요청 시 출금 수수료는 무료입니다.)</span>
                <span>
                  - 해외송금에서 발생하는 수수료에는 송금은행의 송금수수료, 전신료, 결제은행수수료, 입금은행 취급수수료
                  등이 포함됩니다.
                </span>
                <div className='agree__box'>
                  <CheckBox
                    meta={{
                      label: `출금 시 동의사항’에 동의합니다.`,
                      style: {},
                      className: 'square',
                    }}
                    value={isAgree}
                    onChange={(new_value, key) => {
                      console.log('isPresent', new_value);
                      setIsAgree(new_value);
                    }}
                  ></CheckBox>
                </div>
              </section>
              <section className='button__box'>
                <div
                  className='account-button'
                  onClick={() => {
                    history.push('/mypage/modify');
                  }}
                >
                  계좌확인
                </div>
                <div className='withdraw-button' onClick={requestWithdraw}>
                  출금요청
                </div>
              </section>
            </div>
          </section>
          {/* list */}
          <div style={{ height: 40 }}></div>
          <BasicTab
            index={tabIndex}
            meta={{
              menus: ['투자내역', '출금내역'],
            }}
            onChange={(value) => {
              setTabIndex(value);
            }}
          ></BasicTab>
          {renderTabs(stat)}
          <div style={{ height: 40 }}></div>
          <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
        </article>
      </article>
    </>
  );
};
