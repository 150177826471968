import React from 'react';
import { ChartContainer, ChartHeader, ChartItem, ChartWrapper } from '../../../styles/ComponentStyle';
import ChartPrice from './ChartPrice';
import ChartTitle from './ChartTitle';
import ChartTitleSkeleton from './ChartTitleSkeleton';

export const CarbonPrice = (props) => {
  const { charts = [], loading, length = 3, multi = false, title = '제목입니다.', content = ['내용입니다.'] } = props;

  return (
    <ChartContainer>
      <ChartHeader>
        <h2 className='font-bold'>{title}</h2>
        {content.map((el, idx) => (
          <p key={idx + 'p'}>{el}</p>
        ))}
      </ChartHeader>
      <ChartWrapper>
        {loading
          ? Array(length)
              .fill()
              .map((_, idx) => <ChartTitleSkeleton key={`chart-key-${idx}`} />)
          : charts.map((el, idx) => {
              if (el === null) return <ChartTitleSkeleton key={`chart-key-${idx}`} />;
              return (
                <ChartItem key={idx + 'chart-item'}>
                  <h2>{el.config.chart_name}</h2>
                  <ChartTitle
                    date={el.config.date}
                    rate={el.config.change_rate}
                    currency={el.config.currency}
                    change={el.config.change}
                    head={el.config.currency !== 'KRW'}
                    price={el.data[el.data.length - 1].value}
                  ></ChartTitle>
                  <ChartPrice data={el.data} width={'100%'} height={'400px'} />
                </ChartItem>
              );
            })}
      </ChartWrapper>
    </ChartContainer>
  );
};

export default CarbonPrice;