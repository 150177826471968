import React, { useState } from 'react';
import { MarginTopContext } from '../contexts';

export const MarginTopProvider = (props) => {
  const { children } = props;
  const [transparent, setTransparent] = useState(true);

  const updateTransparent = (path) => {
    if (
      path &&
      (path.includes('/detail') || path.includes('/cs') || path.includes('/complete') || path.includes('/mypage'))
    ) {
      setTransparent(false);
    } else {
      setTransparent(true);
    }
  };

  return (
    <MarginTopContext.Provider
      value={{
        transparent: transparent,
        updateTransparent: updateTransparent,
      }}
    >
      {children}
    </MarginTopContext.Provider>
  );
};
