import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import ButtonFooterBg from '../../styles/images/button_footer_bg.png';
import PurpleArrow from '../../styles/icon/purple_arrow.svg';
import CertIcon from '../../styles/icon/cert.png';
import CreditIcon from '../../styles/icon/reduction.png';

import { color, radius, media, font, fontSize } from '../../styles/style';

const ButtonFooterWrapper = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
  background-image: url(${ButtonFooterBg});
  background-size: cover;
  .content__wrapper {
    h3 {
      color: #fff;
      font-size: ${fontSize(font.$text_5xl)};
      padding-bottom: 80px;
    }
    @media only screen and (max-width: ${media.$sm}) {
      h3 {
        font-size: ${fontSize(font.$text_5xl, media.$sm)};
        padding: 30px;
        text-align: center;
        white-space: initial;
        word-break: keep-all;
      }
    }
  }
`;

const ButtonCardContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: ${media.$wrapper_max_width};
  min-width: ${media.$wrapper_min_width};
  margin: auto;
  @media only screen and (max-width: ${media.$sm}) {
    display: block;
    width: 100%;
    max-width: unset;
    min-width: unset;
    padding: 0 24px;
  }
`;

const ButtonCard = styled.div`
  border-radius: ${radius.r5};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  box-sizing: content-box;
  padding: 40px;
  height: 140px;
  .button-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    h3 {
      color: ${color.$black_300};
      font-size: 1.5rem;
      padding-bottom: 30px;
    }
    .button-arrow {
      padding: 0.8rem 1.2rem;
      cursor: pointer;
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* border: 1px solid ${color.$main500}; */
      background: ${color.$main["200"]};
      border-radius: ${radius.r6};
      span {
        color: ${color.$main500};
        margin-left: 0.5rem;
        font-weight: ${font.bold};
      }
    }
  }
  .m-icon {
    display: none;
  }
  .icon {
    padding: 20px 0;
    display: block;
    max-width: 150px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    .button-title {
      /* display: block; */
      align-items: center;
      .button-arrow {
        width: 160px;
        margin: 20px 0 0;
      }
    }
    .m-icon {
      width: 100px;
      margin: auto;
      display: block;
      img {
        width: 100%;
      }
    }
    .icon {
      display: none;
    }
  }
`;

export const ButtonFooter = (props) => {
  const history = useHistory();
  const { t } = useTranslation()
  return (
    <ButtonFooterWrapper className='join-footer'>
      <section className='flex flex-col w-full items-center justify-center text-center' data-aos='fade-up'>
        <h3 className='text-white text-4xl font-bold pb-12'>{t('JOIN_CARBON_NEUTRAL_CULTURE')}</h3>
      </section>
      <ButtonCardContainer className=''>
        <ButtonCard data-aos='fade-up'>
          <div className='button-title'>
            <h3 className='text-2xl font-bold'>{t('APPLY_CARBON_REDUCTION_PROJECT_CERT')}</h3>
            <div className='m-icon'>
              <img src={CertIcon} alt='인증서아이콘' />
            </div>
            <div
              className='button-arrow'
              onClick={() => {
                window.open('https://www.pople.credit/');
              }}
            >
              <span>{t('GOTO')}</span>
              <img src={PurpleArrow} alt='오른쪽화살표' />
            </div>
          </div>
          <div className='icon'>
            <img src={CertIcon} alt='감축아이콘' />
          </div>
        </ButtonCard>
        <ButtonCard data-aos='fade-up'>
          <div className='button-title'>
            <h3 className='text-2xl text-center font-bold'>{t('BUY_CARBON_CREDIT')}</h3>
            <div className='m-icon'>
              <img src={CreditIcon} alt='인증서아이콘' />
            </div>
            <div
              className='button-arrow'
              onClick={() => {
                history.push('/marketplace/list');
              }}
            >
              <span>{t('GOTO')}</span>
              <img src={PurpleArrow} alt='오른쪽화살표' />
            </div>
          </div>
          <div className='icon'>
            <img src={CreditIcon} alt='탄소크레딧' />
          </div>
        </ButtonCard>
      </ButtonCardContainer>
    </ButtonFooterWrapper>
  );
};
