import React from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

export const MarketplacePurchaseClosePage = (props) => {
  const history = useHistory();
  const searchObject = queryString.parse(history.location.search);

  console.log('searchObject', searchObject);
  return <article className='basic-page'>MarketplacePurchaseClosePage</article>;
};
