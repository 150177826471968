import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TextInput, Button, Stepper, CheckBox, FileUploader, AddressLocation, MetaTag } from '../../components';
import { useForm } from '../../hooks';
import {
  IND_TERM_KO,
  IND_TERM_EN,
  COMPANY_TERM_KO,
  COMPANY_TERM_EN,
  INFO_TERM_KO,
  INFO_TERM_EN,
} from '../../constants/term';
import _ from 'lodash';
import Handler from '../../Handler';
import { SnackbarContext, LangContext } from '../../contexts';
import API from '../../api';
import queryString from 'query-string';
import { useLabel } from '../../hooks';

export const UserSignUpPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { SIGN_UP_TERM, ERROR, MSG } = useLabel();
  const { isKorean } = useContext(LangContext);
  const searchObject = queryString.parse(history.location.search);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [step, setStep] = useState(0);
  const [termsChecks, setTermsChecks] = useState([false, false, false]);
  const [termsAllCheck, setTermsAllCheck] = useState(false);
  const [formData, formMeta, formChange] = useForm(
    {
      email: '',
      password: '',
      name: '',
      phone: '',
      companyName: '',
      ceoName: '',
      profileImage: '',
      businessNumber: '',
      businessFile: '',
      address: {
        addr1: '',
        addr2: '',
      },
      site: '',
      fax: '',
    },
    {
      email: {
        key: 'email',
        label: t('EMAIL'),
        style: {},
        className: 'point',
        required: true,
        regexp: Handler.REGEXP.EMAIL,
        regexpMsg: ERROR.EMAIL,
        helperText: t('SIGN_UP_EMAIL_GUIDE'),
      },
      password: {
        key: 'password',
        label: t('PASSWORD'),
        type: 'password',
        style: {},
        className: 'point',
        required: true,
        placeholder: t('PASSWORD_PLACEHOLDER'),
        regexp: Handler.REGEXP.PASSWORD,
      },
      name: {
        key: 'name',
        label: searchObject.type === 'personal' ? t('NAME') : t('RESPONSIBILITY'),
        style: {},
        className: 'point',
        required: true,
      },
      phone: {
        key: 'phone',
        label: t('MOBILE'),
        style: {},
        className: 'point',
        type: 'tel',
        regexp: Handler.REGEXP.MOBILE,
        regexpMsg: ERROR.PHONE,
        maxlength: '11',
        required: true,
      },
      companyName: {
        key: 'companyName',
        label: t('COMPANY_NAME'),
        style: {},
        className: 'point',
        required: true,
      },
      ceoName: {
        key: 'ceoName',
        label: t('CEO_NAME'),
        style: {},
        className: 'point',
        required: true,
      },
      businessNumber: {
        key: 'businessNumber',
        label: t('BIZ_NUMBER'),
        style: {},
        className: 'point',
        regexp: Handler.REGEXP.BIZ_NUMBER,
        regexpMsg: ERROR.BIZ_NUMBER,
        maxlength: '10',
        required: true,
      },
      profileImage: {
        type: 'image',
        key: 'profileImage',
        label: t('PROFILE_IMAGE'),
        path: 'signup/profile_image',
        className: 'point',
        helperText: t('PROFILE_IMAGE_GUIDE'),
      },
      businessFile: {
        type: 'file',
        key: 'businessFile',
        label: t('BIZ_CERT'),
        path: 'signup/business_file',
        required: true,
        className: 'point',
      },
      address: {
        required: true,
        className: 'point',
        key: 'address',
        label: t('ADDRESS'),
        type: 'mypage',
      },
      site: {
        key: 'site',
        label: t('HOMEPAGE'),
        style: {},
        className: 'point',
        required: false,
      },
      fax: {
        key: 'fax',
        label: t('FAX'),
        style: {},
        className: 'point',
        required: false,
      },
    },
  );
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [signUpResult, setSignUpResult] = useState(null);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });

  useEffect(() => {
    if (termsChecks) {
      const is_all_true = termsChecks.every((e) => e);
      setTermsAllCheck(is_all_true);
    }
  }, [termsChecks]);

  useEffect(() => {
    document.body.scrollTop = 0;
  }, [step]);

  const onChangeStep = (new_value) => {
    setStep(new_value);
  };

  const onChangeTermsCheck = (new_value, index) => {
    let new_termsChecks = _.clone(termsChecks);
    new_termsChecks[index] = new_value;
    setTermsChecks(new_termsChecks);
  };

  const formValidation = (target) => {
    let message = '';
    if (['email', 'password', 'name', 'phone'].includes(target)) {
      message = ERROR[target.toUpperCase()];
    } else if (target === 'companyName') {
      message = ERROR['COMPANY_NAME'];
    } else if (target === 'ceoName') {
      message = ERROR['CEO_NAME'];
    } else if (target === 'businessNumber') {
      message = ERROR['BIZ_NUMBER'];
    } else if (target === 'businessFile') {
      message = ERROR['BIZ_CERT'];
    }
    updateSnackbar({
      type: 'error',
      open: true,
      message: message,
    });
    return;
  };

  const requestSignup = () => {
    if (formData.password !== passwordConfirm) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: ERROR['PASSWORD'],
      });
      return;
    }

    const PERSONAL_VALIDATION_LIST = ['email', 'name', 'phone', 'password'];
    const TARGET_VALIDATION_LIST =
      searchObject.type === 'company'
        ? [...PERSONAL_VALIDATION_LIST, 'companyName', 'ceoName', 'businessNumber', 'businessFile', 'address']
        : PERSONAL_VALIDATION_LIST;

    if (searchObject.type === 'company') {
      if (!formData.address.addr1) {
        updateSnackbar({
          type: 'error',
          open: true,
          message: ERROR['ADDRESS'],
        });
        return;
      }
    }

    let isValidation = Handler.formValidation(formData, TARGET_VALIDATION_LIST);
    if (!isValidation) {
      let TARGET_KEY = Handler.formToBodyKey(formData, TARGET_VALIDATION_LIST);
      formValidation(TARGET_KEY[0]);
    } else {
      let payload = {
        ...formData,
        address: JSON.stringify(formData.address),
      };
      let REQUEST_API = API.User.SignupEnterprise;
      if (searchObject.type === 'personal') {
        REQUEST_API = API.User.SignupPersonal;
        payload = {
          email: formData.email,
          password: formData.password,
          name: formData.name,
          phone: formData.phone,
        };
      }

      payload.profileImage = payload.profileImage || Handler.GET_DEFAULT_IMAGE(searchObject.type);
      payload.isMarketingAgree = termsChecks[2];

      REQUEST_API(payload)
        .then((res) => {
          let result = res.data;
          setStep(2);
          setSignUpResult(result);
        })
        .catch((err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        });
    }
  };

  const renderFormStep = () => {
    if (searchObject.type === 'company') {
      return (
        <>
          <section className='user-form__container'>
            <header>
              <h2>{t('ACCOUNT_INFO')}</h2>
              <em>
                <div className='ico required'></div> {t('REQUIRED')}
              </em>
            </header>
            <ul className='form__list'>
              <TextInput value={formData.email} onChange={formChange} meta={formMeta.email}></TextInput>
              <TextInput value={formData.password} onChange={formChange} meta={formMeta.password}></TextInput>
              <TextInput
                value={passwordConfirm}
                onChange={(new_value) => {
                  setPasswordConfirm(new_value);
                }}
                meta={{
                  key: 'passwordConfirm',
                  type: 'password',
                  style: { marginTop: 12 },
                  className: 'point',
                  required: true,
                  placeholder: t('PASSWORD_CONFIRM'),
                  equal: formData.password,
                }}
              ></TextInput>
              <TextInput value={formData.name} onChange={formChange} meta={formMeta.name}></TextInput>
              <TextInput value={formData.phone} onChange={formChange} meta={formMeta.phone}></TextInput>
            </ul>
          </section>
          <section className='user-form__container'>
            <header>
              <h2>{t('COMPANY_PROFILE')}</h2>
              <em>
                <div className='ico required'></div> {t('REQUIRED')}
              </em>
            </header>
            <ul className='form__list'>
              <TextInput value={formData.companyName} onChange={formChange} meta={formMeta.companyName}></TextInput>
              <TextInput value={formData.ceoName} onChange={formChange} meta={formMeta.ceoName}></TextInput>
              <TextInput
                value={formData.businessNumber}
                onChange={formChange}
                meta={formMeta.businessNumber}
              ></TextInput>
              <FileUploader
                value={formData.businessFile}
                onChange={formChange}
                meta={formMeta.businessFile}
              ></FileUploader>
              <FileUploader
                value={formData.profileImage}
                onChange={formChange}
                meta={formMeta.profileImage}
              ></FileUploader>
              <AddressLocation value={formData.address} onChange={formChange} meta={formMeta.address}></AddressLocation>
              <TextInput value={formData.site} onChange={formChange} meta={formMeta.site}></TextInput>
              <TextInput
                value={formData.fax}
                onChange={(value, key) => {
                  const reg = new RegExp('^[0-9]{0,11}$');
                  const isValidation = reg.test(value);
                  if (isValidation) {
                    formChange(value, key);
                  }
                }}
                meta={formMeta.fax}
              ></TextInput>
            </ul>
          </section>
          <section className='button__container'>
            <Button
              style={{ width: '180px', marginRight: 24 }}
              onClick={() => {
                setStep(0);
              }}
              label={t('PREV')}
              type='secondary'
            ></Button>
            <Button
              style={{ width: '180px' }}
              onClick={() => {
                requestSignup();
              }}
              label={t('NEXT')}
            ></Button>
          </section>
        </>
      );
    }
    return (
      <>
        <section className='user-form__container'>
          <header>
            <h2>{t('ACCOUNT_INFO')}</h2>
            <em>
              <div className='ico required'></div> {t('REQUIRED')}
            </em>
          </header>
          <ul className='form__list'>
            <FileUploader
              value={formData.profileImage}
              onChange={formChange}
              meta={formMeta.profileImage}
            ></FileUploader>

            <TextInput value={formData.email} onChange={formChange} meta={formMeta.email}></TextInput>
            <TextInput value={formData.name} onChange={formChange} meta={formMeta.name}></TextInput>
            <TextInput value={formData.phone} onChange={formChange} meta={formMeta.phone}></TextInput>
            <TextInput value={formData.password} onChange={formChange} meta={formMeta.password}></TextInput>
            <TextInput
              value={passwordConfirm}
              onChange={(new_value) => {
                setPasswordConfirm(new_value);
              }}
              meta={{
                key: 'passwordConfirm',
                type: 'password',
                style: { marginTop: 12 },
                className: 'point',
                required: true,
                placeholder: t('PASSWORD_CONFIRM'),
                equal: formData.password,
              }}
            ></TextInput>
          </ul>
        </section>
        <section className='button__container'>
          <Button
            style={{ width: '180px', marginRight: 24 }}
            onClick={() => {
              setStep(0);
            }}
            label={t('PREV')}
            type='secondary'
          ></Button>
          <Button
            style={{ width: '180px' }}
            onClick={() => {
              requestSignup();
            }}
            label={t('NEXT')}
          ></Button>
        </section>
      </>
    );
  };

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <article className='content__wrapper'>
          <section className='headding-text__container'>
            <header>
              <h1>{t('SIGN_UP')}</h1>
            </header>
          </section>
          <section className='m-headding-text__container'>
            <header>
              <h2>{t('SIGN_UP')}</h2>
            </header>
          </section>
          <section className='user-stepper__container'>
            <Stepper
              meta={{}}
              value={step}
              label={[t('USE_TERM'), t('ACCOUNT_INFO'), t('VERIFY_EMAIL')]}
              onChange={onChangeStep}
            ></Stepper>
          </section>
          {step === 0 && (
            <section className='user-form__container'>
              <header>
                <h2>
                  {t('MARKETPLACE')} {t('SIGN_UP')}
                </h2>
              </header>
              <ul className='terms__list'>
                <li>
                  <header>
                    <CheckBox
                      value={termsChecks[0]}
                      onChange={(new_value) => {
                        onChangeTermsCheck(new_value, 0);
                      }}
                      meta={{
                        required: true,
                        label: SIGN_UP_TERM['PRIVACY'],
                        subLabel: `(*${t('REQUIRED')})`,
                        className: 'terms',
                      }}
                    ></CheckBox>
                  </header>
                  <div className='text__viewer'>
                    <div className='online-terms__box'>
                      <div
                        dangerouslySetInnerHTML={{ __html: isKorean ? INFO_TERM_KO.content : INFO_TERM_EN.content }}
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <header>
                    <CheckBox
                      value={termsChecks[1]}
                      onChange={(new_value) => {
                        onChangeTermsCheck(new_value, 1);
                      }}
                      meta={{
                        required: true,
                        label: SIGN_UP_TERM['CREDIT'],
                        subLabel: `(*${t('REQUIRED')})`,
                        className: 'terms',
                      }}
                    ></CheckBox>
                  </header>
                  <div className='text__viewer'>
                    {searchObject.type === 'personal' && (
                      <div className='online-terms__box'>
                        <div
                          dangerouslySetInnerHTML={{ __html: isKorean ? IND_TERM_KO.content : IND_TERM_EN.content }}
                        />
                      </div>
                    )}
                    {searchObject.type === 'company' && (
                      <div className='online-terms__box'>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: isKorean ? COMPANY_TERM_KO.content : COMPANY_TERM_EN.content,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </li>
                <li>
                  <header>
                    <CheckBox
                      value={termsChecks[2]}
                      onChange={(new_value) => {
                        onChangeTermsCheck(new_value, 2);
                      }}
                      meta={{
                        label: '마케팅 활용 및 광고성 정보 수신에 동의합니다.',
                        subLabel: '(선택)',
                        className: 'terms',
                      }}
                    ></CheckBox>
                  </header>
                </li>
              </ul>
              <section style={{ display: 'flex', marginTop: 32, marginBottom: 32, justifyContent: 'center' }}>
                <CheckBox
                  value={termsAllCheck}
                  onChange={(new_value) => {
                    if (new_value) {
                      setTermsChecks([true, true, true]);
                    } else {
                      setTermsChecks([false, false, false]);
                    }
                    setTermsAllCheck(new_value);
                  }}
                  meta={{
                    label: t('AGREE_ALL'),
                    className: 'terms',
                  }}
                ></CheckBox>
              </section>
              <section className='button__container'>
                <Button
                  style={{ width: '180px', marginRight: 24 }}
                  onClick={() => {
                    history.push('/user/signup_guide');
                  }}
                  label={t('PREV')}
                  type='secondary'
                ></Button>
                <Button
                  style={{ width: '180px' }}
                  onClick={() => {
                    if (termsChecks[0] && termsChecks[1]) {
                      setStep(1);
                    } else {
                      updateSnackbar({
                        type: 'error',
                        open: true,
                        message: ERROR['REQUIRED_TERM'],
                      });
                    }
                  }}
                  label={t('NEXT')}
                ></Button>
              </section>
            </section>
          )}
          {step === 1 && renderFormStep()}
          {step === 2 && (
            <>
              <section className='user-email__container'>
                <section className='mail__box'>
                  <div className='image'></div>
                  <h1>{MSG['EMAIL_SEND']}</h1>
                </section>
                <section className='value__box'>
                  <h2>{formData.email}</h2>
                </section>
                <section className='guide__box'>
                  <span>{MSG['EMAIL_SEND_SIGN_UP']}</span>
                </section>
                <section className='info__box'>
                  <ul>
                    <li>{MSG['CHECK_SPAM_MAIL']}</li>
                    <li>
                      <em
                        onClick={() => {
                          API.User.PutSignupEmailResend({ key: signUpResult.key }).then(
                            (res) => {
                              updateSnackbar({
                                type: 'success',
                                open: true,
                                message: MSG['MAIL_RESEND_SUCCESS'],
                              });
                            },
                            (err) => {
                              updateSnackbar({
                                type: 'error',
                                open: true,
                                message: err.data.message,
                              });
                            },
                          );
                        }}
                      >
                        {MSG['MAIL_RESEND']}{' '}
                      </em>{' '}
                    </li>
                  </ul>
                </section>
              </section>
            </>
          )}
        </article>
      </article>
    </>
  );
};
