import React from 'react';
import styled from '@emotion/styled';
import { color, font } from '../../styles/style';

const SectionTitleWrapper = styled.article`
  h2 {
    color: ${color.$main500};
    font-size: ${font.$title_04};
    white-space: pre-wrap;
  }
`;
export const SectionTitle = (props) => {
  const { title, style } = props;

  return (
    <SectionTitleWrapper className={''} style={{ ...style }}>
      <h2 className='font-bold'>{title}</h2>
    </SectionTitleWrapper>
  );
};

// export default SectionTitle;
