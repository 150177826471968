import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export const Button = (props) => {
  const { label, onClick, style, radius, type, loading, disabled, ico } = props;

  const renderClassname = () => {
    if (type) {
      return type + '-button';
    } else {
      return 'basic-button';
    }
  };

  return (
    <article className={renderClassname()} style={{ ...style }}>
      <button disabled={loading || disabled} onClick={onClick} style={{ borderRadius: radius }}>
        {!loading && ico && <div className={'ico ' + ico} style={{ marginRight: 8 }}></div>}
        {loading ? <CircularProgress size={14}></CircularProgress> : label}
      </button>
    </article>
  );
};
