import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LoginHeader, MainFooter } from '../components';

export const LoginLayout = (props) => {
  const { children } = props;
  const { pathname } = useLocation();

  useEffect(() => {
    document.body.scrollTop = 0;
  }, [pathname]);

  return (
    <div className='login-layout'>
      <section className='header__box'>
        <LoginHeader></LoginHeader>
      </section>
      <section className='content__box'>{children}</section>
      <section className='footer__box'>
        <MainFooter></MainFooter>
      </section>
    </div>
  );
};
