import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export const ChartCardSkeleton = (props) => {
  return (
    <>
      <div className='price-wrapper'>
        <div className='card-header'>
          <div className='title'>
            <Skeleton width={160} height={40} />
            <Skeleton width={80} />
          </div>
          <div className='icon'>
            <Skeleton variant='circular' width={32} height={32} />
          </div>
        </div>
      </div>
      <Skeleton width={72} height={28} />
      <div className='price'>
        <Skeleton width={100} height={36} />
      </div>
    </>
  );
};

export default ChartCardSkeleton;
