import React from 'react';

export const TermService = ({ title, term, onClose }) => {

  return (
    <>
      <article className='contact-privacy__modal'>
        <header>
          <div className='ico logo-small'></div>
          <div className='ico close' onClick={onClose}></div>
        </header>
        <div className='title__box'>
          <h2>{title}</h2>
        </div>
        <div className="content__box w-full">
          <div className='w-full' dangerouslySetInnerHTML={{ __html: term }}></div> 
        </div>
        {/* <div className='content__box'>
          {terms &&
            terms.map((item) => {
              return (
                <div>
                  <h3>{item.main}</h3>
                  {item.childs &&
                    item.childs.map((child) => {
                      return (
                        <div>
                          {child.sub && <h4>{child.sub}</h4>}
                          {child.list &&
                            child.list.map((li) => {
                              return <p>{li}</p>;
                            })}
                        </div>
                      );
                    })}
                </div>
              );
            })}
        </div> */}
      </article>
    </>
  );
};
