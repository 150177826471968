import React, { useRef, useEffect, useState, useContext } from 'react';
import { color } from '../../../styles/style';
import { ContentContext } from '../../../contexts';
import * as echarts from "echarts"
import styled from '@emotion/styled';

const Card = styled.div`
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.08);
  padding: 20px;
`;
const ChartWrapper = styled(Card)`
  width: 400px;
  canvas {
    width: inherit;
  }
`
export const ChartPrice = (props) => {
  const { data = [], datas = [], width, height, tag = '' } = props;
  const { deviceWidth } = useContext(ContentContext);
  const [chartData, setChartData] = useState(data)
  const chartRef = useRef(null);
  const labels = data.map(e => {
    if (tag === 'RPS') {
      const half = new Date(e.ts).getMonth()+1 === 1 ? '상반기' : '하반기'
      return `${new Date(e.ts).getFullYear()}-${half}`
    }
    const month = `0${new Date(e.ts).getMonth()+1}`
    return `${new Date(e.ts).getFullYear()}-${month.slice(-2)}-${new Date(e.ts).getDate()}`
  })
  const [options, setOptions] = useState({
    tooltip: {
      trigger: 'axis',
      position: function (pt) {
        return [pt[0], '10%'];
      }
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        restore: {},
        saveAsImage: {}
      }
    },
		xAxis: {
	    type: 'category',
	    data: labels,
      boundaryGap: false,
	  },
	  yAxis: {
	    type: 'value',
      boundaryGap: [0, '100%'],
      axisLabel: {
        margin: 4,
        fontSize: 11
      }
	  },
    dataZoom: [
      {
        type: 'inside',
        start: 80,
        end: 100
      },
      {
        start: 0,
        end: 10
      }
    ],
	  series: [
	    {
	      data: chartData,
	      type: 'line',
        itemStyle: {
          color: `${color.$main500}`
        },
        lineStyle: {
          color: `${color.$main500}`,
          width: 3,
          shadowColor: 'rgba(0, 0, 0, 0.08)',
          shadowBlur: 3,
          shadowOffsetY: 3
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: `${color.$main500}`
            },
            {
              offset: 1,
              color: '#fff'
            }
          ])
        },
	    },      
	  ]
	});

  useEffect(() => {
		if (chartRef.current) {
      const chart = echarts.init(chartRef.current);
	    chart.setOption(options);
		}
  }, [options, chartRef]);

  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.getInstanceByDom(chartRef.current) || echarts.init(chartRef.current);
      chart.resize()
    }
  }, [deviceWidth])

  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.getInstanceByDom(chartRef.current) || echarts.init(chartRef.current);
      const zoom = tag === 'RPS' ? 0 : 80
      chart.setOption({
        dataZoom: [
          {
            type: 'inside',
            start: zoom,
            end: 100
          },
          {
            start: 0,
            end: 10
          }
        ],
        series: [{ data: data }],
      });
    }
  }, [data])

  return (
    <>  
    {data && <ChartWrapper
    ref={chartRef}
    style={{
      width: width,
      height: height,
    }}
  />}
    </>
  );
};

export default ChartPrice;
