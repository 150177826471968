import React, { useContext } from 'react';
import Handler from '../../Handler';
import { ContentContext } from '../../contexts';

export const TableWithdraw = (props) => {
  const { data, rowClick, callback } = props;
  const { deviceWidth } = useContext(ContentContext);

  console.log('TableWithdraw', data);
  if (deviceWidth < 1136) {
    return (
      <article className='table-m-withdraw'>
        {data && data.length === 0 && <span className='empty'>데이터가 없습니다.</span>}
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            return (
              <div className='item'>
                <li>
                  <header>출금요청일</header>
                  <div className='data'>{item.withdrawDate}</div>
                </li>
                <li>
                  <header>출금 금액</header>
                  <div className='data'>{Handler.numberWithCommas(item.withdraw)}원</div>
                </li>
                <li>
                  <header>입금일</header>
                  <div className='data'>{item.depositDate}</div>
                </li>
                <li>
                  <header>입금 금액</header>
                  <div className='data'>{Handler.numberWithCommas(item.deposit)}원</div>
                </li>
                <li>
                  <header>비고</header>
                  <div className='data'>
                    <p>{item.note}</p>
                  </div>
                </li>
              </div>
            );
          })}
      </article>
    );
  }
  return (
    <article className='table-withdraw'>
      <ul className='header'>
        <li>출금요청일</li>
        <li>출금 금액</li>
        <li>입금일</li>
        <li>입금 금액</li>
        <li>비고</li>
      </ul>

      {data && data.length === 0 && <span className='empty'>데이터가 없습니다.</span>}

      {data &&
        data.length > 0 &&
        data.map((item, index) => {
          return (
            <ul className='data'>
              <li>{item.withdrawDate}</li>
              <li>{Handler.numberWithCommas(item.withdraw)}원</li>
              <li>{item.depositDate}</li>
              <li>{Handler.numberWithCommas(item.deposit)}원</li>
              <li>{item.note}</li>
            </ul>
          );
        })}
    </article>
  );
};
