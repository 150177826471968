import React, { useEffect, useState, useContext } from 'react';
import { Button } from '../../components';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import API from '../../api';
import { UserContext } from '../../contexts';
import CircularProgress from '@mui/material/CircularProgress';

export const UserKakaoLoginPage = (props) => {
  const history = useHistory();
  const searchObject = queryString.parse(history.location.search);
  const code = searchObject.code;
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const { userInfo, updateAccessToken, updateRefreshToken } = useContext(UserContext);

  useEffect(() => {
    const REST_API_KEY = process.env.REACT_APP_KAKAO_REST_KEY;
    const REDIRECT_URI = window.location.origin + '/kakao_login';
    const data = { grant_type: 'authorization_code', client_id: REST_API_KEY, redirect_uri: REDIRECT_URI, code: code };
    let url = 'https://kauth.kakao.com/oauth/token';
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: queryString.stringify(data),
      url,
    };
    axios(options).then(
      (res) => {
        setResponse(res.data);
      }).catch(
        (err) => {
          console.log('err', err);
          const response = err.response;
          setError(response);
          console.log('response', response);
        }
      )
  }, [code]);

  useEffect(() => {
    if (response && response.access_token) {
      API.User.PostSigninKakao({
        kakaoToken: response.access_token,
        keeping: true,
      }).then(
        (res) => {
          const { seq, tokenRes } = res.data
          if (tokenRes) {
            updateAccessToken(tokenRes.accessToken);
            updateRefreshToken(tokenRes.refreshToken);
          } else {
            history.push('/add_info?seq=' + seq);
          }
        },
        (err) => {
          setMessage(err.data.message);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (userInfo) {
      history.push((!userInfo.name || !userInfo.phone) ? '/add_info' : '/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  if (message) {
    return (
      <article className='basic-page'>
        <article className='content__wrapper'>
          <section className='kakao-error__box'>
            <div className='text__box'>
              <h1>에러</h1>
              <span>{message}</span>
            </div>
          </section>
          <section className='button__box'>
            <Button
              style={{ width: '280px', marginTop: 28 }}
              onClick={() => {
                history.push('/user/login');
              }}
              label='돌아가기'
            ></Button>
          </section>
        </article>
      </article>
    );
  }

  if (error) {
    return (
      <article className='basic-page'>
        <article className='content__wrapper'>
          <section className='kakao-error__box'>
            <div className='text__box'>
              <h1>에러</h1>
              <span>{error.data.error}</span>
              <span>{error.data.error_code}</span>
              <span>{error.data.error_description}</span>
            </div>
          </section>
          <section className='button__box'>
            <Button
              style={{ width: '280px', marginTop: 28 }}
              onClick={() => {
                history.push('/user/login');
              }}
              label='돌아가기'
            ></Button>
          </section>
        </article>
      </article>
    );
  }

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <div style={{ height: 80 }}></div>
        <CircularProgress size={48}></CircularProgress>
      </article>
    </article>
  );
};
