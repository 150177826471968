import React from 'react';

export const NewsDetailPage = (props) => {

  return (
    <article className='purchase__container'>
      <div className='normal__box'></div>
    </article>
  );
};
