import React from 'react';
import styled from '@emotion/styled';
import { color, font, media } from '../../../styles/style';

const ValueWrapper = styled.div`
  background: ${color.$white};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 30px 16px;
  min-height: 200px;
  .value-image {
    height: 80px;
    margin: 20px auto;
    img {
      height: 100%;
      width: auto;
    }
  }
  p {
    color: ${color.$mono_100_light};
    line-height: ${font.$leading_02};
    white-space: pre-wrap;
  }
  @media only screen and (max-width: ${media.$sm}) {
    margin-bottom: 24px;
  }
`;

const BrandValueCard = (props) => {
  const { title, description, color, image } = props;

  return (
    <ValueWrapper>
      <h3 className='text-xl font-bold'>{title}</h3>
      {image && (
        <div className='value-image'>
          <img src={image} alt={title} />
        </div>
      )}
      {description && <p>{description}</p>}
    </ValueWrapper>
  );
};

export default BrandValueCard;
