import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DropdownInput, SelectBox } from '../../../components';
import { color } from '../../../styles/style';
import { ContentContext } from '../../../contexts';
import styled from '@emotion/styled';

const SearchContainer = styled.section`
  background-color: ${color.$main100};
  border-bottom: 0;
  border-radius: 20px;
  padding: 40px 0;
  margin: 40px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .input__box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 1136px) {
    padding: 40px 16px;
    .basic-button {
      width: 100px;
    }
  }
`;

const SelectBoxContainer = styled.div`
  display: flex;
  padding: 10px 0;
  .select__box {
    background-color: #fff;
    /* border-radius: 25px; */
  }
`;

export const SearchBox = (props) => {
  const {
    area,
    areaChange,
    forward,
    forwardChange,
    keyword,
    keywordType,
    keywordChange,
    keywordTypeChange,
    searchRequest,
  } = props;

  useEffect(() => {}, []);
  const { deviceWidth } = useContext(ContentContext);
  const { t } = useTranslation()
  return (
    <SearchContainer>
      <div className='input__box'>
        <DropdownInput
          value={keyword}
          menuValue={keywordType}
          onChange={(value, key) => keywordChange(value)}
          onMenuChange={(value, key) => keywordTypeChange(value)}
          onEnter={searchRequest}
          meta={{
            style: { width: '468px' },
            placeholder: t('KEYWORD'),
            menus: [
              {
                value: 'ProjectTitle',
                label: t('BIZ_TITLE'),
              },
              {
                value: 'ProjectId',
                label: t('BIZ_ID'),
              },
              {
                value: 'CompanyName',
                label: '판매자명',
              },
            ],
          }}
        ></DropdownInput>
        <Button
          style={{ width: '120px', height: '50px', marginLeft: 12 }}
          onClick={searchRequest}
          label={t('SEARCH')}
        ></Button>
      </div>
      <SelectBoxContainer>
        <SelectBox
          value={area}
          onChange={(value) => areaChange(value)}
          meta={{
            key: 'areaType',
            type: 'round',
            style: {
              width: deviceWidth > 1200 ? 274 : 150,
              marginRight: 16,
            },
            menus: [
              {
                label: t('ALL'),
                value: 'SELECT_ALL',
              },
              {
                label: t('CARBON_REMOVAL'),
                value: 'Removal',
              },
              {
                label: t('CARBON_REDUCTION'),
                value: 'Reduction',
              },
            ],
          }}
        ></SelectBox>
        <SelectBox
          value={forward}
          onChange={(value) => forwardChange(value)}
          meta={{
            type: 'round',
            key: 'isForwardTrade',
            style: {
              width: deviceWidth > 1200 ? 274 : 180,
            },
            menus: [
              {
                label: t('ALL'),
                value: 'SELECT_ALL',
              },
              {
                label: t('PRE_PURCHASEMENT'),
                value: 'true',
              },
              {
                label: t('PURCHASEMENT'),
                value: 'false',
              },
            ],
          }}
        ></SelectBox>
      </SelectBoxContainer>
    </SearchContainer>
  );
};
