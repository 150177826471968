import React from 'react';
import Handler from '../../Handler';

export const Stepper = (props) => {
  const { value, label, onChange, meta } = props;

  return (
    <article
      style={meta && meta.style ? { ...meta.style } : {}}
      className={Handler.BuildArticleClassname('form-stepper', meta)}
    >
      <ul>
        {label.map((item, index) => {
          return (
            <li key={`stepper-item-${index}`} className={index === value ? 'on' : ''}>
              <span>
                {index + 1}. {item}
              </span>
            </li>
          );
        })}
      </ul>
    </article>
  );
};
