import moment from 'moment-timezone';

export const log = (arg) => {
  if (process.env.REACT_APP_ENV === 'production') return;
  return console.log(arg);
}

const REGEXP = {
  EMAIL:
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])",
  PASSWORD: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()+=~_-]).{8,12}$',
  MOBILE: '^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$',
  BIZ_NUMBER: '^[0-9]{0,10}$',
};

const VARIABLES = {
  PAGE_LIMIT: 10,
};

const GET_DEFAULT_IMAGE = (type) => {
  const COMPNAY_LIST = [
    'https://d1sjg004kl9yle.cloudfront.net/default/default_company_1.png',
    'https://d1sjg004kl9yle.cloudfront.net/default/default_company_2.png',
    'https://d1sjg004kl9yle.cloudfront.net/default/default_company_3.png',
  ];

  const USER_LIST = [
    'https://d1sjg004kl9yle.cloudfront.net/default/default_user_1.png',
    'https://d1sjg004kl9yle.cloudfront.net/default/default_user_2.png',
    'https://d1sjg004kl9yle.cloudfront.net/default/default_user_3.png',
  ];

  let target_index = getRandomInt(0, 3);
  console.log('target_index', target_index);
  if (type === 'company') {
    return COMPNAY_LIST[target_index];
  } else {
    return USER_LIST[target_index];
  }
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

const gotoOuterLink = (path) => {
  window.open(path);
};

const genPlaneText = (html) => {
  return html.replace(/(<([^>]+)>)/gi, '');
};

const ENUM = {
  ACTION: {
    STATUS: (value) => {
      switch (value) {
        case 'WritingReductionCert':
        case 'WritingProject': {
          return '임시저장';
        }
        case 'WaitingForAuditReductionCert':
        case 'WaitingForAuditProject': {
          return '1차 심사 대기';
        }
        case 'AuditReductionCert':
        case 'AuditProject': {
          return '1차 심사중';
        }
        case 'RequestImproveReductionCert':
        case 'RequestImproveProject': {
          return '보완요청';
        }
        case 'ReductionCertRejected':
        case 'ProjectRejected': {
          return '최종 반려';
        }
        case 'ReductionCertApproved':
        case 'ProjectApproved': {
          return '최종 승인';
        }
        case 'WaitingForAuditLaunch': {
          return '착수 심사 대기';
        }
        case 'AuditLaunch': {
          return '착수 심사중';
        }
        case 'LaunchApproved': {
          return '착수 승인';
        }
        case 'AuditCancel': {
          return '사업 중단 요청';
        }
        case 'CancelApproved': {
          return '사업 중단';
        }
        case 'WritingReductionCertForImprove':
        case 'WritingProjectForImprove': {
          return '보완 진행중';
        }
        case 'LaunchRejected': {
          return '착수 심사 반려';
        }
        case 'RequestImproveLaunch': {
          return '보완 요청';
        }
        case 'CanceledReductionCert':
        case 'CanceledProject': {
          return '심사 중단';
        }
        case 'ProjectGiveUp': {
          return '최종 반려';
        }
        case 'LaunchGiveUp': {
          return '최종 반려';
        }
        case 'ReductionCertGiveUp': {
          return '최종 반려';
        }
        default: {
          return 'none';
        }
      }
    },
    NEXT: (value) => {
      switch (value) {
        case 'None': {
          return 'None';
        }
        case 'ModifyWriting': {
          return '수정';
        }
        case 'RemoveWriting': {
          return '삭제';
        }
        case 'CanceledProject': {
          return '사업 중단';
        }
        case 'CancelWaiting': {
          return '취소';
        }
        case 'ImproveProjectStart': {
          return '보완 수락';
        }
        case 'ImproveProjectGiveUp': {
          return '보완 포기';
        }
        case 'LaunchProject': {
          return '착수 신고';
        }
        case 'ModifyProject': {
          return '변경';
        }
        case 'ImproveProjectComplete': {
          return '보완 완료';
        }
        case 'ImproveLaunchComplete': {
          return '보완 완료';
        }
        case 'ImproveLaunchGiveUp': {
          return '보완 포기';
        }
        case 'ImproveReductionCertGiveUp': {
          return '보완 포기';
        }
        case 'ImproveReductionCertStart': {
          return '보완 수락';
        }
        case 'ImproveReductionCertComplete': {
          return '보완 완료';
        }
        case 'CanceledReductionCert': {
          return '심사 중단';
        }
        case 'IssueCredit': {
          return '크레딧 발급 신청';
        }
        default: {
          return 'none';
        }
      }
    },
    STOP_REASON: (value) => {
      console.log('stopReason', value);
      switch (value) {
        case 'CanNotProceed': {
          return '사업 진행 불가능';
        }
        case 'UserSignout': {
          return '회원 탈퇴';
        }
        case 'Etc': {
          return '기타';
        }
        default: {
          return 'none';
        }
      }
    },
  },
  REDUCTION_ACTION: {
    STATUS: (value) => {
      switch (value) {
        case 'WritingReductionCert':
        case 'WritingProject': {
          return '임시저장';
        }
        case 'WaitingForAuditReductionCert':
        case 'WaitingForAuditProject': {
          return '1차 심사 대기';
        }
        case 'AuditReductionCert':
        case 'AuditProject': {
          return '1차 심사중';
        }
        case 'RequestImproveReductionCert':
        case 'RequestImproveProject': {
          return '보완요청';
        }
        case 'ReductionCertRejected':
        case 'ProjectRejected': {
          return '최종 반려';
        }
        case 'ReductionCertApproved':
        case 'ProjectApproved': {
          return '최종 승인';
        }
        case 'WaitingForAuditLaunch': {
          return '착수 심사 대기';
        }
        case 'AuditLaunch': {
          return '착수 심사중';
        }
        case 'LaunchApproved': {
          return '착수 승인';
        }
        case 'AuditCancel': {
          return '심사 중단 요청';
        }
        case 'CancelApproved': {
          return '심사 중단';
        }
        case 'WritingReductionCertForImprove':
        case 'WritingProjectForImprove': {
          return '보완 진행중';
        }
        case 'LaunchRejected': {
          return '착수 심사 반려';
        }
        case 'RequestImproveLaunch': {
          return '보완 요청';
        }
        case 'CanceledReductionCert':
        case 'CanceledProject': {
          return '심사 중단';
        }
        case 'ProjectGiveUp': {
          return '최종 반려';
        }
        case 'LaunchGiveUp': {
          return '최종 반려';
        }
        case 'ReductionCertGiveUp': {
          return '최종 반려';
        }
        default: {
          return 'none';
        }
      }
    },
    NEXT: (value) => {
      switch (value) {
        case 'None': {
          return 'None';
        }
        case 'ModifyWriting': {
          return '수정';
        }
        case 'RemoveWriting': {
          return '삭제';
        }
        case 'CanceledProject': {
          return '심사 중단';
        }
        case 'CancelWaiting': {
          return '취소';
        }
        case 'ImproveProjectStart': {
          return '보완 수락';
        }
        case 'ImproveProjectGiveUp': {
          return '보완 포기';
        }
        case 'LaunchProject': {
          return '착수 신고';
        }
        case 'ModifyProject': {
          return '변경';
        }
        case 'ImproveProjectComplete': {
          return '보완 완료';
        }
        case 'ImproveLaunchComplete': {
          return '보완 완료';
        }
        case 'ImproveLaunchGiveUp': {
          return '보완 포기';
        }
        case 'ImproveReductionCertGiveUp': {
          return '보완 포기';
        }
        case 'ImproveReductionCertStart': {
          return '보완 수락';
        }
        case 'ImproveReductionCertComplete': {
          return '보완 완료';
        }
        case 'CanceledReductionCert': {
          return '심사 중단';
        }
        case 'IssueCredit': {
          return '크레딧 발급 신청';
        }
        default: {
          return 'none';
        }
      }
    },
    STOP_REASON: (value) => {
      console.log('stopReason', value);
      switch (value) {
        case 'CanNotProceed': {
          return '사업 진행 불가능';
        }
        case 'UserSignout': {
          return '회원 탈퇴';
        }
        case 'Etc': {
          return '기타';
        }
        default: {
          return 'none';
        }
      }
    },
  },
  CREDIT_ACTION: {
    STATUS: (value) => {
      switch (value) {
        case 'WaitingForAuditZCCredit': {
          return '1차 심사 대기';
        }
        case 'AuditZCCredit': {
          return '1차 심사중';
        }
        case 'RequestImproveZCCredit': {
          return '보완요청';
        }
        case 'ZCCreditRejected': {
          return '최종 반려';
        }
        case 'ZCCreditApproved': {
          return '1차 심사 완료';
        }
        case 'AuditApproved': {
          return '1차 최종 승인';
        }
        case 'WaitingForAuditCommittee': {
          return '2차 심사 대기';
        }
        case 'AuditCommittee': {
          return '2차 심사중';
        }
        case 'CommitteeApproved': {
          return '2차 최종 승인';
        }
        case 'AuditCancel': {
          return '심사 중단 요청';
        }
        case 'CancelApproved': {
          return '심사 중단';
        }
        case 'WritingCommitteeForImprove':
        case 'WritingZCCreditForImprove': {
          return '보완 진행중';
        }
        case 'CommitteeRejected': {
          return '2차 심사 반려';
        }
        case 'RequestImproveCommittee': {
          return '보완 요청';
        }
        case 'CanceledZCCredit': {
          return '심사 중단';
        }
        case 'CommitteeGiveUp':
        case 'CommitteeRejected':
        case 'ZCCreditGiveUp': {
          return '최종 반려';
        }
        case 'CommitteeGiveUp': {
          return '최종 반려';
        }
        default: {
          return 'none';
        }
      }
    },
    NEXT: (value) => {
      switch (value) {
        case 'None': {
          return 'None';
        }
        case 'ModifyWriting': {
          return '수정';
        }
        case 'RemoveWriting': {
          return '삭제';
        }
        case 'CanceledZCCredit': {
          return '심사 중단';
        }
        case 'CancelWaiting': {
          return '취소';
        }
        case 'ImproveCommitteeStart':
        case 'ImproveZCCreditStart': {
          return '보완 수락';
        }
        case 'ImproveCommitteeGiveUp':
        case 'ImproveZCCreditGiveUp': {
          return '보완 포기';
        }
        case 'CommitteeZCCredit': {
          return '착수 신고';
        }
        case 'ModifyZCCredit': {
          return '변경';
        }
        case 'ImproveCommitteeComplete':
        case 'ImproveZCCreditComplete': {
          return '보완 완료';
        }
        case 'ImproveCommitteeComplete': {
          return '보완 완료';
        }
        case 'ImproveCommitteeGiveUp': {
          return '보완 포기';
        }
        case 'IssueCredit': {
          return '크레딧 발급 신청';
        }
        default: {
          return 'none';
        }
      }
    },
    STOP_REASON: (value) => {
      console.log('stopReason', value);
      switch (value) {
        case 'CanNotProceed': {
          return '사업 진행 불가능';
        }
        case 'UserSignout': {
          return '회원 탈퇴';
        }
        case 'Etc': {
          return '기타';
        }
        default: {
          return 'none';
        }
      }
    },
  },
  AREA_TYPE: (value) => {
    switch (value) {
      case 'Removal': {
        return 'CARBON_REMOVAL';
      }
      case 'Reduction': {
        return 'CARBON_REDUCTION';
      }
      case 'Forestry': {
        return 'FORESTRY';
      }
      case 'BioChar': {
        return 'BIOCHAR';
      }
      case 'SoilCarbon': {
        return 'SOIL';
      }
      case 'DAC': {
        return 'DAC';
      }
      case 'RenewableEnergy': {
        return 'RENEWABLEENERGY';
      }
      case 'EMobility': {
        return 'EMOBILITY';
      }
      case 'Recycle': {
        return 'RECYCLE';
      }
      case 'ReplaceElement': {
        return 'REPLACEELEMENT';
      }
      case 'EnergyConversion': {
        return 'ENERGYCONVERSION';
      }
      case 'Chemical': {
        return 'CHEMICAL';
      }
      case 'Agriculture': {
        return 'AGRICULTURE';
      }
      case 'CCS': {
        return 'CCS';
      }
      case 'SecondHand': {
        return 'SECONDHAND';
      }
      case 'ReplaceVehicle': {
        return 'REPLACEVEHICLE';
      }
      case 'Service': {
        return 'SERVICE';
      }
      case 'Etc': {
        return 'ETC';
      }
      default: {
        return '';
      }
    }
  },
  AREA: (value) => {
    switch (value) {
      case 'Removal': {
        return '탄소 제거';
      }
      case 'Reduction': {
        return '탄소 감축';
      }
      default: {
        return 'none';
      }
    }
  },
  SUB_AREA: (value) => {
    switch (value) {
      case 'Forestry': {
        return '산림';
      }
      case 'BioChar': {
        return '바이오차';
      }
      case 'SoilCarbon': {
        return '토양탄소';
      }
      case 'DAC': {
        return 'DAC';
      }
      case 'RenewableEnergy': {
        return '재생에너지';
      }
      case 'EMobility': {
        return 'e-mobility';
      }
      case 'Recycle': {
        return '재활용';
      }
      case 'ReplaceElement': {
        return '대체물질';
      }
      case 'EnergyConversion': {
        return '에너지전환';
      }
      case 'Chemical': {
        return '화학공정';
      }
      case 'Agriculture': {
        return '농업';
      }
      case 'CCS': {
        return 'CCS';
      }
      case 'SecondHand': {
        return '중고거래';
      }
      case 'ReplaceVehicle': {
        return '이동수단 대체';
      }
      case 'Service': {
        return '서비스';
      }
      case 'Etc': {
        return '기타';
      }
      default: {
        return 'none';
      }
    }
  },
  // AREA: (value) => {
  //   switch (value) {
  //     case 'CSR': {
  //       return 'CSR';
  //     }
  //     case 'CarbonAbsorption': {
  //       return '탄소 흡수';
  //     }
  //     case 'GreenNewDeal': {
  //       return '그린뉴딜';
  //     }
  //     default: {
  //       return 'none';
  //     }
  //   }
  // },
  // SUB_AREA: (value) => {
  //   switch (value) {
  //     case 'RenewableEnergy': {
  //       return '재생 에너지';
  //     }
  //     case 'MicroGrid': {
  //       return '마이크로그리드';
  //     }
  //     case 'FuelConversion': {
  //       return '연료전환';
  //     }
  //     case 'WaterTreatment': {
  //       return '수처리';
  //     }
  //     case 'Forestry': {
  //       return '산림 조성';
  //     }
  //     case 'ForestManagement': {
  //       return '산림 관리';
  //     }
  //     case 'BioChar': {
  //       return 'BioChar';
  //     }
  //     case 'DAC': {
  //       return 'DAC';
  //     }
  //     case 'EMobility': {
  //       return 'e-mobility';
  //     }
  //     case 'EnergyConversion': {
  //       return '에너지 전환';
  //     }
  //     case 'SmartCity': {
  //       return '스마트 시티';
  //     }
  //     default: {
  //       return 'none';
  //     }
  //   }
  // },
};

const buildPaymentStatusText = (status) => {
  switch (status) {
    case 'Complete': {
      return 'PAYMENT_COMPLETE';
    }
    case 'DepositComplete': {
      return 'DEPOSIT_COMPLETE';
    }
    case 'DepositReady': {
      return 'DEPOSIT_WAITING';
    }
    case 'DepositCancel': {
      return 'DEPOSIT_CANCEL';
    }
    default: {
      return '';
    }
  }
};

const buildPaymentTypeText = (type) => {
  switch (type) {
    case 'CARD': {
      return '신용카드';
    }
    case 'BANK': {
      return '계좌이체';
    }
    case 'VBANK': {
      return '가상계좌';
    }
    case 'MOBILE': {
      return '휴대폰';
    }
    case 'TAXBILL': {
      return '세금계산서\n발행';
    }
    default: {
      return '기타';
    }
  }
};

const curDict = {
  KRW: '₩',
  EUR: '€',
  USD: '$',
};

const formToBody = (formData, formMeta) => {
  console.log('formToBody formData', formData);
  console.log('formToBody formMeta', formMeta);
  let isValidation = true;
  Object.keys(formMeta).forEach((key) => {
    console.log('key', key);
    let target = formMeta[key];
    if (target.required && !formData[key]) {
      // console.log('[[[[[required MISSING target key]]]]]]', key);
      isValidation = false;
    }
  });

  if (isValidation) {
    return formData;
  } else {
    return null;
  }
};

const formToBodyKey = (formData, formMeta) => {
  console.log('formToBody formData', formData);
  console.log('formToBody formMeta', formMeta);
  let key_list = [];

  for (let i in formMeta) {
    let target = formMeta[i];
    console.log('target', target);
    if (!formData[target]) {
      key_list.push(target);
    }
  }

  return key_list;
};

const formValidation = (formData, validationList) => {
  console.log('formValidation formData', formData);
  console.log('formValidation validationList', validationList);

  let isValidation = true;
  Object.keys(formData).forEach((key) => {
    let target = formData[key];
    if (validationList.includes(key)) {
      console.log('target', target);
      if (!target) {
        console.log('[[[[[required MISSING target key]]]]]]', key);

        isValidation = false;
      }
    }
  });

  return isValidation;
};

// const addressToDbData = (value) => {
//   if (value) {
//     return {
//       post: value.daum.zonecode,
//       address: value.daum.roadAddress,
//       addressDetail: value.detail,
//     };
//   } else {
//     return null;
//   }
// };

// const dbDataToAddress = (db_data) => {
//   if (db_data.address && db_data.addressDetail && db_data.post) {
//     return {
//       daum: {
//         zonecode: db_data.post,
//         roadAddress: db_data.address,
//       },
//       detail: db_data.addressDetail,
//     };
//   } else {
//     return null;
//   }
// };

const numberWithCommas = (x) => {
  // console.log('numberWithCommas x', x);
  if (!x) {
    return '0';
  }
  if (isNaN(Number(x))) {
    return x;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const getYYYYMMDDByUnix = (unix) => {
  //server에서 second로 떨어짐
  console.log('unix', unix);
  let temp_moment = moment(unix * 1000).format('YYYY.MM.DD');
  return temp_moment;
};

const getYYYYMMDDHHMMByUnix = (unix) => {
  //server에서 second로 떨어짐
  let temp_moment = moment(unix * 1000).format('YYYY.MM.DD HH:mm');
  return temp_moment;
};

const dbDateToYYYYMMDDHHMMSS = (date) => {
  if (date) {
    return date.slice(0, 19).replace('T', ' ');
  } else {
    return null;
  }
};

const dbDateToYYYYMMDD = (date) => {
  if (date) {
    return date.slice(0, 10).replace('T', ' ');
  } else {
    return null;
  }
};

const dbDateToYYYYMMDDComma = (date) => {
  if (date) {
    return date.slice(0, 10).replace('T', ' ').replaceAll('-', '.');
  } else {
    return null;
  }
};

const dbDashDateToCommaDate = (date) => {
  if (date) {
    return date.replaceAll('-', '.');
  } else {
    return null;
  }
};

const dbCommaDateToDashDate = (date) => {
  if (date) {
    return date.replaceAll('.', '-');
  } else {
    return null;
  }
};

const getYYYYMMDDByTimestamp = (timestamp) => {
  //yyyy-mm-dd
  let parsed_timestampe = String(timestamp).substring(0, 19);
  var d = new Date(parsed_timestampe),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

const getTimebyTimestamp = (timestamp) => {
  let parsed_timestampe = String(timestamp).substring(0, 19);
  var t1 = new Date().getTime();
  var t2 = Date.parse(parsed_timestampe);

  var dif = t1 - t2;
  // console.log('dif', dif);
  var dif_second = Math.floor(dif / 1000);

  //시간
  //시간, 분
  //day
  //30일

  if (dif_second > 2592000) {
    //30일
    return Math.floor(dif_second / 2592000) + '달전';
  } else if (dif_second > 86400) {
    //하루보다 크면
    return Math.floor(dif_second / 86400) + '일전';
  } else if (dif_second > 3600) {
    return Math.floor(dif_second / 3600) + '시간전';
  } else {
    return Math.floor(dif_second / 60) + '분전';
  }
};

//component

const BuildArticleClassname = (value, meta) => {
  if (!value || !meta) {
    return '';
  }
  // console.log('meta', meta);
  let return_classname = value;

  if (meta.className) {
    return_classname += ' ' + meta.className;
  }
  if (meta.type) {
    return_classname += ' ' + meta.type;
  }
  if (meta.disabled) {
    // return_classname += ' ' + 'disabled';
    return_classname = `${return_classname} disabled`;
  }
  if (meta.readOnly) {
    // return_classname += ' ' + 'readOnly';
    return_classname = `${return_classname} readOnly`;
  }
  return return_classname;
};

//content

const buildMethodologyMenus = (methodology_list, area, sub_area) => {
  let return_menus = [];
  console.log('buildMethodologyMenus', methodology_list);
  for (let i in methodology_list) {
    let target = methodology_list[i];
    if (target.isValid && target.area === area && target.subArea === sub_area) {
      return_menus.push({
        value: target.seq,
        label: target.title,
      });
    }
  }
  return return_menus;
};

const getMethodologyBySeq = (methodology_list, seq) => {
  let return_value = null;

  if (methodology_list.length === 0 || !seq) {
    return null;
  }

  for (let i in methodology_list) {
    let target = methodology_list[i];
    if (target.seq === Number(seq)) {
      return_value = target;
    }
  }

  return return_value;
};

const buildTermsValue = (methodology) => {
  let reutrn_value = [];
  console.log('buildTermsValue methodology', methodology);
  let terms_mapper = methodology.termsMapper;

  for (let i in terms_mapper) {
    let target = terms_mapper[i];
    reutrn_value.push({
      title: target.terms.title,
      file: target.terms.termsFile,
    });
  }

  return reutrn_value;
};

//from server mapper to view data. 이미 생성된 방법론의 경우, 밸리드 체크하지 않는다.
const getEvidenceByMapper = (mapper, valid_check) => {
  let return_value = [];

  console.log('getEvidenceByMapper', mapper);
  for (let i in mapper) {
    let target = mapper[i];
    if (valid_check) {
      if (target.status === 'Valid') {
        return_value.push({
          seq: target.evidence.seq,
          type: target.type,
          data: target.data,
        });
      }
    } else {
      return_value.push({
        seq: target.evidence.seq,
        type: target.type,
        data: target.data,
      });
    }
  }
  return return_value;
};

// 유효한 빈값 생성용, 방법론 셀렉트 박스 변경시 사용됨, 항상 valid 체크
const buildEvidenceValue = (methodology) => {
  let reutrn_value = [];
  for (let i in methodology.evidenceMapper) {
    let target_mapper = methodology.evidenceMapper[i];
    console.log('buildEvidenceValue target_mapper', target_mapper);
    if (target_mapper.evidence.status === 'Valid') {
      reutrn_value.push({
        seq: target_mapper.evidence.seq,
        type: 'File',
        data: '',
      });
    }
  }

  return reutrn_value;
};

//서버에서 초기 mapper가 있는 경우, 밸리드 체크하지 않는다.
const buildEvidenceLabel = (methodology, valid_check) => {
  console.log('buildEvidenceLabel methodology', methodology);
  let reutrn_value = [];
  for (let i in methodology.evidenceMapper) {
    let target_mapper = methodology.evidenceMapper[i];
    if (valid_check) {
      console.log(' buildEvidenceLabel target_mapper', target_mapper);
      if (target_mapper.evidence.status === 'Valid') {
        reutrn_value.push({
          title: target_mapper.evidence.title,
          isForce: target_mapper.isForce,
        });
      }
    } else {
      reutrn_value.push({
        title: target_mapper.evidence.title,
        isForce: target_mapper.isForce,
      });
    }
  }

  return reutrn_value;
};

const buildSubAreaMenus = (business_list, area) => {
  let return_value = [];

  if (area && business_list) {
    let sub_area_list = business_list[area];
    for (let i in sub_area_list) {
      let target = sub_area_list[i];
      return_value.push({
        value: target,
        label: Handler.ENUM.SUB_AREA(target),
      });
    }
  }

  return return_value;
};

// monitoring evidence
const buildMonitoringValue = (origin_mapper, type, monitoringInfo, only_mapper) => {
  let return_value = [];

  if (only_mapper) {
    // view용으로 mapper에 있는 값만 보여줄때 사용
    console.log('origin_mapper', origin_mapper);
    for (let i in origin_mapper) {
      let target = origin_mapper[i];
      return_value.push({
        seq: target.seq,
        file: target.file,
        reason: target.reason,
      });
    }
  } else {
    if (origin_mapper) {
      console.log('origin_mapper', origin_mapper);
      for (let i in monitoringInfo) {
        let target = monitoringInfo[i];
        if (target.status === 'Valid' && (target.type === 'All' || target.type === type)) {
          let is_exist_in_mapper = false;
          let selected_mapper = null;
          for (let j in origin_mapper) {
            let mapper_target_seq = origin_mapper[j].evidence.seq;
            if (mapper_target_seq === target.seq) {
              is_exist_in_mapper = true;
              selected_mapper = origin_mapper[j];
            }
          }
          if (is_exist_in_mapper) {
            return_value.push({
              seq: target.seq,
              file: selected_mapper.file,
              reason: selected_mapper.reason,
            });
          } else {
            return_value.push({
              seq: target.seq,
              file: '',
              reason: '',
            });
          }
        }
      }
    } else {
      for (let i in monitoringInfo) {
        let target = monitoringInfo[i];
        if (target.status === 'Valid' && (target.type === 'All' || target.type === type)) {
          return_value.push({
            seq: target.seq,
            file: '',
            reason: '',
          });
        }
      }
    }
  }

  return return_value;
};

const buildMonitoringLabel = (origin_mapper, type, monitoringInfo, only_mapper) => {
  let return_value = [];
  if (only_mapper) {
    // view용으로 mapper에 있는 값만 보여줄때 사용
    for (let i in origin_mapper) {
      let target = origin_mapper[i];
      console.log('targettarget,', target);
      return_value.push({
        title: target.evidence.title,
        isForce: target.evidence.isForce,
      });
    }
  } else {
    for (let i in monitoringInfo) {
      let target = monitoringInfo[i];
      if (target.status === 'Valid' && (target.type === 'All' || target.type === type)) {
        return_value.push({
          title: target.title,
          isForce: target.isForce,
        });
      }
    }
  }

  return return_value;
};

//credit issue
const buildProjectMenus = (project_list) => {
  let return_value = [];

  for (let i in project_list) {
    let target = project_list[i];
    return_value.push({
      value: target.seq,
      label: target.title,
    });
  }
  return return_value;
};

const getProjectBySeq = (seq, project_list) => {
  let return_value = null;

  for (let i in project_list) {
    let target = project_list[i];
    if (Number(seq) === target.seq) {
      return_value = target;
    }
  }
  return return_value;
};
const buildMonitorDegreeMenus = (reduction_list) => {
  let return_value = [];

  for (let i in reduction_list) {
    let target = reduction_list[i];
    return_value.push({
      value: target.seq,
      label: target.monitorDegree + '차',
    });
  }
  return return_value;
};

const getCreditValue = (seq, reduction_list) => {
  let return_value = null;

  for (let i in reduction_list) {
    let target = reduction_list[i];
    if (Number(seq) === target.seq) {
      return_value = target.lastDoc.realReduction;
    }
  }
  return return_value;
};

const jsonParser = (value) => {
  try {
    if (typeof value === 'string') {
      return typeof JSON.parse(value) === 'string' ? JSON.parse(JSON.parse(value)) : JSON.parse(value);
    }
    return value;
  } catch (err) {
    return null;
  }
};

const findZonecode = (list) => {
  if (!list || list.length === 0) return '';
  const postcode = list.find((el) => el.types.includes('postal_code'));
  return postcode ? postcode['long_name'] : '';
};

const Handler = {
  REGEXP: REGEXP,
  VARIABLES: VARIABLES,
  ENUM: ENUM,
  curDict: curDict,
  formToBody: formToBody,
  formToBodyKey: formToBodyKey,
  formValidation: formValidation,
  numberWithCommas: numberWithCommas,
  gotoOuterLink: gotoOuterLink,
  genPlaneText: genPlaneText,
  // addressToDbData: addressToDbData,
  // dbDataToAddress: dbDataToAddress,
  BuildArticleClassname: BuildArticleClassname,
  getYYYYMMDDByUnix: getYYYYMMDDByUnix,
  getYYYYMMDDHHMMByUnix: getYYYYMMDDHHMMByUnix,
  dbDateToYYYYMMDDHHMMSS: dbDateToYYYYMMDDHHMMSS,
  dbDateToYYYYMMDD: dbDateToYYYYMMDD,
  dbDateToYYYYMMDDComma: dbDateToYYYYMMDDComma,
  buildMethodologyMenus: buildMethodologyMenus,
  getMethodologyBySeq: getMethodologyBySeq,
  getEvidenceByMapper: getEvidenceByMapper,
  buildEvidenceValue: buildEvidenceValue,
  buildEvidenceLabel: buildEvidenceLabel,
  buildTermsValue: buildTermsValue,
  buildSubAreaMenus: buildSubAreaMenus,
  buildMonitoringValue: buildMonitoringValue,
  buildMonitoringLabel: buildMonitoringLabel,
  dbDashDateToCommaDate: dbDashDateToCommaDate,
  dbCommaDateToDashDate: dbCommaDateToDashDate,
  buildProjectMenus: buildProjectMenus,
  getProjectBySeq: getProjectBySeq,
  buildMonitorDegreeMenus: buildMonitorDegreeMenus,
  getCreditValue: getCreditValue,
  buildPaymentStatusText: buildPaymentStatusText,
  buildPaymentTypeText: buildPaymentTypeText,
  GET_DEFAULT_IMAGE: GET_DEFAULT_IMAGE,
  jsonParser: jsonParser,
  findZonecode: findZonecode,
};

export default Handler;
