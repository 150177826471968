import Main00 from '../styles/images/main_00.png';
import Main01 from '../styles/images/main_01.png';
import Main02 from '../styles/images/main_02.png';
import Main03 from '../styles/images/main_03.png';
import LowMain00 from '../styles/images/low_main_00.png';
import LowMain01 from '../styles/images/low_main_01.png';
import LowMain02 from '../styles/images/low_main_02.png';
import LowMain03 from '../styles/images/low_main_03.png';

import MainGraph from '../styles/images/main_graph.svg';
import MainGraphEN from '../styles/images/main_graph_en.svg';
import MobileMainGraph from '../styles/images_m/main_graph_ko.svg';
import MobileMainGraphEn from '../styles/images_m/main_graph_en.svg';
import Section03Bg from '../styles/images/section_03.png';
import Section04Bg from '../styles/images/section_04.png';
import Earth01 from '../styles/images/earth_01.png';
import Earth02 from '../styles/images/earth_02.png';
import Earth03 from '../styles/images/earth_03.png';
import Earth04 from '../styles/images/earth_04.png';
import ValueIcon01 from '../styles/icon/value-01.svg';
import ValueIcon02 from '../styles/icon/value-02.svg';
import ValueIcon03 from '../styles/icon/value-03.svg';
import ReduceIcon01 from '../styles/icon/reduce-01.svg';
import ReduceIcon02 from '../styles/icon/reduce-02.svg';
import ReduceIcon03 from '../styles/icon/reduce-03.svg';
// import LogoLotte from '../styles/images/partners/logo_lotte_chemical.png';
import LogoBlue from '../styles/images/partners/logo_blue_point.png';
import LogoPostech from '../styles/images/partners/logo_postech.png';
import LogoVery from '../styles/images/partners/logo_verywords.png';
// import LogoJeJuUnited from '../styles/images/partners/logo_jeju_united.png';
import LogoDoGood from '../styles/images/partners/logo_dogood.png';
import LogoAsia from '../styles/images/partners/logo_asia_networks_holdings.png';
import LogoOpenSurvey from '../styles/images/partners/logo_open_survey.png';
import LogoEnvironment from '../styles/images/partners/logo_environment_fnd.png';
import LogoCarmoa from '../styles/images/partners/logo_carmoa.png';
import LogoAtwom from '../styles/images/partners/logo_atwom.png';
import LogoNextGroup from '../styles/images/partners/logo_next_group.png';

/* 팝플메인 */
export const PAGE_TITLE = '국내 최초의 자발적 탄소거래 플랫폼';
export const PAGE_TITLE_DESC = '팝플은 지속가능한 지구를 위해\n새로운 탄소 중립 문화를 만들어갑니다.';

export const SECTION_01_TITLE = '지구를 위해 행동합니다.';
export const SECTION_01_TITLE_DESC =
  '사소한 실천은 습관이 되고,\n작은 행동들이 모여 세상을 변화시킬 수 있습니다.\n지구를 위한 책임있는 행동만이 기후위기에 대응할 수 있습니다.';
export const SECTION_02_TITLE = '행동하는 사람들을 위한\n플랫폼입니다.';
export const SECTION_02_TITLE_DESC =
  '탄소 감축 사업자는 감축량을 인정받아 크레딧을 인증 받고,\n기후변화 대응에 기여하고자 하는 개인 및 기업은 크레딧을 구매하여\n탄소 상쇄를 할 수 있습니다.';
export const SECTION_03_BG = Section03Bg;
export const SECTION_03_TITLE = '팝플은 국제적 가이드라인을 적용합니다.';
export const SECTION_03_TITLE_DESC = '팝플은 신뢰할 수 있는 기준과 원칙에\n혁신적 기술을 접목한 인증절차를 진행합니다.';
export const SECTION_03_LIST = [
  { title: '신뢰할 수 있는 가치와 원칙', icon: ValueIcon01, path: '/standard' },
  { title: '과학 기반의 정량적 방법론', icon: ValueIcon02, path: '/cs/contact_us' },
  { title: '혁신적인 디지털 솔루션(MRV)', icon: ValueIcon03, path: '/solution' },
];
export const SECTION_04_BG = Section04Bg;
export const SECTION_04_TITLE = '탄소감축은 우리의 일상에서 시작됩니다.';
export const SECTION_04_LIST = [
  { title: '불필요한 이메일\n10통 삭제', desc: '14.6 kg 감소', icon: ReduceIcon01 },
  { title: '장거리 비행\n1회 줄이기', desc: '1.68t 감소', icon: ReduceIcon02 },
  { title: '샤워시간\n1분 줄이기', desc: '7kg 감소', icon: ReduceIcon03 },
];
export const SECTION_05_TITLE = '팝플과 함께하는 기업들입니다.';
export const MAIN_IMAGES = [
  { image: Main00, preview: LowMain00 },
  { image: Main01, preview: LowMain01 },
  { image: Main02, preview: LowMain02 },
  { image: Main03, preview: LowMain03 },
];
export const MAIN_GRAPH = MainGraph;
export const MAIN_GRAPH_EN = MainGraphEN;
export const MOBILE_MAIN_GRAPH = MobileMainGraph;
export const MOBILE_MAIN_GRAPH_EN = MobileMainGraphEn;
export const EARTH_LIST = [Earth01, Earth02, Earth03, Earth04];
export const PARTNER_LIST = [
  // LogoLotte,
  LogoBlue,
  LogoPostech,
  LogoVery,
  // LogoJeJuUnited,
  LogoDoGood,
  LogoAsia,
  LogoOpenSurvey,
  LogoEnvironment,
  LogoCarmoa,
  LogoAtwom,
  LogoNextGroup,
];
