import React, { useEffect, useContext, useState, useRef } from 'react';
import { Breadcrumbs, TextInput, TextArea, Button, CheckBox, MetaTag } from '../../components';
import { useForm, useLabel } from '../../hooks';
import { UserContext, SnackbarContext, LangContext, ContentContext } from '../../contexts';
import { INFO_TERM_KO, INFO_TERM_EN } from '../../constants/term';
import { TermService } from '../../components/footer/components';
import Handler from '../../Handler';
import API from '../../api';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { debounce } from 'lodash';
import { t } from 'i18next';
import { modalStyle, modalMobileStyle } from '../../styles/style';

export const CsContactUsPage = (props) => {
  const { kakao } = window;
  const { MSG, ERROR } = useLabel();
  const { deviceWidth } = useContext(ContentContext);
  const { userInfo } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const [term, setTerm] = useState('');
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      name: '',
      email: '',
      phone: '',
      message: '',
    },
    {
      name: {
        key: 'name',
        label: t('NAME'),
        className: 'point',
        required: true,
      },
      email: {
        key: 'email',
        label: t('EMAIL'),
        style: {},
        className: 'point',
        regexp: '/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/i',
        required: true,
      },
      phone: {
        key: 'phone',
        label: t('MOBILE'),
        className: 'point',
        required: true,
      },
      message: {
        key: 'message',
        label: t('MESSAGE'),
        maxSize: 500,
        className: 'point',
        required: true,
      },
    },
  );
  const [agree, setAgree] = useState(false);
  const [open, setOpen] = useState(false);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });

  useEffect(() => {
    setMetaObject({
      title: 'Contact Us',
      description: '고객센터 및 상담문의',
      keywords: '팝플이란, 팝플',
      image: '',
      canonical: '',
      url: window.location.href,
    });
  }, []);

  useEffect(() => {
    if (open) isKorean ? setTerm(INFO_TERM_KO) : setTerm(INFO_TERM_EN);
  }, [open]);

  const mapRef = useRef(null);
  useEffect(() => {
    if (userInfo) {
      setFormData({
        ...formData,
        email: userInfo.email,
        name: userInfo.name,
        phone: userInfo.phone,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_KEY}&autoload=false`;
    document.head.appendChild(script);

    script.onload = () => {
      if (mapRef.current) {
        window.kakao.maps.load(() => {
          var options = {
            //지도를 생성할 때 필요한 기본 옵션
            center: new window.kakao.maps.LatLng(37.52336414995826, 126.92344613831285),

            level: 3, //지도의 레벨(확대, 축소 정도)
          };
          var map = new window.kakao.maps.Map(mapRef.current, options);
          //마커가 표시 될 위치
          let markerPosition = new window.kakao.maps.LatLng(37.52336414995826, 126.92344613831285);

          // 마커를 생성
          let marker = new window.kakao.maps.Marker({
            position: markerPosition,
          });

          // 마커를 지도 위에 표시
          marker.setMap(map);

          // 인포윈도우로 장소에 대한 설명을 표시합니다
          var infowindow = new window.kakao.maps.InfoWindow({
            content:
              '<div style="width:180px;text-align:center;padding:6px 0;font-size : 12px;">' +
              '서울특별시 영등포구 의사당대로 83 오투타워 19층' +
              '</div>',
          });
          infowindow.open(map, marker);
        });
      }
    };
    return () => script.remove();
  }, []);

  const onSubmit = debounce(() => {
    requestSubmit();
  }, 300);

  const validation = (data) => {
    if (!data.email || data.email) {
      const reg = new RegExp(Handler.REGEXP.EMAIL);
      if (!reg.test(data.email)) {
        updateSnackbar({
          type: 'error',
          open: true,
          message: ERROR.EMAIL,
        });
        return false;
      }
    }
    if (!data.phone || data.phone) {
      const reg = new RegExp(Handler.REGEXP.MOBILE);
      if (!reg.test(data.phone)) {
        updateSnackbar({
          type: 'error',
          open: true,
          message: ERROR.PHONE,
        });
        return false;
      }
    }
    if (!agree) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: ERROR.AGREE_PRIVACY_TERM,
      });
      return false;
    }
    return true;
  };

  const requestSubmit = () => {
    if (validation(formData)) {
      let body = Handler.formToBody(formData, formMeta);
      if (body) {
        API.CS.PostContact({ ...body }).then(
          (res) => {
            updateSnackbar({
              type: 'success',
              open: true,
              message: '문의가 등록되었습니다.',
            });
            setFormData({
              name: '',
              email: '',
              phone: '',
              message: '',
            });
          },
          (err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          },
        );
      } else {
        updateSnackbar({
          type: 'error',
          open: true,
          message: ERROR.REQUIRED_INFO,
        });
      }
    }
  };

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <article className='content__wrapper'>
          <section className='headding-text__container'>
            <header>
              <h1 className='font-bold'>Contact us</h1>
            </header>
            <div className='right__box'>
              <Breadcrumbs type={'small'}></Breadcrumbs>
            </div>
          </section>
          <section className='m-headding-text__container'>
            <header>
              <h2>Contact us</h2>
            </header>
          </section>
          <section className='contact-us-form__container'>
            <ul className='form__box'>
              <div className='guide-phone__box'>
                <header>
                  <h3 style={{ marginRight: 17 }}>{t('INQUIRY')}</h3>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h3>02-6274-3600</h3>
                  </div>
                </header>
                <div className='time__box'>
                  <div className='item'>
                    <header>
                      <div className='ico next'></div>
                      <span className='font-bold'>{t('WEEKDAYS')}</span>
                    </header>
                    <span className='pl-2'>09:00 ~ 18:00</span>
                  </div>
                  <div className='item'>
                    <header>
                      <div className='ico next'></div>
                      <span className='font-bold'>{t('LUNCH')}</span>
                    </header>
                    <span className='pl-2'>12:00 ~ 13:00</span>
                  </div>
                </div>
              </div>
              <div className='guide-text__box'>
                <span>{t('CONTACT_US_GUIDE')}</span>
              </div>
              <TextInput value={formData.name} onChange={formChange} meta={formMeta.name}></TextInput>
              <TextInput
                value={formData.email}
                onChange={formChange}
                meta={{
                  key: 'email',
                  label: t('EMAIL'),
                  style: {},
                  className: 'point',
                  regexp: Handler.REGEXP.EMAIL,
                  required: true,
                }}
              ></TextInput>
              <TextInput
                value={formData.phone}
                onChange={(value, key) => {
                  const reg = new RegExp('^[0-9]{0,11}$');
                  const isValidation = reg.test(value);
                  if (isValidation) {
                    formChange(value, key);
                  }
                }}
                meta={formMeta.phone}
              ></TextInput>
              <TextArea value={formData.message} onChange={formChange} meta={formMeta.message}></TextArea>
              <div className='aggree__box'>
                <CheckBox
                  value={agree}
                  onChange={(value) => {
                    setAgree(value);
                  }}
                  meta={{ label: MSG.AGREE_PRIVACY_TERM }}
                ></CheckBox>
                <div className='option__box'>
                  <span
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    {t('SHOW')}
                  </span>
                </div>
              </div>
              <section className='button__box'>
                <Button style={{ width: '100%' }} onClick={(e) => onSubmit(e)} label={t('SUBMIT')}></Button>
              </section>
            </ul>
            <div className='map__box'>
              <div className='daum-map__box'>
                <div id='map' ref={mapRef} style={{ width: '665px', height: '665px' }}></div>
              </div>
              <div className='text__box'>
                <span>{t('COMPANY_POSTCODE')}</span>
                <span>{t('COMPANY_ADDRESS')}</span>
                <span>{t('CONTACT')} : 02-6274-3600, FAX : 02-6221-0801</span>
                <span>{t('EMAIL')} : info@pople.earth</span>
              </div>
            </div>
          </section>
        </article>
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <Box sx={deviceWidth > 1136 ? modalStyle : modalMobileStyle}>
            <TermService
              title={term.title}
              term={term.content}
              onClose={() => {
                setOpen(false);
              }}
            />
          </Box>
        </Modal>
      </article>
    </>
  );
};
