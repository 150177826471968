import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Button, TablePaging, MetaTag, TextInput, ModalCertification } from '../../components';
import { useLabel, usePagination } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { UserContext, SnackbarContext, ContentContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { genMypageRoutes } from '../../constants';
import { MypageNavList } from './components/MypageNavList';

const certification_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  borderRadius: 0,
};

const m_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  boxSizing: 'border-box',
};

export const PurchaseListPage = (props) => {
  const history = useHistory();
  const { deviceWidth } = useContext(ContentContext);
  const { t } = useTranslation();
  const { ERROR } = useLabel();
  const { userInfo, isPersonal } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);
  const [search, setSearch] = useState('');
  const [certificationOpen, setCertificationOpen] = useState(false);
  const [certificationData, setCertificationData] = useState(false);
  const [userMenu, setUserMenu] = useState(null);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });
  useEffect(() => {
    setMetaObject({
      title: '마이페이지 - 구매목록',
      description: '크레딧 구매목록',
      keywords: '팝플이란, 팝플',
      image: '',
      canonical: '',
      url: window.location.href,
    });
  }, []);

  console.log('list', list);
  console.log('userMenu', userMenu);

  useEffect(() => {
    requestNewList(page);
  }, [page]);

  useEffect(() => {
    requestSearch();
  }, []);

  useEffect(() => {
    if (userInfo) {
      setUserMenu(genMypageRoutes(isPersonal));
    }
  }, [userInfo, isPersonal]);

  const requestSearch = () => {
    requestNewList(0);
  };

  const requestNewList = (init_page) => {
    API.User.GetPaymentList({
      page: init_page === 0 ? init_page : page,
      limit: Handler.VARIABLES.PAGE_LIMIT,
      searchType: 'Title',
      searchValue: search,
    }).then(
      (res) => {
        let result = res.data;
        console.log('result', result);
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <div className='content__wrapper'>
          <MypageNavList userMenu={userMenu} />
          <section className='headding-text__container'>
            <header>
              <h1>{t('PURCHASEMENT_LIST')}</h1>
            </header>
            <div className='right__box'>
              <Breadcrumbs type={'small'}></Breadcrumbs>
            </div>
          </section>
          <section className='purchase-list__container'>
            <div className='serach__box'>
              <TextInput
                value={search}
                onChange={(value, key) => {
                  setSearch(value);
                }}
                onEnter={() => {
                  requestSearch();
                }}
                meta={{ style: { width: '446px' }, placeholder: '사업명을 입력해 주세요.', className: 'round' }}
              ></TextInput>
              <Button
                style={{ width: '80px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  requestSearch();
                }}
                label={t('SEARCH')}
              ></Button>
            </div>
            <section className='list__box'>
              <ul className='header'>
                <li>
                  <span>{t('ORDER_DATE')}</span>
                </li>
                <li>
                  <span>{t('ORDER_HISTORY')}</span>
                </li>
                <li>
                  <span>
                    {t('ORDER_NUMBER')} / {t('PURCHASE_AMOUNT')}
                  </span>
                </li>
                <li>
                  <span>{t('ORDER_DETAIL')}</span>
                </li>
              </ul>
              <ul className='data'>
                {list &&
                  list.map((item, index) => {
                    return (
                      <li>
                        <div className='date'>
                          <span>{Handler.getYYYYMMDDByUnix(item.createdAt)}</span>
                          <em>
                            {t('ORDER_NUMBER')} : {item.orderNumber}
                          </em>
                        </div>
                        {item.project && (
                          <div className='title'>
                            <div className='img__box'>
                              {item.project.mainImages && item.project.mainImages.length > 0 && (
                                <img src={item.project.mainImages[0]} alt={item.project.title}></img>
                              )}
                            </div>
                            <div
                              className='title__box'
                              onClick={() => {
                                history.push('/mypage/purchase_detail/' + item.seq);
                              }}
                            >
                              <span className='name'>{item.project.title}</span>
                              <span className='id'>{item.project.id}</span>
                              <div className='price'>
                                <span>
                                  {Handler.numberWithCommas(Math.floor(item.price / item.amount))}
                                  {t('WON')}
                                </span>
                                <span>·</span>
                                <span>{Handler.numberWithCommas(item.amount)}VRC</span>
                              </div>
                              <div className='total-price'>
                                <span>
                                  {Handler.numberWithCommas(item.price)}
                                  {t('WON')}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='order'>
                          <em>{item.orderNumber}</em>
                          <span>
                            {Handler.numberWithCommas(item.price)}
                            {t('WON')}
                          </span>
                        </div>
                        <div className='button'>
                          <div
                            className='detail-button'
                            onClick={() => {
                              history.push('/mypage/purchase_detail/' + item.seq);
                            }}
                          >
                            {t('ORDER_DETAIL')}
                          </div>
                          {item.cert && item.cert?.pdfUrl !== undefined ? (
                            <a className='cert-button' href={item.cert?.pdfUrl} download>
                              {t('REDUCTION_CERTIFICATE')}
                            </a>
                          ) : (
                            <div className='item-status'>
                              <p>
                                {item.type === 'TAXBILL'
                                  ? t('TAXBILL')
                                  : t(Handler.buildPaymentStatusText(item.status))}
                              </p>
                            </div>
                          )}
                        </div>
                      </li>
                    );
                  })}
                {list && list.length === 0 && (
                  <div className='empty'>
                    <div className='ico no-data'></div>
                    <span>{ERROR['NO_DATA_FOUND']}</span>
                  </div>
                )}
              </ul>
            </section>
          </section>

          <div style={{ height: 36 }}></div>
          <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
        </div>
        <Modal
          open={certificationOpen}
          onClose={() => {
            setCertificationOpen(false);
          }}
        >
          <Box sx={deviceWidth > 1136 ? certification_style : m_style}>
            <ModalCertification
              data={certificationData}
              onClose={() => {
                setCertificationOpen(false);
              }}
            ></ModalCertification>
          </Box>
        </Modal>
      </article>
    </>
  );
};
