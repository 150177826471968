import React, { useEffect, useContext, useState } from 'react';
import {
  Breadcrumbs,
  TablePaging,
  Button,
  DropdownInput,
  TableMethodology,
  TableMethodologyHistory,
} from '../../components';
import { usePagination } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 830,
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  boxSizing: 'border-box',
  borderRadius: 2,
  overflow: 'hidden',
};

export const MethodologyListPage = (props) => {
  const { updateSnackbar } = useContext(SnackbarContext);
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState('Title');
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(1, 10);
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [histroyList, setHistroyList] = useState(null);
  const [
    historyPage,
    totalHistoryPage,
    totalHistoryCount,
    onHistoryPageChange,
    setHistoryPage,
    setTotalHistoryPage,
    setTotalHistoryCount,
  ] = usePagination(1, 10);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log('page effect', page);
    if (page) {
      requestNewList();
    }
  }, [page]);

  useEffect(() => {
    if (selectedSlug) {
      console.log('historyPage', historyPage);
      if (historyPage) {
        requestNewHistoryList();
      }
    }
  }, [selectedSlug, historyPage]);

  useEffect(() => {
    if (histroyList && histroyList.length > 0 && historyPage > 0) {
      let modal_doc = document.getElementById('methodology-history__modal');
      if (modal_doc) {
        modal_doc.scrollTop = 0;
      }
    }
  }, [historyPage]);

  const requsetSearch = () => {
    requestNewList(1);
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedSlug(null);
    setHistoryPage(1);
  };

  const requestNewList = (init_page) => {
    API.Methodology.GetList({
      page: init_page ? init_page : page,
      limit: Handler.VARIABLES.PAGE_LIMIT,
      searchType: searchType,
      searchValue: search,
    }).then(
      (res) => {
        let result = res.data;
        console.log('result', result);
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestNewHistoryList = (init_page) => {
    console.log('requestNewHistoryList selectedSlug', selectedSlug);
    console.log('requestNewHistoryList historyPage', historyPage);

    API.Methodology.GetHistoryList(selectedSlug, {
      page: init_page ? init_page : historyPage,
      limit: 2,
      ordering: 'DESC',
    }).then(
      (res) => {
        let result = res.data;
        console.log('GetHistoryList result', result);
        setHistroyList(result.content);
        setTotalHistoryPage(result.totalPages);
        setOpen(true);
      },
      (err) => {
        closeModal();
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  // const tableRowClick = (index) => {
  //   console.log('tableRowClick index', index);
  //   let target_seq = list[index].seq;
  //   console.log('tableRowClick target_seq', target_seq);
  //   // history.push('/mypage/inquiry_detail/' + target_seq);
  // };

  const tableCallback = (type, value) => {
    console.log('tableCallback type', type);
    console.log('tableCallback value', value);

    if (type === 'selected_slug') {
      setSelectedSlug(value);
    }
  };

  return (
    <article className='basic-page'>
      <Breadcrumbs content='methodology' title={'방법론 현황'}></Breadcrumbs>
      <article className='content__wrapper'>
        <div className='search__container'>
          <DropdownInput
            value={search}
            menuValue={searchType}
            onChange={(value, key) => {
              setSearch(value);
            }}
            onMenuChange={(value, key) => {
              console.log('menu', value);
              setSearchType(value);
            }}
            onEnter={() => {
              requsetSearch();
            }}
            meta={{
              style: { width: '590px' },
              placeholder: '궁금하신 내용을 입력해 주세요.',
              menus: [
                {
                  value: 'Title',
                  label: '방법론명',
                },
                {
                  value: 'Id',
                  label: '방법론 고유번호',
                },
              ],
            }}
          ></DropdownInput>
          <Button
            style={{ width: '80px', height: '50px', marginLeft: 12 }}
            onClick={() => {
              requsetSearch();
            }}
            label='검색'
          ></Button>
        </div>
        <div className='border-grey-200'></div>
        <TableMethodology data={list} callback={tableCallback}></TableMethodology>
        <div style={{ height: 40 }}></div>
        <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
      </article>
      <Modal
        open={open}
        onClose={() => {
          closeModal();
        }}
      >
        <Box sx={style}>
          <article className='methodology-history__modal' id='methodology-history__modal'>
            <header>
              <h4>방법론 개정 이력</h4>
              <div
                onClick={() => {
                  closeModal();
                }}
                className='ico close-small'
              ></div>
            </header>
            <div className='table__box'>
              <TableMethodologyHistory data={histroyList}></TableMethodologyHistory>
            </div>
            <TablePaging page={historyPage} totalPage={totalHistoryPage} onChange={onHistoryPageChange}></TablePaging>
          </article>
        </Box>
      </Modal>
    </article>
  );
};
