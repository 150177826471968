import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { color } from '../../../styles/style';
import { ChartContainer, ChartHeader, ChartItem, RenewableChartWrapper } from '../../../styles/ComponentStyle';
import ChartPrice from './ChartPrice';
import ChartTitle from './ChartTitle';
import ChartTitleSkeleton from './ChartTitleSkeleton';
import styled from '@emotion/styled';

const ChartButton = styled.article`
  display: flex;
  margin-bottom: 20px;
  button {
    width: 100px;
    padding: 8px;
    border: 1px solid #eee;
    &:first-of-type {
      border-radius: 8px 0px 0px 8px;
    }
    &:last-of-type {
      border-radius: 0px 8px 8px 0px;
    }
    &.on {
      background-color: ${color.$main500};
      color: #fff;
    }
  }
`;

export const RenewablePrice = (props) => {
  const { charts = [], multi = false, title = '', content = [''], chart_title = '', loading } = props;
  const { t } = useTranslation();
  const [chartData, setChartData] = useState(null);
  const [selected, setSelected] = useState(0);
  const getUnit = (tag) => {
    if (tag === 'RPS') return 'SMP + 1REC';
    if (tag === 'REC') return 'REC';
    if (tag === 'SMP') return 'kWh';
    return '';
  };

  useEffect(() => {
    setChartData(charts[selected]);
  }, [selected]);

  useEffect(() => {
    if (charts && charts?.length) {
      setChartData(charts[0]);
    }
  }, [charts]);

  return (
    <>
      <ChartHeader>
        <h2 className='font-bold'>{title}</h2>
        {content.map((el, idx) => (
          <p key={idx + 'p'}>{el}</p>
        ))}
      </ChartHeader>
      {
        <RenewableChartWrapper>
          <ChartItem>
            <h2>{chart_title}</h2>
            {loading || !chartData ? (
              <ChartTitleSkeleton />
            ) : (
              <>
                <ChartTitle
                  date={chartData?.config.date}
                  rate={chartData?.config.change_rate}
                  currency={chartData?.config.currency}
                  change={chartData?.config.change}
                  head={chartData?.currency !== 'KRW'}
                  unit={getUnit(chartData?.config.tag)}
                  price={chartData?.data[chartData.data.length - 1].value}
                >
                  <ChartButton>
                    <button className={selected === 0 ? 'on' : ''} onClick={() => setSelected(0)}>
                      {t('LAND')}
                    </button>
                    <button className={selected === 1 ? 'on' : ''} onClick={() => setSelected(1)}>
                      {t('JEJU')}
                    </button>
                    {chartData?.config.tag === 'RPS' && (
                      <button className={selected === 2 ? 'on' : ''} onClick={() => setSelected(2)}>
                        {t('ALL')}
                      </button>
                    )}
                  </ChartButton>
                </ChartTitle>
                <ChartPrice data={chartData?.data} tag={chartData?.config.tag} width={'100%'} height={'400px'} />
              </>
            )}
          </ChartItem>
        </RenewableChartWrapper>
      }
    </>
  );
};

export default RenewablePrice;