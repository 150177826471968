import IconMonitor from '../styles/icon/icon_monitor.svg';
import IconReport from '../styles/icon/icon_report.svg';
import IconVerification from '../styles/icon/icon_verification.svg';
import IconDataCollect from '../styles/icon/icon_data_collect.svg';
import IconDataSave from '../styles/icon/icon_data_save.svg';
import IconDataCategory from '../styles/icon/icon_data_category.svg';
import IconDbSelect from '../styles/icon/icon_db_select.svg';
import IconPublishCredit from '../styles/icon/icon_publish_credit.svg';

export const STANDARD_PAGE_SECTION_TITLE = '팝플 스탠다드 요약';
export const STANDARD_LIST = [
  {
    title: 'POPLE_STANDARD_AC',
    content:
      'POPLE_STANDARD_AC_DESCRIPTION',
  },
  {
    title: 'POPLE_STANDARD_VISION',
    content:
      'POPLE_STANDARD_VISION_DESCRIPTION',
  },
  {
    title: 'POPLE_PROJECT_SCOPE',
    content:
      'POPLE_PROJECT_SCOPE_DESCRIPTION',
    sublist: Array(5).fill().map((_, idx) => `POPLE_PROJECT_SCOPE_ITEM_0${idx + 1}`)
  },
  {
    title: 'POPLE_RULE',
    content:
      'POPLE_RULE_DESCRIPTION',
  },
  {
    title: 'POPLE_METHODOLOGY',
    content:
      'POPLE_METHODOLOGY_DESCRIPTION_01',
    sublist: Array(3).fill().map((_, idx) => `POPLE_METHODOLOGY_ITEM_0${idx + 1}`),
    content2:
      'POPLE_METHODOLOGY_DESCRIPTION_02',
  },
  {
    title: 'POPLE_GOVERNANCE',
    content:
      'POPLE_GOVERNANCE_DESCRIPTION',
  },
  {
    title: 'POPLE_PROJECT',
    content:
      'POPLE_PROJECT_DESCRIPTION_01',
      sublist: Array(3).fill().map((_, idx) => `POPLE_METHODOLOGY_ITEM_0${idx + 1}`),
    content2:
      'POPLE_PROJECT_DESCRIPTION_02',
  },
  {
    title: 'POPLE_REGISTRY',
    content:
      'POPLE_REGISTRY_DESCRIPTION',
  },
  {
    title: 'POPLE_MARKETPLACE',
    content:
      'POPLE_MARKETPLACE_DESCRIPTION',
  },
  {
    title: 'VRC (Verified Removal(Reduction) Credit)',
    content:
      'VRC_LONG_DESCRIPTION',
  },
];

export const DIGITAL_MRV_PHRASE =
  '디지털 MRV는 탄소 감축량 산정을 위한\n모든 데이터를 디지털화하여\n탄소 배출량을 자동으로 측정·산정하는\n혁신적인 시스템입니다.';
export const DIGITAL_MRV_LIST = [
  {
    key: 'MONITORING',
    value: 'MONITORING_DESCRIPTION',
    icon: IconMonitor,
  },
  {
    key: 'REPORTING',
    value: 'REPORTING_DESCRIPTION',
    icon: IconReport,
  },
  {
    key: 'VERIFICATION',
    value: 'VERIFICATION_DESCRIPTION',
    icon: IconVerification,
  },
];

export const DIGITAL_MRV_API = '데이터 수집 인터페이스 체계';
export const DIGITAL_MRV_API_DESC =
  '팝플의 디지털 MRV는 탄소 감축 사업자의 비즈니스 현황에 맞는\n데이터 수집 인터페이스를 제공합니다.';
export const DIGITAL_MRV_API_LIST = [
  {
    key: 'API_BASED_INTERFACE',
    value: 'API_BASED_INTERFACE_DESCRIPTION',
  },
  {
    key: 'DATA_CONDITION_INTERFACE',
    value: 'DATA_CONDITION_INTERFACE_DESCRIPTION',
  },
];

export const DIGITAL_MRV_PROCESS = '탄소 감축량 자동 산정\n시스템 프로세스';

export const DIGITAL_MRV_PROCESS_LIST = [
  {
    key: 'DATA_COLLECT_INPUT',
    value:
      'DATA_COLLECT_INPUT_DESCRIPTION',
    icon: IconDataCollect,
  },
  {
    key: 'DATA_SAVE',
    value: 'DATA_SAVE_DESCRIPTION',
    icon: IconDataSave,
  },
  {
    key: 'DATA_CLASSIFICATION',
    value:
      'DATA_CLASSIFICATION_DESCRIPTION',
    icon: IconDataCategory,
  },
  {
    key: 'INVENTORY_DB',
    value: 'INVENTORY_DB_DESCRIPTION',
    icon: IconDbSelect,
  },
  {
    key: 'EVALUATE_PUBLISH',
    value: 'EVALUATE_PUBLISH_DESCRIPTION',
    icon: IconPublishCredit,
  },
];
export const DIGITAL_MRV_STRENGTH = '디지털 MRV 장점';
export const DIGITAL_MRV_STRENGTH_LIST = Array(5).fill().map((_, idx) => `DIGITAL_MRV_STRENGTH_ITEM_0${idx+1}`)

// export const DIGITAL_MRV_STRENGTH_LIST = [
//   '데이터 입력∙감축량 산정∙크레딧 발행 자동화 시스템을 통해 인적오류 및 데이터 손실 가능성을 최소화합니다.',
//   '실시간 모니터링 시스템으로 데이터 이상 패턴을 감지하여 신뢰도 높은 양질의 데이터만을 확보합니다.',
//   '디지털 데이터를 반영한 프로세스 자동화로 시간과 비용이 절감됩니다.',
//   '검/인증 등 크레딧 발행 전까지의 절차를 투명하게 공개하여 감축 사업자 및 크레딧 구매자 등 시장 참여자들이\n신뢰도 있는 크레딧을 거래할 수 있습니다.',
//   '크레딧 이중 계산 및 이중 발행을 방지하여 시장 내 크레딧 가치를 유지해줍니다.',
// ];
