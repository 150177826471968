import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import {
  PAGE_TOP,
  BRAND_KEYWORD_GRAPH,
  BRAND_KEYWORD_BG,
  BRAND_COLOR_MOTIF_IMAGE,
  BRAND_LOGO_STORY_IMAGE,
  BRAND_COLOR_SYSTEM_LIST,
  BRAND_LOGO_GUIDE_LIST,
  BRAND_KEYWORD_GRAPH_EN,
  BRAND_COLOR_MOTIF_IMAGE_EN
} from '../../constants';
import styled from '@emotion/styled';
import { PageTitle, SectionTitle, MetaTag } from '../../components';
import { ContentContext, LangContext } from '../../contexts';
import { color, font, fontSize, media } from '../../styles/style';

const KeywordWrapper = styled.section`
  padding: 80px 40px;
  background-image: url(${BRAND_KEYWORD_BG});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  min-height: 700px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    color: #fff;
  }
  .image-box {
    height: 600px;
    padding: 60px;
    img {
      height: 100%;
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    min-height: 250px;
    padding: 60px 24px;
    background-size: cover;
    h2 {
      padding-bottom: 40px;
    }
    .image-box {
      height: 210px;
      padding: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const BrandSection = styled.section`
  padding: 140px 0;
  width: 100%;
  min-width: ${media.$wrapper_min_width};
  max-width: ${media.$wrapper_max_width};
  h2,
  p {
    text-align: ${(prop) => (prop.align ? prop.align : 'left')};
  }
  p {
    margin-top: 24px;
  }
  .section-img {
    padding-top: 140px;
    &.color {
      height: 400px;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: auto;
        height: 100%;
      }
    }
    img {
      width: 100%;
    }
  }
  .section-header {
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 60px 24px;
    min-width: unset;
    text-align: center;
    .section-img {
      padding-top: 40px;
      &.color {
        height: 180px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .section-header {
      justify-content: center;
      flex-direction: column;
      h2,
      p {
        text-align: center;
      }
    }
    p {
      word-break: keep-all;
    }
  }
`;

const BrandColorSystem = styled.section`
  width: 100%;
  display: flex;
  padding-top: 60px;
  div.box {
    width: 100%;
    min-height: 500px;
    &.purple {
      background: ${color.$main500};
      display: flex;
      align-items: flex-end;
      justify-content: end;
      .color-box {
        p {
          color: #fff;
          padding: 0 40px;
        }
      }
    }
    &.variation {
      display: flex;
      flex-direction: column;
      .box-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: end;
        .color-box {
          height: 100%;
          width: 100%;
          align-items: flex-start;
          color: ${color.$main500};
          &:first-of-type {
            background-color: ${color.$main300};
          }
          &:last-of-type {
            background-color: ${color.$main100};
          }
        }
      }
    }
    .color-box {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: end;
      p {
        padding: 0 40px;
        &:last-of-type {
          padding-bottom: 30px;
        }
      }
      p.name {
        font-size: ${fontSize(font.$text_5xl)};
        font-weight: 800;
        text-align: right;
      }
    }
  }
  @media only screen and (max-width: ${media.$md}) {
    flex-direction: column;
    div.box {
      min-height: 360px;
      &.purple {
        justify-content: flex-start;
        .color-box p {
          padding-left: 24px;
        }
      }
      &.variation {
        min-height: 120px;
        .box-wrapper {
          flex-direction: row;
          .color-box {
            padding: 24px 0;
            p {
              padding: 0 0 0 24px;
              text-align: left;
            }
            p.name {
              padding-top: 24px;
            }
          }
        }
      }
      .color-box {
        align-items: flex-start;
        p.color {
          font-size: ${fontSize(font.$text_xs)};
        }
        p.name {
          font-size: ${fontSize(font.$text_4xl, media.$sm)};
          padding: 24px;
        }
      }
    }
  }
`;

const BrandColorSection = styled.section`
  text-align: center;
  display: block;
  width: 100%;
`;

const BrandLogoTable = styled.article`
  table {
    width: 100%;
    margin-top: 40px;
    tr {
      border-bottom: 1px solid #eee;
      &:last-child {
        border: 0;
      }
      th {
        border-right: 1px solid #eee;
        p {
          font-weight: 400;
          margin: 0;
        }
      }
      th,
      td {
        padding: 24px;
        vertical-align: middle;
        p.title {
          font-weight: 800;
        }
      }
      td {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: inherit;
        min-height: 140px;
        p {
          text-align: center;
        }
        .icon {
          height: 50px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .slogan {
            color: ${color.$black_800};
            margin-right: 20px;
          }
          img {
            height: 100%;
            object-position: center;
          }
        }
      }
    }
  }
`;
const BrandLogoItems = styled.article`
  padding-top: 60px;
  div.logo-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    margin-bottom: 40px;
    h4,
    p {
      text-align: center;
    }
    h4 {
      position: relative;
      display: block;
      width: 100%;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: -20px;
        width: 40px;
        height: 5px;
        background: #999;
      }
    }
    .logo-image {
      img {
        padding: 20px;
      }
    }
  }
`;

export const BrandPage = (props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation()
  const { deviceWidth } = useContext(ContentContext);
  const { isKorean } = useContext(LangContext);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });

  useEffect(() => {
    setMetaObject({
      title: t('BRAND_INDENTITY'),
      description: t('BRAND_INDENTITY_DESCRIPTION'),
      keywords: '팝플이란, 팝플',
      image: PAGE_TOP.BRAND.image,
      canonical: '',
      url: window.location.href,
    });
  }, []);

  return (
    <>
      <MetaTag props={metaObject} />{' '}
      <article className='basic-page'>
        <PageTitle
          title={t('BRAND_INDENTITY')}
          description={t('BRAND_INDENTITY_DESCRIPTION')}
          image={PAGE_TOP.BRAND.image}
          preview={PAGE_TOP.BRAND.preview}
        />
        <KeywordWrapper>
          <h2 className='font-bold'>{t('BRAND_KEYWORD')}</h2>
          <div className='image-box'>
            <img src={isKorean ? BRAND_KEYWORD_GRAPH : BRAND_KEYWORD_GRAPH_EN} alt='' />
          </div>
        </KeywordWrapper>
        <BrandSection align=''>
          <SectionTitle title={t('LOGO_STORY')}></SectionTitle>
          <p>{t('LOGO_STORY_DESCRIPTION')}</p>
          <div className='section-img'>
            <img src={BRAND_LOGO_STORY_IMAGE} alt='' />
          </div>
        </BrandSection>
        <BrandSection align='center'>
          <SectionTitle title={t('COLOR_MOTIF')}></SectionTitle>
          <p>{t('COLOR_MOTIF_DESCRIPTION')}</p>
          <div className='section-img color'>
            <img src={isKorean ?BRAND_COLOR_MOTIF_IMAGE : BRAND_COLOR_MOTIF_IMAGE_EN} alt='' />
          </div>
        </BrandSection>
        <BrandColorSection>
          <SectionTitle title={t('COLOR_SYSTEM')}></SectionTitle>
          <BrandColorSystem>
            <div className='box purple'>
              <div className='color-box'>
                <p className='color'>{BRAND_COLOR_SYSTEM_LIST[0].hex}</p>
                <p className='color'>{BRAND_COLOR_SYSTEM_LIST[0].rgb}</p>
                <p className='color'>{BRAND_COLOR_SYSTEM_LIST[0].cmyk}</p>
                <p className='name'>{BRAND_COLOR_SYSTEM_LIST[0].name}</p>
              </div>
            </div>
            <div className='box variation'>
              <div className='box-wrapper'>
                {BRAND_COLOR_SYSTEM_LIST.filter((_, idx) => idx > 0).map((item) => {
                  return (
                    <div key={`brand-color-list-${item.name}`} className='color-box'>
                      <p className='color'>{item.hex}</p>
                      <p className='color'>{item.rgb}</p>
                      <p className='color'>{item.cmyk}</p>
                      <p className='name'>{item.name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </BrandColorSystem>
        </BrandColorSection>
        <BrandSection>
          <article className='section-header'>
            <div>
              <SectionTitle title={t('LOGO_GUIDE')}></SectionTitle>
              <p>{t('LOGO_GUIDE_DESCRIPTION')}</p>
            </div>
          </article>
          {deviceWidth > 1400 ? (
            <BrandLogoTable className='section-table'>
              <table>
                <tbody>
                  {BRAND_LOGO_GUIDE_LIST &&
                    BRAND_LOGO_GUIDE_LIST.map((logo, index) => {
                      return (
                        <tr key={`logo-icon-guide-list-${index}`}>
                          <th>
                            <p className='title'>{t(logo.title)}</p>
                            <p>{t(logo.desc)}</p>
                          </th>
                          <td>
                            {logo.icon &&
                              logo.icon.map((icon, idx) => {
                                return (
                                  <article key={`logo-icon-list-${idx}`}>
                                    <div className='icon'>
                                      {logo.title === 'LOGO_TYPE_SLOGAN_MIX' && (
                                        <span className='slogan'>{idx === 0 ? t('LOGO_TYPE_SLOGAN_VERTICAL') : t('LOGO_TYPE_SLOGAN_HORIZONTAL')}</span>
                                      )}
                                      <img src={icon} alt='' />
                                    </div>
                                    {index === 3 && (
                                      <div>
                                        <p className='title'>{t(logo.icon_desc[idx].key)}</p>
                                        <p>{t(logo.icon_desc[idx].value)}</p>
                                      </div>
                                    )}
                                  </article>
                                );
                              })}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </BrandLogoTable>
          ) : (
            <BrandLogoItems>
              {BRAND_LOGO_GUIDE_LIST &&
                BRAND_LOGO_GUIDE_LIST.map((logo) => {
                  if (logo.mobile) {
                    return (
                      <div key={`brand-logo-guide-${logo.title}`} className='logo-items'>
                        <h4>{t(logo.title)}</h4>
                        <p>{t(logo.desc)}</p>
                        {logo.icon &&
                          logo.icon.map((item, index) => {
                            return (
                              <div className='logo-image'>
                                {logo.icon_desc && <p>{t(logo.icon_desc[index].key)}</p>}
                                {logo.icon_desc && <p>{t(logo.icon_desc[index].value)}</p>}
                                <img src={item} alt='' />
                              </div>
                            );
                          })}
                      </div>
                    );
                  }
                })}
            </BrandLogoItems>
          )}
        </BrandSection>
      </article>
    </>
  );
};