import React, { Suspense, useEffect } from 'react';
import Routers from './Routers';
import Provider from './Provider';
import './styles/index.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DialogLayer, SnackbarLayer } from './layers';

import { GlobalDebug } from "./remove-consoles"

const theme = createTheme({
  palette: {
    primary: {
      main: '#5d3be5',
    },
    secondary: {
      main: '#f41515',
    },
    info: {
      main: '#777',
    },
  },
});


const App = () => {
  
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      GlobalDebug(false, false); 
    } else {
      GlobalDebug(true, true); 
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider>
        <DialogLayer></DialogLayer>
        <SnackbarLayer></SnackbarLayer>
        <Suspense fallback={<div>로딩중...</div>}>
          <Routers></Routers>
        </Suspense>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
