import React from 'react';
import Handler from '../../Handler';

export const BasicTab = (props) => {
  const { index, onChange, meta } = props;

  return (
    <article
      className={Handler.BuildArticleClassname('basic-tab', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      <ul>
        {meta &&
          meta.menus.map((item, menu_index) => {
            return (
              <li
                onClick={() => {
                  onChange(menu_index);
                }}
                className={index === menu_index ? 'active' : ''}
              >
                <span>{item}</span>
              </li>
            );
          })}
      </ul>
    </article>
  );
};
