import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PageTitle, SectionTitle, MetaTag } from '../../components';
import { color, media, font, fontSize } from '../../styles/style';
import {
  PAGE_TOP,
  MARKET_INTRO_LIST,
  MARKET_PROCESS_LIST,
  POPLE_MORE_LIST,
} from '../../constants';
import MarketplaceMoreBg from '../../styles/images/market_more_bg.png';
import PurpleArrowSmall from '../../styles/icon/purple_arrow_small.svg';
import WhiteArrow from '../../styles/icon/white_arrow.svg';
import styled from '@emotion/styled';

export const MarketplaceIntroPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation()
  const [leftList, setLeftList] = useState([]);
  const [rightList, setRightList] = useState([]);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });

  useEffect(() => {
    setLeftList(MARKET_PROCESS_LIST.filter((_, idx) => idx < 3));
    setRightList(MARKET_PROCESS_LIST.filter((_, idx) => idx >= 3));
    setMetaObject({
      title: t('MARKETPLACE'),
      description: t('MARKETPLACE_DESCRIPTION'),
      keywords: '팝플이란, 팝플',
      image: PAGE_TOP.MARKET.image,
      canonical: '',
      url: window.location.href,
    });
  }, []);

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <PageTitle
          title={t('MARKETPLACE')}
          description={t('MARKETPLACE_DESCRIPTION')}
          image={PAGE_TOP.MARKET.image}
          preview={PAGE_TOP.MARKET.preview}
        />
        <section className='h-full'>
          <div className='flex justify-center w-screen max-md:p-16 p-40'>
            <h2 className='max-md:text-2xl text-3xl whitespace-pre-wrap text-center leading-10 text-gray-700'>
              {t('MARKETPLACE_SLOGAN_BLACK_01')}
              <span className='max-md:text-2xl text-3xl font-extrabold text-primary'>{t('MARKETPLACE_SLOGAN_PRIMARY')}</span>
              {t('MARKETPLACE_SLOGAN_BLACK_02')}
            </h2>
          </div>
          <section className='w-full m-auto min-w-screen-xl max-w-screen-xl'>
            <h2 className='text-primary text-center font-bold'>{t('MARKETPLACE_WORKS')}</h2>
            <h2 className='text-xl mt-4 text-center whitespace-pre-wrap'>{t('MARKETPLACE_WORKS_DESCRIPTION')}</h2>
            <section className='max-w-screen-middle m-auto md:flex justify-center py-24'>
              {MARKET_INTRO_LIST &&
                MARKET_INTRO_LIST.map((item, index) => {
                  return (
                    <article className='flex flex-wrap justify-between max-md:flex-col max-md:items-center max-md:justify-center items-baseline relative w-full min-w-64' key={'process-item' + index}>
                      <div className='flex flex-wrap flex-col justify-center text-center items-center w-4/5'>
                        <div className='flex flex-col items-center text-center h-48'>
                          <span className='bg-main-200 p-2 w-36 text-main-800 text-sm font-bold rounded-lg'>{t(item.label)}</span>
                          <img className='object-fill w-40 p-2' src={item.icon} alt={item.title} />
                        </div>
                        <div>
                          <h3 className='text-gray-800 font-bold mb-3'>{t(item.title)}</h3>
                          <p className='text-gray-600'>{t(item.desc)}</p>
                        </div>
                      </div>
                      {index !== 3 && (
                        <div className='flex-wrap w-1/5 max-md:mt-3 max-md:rotate-90 relative flex' style={{ width: '10px'}}>
                          <img src={PurpleArrowSmall} alt='' className='inherit w-full h-full' />
                        </div>
                      )}
                    </article>
                  );
                })}
            </section>
          </section>
        </section>
        <MarketPlaceBuyCredit>
          <div className='section-title'>{t('HOW_TO_BUY_CARBON_CREDIT')}</div>
          <BuyProcess>
            <article className='buy-process'>
              {leftList &&
                leftList.map((item, index) => {
                  return (
                    <div key={`leftList-intro-${index}`} className='buy-process-item'>
                      <p className='number'>0{index + 1}.</p>
                      <div>
                        <h3 className='font-bold'>{t(item.key)}</h3>
                        <p className='text-gray-600'>{t(item.value)}</p>
                      </div>
                    </div>
                  );
                })}
            </article>
            <article className='buy-process'>
              {rightList &&
                rightList.map((item, index) => {
                  return (
                    <div key={`rightList-intro-${index}`} className='buy-process-item'>
                      <p className='number'>0{index + 4}.</p>
                      <div>
                        <h3 className='font-bold'>{t(item.key)}</h3>
                        <p className='text-gray-600'>{t(item.value)}</p>
                      </div>
                    </div>
                  );
                })}
            </article>
          </BuyProcess>
        </MarketPlaceBuyCredit>
        <PopleMore>
          <h2>{t('SEE_MORE_IN_POPLE')}</h2>
          <section className='more-container'>
            <article className='more-wrapper'>
              {POPLE_MORE_LIST &&
                POPLE_MORE_LIST.map((item, index) => {
                  return (
                    <article key={`pople-more-list-${index}`}>
                      <div className='upper'>
                        <div>
                          <p className='title'>{t(item.title)}</p>
                          <p className='desc'>{t(item.desc)}</p>
                        </div>
                        <div
                          className='arrow'
                          onClick={() => {
                            history.push(item.path);
                          }}
                        >
                          <img src={WhiteArrow} alt='' />
                        </div>
                      </div>
                      <div className='under'>
                        <img src={item.icon} alt='' />
                      </div>
                    </article>
                  );
                })}
            </article>
          </section>
        </PopleMore>
      </article>
    </>
  );
};

const MarketPlaceBuyCredit = styled.section`
  padding: 140px 0;
  width: 100%;
  margin: auto;
  min-width: ${media.$wrapper_min_width};
  max-width: ${media.$wrapper_max_width};
  .section-title {
    text-align: center;
    background: ${color.$main500};
    color: ${color.$white};
    border-radius: 50px;
    padding: 10px;
  }
  @media only screen and (max-width: ${media.$sm}) {
    min-width: unset;
    max-width: unset;
    padding: 60px 24px;
  }
`;

const BuyProcess = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  .buy-process {
    padding: 60px 40px;
  }
  .buy-process-item {
    display: flex;
    padding: 40px 0;
    p.number {
      font-size: ${font.$text_5xl};
      font-weight: ${font.bold};
      color: ${color.$main500};
      padding-right: 20px;
    }
    h3 {
      padding-bottom: 20px;
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    grid-template-columns: 1fr;
    .buy-process {
      padding: 10px 0 0 0;
    }
    .buy-process-item {
      padding: 20px 0;
    }
  }
`;

const PopleMore = styled.section`
  background-image: url(${MarketplaceMoreBg});
  background-size: cover;
  width: 100%;
  text-align: center;
  /* height: 100vh; */
  padding: 140px;
  h2 {
    color: ${color.$white};
  }
  section.more-container {
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    margin-top: 20px;
    .more-wrapper {
      display: grid;
      /* grid-template-columns: 1fr 1fr 1fr; */
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      width: 100%;
      margin: auto;
      padding: 30px;
      min-width: ${media.$wrapper_min_width};
      max-width: ${media.$wrapper_max_width};
      article {
        display: flex;
        flex-direction: column;
        border-right: 2px solid rgba(255, 255, 255, 0.2);
        &:last-child {
          border: none;
        }
        padding: 30px;
        .upper {
          text-align: left;
          display: flex;
          justify-content: space-between;
          p {
            color: ${color.$white};
            &.title {
              font-size: ${font.$title_02};
              padding-bottom: 16px;
            }
          }
          .arrow {
            cursor: pointer;
          }
        }
        .under {
          display: flex;
          align-items: flex-end;
          justify-content: end;
          width: 100%;
          img {
            width: 90px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 60px 24px;
    section.more-container {
      .more-wrapper {
        grid-template-columns: 1fr;
        min-width: unset;
        max-width: unset;
        padding: 0;
        article {
          border-right: 0;
          border-bottom: 2px solid rgba(255, 255, 255, 0.2);
          .upper {
            font-size: ${fontSize(font.$text_base, media.$sm)};
            p.title {
              font-size: ${fontSize(font.$text_xl, media.$sm)};
            }
          }
        }
      }
    }
  }
`;
