import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../../api/Config';
import styled from '@emotion/styled';
import { SnackbarContext } from '../../contexts';
import KakaoIcon from '../../styles/icon/sns_kakao.svg';
import TwitterIcon from '../../styles/icon/sns_x.svg';
import FaceBookIcon from '../../styles/icon/sns_facebook.svg';
import LinkIcon from '../../styles/icon/sns_link.svg';
import { useLabel } from '../../hooks';
// import ReactGA from 'react-ga';

const SocialWrapper = styled.div`
  display: flex;
`;
const SocialButton = styled.div`
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  .thumbnail {
    width: 40px;
    height: 40px;
    img {
      width: 100%;
    }
  }
  p {
    font-size: 14px;
    margin-top: 8px;
  }
`;

export const Social = ({ props }) => {
  const { t } = useTranslation();
  const { SOCIAL } = useLabel();
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const { updateSnackbar } = useContext(SnackbarContext);
  const kakaoShare = () => {
    // ReactGA.event({
    //   category: 'Share',
    //   action: 'Kakao',
    // });

    // const apiKey = process.env.REACT_APP_KAKAO_MAP_KEY;
    const apiKey = process.env.REACT_APP_KAKAO_KEY;
    if (window.Kakao) {
      const kakao = window.Kakao;
      if (!kakao.isInitialized()) {
        kakao.init(apiKey);
      }
      kakao.Link.sendCustom({
        templateId: 84682,
        templateArgs: {
          title: props.title,
          description: props.description,
          image: `${BASE_URL}${props.image}`,
          url: props.canonical,
        },
      });
    }
  };

  const facebookShare = () => {
    // ReactGA.event({
    //   category: 'Share',
    //   action: 'Facebook',
    // });
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(props.url)}&quote=${encodeURIComponent(
        props.title,
      )}`,
      'facebook',
      'width=500,height=600',
    );
  };
  const twitterShare = () => {
    // ReactGA.event({
    //   category: 'Share',
    //   action: 'Twitter',
    // });
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(props.url)}&text=${encodeURIComponent(
        props.title,
      )}&hashtags=팝플,그리너리,탄소크레딧`,
      'twitter',
      'width=500,height=600',
    );
  };
  const linkCopy = () => {
    // ReactGA.event({
    //   category: 'Share',
    //   action: 'Link',
    // });
    window.navigator.clipboard.writeText(props.url);
    updateSnackbar({
      type: 'success',
      open: true,
      message: '클립보드에 복사되었습니다.',
    });
  };

  const sns = [
    {
      name: SOCIAL.KAKAO,
      src: KakaoIcon,
      callback: kakaoShare,
    },
    {
      name: SOCIAL.FACEBOOK,
      src: FaceBookIcon,
      callback: facebookShare,
    },
    {
      name: SOCIAL.TWITTER,
      src: TwitterIcon,
      callback: twitterShare,
    },
    {
      name: SOCIAL.LINK,
      src: LinkIcon,
      callback: linkCopy,
    },
  ];
  const snsList = sns.map((el, index) => (
    <SocialButton key={index} onClick={el.callback}>
      <div className='thumbnail'>
        <img src={el.src} alt={el.name} />
      </div>
      <p>{el.name}</p>
    </SocialButton>
  ));
  return <SocialWrapper>{snsList}</SocialWrapper>;
};
