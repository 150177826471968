import FootprintTitleBg from '../styles/images/title/footprint_title_bg.png';
import PriceTitleBg from '../styles/images/title/price_title_bg.png';
import Email from '../styles/icon/footprint_email.svg';
import Hand from '../styles/icon/footprint_hand.svg';
import Vod from '../styles/icon/footprint_vod.svg';
import Public from '../styles/icon/footprint_public.svg';
import Vegan from '../styles/icon/footprint_vegan.svg';
import Bag from '../styles/icon/footprint_bag.svg';
import Shower from '../styles/icon/footprint_shower.svg';
import Plane from '../styles/icon/footprint_plane.svg';
import Temp from '../styles/icon/footprint_temp.svg';

import IMG_MAIL from '../styles/images/footprint/mail.png';
import IMG_VIDEO from '../styles/images/footprint/video.png';
import IMG_HAND from '../styles/images/footprint/hand.png';
import IMG_PLANE from '../styles/images/footprint/plane.png';
import IMG_BUS from '../styles/images/footprint/bus.png';
import IMG_PHONE from '../styles/images/footprint/phone.png';
import IMG_SHOWER from '../styles/images/footprint/shower.png';
import IMG_HEAT from '../styles/images/footprint/heat.png';
import IMG_VEGETABLE from '../styles/images/footprint/vegetable.png';

import KOR from '../styles/icon/kor_flag.svg';
import EUR from '../styles/icon/eur_flag.svg';
import GLOBAL from '../styles/icon/global_flag.svg';
import KODEX from '../styles/icon/kodex_flag.svg';
import SNP from '../styles/icon/snp_flag.svg';

export const TREND_NEWS_TITLE = '';
export const TREND_NEWS_TITLE_DESC = '';

export const FOOTPRINT_TITLE = '탄소발자국 줄이기';
export const FOOTPRINT_TITLE_DESC = '일상 속에서 탄소 감축을 실천해 보세요.';
export const FOOTPRINT_TITLE_BG = FootprintTitleBg;
export const PRICE_TITLE_BG = PriceTitleBg;

export const FOOTPRINT_PHRASE = '탄소 감축은 우리의 일상에서부터 시작됩니다.';
export const FOOTPRINT_PHRASE_DESC = [
  '‘탄소발자국’을 아시나요?\n내가 걸어온 길에 발자국이 남는 것처럼\n일상 속 다양한 소비 활동은 탄소발자국을 남게 합니다.',
  '일상 속 작은 변화로 탄소발자국을 줄여보세요.\n우리의 행동이 모이면 탄소중립은 더욱 가까워집니다.',
];

export const FOOTPRINT_LIST = [
  { key: 'FOOTPRINT_ITEM_01', value: '14.6 kg', icon: Email, img: IMG_MAIL, hover: false },
  { key: 'FOOTPRINT_ITEM_02', value: '2.2 kg', icon: Vod, img: IMG_VIDEO, hover: false },
  { key: 'FOOTPRINT_ITEM_03', value: '10.5 kg', icon: Hand, img: IMG_HAND, hover: false },
  { key: 'FOOTPRINT_ITEM_04', value: '1.68 t', icon: Plane, img: IMG_PLANE, hover: false },
  { key: 'FOOTPRINT_ITEM_05', value: '40 g', icon: Public, img: IMG_BUS, hover: false },
  { key: 'FOOTPRINT_ITEM_06', value: '0.3 kg', icon: Bag, img: IMG_PHONE, hover: false },
  { key: 'FOOTPRINT_ITEM_07', value: '7 kg', icon: Shower, img: IMG_SHOWER, hover: false },
  { key: 'FOOTPRINT_ITEM_08', value: '231 kg', icon: Temp, img: IMG_HEAT, hover: false },
  { key: 'FOOTPRINT_ITEM_09', value: '0.8 kg', icon: Vegan, img: IMG_VEGETABLE, hover: false },
];

export const CHART_ICON = (word) => {
  if (word.startsWith('KAU')) return KOR;
  if (word.startsWith('EU')) return EUR;
  if (word.startsWith('CBL')) return GLOBAL;
  if (word.startsWith('KODEX')) return KODEX;
  if (word.startsWith('S&P')) return SNP;
}
