import React from 'react';
import styled from '@emotion/styled';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ToastViewer, FileMultipleViewer } from '../../components';
import { PopleAccordion } from '../../styles/ComponentStyle';
import { color, media } from '../../styles/style';
import Handler from '../../Handler';
const FaqAccordion = styled(PopleAccordion)`
  .notice-summary {
    .category {
      color: ${color.$black_800};
      padding-bottom: 10px;
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
  }
`;

export const BoardAccordion = (props) => {
  const { data, type, init } = props;

  const renderSummary = (data) => {
    if (type === 'notice') {
      return (
        <div className={'notice-summary'}>
          <div className='first'>
            {/* <div className='seq'>{data.seq}</div> */}
            <div className='title'>
              <p className='date'>{Handler.getYYYYMMDDByUnix(data.createdAt)}</p>
              <span className='ellipsis-text'>{data.title}</span>
            </div>
          </div>
          {/* <div className='second'>
            <div className='date'>{Handler.getYYYYMMDDByUnix(data.createdAt)}</div>
          </div> */}
        </div>
      );
    } else if (type === 'faq') {
      return (
        <div className={'notice-summary'}>
          <div className='first'>
            {/* <div className='seq'>{data.seq}</div> */}
            <div className='category'>{data.category}</div>
            <div className='title'>
              <span className='ellipsis-text'>{data.title}</span>
            </div>
          </div>
          {/* <div className='second'>
            <div className='date'>{Handler.getYYYYMMDDByUnix(data.createdAt)}</div>
          </div> */}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <article className=''>
      <div className='accordion__box'>
        {data && data.length === 0 && (
          <div className='empty__box'>
            <span>데이터가 없습니다.</span>
          </div>
        )}
        <FaqAccordion>
          {data &&
            data.map((item, index) => {
              let isInit = false;

              if (init && Number(init) === item.seq) {
                isInit = true;
              }
              return (
                <Accordion key={'accordion-item' + index} className={item.isTop ? 'top' : ''} defaultExpanded={isInit}>
                  <AccordionSummary expandIcon={<div className='ico arrow-down'></div>}>
                    {renderSummary(item)}
                  </AccordionSummary>
                  <AccordionDetails>
                    <ToastViewer value={item.content} meta={{}}></ToastViewer>
                    {item.files && item.files.length > 0 && (
                      <FileMultipleViewer value={item.files} meta={{ label: '첨부파일' }}></FileMultipleViewer>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </FaqAccordion>
      </div>
    </article>
  );
};
