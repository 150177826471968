import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './locales/i18n';
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>,
  //   <React.StrictMode>

  // </React.StrictMode>,
);
reportWebVitals();
