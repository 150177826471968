import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import { MetaTag } from '../../components';
import { SnackbarContext, UserContext } from '../../contexts';
import API from '../../api';

export const UserSignUpGuidePage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { updateAccessToken, updateRefreshToken, userInfo } = useContext(UserContext);
  const [tablIndex, setTablIndex] = useState(0);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });
  useEffect(() => {
    setMetaObject({
      title: t('SIGN_UP'),
      description: t('SIGN_UP'),
      keywords: '팝플이란, 팝플',
      image: '',
      canonical: '',
      url: window.location.href,
    });
  }, []);

  const responseGoogle = (e) => {
    let access_token = e.tokenId;
    API.User.PostSigninGoogle({
      googleToken: access_token,
      keeping: true,
    }).then(
      (res) => {
        const { seq, tokenRes } = res.data;
        if (tokenRes) {
          updateAccessToken(tokenRes.accessToken);
          updateRefreshToken(tokenRes.refreshToken);
        } else {
          history.push('/add_info?seq=' + seq);
        }
      }).catch(
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            duration: 2000,
            message: err.data.message,
          });
        })
  };

  const signWithKakao = useCallback((e) => {
    e.preventDefault();
    const REST_API_KEY = process.env.REACT_APP_KAKAO_REST_KEY;
    const REDIRECT_URI = window.location.origin + '/kakao_login';
    window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;
  }, [])

  useEffect(() => {
    if (userInfo) {
      history.push((!userInfo.name || !userInfo.phone)? '/add_info': '/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <article className='content__wrapper'>
          <section className='headding-text__container'>
            <header>
              <h1>{t('SIGN_UP')}</h1>
            </header>
          </section>
          <section className='m-headding-text__container'>
            <header>
              <h2>{t('SIGN_UP')}</h2>
            </header>
          </section>
          <section className='singup-select__container'>
            <div className='m-tab__box'>
              <ul>
                <li
                  onClick={() => {
                    setTablIndex(0);
                  }}
                  className={tablIndex === 0 ? 'on' : ''}
                >
                  {t('INDIVIDUAL')}
                </li>
                <li
                  onClick={() => {
                    setTablIndex(1);
                  }}
                  className={tablIndex === 1 ? 'on' : ''}
                >
                  {t('COMPANY')}
                </li>
              </ul>
            </div>
            <ul>
              <li className={tablIndex === 0 ? 'on' : ''}>
                <header>
                  <div className='ico small-next' style={{ marginRight: 4 }}></div>
                  <span>{t('INDIVIDUAL')}</span>
                </header>
                <div className='illust__box'>
                  <div className='illust-personal'></div>
                </div>
                <button
                  className='signup-button'
                  onClick={() => {
                    history.push('/user/signup?type=personal');
                  }}
                >
                  <div className='ico small-email'></div>
                  {t('SIGN_UP_EMAIL')}
                </button>
                <section className='sns__box'>
                  <header>{t('SIGN_UP_WITH_SNS')}</header>
                  <div className='button__box'>
                    <div
                      className='kakao'
                      onClick={(e) => signWithKakao(e)}
                    >
                      <div className='ico small-kakao'></div>
                      <span>Kakao</span>
                    </div>
                    <GoogleLogin
                      clientId='1006780416021-r5a256ddio6npc2k5kv8jmmcr134qjot.apps.googleusercontent.com'
                      render={(renderProps) => (
                        <li
                          onClick={(e) => {
                            e.preventDefault();
                            renderProps.onClick();
                          }}
                          className='google'
                        >
                          <div className='ico small-google'></div>
                          <span>Google</span>
                        </li>
                      )}
                      onSuccess={responseGoogle}
                      onFailure={(e) => {
                        if (e.error === 'popup_closed_by_user') {
                          console.log('popup_closed_by_user');
                        } else {
                          updateSnackbar({
                            type: 'error',
                            open: true,
                            message: e.error,
                          });
                        }
                      }}
                      cookiePolicy={'single_host_origin'}
                      isSignedIn={false}
                    />
                  </div>
                </section>
              </li>
              <div className='border-line'></div>
              <li className={tablIndex === 1 ? 'on' : ''}>
                <header>
                  <div className='ico small-next' style={{ marginRight: 4 }}></div>
                  <span>{t('COMPANY')}</span>
                </header>
                <div className='illust__box'>
                  <div className='illust-company'></div>
                </div>
                <button
                  className='signup-button'
                  onClick={() => {
                    history.push('/user/signup?type=company');
                  }}
                >
                  <div className='ico small-email-2'> </div> {t('SIGN_UP_EMAIL')}
                </button>
              </li>
            </ul>
          </section>
        </article>
      </article>
    </>
  );
};
