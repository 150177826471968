import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import tranEn from '../constants/translation_en.json';
import tranKo from '../constants/translation_ko.json';
import formEn from '../constants/i18n/form/en.json';
import formKo from '../constants/i18n/form/ko.json';
import msgEn from '../constants/i18n/message/en.json';
import msgKo from '../constants/i18n/message/ko.json';

const resources = {
  en: { translation: tranEn, msg: msgEn, form: formEn },
  ko: { translation: tranKo, msg: msgKo, form: formKo },
};

i18n.use(initReactI18next).init({
  resources,
  lng: window.navigator.language.toLocaleLowerCase().substring(0,2) === 'ko' ? 'ko' : 'en',
  fallbackLng: 'ko',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
})

export default i18n;