import React, { useEffect, useState } from 'react';
import { LangContext } from '../contexts';
import { useTranslation } from 'react-i18next';

export const LangProvider = (props) => {
  const { children } = props;
  const { i18n } = useTranslation()
  const [isKorean, setIsKorean] = useState(true)


  useEffect(() => {
    i18n.language === 'ko' ? setIsKorean(true) : setIsKorean(false)
  }, [i18n, i18n.language])

  return (
    <LangContext.Provider
      value={{
        isKorean: isKorean,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};
