import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { ContentContext,LangContext } from '../../contexts';
import {
  PAGE_TOP,
  BIZ_SERVICE_LIST,
  BIZ_ICON_LIST,
} from '../../constants';
import styled from '@emotion/styled';
import BizBg from '../../styles/images/biz_bg.png';
import BizGraph from '../../styles/images/biz_graph_01.svg';
import BizGraphEn from '../../styles/images/biz_graph_01_en.svg';
import BizService from '../../styles/images/biz_service.svg';
import BizServiceEn from '../../styles/images/biz_service_en.svg';
import GreyArrow from '../../styles/icon/grey_arrow.svg';
import { color, font, fontSize, media } from '../../styles/style';
import { ButtonFooter, SectionTitle, PageTitle, MetaTag } from '../../components';

const BizGraphWrapper = styled.section`
  background-image: url(${BizBg});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  text-align: center;
  padding: 140px;
  h2 {
    font-size: ${font.$text_5xl};
    & + p {
      padding: 40px 0;
      font-size: ${font.$text_xl};
      font-weight: 400;
    }
  }
  h2,
  p {
    color: ${color.$white};
    white-space: pre-wrap;
    line-height: ${font.$leading_03};
  }
  div.mid-title {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 60px;
    p {
      display: flex;
      justify-content: center;
      font-weight: 800;
      font-size: ${fontSize(font.$text_xl)};
      &::before {
        content: '';
        position: absolute;
        width: 30px;
        height: 6px;
        top: -24px;
        /* left: 0;
        right: 0; */
        background: ${color.$white};
      }
    }
  }
  .mid-graph {
    padding: 40px;
    border-top: 1px solid ${color.$white};
    border-bottom: 1px solid ${color.$white};
    max-width: 1000px;
    margin: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 60px 24px;
    word-break: keep-all;
    p {
      font-size: ${fontSize(font.$text_xl, media.$sm)};
    }
    div.mid-title {
      p {
        font-weight: 600;
      }
    }
    .mid-graph {
      border: 0;
      padding: 0;
    }
  }
`;

const BizServiceWrapper = styled.div`
  padding: 80px 40px;
  height: 600px;
  width: 100%;
  box-sizing: content-box;
  text-align: center;
  @media only screen and (max-width: ${media.$sm}) {
    height: unset;
  }
  .service-items {
    position: relative;
    padding-top: 40px;
    .item {
      position: relative;
      padding: 10px;
      margin-left: 40px;
      display: flex;
      flex-wrap: wrap;
      &:first-of-type {
        &::before {
          background: ${color.$black_ddd};
        }
      }
      &:nth-of-type(2) {
        &::before {
          background: ${color.$main300};
        }
      }
      &:last-of-type {
        &::before {
          background: ${color.$main500};
        }
      }
      &::before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        left: -10px;
        top: 20%;
      }
      .title-box {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        h4,
        p,
        div,
        span {
          text-align: left;
        }
        h4 {
          color: ${color.$main500};
          font-size: ${fontSize(font.$text_sm)};
          margin-bottom: 10px;
        }
        p {
          font-size: ${fontSize(font.$text_xs)};
        }
        .more {
          margin: 8px 0;
          border-bottom: 1px solid ${color.$black_600};
          display: inline-flex;
          span {
            font-size: ${fontSize(font.$text_sm)};
            font-weight: 600;
            color: ${color.$black_600};
            margin-right: 5px;
          }
        }
      }
      .icon-box {
        width: 50px;
        margin-right: 20px;
        img {
          width: 100%;
        }
      }
    }
  }
  .image-box {
    height: 600px;
    padding-top: 30px;
    img {
      height: 100%;
    }
  }
`;

const BizProjectWrapper = styled.article`
  padding: 140px 0;
  text-align: center;
  .category {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    p {
      position: relative;
      margin-right: 40px;
      width: 60px;
      &:last-of-type {
        margin: 0;
      }
      &::before {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 20px;
        top: 5px;
        left: -20px;
      }
      &:first-of-type::before {
        background: ${color.$main500};
      }
      &:last-of-type::before {
        background: ${color.$main300};
      }
    }
  }
`;

const IconContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 160px);
  gap: 60px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    p {
      padding: 20px 0 0;
    }
    img {
      width: 100%;
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    gap: 5px;
    grid-template-columns: repeat(4, 85px);
    div {
      padding: 5px;
      p {
        font-size: ${fontSize(font.$text_sm, media.$sm)};
      }
    }
  }
`;

export const BizPage = (props) => {
  const history = useHistory();
  const { t, language, i18n } = useTranslation()
  const { isKorean } = useContext(LangContext);
  const { deviceWidth } = useContext(ContentContext);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });

  const gotoLink = (type, path) => {
    if (type === 'out') window.open(path);
    else history.push(path);
  };

  useEffect(() => {
    setMetaObject({
      title: t('BIZ_INTRO'),
      description: t('BIZ_INTRO_DESCRIPTION'),
      keywords: '팝플이란, 팝플',
      image: PAGE_TOP.BIZ.image,
      canonical: '',
      url: window.location.href,
    });
  }, []);

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <PageTitle title={t('BIZ_INTRO')} description={t('BIZ_INTRO_DESCRIPTION')} image={PAGE_TOP.BIZ.image} />
        <BizGraphWrapper>
          <h2>{t('BIZ_PHRASE')}</h2>
          <p>{t('BIZ_PHRASE_DESCRIPTION')}</p>
          <div className='mid-title'>
            <p>{t('BIZ_ABOUT_NETZERO')}</p>
          </div>
          <div className='mid-graph'>
            <img src={isKorean ? BizGraph : BizGraphEn} alt='' />
          </div>
        </BizGraphWrapper>
        <section className='content__wrapper'>
          <BizServiceWrapper>
            <SectionTitle title={t('BIZ_POPLE_SERVICE')} />
            {deviceWidth > 640 ? (
              <div className='image-box'>
                <img src={isKorean ? BizService :  BizServiceEn} alt='' />
              </div>
            ) : (
              <div className='service-items'>
                {BIZ_SERVICE_LIST &&
                  BIZ_SERVICE_LIST.map((item) => (
                    <div key={`biz-service-list-${item.type}`} className='item'>
                      <div className='icon-box'>
                        <img src={item.icon} alt='' />
                      </div>
                      <div className='title-box'>
                        <h4>{t(item.title)}</h4>
                        <p>{t(item.desc)}</p>
                        <div className='more' onClick={() => gotoLink(item.type, item.path)}>
                          <span>{t('MORE')}</span>
                          <span className='arrow'>
                            <img src={GreyArrow} alt='' />
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </BizServiceWrapper>
        </section>
        <section className='content__wrapper'>
          <BizProjectWrapper>
            <SectionTitle title={t('PROJECT_CATEGORY')} />
            <div className='category'>
              <p>{t('CARBON_REMOVAL')}</p>
              <p>{t('CARBON_REDUCTION')}</p>
            </div>
            <IconContainer>
              {BIZ_ICON_LIST &&
                BIZ_ICON_LIST.map((item) => (
                  <div key={`biz-icon-list-${item.name}`} className='item'>
                    <img src={item.icon} alt='' />
                    <p>{t(item.name)}</p>
                  </div>
                ))}
            </IconContainer>
          </BizProjectWrapper>
        </section>
        <ButtonFooter />
      </article>
    </>
  );
};
