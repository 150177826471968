import React from 'react';
import { PageTitle } from '../../components';

export const CreditInfoPage = (props) => {

  return (
    <article className='purchase__container'>
      <PageTitle title='팝플크레딧' description='팝플크레딧 안내' />
      <div className='normal__box'></div>
    </article>
  );
};
