import { useTranslation } from 'react-i18next';
export const useLabel = () => {
  const { t } = useTranslation();
  const { t: TransMsg } = useTranslation('msg');
  const { t: TransForm } = useTranslation('form');

  /* PURCHASE */
  const PURCHASE_WAY = t('PURCHASE_WAY', { returnObjects: true });
  const PURCHASE_GIFT = t('PURCHASE_GIFT', { returnObjects: true });
  const PURCHASE_CAUTIOUS = t('PURCHASE_CAUTIOUS', { returnObjects: true });
  const PURCHASE_REASON_LIST = t('PURCHASE_REASON_LIST', { returnObjects: true });
  const PURCHASE_AGREE = t('PURCHASE_AGREE', { returnObjects: true });

  /* CARBON PRICE */
  const CARBON_PRICE_DICT = t('CARBON_PRICE_DICT', { returnObjects: true });
  const RENEWABLE_PRICE_DICT = t('RENEWABLE_PRICE_DICT', { returnObjects: true });

  /* USER & TERM */
  const SIGN_UP_TERM = t('SIGN_UP_TERM', { returnObjects: true });
  const USER_WITHDRAWAL = t('WITHDRAWAL', { returnObjects: true });

  const SOCIAL = t('SOCIAL', { returnObjects: true });

  /* FORM */
  const BUTTON = TransForm('BUTTON', { returnObjects: true });
  const PLACEHOLDER = TransForm('PLACEHOLDER', { returnObjects: true });

  /* MESSAGE */
  const ERROR = TransMsg('ERROR', { returnObjects: true });
  const MSG = TransMsg('MSG', { returnObjects: true });
  const VALIDATION = TransMsg('VALIDATION', { returnObjects: true });
  return {
    PURCHASE_WAY,
    PURCHASE_GIFT,
    PURCHASE_CAUTIOUS,
    PURCHASE_AGREE,
    PURCHASE_REASON_LIST,
    CARBON_PRICE_DICT,
    RENEWABLE_PRICE_DICT,
    SOCIAL,
    SIGN_UP_TERM,
    USER_WITHDRAWAL,
    BUTTON,
    PLACEHOLDER,
    ERROR,
    MSG,
    VALIDATION,
  };
};
