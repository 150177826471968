import React, { useContext } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { ContentContext } from '../../contexts';
const containerStyle = {
  width: '800px',
  height: '400px',
};
const m_containerStyle = {
  width: '300px',
  height: '300px',
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

export const GoogleMapWrapper = (props) => {
  const { address } = props;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });
  const { deviceWidth } = useContext(ContentContext);
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map, address) {
    console.log('map: ' + address);
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK') {
        map.setCenter(results[0].geometry.location);
        map.setZoom(11);
        var marker = new window.google.maps.Marker({
          map: map,
          position: results[0].geometry.location,
        });
      }
    });
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={deviceWidth > 650 ? containerStyle : m_containerStyle}
      center={center}
      onLoad={(map) => onLoad(map, address)}
      onUnmount={onUnmount}
    >
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(GoogleMap);
