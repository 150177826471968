import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalCertification } from '../../components';
import { useHistory, useParams } from 'react-router-dom';
import { SnackbarContext, ContentContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useLabel } from '../../hooks';

const certification_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  borderRadius: 0,
};

const m_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  boxSizing: 'border-box',
};

export const MypagePurchaseDetailPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { PURCHASE_WAY } = useLabel();
  const { deviceWidth } = useContext(ContentContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [data, setData] = useState(null);
  const { slug } = useParams();
  const [certificationOpen, setCertificationOpen] = useState(false);
  const [certificationData, setCertificationData] = useState(false);

  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  const refreshData = () => {
    API.User.GetPaymentDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('refreshData result', result);
        setData(result);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const buildStatusText = (status) => {
    switch (status) {
      case 'Complete': {
        return '결제완료';
      }
      case 'DepositComplete': {
        return '입금완료';
      }
      case 'DepositReady': {
        return '입금대기';
      }
      case 'DepositCancel': {
        return '입금취소';
      }
      default: {
        return 'none';
      }
    }
  };

  const renderBuildType = (data) => {
    if (data.type === 'VBANK') {
      return (
        <div className='vank__box'>
          <span style={{ color: '#f41515', fontSize: 14, fontWeight: 'bold' }}>{t('VIRTUAL_ACCOUNT')}</span>
          <div className='detail__list'>
            <div className='detail__item'>
              <header>- {t('BANK_NAME')} :</header>
              <span>{data.vbankName}</span>
            </div>
            <div className='detail__item'>
              <header>- {t('BANK_ACCOUNT_USER')} :</header>
              <span>{data.vbankHolder}</span>
            </div>
            <div className='detail__item'>
              <header>- {t('BANK_ACCOUNT_NUMBER')} :</header>
              <span>{data.vbankNum}</span>
            </div>
          </div>
        </div>
      );
    } else {
      return Handler.buildPaymentTypeText(data.type);
    }
  };

  if (!data) {
    return null;
  }

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header className='custom'>
            <h1>{t('BUY_DETAIL')}</h1>
            <section className='breadcrumb__box'>
              <ul>
                <li
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  Home
                </li>
                <li>{t('MYPAGE')}</li>
                <li
                  onClick={() => {
                    history.push('/mypage/purchase_list');
                  }}
                >
                  {t('PURCHASEMENT_LIST')}
                </li>
                <li className='active'>{t('BUY_DETAIL')}</li>
              </ul>
            </section>
          </header>
        </section>
        <section className='purchase-detail__container'>
          <section className='order__box'>
            <header>
              <span>{t('DETAIL')}</span>
            </header>
            <div className='table__box'>
              <ul className='header'>
                <li>{t('ORDER_NUMBER')}</li>
                <li>{t('ORDER_HISTORY')}</li>
                <li>{t('REDUCTION_CERTIFICATE')}</li>
              </ul>
              <div className='m-order-number__box'>
                <div className='header'>{t('ORDER_NUMBER')}</div>
                <div className='value'>{data.orderNumber}</div>
              </div>
              <ul className='data'>
                <li>{data.orderNumber}</li>
                <li>
                  <div className='img__box'>
                    {data.project.mainImages && data.project.mainImages.length > 0 && (
                      <img src={data.project.mainImages[0]} alt={data.project.title}></img>
                    )}
                  </div>
                  <div className='title__box'>
                    <span className='name'>{data.project.title}</span>
                    <span className='id'>{data.project.id}</span>
                    <div className='price'>
                      <span>
                        {Handler.numberWithCommas(Math.floor(data.price / data.amount))}
                        {t('WON')}
                      </span>
                      <span>·</span>
                      <span>{Handler.numberWithCommas(data.amount)}VRC</span>
                    </div>
                  </div>
                </li>
                <li>
                  {data.cert && (
                    <div>
                      {data.cert?.pdfUrl !== undefined ? (
                        <a className='cert-button' href={data.cert?.pdfUrl} download>
                          {t('REDUCTION_CERTIFICATE')}
                        </a>
                      ) : (
                        <div
                          className='cert-button'
                          onClick={() => {
                            setCertificationData(data.cert);
                            setCertificationOpen(true);
                          }}
                        >
                          {t('REDUCTION_CERTIFICATE')}
                        </div>
                      )}
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className='payment__box'>
            <header>
              <span>{t('PURCHASE_INFO')}</span>
              <div className='status'>{t(Handler.buildPaymentStatusText(data.status))}</div>
            </header>
            <div className='table__box'>
              <ul className='header'>
                <li>{t('PURCHASE_DATE')}</li>
                <li className={data.type}>{t('PAYMENT_TYPE')}</li>
                <li>{t('SALE_PRICE')}</li>
                <li>{t('PURCHASE_AMOUNT')}</li>
                <li>{t('RECEIPT')}</li>
              </ul>
              <ul className='data'>
                <li>{Handler.getYYYYMMDDHHMMByUnix(data.createdAt)}</li>
                <li className={data.type}>{renderBuildType(data)}</li>
                <li>
                  {Handler.numberWithCommas(data.price)}
                  {t('WON')}
                </li>
                <li>
                  {Handler.numberWithCommas(data.price)}
                  {t('WON')}
                </li>
                <li>
                  {data.receiptUrl ? (
                    <div
                      className='button'
                      onClick={() => {
                        window.open(data.receiptUrl, '_blank');
                      }}
                    >
                      {t('SHOW_RECEIPT')}
                    </div>
                  ) : (
                    '-'
                  )}
                </li>
              </ul>
            </div>
          </section>
          {/* <section className='m-receipt__box'>
            <div className='button'>영수증 보기</div>
          </section> */}
          <section className='buyer__box'>
            <header>
              <span>{t('BUYER_INFO')}</span>
            </header>
            <div className='table__box'>
              <ul className='header'>
                <li>{t('NAME')}</li>
                <li>{t('BUY_REASON')}</li>
                <li>{t('MOBILE')}</li>
                {data.presentName && (
                  <li className='present-person'>
                    {PURCHASE_WAY['PRESENT']}/{PURCHASE_WAY['DONATION']}
                  </li>
                )}
                {data.presentName && <li className='present-email'></li>}
              </ul>
              <ul className='data'>
                <li>{data.user.name}</li>
                <li>{data.reason}</li>
                <li>{data.user.phone}</li>
                {data.presentName && (
                  <li className='present-person'>
                    <div className='ico gift'></div> {data.presentName}{' '}
                    {data.presentEmail && <span>({data.presentEmail})</span>}
                  </li>
                )}
                {data.presentName && (
                  <li className='present-email'>
                    {data.presentEmail && (
                      <div
                        onClick={() => {
                          console.log('data', data);
                          API.Payment.PutEmailResend(data.seq).then(
                            (res) => {
                              updateSnackbar({
                                type: 'success',
                                open: true,
                                message: '이메일 재발송 성공',
                              });
                            },
                            (err) => {
                              updateSnackbar({
                                type: 'error',
                                open: true,
                                message: err.data.message,
                              });
                            },
                          );
                        }}
                        className='button'
                      >
                        메일 재발송
                      </div>
                    )}
                  </li>
                )}
              </ul>
            </div>
          </section>
          <section className='button__box'>
            <div
              className='button'
              onClick={() => {
                history.push('/mypage/purchase_list');
              }}
            >
              {t('LIST')}
            </div>
          </section>
        </section>
      </article>
      <Modal
        open={certificationOpen}
        onClose={() => {
          setCertificationOpen(false);
        }}
      >
        <Box sx={deviceWidth > 1136 ? certification_style : m_style}>
          <ModalCertification
            data={certificationData}
            onClose={() => {
              setCertificationOpen(false);
            }}
          ></ModalCertification>
        </Box>
      </Modal>
    </article>
  );
};
