import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const MypageNavList = (props) => {
  const history = useHistory();
  const { userMenu } = props;
  const { t } = useTranslation();

  return (
    <section className='mypage-nav__container'>
      <ul>
        {userMenu &&
          userMenu.map((item, index) => {
            return (
              <li
                className={history.location.pathname === item.path ? 'on' : ''}
                onClick={() => {
                  history.push(item.path);
                }}
              >
                <span>{t(item.text)}</span>
              </li>
            );
          })}
      </ul>
    </section>
  );
};
