import React, { useContext, useState, useEffect } from 'react';
import Utils from '../../api/Utils';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext, ContentContext, LangContext } from '../../contexts';
import { ROUTE_PATH, genRightPath } from '../../constants/route.constants';
// import API from '../../api';
import { MainHeader, PcNavigation, MobileNavigation, PcSubNavigation } from './HeaderNavStyle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export const HeaderNav = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { userInfo, isPersonal, clearToken } = useContext(UserContext);
  const { deviceWidth } = useContext(ContentContext);
  const { isKorean } = useContext(LangContext);
  const [navPosition, setNavPosition] = useState(window.scrollY);
  const [hoverItem, setHoverItem] = useState(null);
  const [mobileMenu, setMobileMenu] = useState([]);
  const [userMenu, setUserMenu] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  let CLEAR_FUNC = null;
  const LEFT_LIST = ROUTE_PATH;

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    Utils.SetStorage('language', lang);
  };

  useEffect(() => {
    if (userInfo) {
      let myPageChild = [
        {
          text: 'PURCHASEMENT_LIST',
          path: '/mypage/purchase_list',
        },
        {
          text: 'MODIFY_MYINFO',
          path: '/mypage/modify',
        },
        {
          type: 'action',
          text: 'LOGOUT',
          action: () => {
            clearToken();
          },
        },
      ];
      if (!isPersonal) {
        myPageChild.splice(1, 0, {
          text: 'SELL_LIST',
          path: '/mypage/sell_list',
        });
      }
      setUserMenu([
        ...genRightPath(isKorean, true),
        {
          type: 'mypage',
          text: userInfo.name + ' 님',
          activePath: 'mypage',
          child: myPageChild,
        },
      ]);
    } else {
      setUserMenu(genRightPath(isKorean));
    }
  }, [userInfo, isKorean]);

  useEffect(() => {
    setMobileMenu(genMobileMenu());
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', updateNavPosition);
    return () => window.removeEventListener('scroll', updateNavPosition);
  }, []);

  const genMobileMenu = () => {
    if (userMenu && userMenu.length > 0) {
      const [registry, ...menus] = userMenu;
      return [...LEFT_LIST, ...menus, registry];
    }
    return [...LEFT_LIST];
  };

  const updateNavPosition = () => {
    setNavPosition(window.scrollY);
  };

  const onMouseEnterHandler = (item) => {
    if (CLEAR_FUNC) {
      clearTimeout(CLEAR_FUNC);
    }
    setHoverItem(item);
  };

  const onMouseLeaveHandler = () => {
    CLEAR_FUNC = setTimeout(() => {
      setHoverItem(null);
    }, [500]);
  };

  const gotoPath = (path, type = null) => {
    if (type === 'outer') {
      window.location.href = path;
    } else {
      history.push(path);
    }
  };

  const renderSubMenu = (arr) => {
    let subMenus = [];
    arr.forEach((list) => {
      if (list.child) subMenus.push(list.child);
    });
    const navList = [...subMenus].filter((li) => li.length > 0);
    return (
      <>
        {navList &&
          navList.map((e, index) => (
            <ul key={'navlist' + index}>
              {e.map((ls, idx) => (
                <li key={index + 'sub-navlist' + idx} onClick={() => gotoPath(ls.path, ls.type)}>
                  {(ls.type && ls.type === 'action' && (
                    <span onClick={() => ls.action()}>
                      {t(ls.text)}
                      <span className='ico logout'></span>
                    </span>
                  )) || <span>{t(ls.text)}</span>}
                </li>
              ))}
            </ul>
          ))}
      </>
    );
  };

  const renderNavClassname = (type, index) => {
    let return_classname = '';
    let pathArray = history.location.pathname.split('/');
    let parentPath = pathArray[1];

    if (hoverItem) {
      if (type === hoverItem.type && hoverItem.index === index) {
        return_classname += ' on';
      }
    }

    if (type === 'left') {
      if (parentPath === LEFT_LIST[index].activePath) {
        return_classname += ' active';
      }
    } else {
      if (parentPath === userMenu[index].activePath) {
        return_classname += ' active';
      }
    }
    return return_classname;
  };

  const isTransparent = () => {
    const noPageTitle =
      location.pathname.includes('/detail') ||
      location.pathname.includes('/cs') ||
      location.pathname.includes('/complete') ||
      location.pathname.includes('/mypage');
    return navPosition === 0 && !hoverItem && !noPageTitle;
  };

  if (deviceWidth > 1136) {
    return (
      <MainHeader className={isTransparent() ? 'is__transparent' : ''}>
        <article className='content__wrapper'>
          <PcNavigation login={userInfo}>
            <article className={userInfo ? 'pc-nav__main pc-nav__wrapper loggedin' : 'pc-nav__main pc-nav__wrapper'}>
              <section className='left__side'>
                <section className='logo__box'>
                  <div
                    className={isTransparent() ? 'ico nav-logo-white' : 'ico nav-logo-purple'}
                    onClick={() => {
                      history.push('/');
                    }}
                  ></div>
                </section>
                <section className='nav__box'>
                  <ul>
                    {LEFT_LIST.map((item, index) => {
                      return (
                        <li
                          key={'left-list-index' + index}
                          onClick={() => {
                            console.log('work');
                            if (item.type === 'link') window.open(item.path);
                          }}
                          onMouseEnter={(e) => {
                            onMouseEnterHandler({
                              index: index,
                              type: 'left',
                            });
                          }}
                          onMouseLeave={(e) => {
                            onMouseLeaveHandler();
                          }}
                          className={renderNavClassname('left', index)}
                        >
                          <span>{t(item.text)}</span>
                        </li>
                      );
                    })}
                  </ul>
                </section>
              </section>
              <section className='right__side'>
                <ul className={userInfo ? 'loggedin' : ''}>
                  {userMenu &&
                    userMenu.map((item, index) => {
                      const { type, text, path, action } = item;
                      switch (type) {
                        case 'sub':
                        case 'mypage': {
                          return (
                            <li
                              key={'sub-' + index}
                              onMouseEnter={(e) => {
                                onMouseEnterHandler({
                                  index: index,
                                  type: 'right',
                                });
                              }}
                              onMouseLeave={(e) => {
                                onMouseLeaveHandler();
                              }}
                              className={renderNavClassname('right', index)}
                            >
                              <span>{t(text)}</span>
                            </li>
                          );
                        }
                        case 'link': {
                          return (
                            <li
                              key={'link-' + index}
                              onClick={() => {
                                window.open(item.path);
                              }}
                            >
                              <span className='registry'>{t(text)}</span>
                              {<span className={isTransparent() ? 'ico outer' : 'ico outer_black'}></span>}
                            </li>
                          );
                        }
                        case 'sign': {
                          return (
                            <li className='sign' key={'link-' + index}>
                              <span
                                onClick={() => {
                                  history.push(item.list[0].path);
                                }}
                              >
                                {t(item.list[0].text)}
                              </span>
                              <span className='divider'>|</span>
                              <span
                                onClick={() => {
                                  history.push(item.list[1].path);
                                }}
                              >
                                {t(item.list[1].text)}
                              </span>
                            </li>
                          );
                        }
                        case 'action': {
                          return (
                            <li
                              key={'action-' + index}
                              onClick={() => {
                                action();
                              }}
                            >
                              <span>{t(text)}</span>
                            </li>
                          );
                        }
                        default: {
                          return (
                            <li key={'default-' + index}>
                              <span>{t(text)}</span>
                            </li>
                          );
                        }
                      }
                    })}
                </ul>
                {
                  <div className={isTransparent() ? 'language is__transparent' : 'language'}>
                    <div className='language-btn ml-2'>
                      <button className={isKorean ? 'selected' : ''} onClick={() => handleChangeLanguage('ko')}>
                        KO
                      </button>
                      <button className={isKorean ? '' : 'selected'} onClick={() => handleChangeLanguage('en')}>
                        EN
                      </button>
                    </div>
                  </div>
                }
              </section>
            </article>
          </PcNavigation>
          <PcSubNavigation
            className={hoverItem ? 'open' : ''}
            onMouseEnter={(e) => {
              onMouseEnterHandler(hoverItem);
            }}
            onMouseLeave={(e) => {
              onMouseLeaveHandler();
            }}
          >
            {hoverItem && (
              <article className={userInfo ? 'pc-nav__wrapper loggedin' : 'pc-nav__wrapper'}>
                <section className='left__side'>
                  <div className='logo__fake'></div>
                  <section className='sub-nav__list sub-nav__box'>{renderSubMenu(LEFT_LIST)}</section>
                </section>
                <section
                  className={userInfo ? 'sub-nav__list sub-right__side loggedin' : 'sub-nav__list sub-right__side'}
                >
                  {renderSubMenu(userMenu)}
                </section>
              </article>
            )}
          </PcSubNavigation>
        </article>
      </MainHeader>
    );
  } else {
    return (
      <MobileNavigation className={navPosition > 0 ? 'shadow' : ''}>
        <div className='menu__box'>
          <div
            className='ico header-menu'
            onClick={() => {
              setMenuOpen(true);
            }}
          ></div>
          <div className='ico logo-small' onClick={() => history.push('/')}></div>
          {userInfo ? (
            <div className='ico mypage' onClick={() => history.push('/mypage/purchase_list')}></div>
          ) : (
            <div className='login' onClick={() => history.push('/user/login')}>
              {t('LOGIN')}
            </div>
          )}
        </div>
        <div className={menuOpen ? 'menu-list__box open' : 'menu-list__box'}>
          <header>
            <div className='logo__box'>
              <div className='ico logo-white' onClick={() => history.push('/')}></div>
            </div>
            <div className='close__box'>
              <div
                className='ico button_close'
                onClick={() => {
                  setMenuOpen(false);
                }}
              ></div>
            </div>
          </header>
          <div className={userInfo ? 'mobile-list__box user' : 'mobile-list__box'}>
            {mobileMenu &&
              mobileMenu.map((item, index) => {
                if (item.type === 'sub' || item.type === 'mypage') {
                  return (
                    <div
                      key={`mobile-menu=${index}`}
                      className={
                        mobileMenu.length === index + 1 ? 'mobile-accordion__item last' : 'mobile-accordion__item'
                      }
                    >
                      <Accordion>
                        <AccordionSummary expandIcon={<div className='ico white-arrow-down'></div>}>
                          <h3>{item.type === 'mypage' ? t('MYPAGE') : t(item.text)}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          {item.child.map((sub_item, sub_index) => {
                            return sub_item.type && sub_item.type === 'action' ? null : (
                              // <div
                              //   className='mobile-child__item'
                              //   onClick={() => {
                              //     sub_item.action();
                              //   }}
                              // >
                              //   <span>{sub_item.text}</span>
                              // </div>
                              <div
                                key={`mobile-menu-child-${sub_index}`}
                                className='mobile-child__item'
                                onClick={() => {
                                  history.push(sub_item.path);
                                }}
                              >
                                <span>{t(sub_item.text)}</span>
                              </div>
                            );
                          })}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                }
                if (item.type === 'link') {
                  return (
                    <div
                      className={
                        mobileMenu.length === index + 1 ? 'mobile-accordion__item last' : 'mobile-accordion__item'
                      }
                      onClick={() => {
                        window.open(item.path);
                      }}
                    >
                      <Accordion>
                        <AccordionSummary>
                          <h3>{t(item.text)}</h3>
                          <div className='ico outer'></div>
                        </AccordionSummary>
                      </Accordion>
                    </div>
                  );
                }
              })}
          </div>
          <div className='login__box'>
            {!userInfo ? (
              <>
                <div
                  className='login'
                  onClick={() => {
                    history.push('/user/login');
                  }}
                >
                  {t('LOGIN')}
                </div>
                <div
                  className='signup'
                  onClick={() => {
                    history.push('/user/signup_guide');
                  }}
                >
                  {t('SIGN_UP')}
                </div>
              </>
            ) : (
              <div onClick={() => clearToken()}>{t('LOGOUT')}</div>
            )}
            <div className='flex w-full flex-col'>
              <div className='language-btn mt-4 items-end'>
                <button
                  className={
                    isKorean
                      ? 'border border-solid border-white bg-white text-primary p-2'
                      : 'border border-solid border-white p-2'
                  }
                  onClick={() => handleChangeLanguage('ko')}
                >
                  KO
                </button>
                <button
                  className={
                    isKorean
                      ? 'border border-solid border-white p-2'
                      : 'border border-solid border-white bg-white text-primary p-2'
                  }
                  onClick={() => handleChangeLanguage('en')}
                >
                  EN
                </button>
              </div>
            </div>
          </div>
        </div>
      </MobileNavigation>
    );
  }
};
