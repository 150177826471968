import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { color, font, fontSize, media } from '../../../styles/style';
import { EARTH_LIST } from '../../../constants/main.constants';

const earth_settings = {
  dots: false,
  infinite: true,
  centerMode: true,
  lazyLoad: true,
  speed: 2000,
  autoplaySpeed: 5000,
  autoplay: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        // initialSlide: 2,
        centerMode: false,
        dots: true,
      },
    },
  ],
};

const EarthContainer = styled.section`
  width: 100%;
  padding: 140px 0;
  /* background-color: ${color.$main100}; */
  /* the slides */
  .slick-slide {
    margin: 0 15px;
  }

  /* the parent */
  .slick-list {
    margin: 0 -15px;
    height: 100%;
  }

  .slick-track {
    display: flex;
  }
  @media only screen and (max-width: 640px) {
    box-sizing: content-box;
    padding: 60px 24px;
    /* the slides */
    .slick-slide {
      margin: 0;
    }

    /* the parent */
    .slick-list {
      /* margin: 0 -60px; */
      width: 300px;
      margin: auto;
      height: 100%;
    }
    .slick-dots {
      bottom: 20px;
      li {
        margin: 0 2px;
        button {
          &::before {
            font-size: 14px;
            color: #fff;
          }
        }
        &.slick-active {
          button {
            &::before {
            }
          }
        }
      }
    }
  }
`;

const EarthImage = styled.div`
  border-radius: 20px;
  overflow: hidden;
  img {
    width: 100%;
    object-fit: fill;
    object-position: 10%;
  }
  @media only screen and (max-width: ${media.$sm}) {
    /* margin: 0 20px; */
  }
`;

const TitleWrapper = styled.div`
  padding: 80px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: ${fontSize(font.$text_5xl)};
    font-weight: ${font.bold};
    padding-bottom: 26px;
  }
  p {
    padding-top: 20px;
    font-size: ${fontSize(font.$text_base)};
  }
  h2,
  p {
    text-align: center;
    line-height: 1.5;
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 30px 40px;
    h2,
    p {
      word-break: keep-all;
      white-space: initial;
    }
    h2 {
      font-size: ${fontSize(font.$text_5xl, media.$sm)};
      padding-bottom: 26px;
    }
    p {
      padding-top: 0;
      font-size: ${fontSize(font.$text_base, media.$sm)};
    }
  }
`;

export const Earth = (props) => {
  const { t } = useTranslation();

  return (
    <EarthContainer className=''>
      <div className='slider-container' data-aos-offset="100" data-aos='fade-up'>
        <Slider {...earth_settings}>
          {EARTH_LIST &&
            EARTH_LIST.map((img, idx) => (
              <EarthImage key={`earth-list-${idx}`} data-aos-offset="100" data-aos='fade-up'>
                <img src={img} alt='' />
              </EarthImage>
            ))}
        </Slider>
      </div>
      <TitleWrapper>
        <h2 data-aos='fade-up'>{t('MAIN_SECTION_01_TITLE')}</h2>
        <p data-aos='fade-up'>{t('MAIN_SECTION_01_DESCRIPTION')}</p>
      </TitleWrapper>
    </EarthContainer>
  );
};
