import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'market/user/';

/* 개인 이메일 회원가입 */
const SignupPersonal = (body) => {
  const url = BASE_URL + PATH + 'sign-up/personal';
  return AxiosInstance.post(url, body);
};

/* 기업 이메일 회원가입 */
const SignupEnterprise = (body) => {
  const url = BASE_URL + PATH + 'sign-up/enterprise';
  return AxiosInstance.post(url, body);
};

/* 개인 / 기업 이메일 회원 로그인 */
const Signin = (body) => {
  const url = BASE_URL + PATH + 'sign-in';
  return AxiosInstance.post(url, body);
};

/* 유저 정보 조회 */
const Get = () => {
  const url = BASE_URL + 'market/user';
  return AxiosInstance.get(url);
};

/* 유저 정보 수정 */
const Put = (body) => {
  const url = BASE_URL + 'market/user';
  return AxiosInstance.put(url, body);
};

/* 유저 비밀번호 찾기 */
const FindPassword = (body) => {
  const url = BASE_URL + PATH + 'password-resets';
  return AxiosInstance.put(url, body);
};

/* 유저 비밀번호 변경 */
const ModifyPassword = (body) => {
  const url = BASE_URL + PATH + 'password';
  return AxiosInstance.put(url, body);
};

const GetInquiry = (params) => {
  const url = BASE_URL + PATH + 'inquiry' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetInquiryDetail = (slug) => {
  const url = BASE_URL + PATH + 'inquiry/' + slug;
  return AxiosInstance.get(url);
};

const PutInquiry = (slug, body) => {
  const url = BASE_URL + PATH + 'inquiry/' + slug;
  return AxiosInstance.put(url, body);
};

const DeleteInquiry = (slug) => {
  const url = BASE_URL + PATH + 'inquiry/' + slug;
  return AxiosInstance.delete(url);
};

const PostInquiry = (body) => {
  const url = BASE_URL + PATH + 'ionquiry';
  return AxiosInstance.post(url, body);
};

/* 회원탈퇴 요청 */
const PostSignout = (body) => {
  const url = BASE_URL + PATH + 'sign-out';
  return AxiosInstance.post(url, body);
};

/* 회원가입 이메일 재발송 */
const PutSignupEmailResend = (body) => {
  const url = BASE_URL + PATH + 'sign-up/email-resend';
  return AxiosInstance.put(url, body);
};

/* 회원가입 이메일 재발송 */
const PutSignupCertification = (body) => {
  const url = BASE_URL + PATH + 'sign-up/certificate';
  return AxiosInstance.put(url, body);
};

/* SNS 계정 회원정보 갱신 */
const PutSignupInfo = (body) => {
  const url = BASE_URL + PATH + 'oauth/info';
  return AxiosInstance.put(url, body);
};

/* SNS 계정 회원정보 조회 */
const GetSignupInfo = (seq) => {
  const url = BASE_URL + PATH + 'oauth/info/' + seq;
  return AxiosInstance.get(url);
};

/* 카카오 회원 로그인 */
const PostSigninKakao = (body) => {
  const url = BASE_URL + PATH + 'sign-in/kakao';
  return AxiosInstance.post(url, body);
};

/* 구글 회원 로그인 */
const PostSigninGoogle = (body) => {
  const url = BASE_URL + PATH + 'sign-in/google';
  return AxiosInstance.post(url, body);
};

/* 유저 로그아웃 */
const PutLogout = () => {
  const url = BASE_URL + PATH + 'logout';
  return AxiosInstance.post(url);
};

/* 유저 계좌번호 저장 */
const PutAccount = (body) => {
  const url = BASE_URL + PATH + 'account';
  return AxiosInstance.put(url, body);
};

/* 유저 구매 페이지네이션 조회 */
const GetPaymentList = (params) => {
  const url = BASE_URL + PATH + 'payment' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url); 
};

/* 유저 구매 상세 조회 */
const GetPaymentDetail = (slug) => {
  const url = BASE_URL + PATH + 'payment/' + slug;
  return AxiosInstance.get(url);
};

/* 유저 선도구매 페이지네이션 조회 */
const GetForwardPaymentList = (params) => {
  const url = BASE_URL + PATH + 'forward-payment' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

/* 유저 선도구매 상세 조회 */
const GetForwardPaymentDetail = (slug) => {
  const url = BASE_URL + PATH + 'forward-payment/' + slug;
  return AxiosInstance.get(url);
};

/* 유저 판매목록 페이지네이션 조회 */
const GetSaleList = (params) => {
  const url = BASE_URL + PATH + 'sale' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

/* 유저 판매 상세 조회 */
const GetSaleDetail = (slug) => {
  const url = BASE_URL + PATH + 'sale/' + slug;
  return AxiosInstance.get(url);
};

/* 정산/투자 현황 조회 */
const GetCalculateStat = () => {
  const url = BASE_URL + PATH + 'calculate/stat';
  return AxiosInstance.get(url);
};

/* 정산/투자 페이지네이션 조회 */
const GetCalculateList = (params) => {
  const url = BASE_URL + PATH + 'calculate' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

/* 출금 내역 페이지네이션 조회 */
const GetWithdrawList = (params) => {
  const url = BASE_URL + PATH + 'withdraw' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

/* 출금 요청 */
const PostWithdraw = (body) => {
  const url = BASE_URL + PATH + 'withdraw';
  return AxiosInstance.post(url, body);
};

const User = {
  Signin: Signin,
  Get: Get,
  Put: Put,
  FindPassword: FindPassword,
  ModifyPassword: ModifyPassword,
  PostInquiry: PostInquiry,
  GetInquiry: GetInquiry,
  GetInquiryDetail: GetInquiryDetail,
  PostSignout: PostSignout,
  PutInquiry: PutInquiry,
  DeleteInquiry: DeleteInquiry,
  SignupPersonal: SignupPersonal,
  SignupEnterprise: SignupEnterprise,
  PutSignupEmailResend: PutSignupEmailResend,
  PutSignupCertification: PutSignupCertification,
  PostSigninKakao: PostSigninKakao,
  PutSignupInfo: PutSignupInfo,
  PutLogout: PutLogout,
  PostSigninGoogle: PostSigninGoogle,
  PutAccount: PutAccount,
  GetPaymentList: GetPaymentList,
  GetPaymentDetail: GetPaymentDetail,
  GetForwardPaymentList: GetForwardPaymentList,
  GetForwardPaymentDetail: GetForwardPaymentDetail,
  GetSaleList: GetSaleList,
  GetSaleDetail: GetSaleDetail,
  GetCalculateStat: GetCalculateStat,
  GetCalculateList: GetCalculateList,
  GetWithdrawList: GetWithdrawList,
  PostWithdraw: PostWithdraw,
  GetSignupInfo: GetSignupInfo,
};

export default User;
