import React, { useEffect, useContext, useState } from 'react';
import { Button, DropdownInput, TablePaging, SelectBox } from '../../components';
import { usePagination } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';

export const ContributorsListPage = (props) => {
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState('Title');
  const [userType, setUserType] = useState('SELECT_ALL');
  const [lastSearchBody, setLastSearchBody] = useState(null);
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 10);

  useEffect(() => {
    requestNewList(0);
  }, []);

  useEffect(() => {
    if (lastSearchBody) {
      requestNewList();
    }
  }, [page]);

  //event
  const requsetSearch = () => {
    requestNewList(0);
  };

  //server
  const requestNewList = (init_page) => {
    let params = null;

    if (init_page) {
      params = {
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
        searchType: searchType,
        userType: userType,
        searchValue: search,
      };

      setLastSearchBody({ ...params });
    } else {
      params = {
        ...lastSearchBody,
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
      };
    }
    console.log('requestNewList list', list);

    API.Marketplace.GetContributors(params).then(
      (res) => {
        let result = res.data;
        console.log('GetContributors result', result);
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  return (
    <article className='basic-page'>
      <div className='contributor-illust__container'>
        <div className='content__wrapper'>
          <div className='title__box'>
            <h1>Contributors</h1>
            <span className='big-title'>
              지구를 위해 팝플과 함께하는
              <br />
              사람들을 소개합니다.
            </span>
            <span> 나와 지구를 위한 일에 여러분도 동참해주세요.</span>
          </div>
        </div>
      </div>
      <div className='content__wrapper'>
        <div className='search__container contributor'>
          <div className='contributor-input__box'>
            <div className='tab__box'>
              {/* <ul>
                <li
                  className={userType === 'Enterprise' ? 'on' : ''}
                  onClick={() => {
                    setUserType('Enterprise');
                  }}
                >
                  <span>기업</span>
                </li>
                <li
                  className={userType === 'Personal' ? 'on' : ''}
                  onClick={() => {
                    setUserType('Personal');
                  }}
                >
                  <span>개인</span>
                </li>
              </ul> */}
              <SelectBox
                value={userType}
                onChange={(value) => {
                  setUserType(value);
                }}
                meta={{
                  key: 'userType',
                  type: 'round',
                  style: {
                    width: 150,
                    marginRight: 16,
                  },
                  menus: [
                    {
                      label: t('ALL'),
                      value: 'SELECT_ALL',
                    },
                    {
                      label: t('COMPANY'),
                      value: 'Enterprise',
                    },

                    {
                      label: t('INDIVIDUAL'),
                      value: 'Personal',
                    },
                  ],
                }}
              ></SelectBox>
            </div>
            <div className='dropdown__box'>
              <DropdownInput
                value={search}
                menuValue={searchType}
                onChange={(value, key) => {
                  setSearch(value);
                }}
                onMenuChange={(value, key) => {
                  console.log('menu', value);
                  setSearchType(value);
                }}
                onEnter={() => {
                  requsetSearch();
                }}
                meta={{
                  style: { width: '468px' },
                  placeholder: '검색어',
                  menus: [
                    {
                      value: 'Title',
                      label: '사업명',
                    },
                    {
                      value: 'Id',
                      label: '사업ID',
                    },
                    {
                      value: 'CertNumber',
                      label: '인증서 번호',
                    },
                    {
                      value: 'CompanyName',
                      label: '기업명',
                    },
                    {
                      value: 'Name',
                      label: '이름',
                    },
                  ],
                }}
              ></DropdownInput>
              <Button
                style={{ width: '80px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  requsetSearch();
                }}
                label={t('SEARCH')}
              ></Button>
            </div>
          </div>
        </div>
        <div className='contributors__container'>
          <ul>
            {list &&
              list.map((item, index) => {
                return (
                  <li>
                    <div className='web__box'>
                      <div className='img__box'>
                        <img src={item.user.profilePath}></img>
                      </div>
                      <div className='caption__box'>
                        <section className='title'>
                          <h4>
                            {item.user.type === 'Integrated'
                              ? `${item.user.companyName?.substring(0, 1)}O${item.user.companyName?.substring(2)}`
                              : `${item.user.name?.substring(0, 1)}O${item.user.name?.substring(2)}`}
                          </h4>
                          <div className='company'>
                            <span className='border'>{item.purchaseType === 'NormalPersonal' ? '개인' : '기업'}</span>
                            <span>{Handler.getYYYYMMDDByUnix(item.createdAt)}</span>
                            {item.isForward ? <div className='forward'>선도구매</div> : null}
                          </div>
                        </section>
                        <section className='price__box'>
                          <h1>{Handler.numberWithCommas(item.amount)} VRC</h1>
                          <span>상쇄</span>
                        </section>
                        <section className='info__box'>
                          <div className='area__box'>
                            <div className='item__list'>
                              <div className='item'>
                                <span>{item.project.id}</span>
                                <b>{item.project.title}</b>
                              </div>
                              <div className='item'>
                                <span>{item.cert.SN}</span>
                                <b>{item.cert.Reason}</b>
                              </div>
                            </div>
                          </div>
                          <div className='logo__box'>
                            <div className='ico big-cert-img'></div>
                          </div>
                        </section>
                      </div>
                    </div>
                    <div className='mobile__box'>
                      <section className='top__box'>
                        <div className='img__box'>
                          <img src={item.user.profilePath}></img>
                        </div>
                        <div className='caption__box'>
                          <div className='company'>
                            <span className='border'>{item.purchaseType === 'NormalPersonal' ? '개인' : '기업'}</span>
                            <span>{Handler.getYYYYMMDDByUnix(item.createdAt)}</span>
                            {item.isForward ? <div className='forward'>선도구매</div> : null}
                          </div>
                          <section className='title'>
                            <h4>
                              {item.user.type === 'Integrated'
                                ? `${item.user.companyName?.substring(0, 1)}O${item.user.companyName?.substring(2)}`
                                : `${item.user.name?.substring(0, 1)}O${item.user.name?.substring(2)}`}
                            </h4>
                          </section>
                          <section className='price__box'>
                            <h1>{Handler.numberWithCommas(item.amount)} VRC</h1>
                            <span>상쇄</span>
                          </section>
                        </div>
                      </section>
                      <section className='bottom__box'>
                        <section className='info__box'>
                          <div className='area__box'>
                            <div className='item__list'>
                              <div className='item'>
                                <span>{item.project.id}</span>
                                <b>{item.project.title}</b>
                              </div>
                              <div className='item'>
                                <span>{item.cert.SN}</span>
                                <b>{item.cert.Reason}</b>
                              </div>
                            </div>
                          </div>
                        </section>
                      </section>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <div style={{ height: 40 }}></div>

        <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
      </div>
    </article>
  );
};
