import React from 'react';
import { useState } from 'react';
import Handler from '../../Handler';

export const TextInput = (props) => {
  const { value, onChange, meta, onEnter } = props;
  const [passwordShow, setPasswordShow] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const onChangeInput = (e) => {
    let new_value = e.target.value;
    onChange(e.target.value, meta.key);
    if (meta.regexp) {
      if (new_value) {
        let targetRegexp = new RegExp(meta.regexp, 'g');
        const isValidation = targetRegexp.test(new_value);
        setError(!isValidation);
        setErrorMsg(!isValidation ? meta.regexpMsg : '');
      } else {
        setError(false);
        setErrorMsg(null);
      }
    }
    if (meta.equal) {
      console.log('meta.equal', meta.equal);
      if (meta.equal === new_value) {
        setError(false);
      } else {
        setError(true);
      }
    }
  };

  const buildInputType = () => {
    switch (meta.type) {
      case 'password': {
        if (passwordShow) {
          return 'text';
        } else {
          return 'password';
        }
      }
      case 'number': {
        return 'number';
      }
      case 'tel': {
        return 'tel';
      }
      default: {
        return 'text';
      }
    }
  };

  const buildInputClassname = () => (error ? 'error' : null);

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span>*</span>;
      }
    }
  };

  return (
    <article
      className={Handler.BuildArticleClassname('form-text-input', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>{meta.label}</span>
          {renderRequired()}
        </header>
      )}
      <div className='input__box'>
        <input
          className={buildInputClassname()}
          placeholder={meta.placeholder}
          disabled={meta.disabled || meta.readOnly}
          value={value}
          onChange={onChangeInput}
          type={buildInputType()}
          maxLength={meta.maxlength}
          autoComplete={meta?.keepInfo ? '' : 'new-password'}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (onEnter) {
                onEnter();
              }
            }
          }}
        ></input>
        {(meta.key === 'password' || meta.key === 'passwordConfirm') && (
          <div className='eye__box'>
            <div
              className={passwordShow ? 'ico eye-off' : 'ico eye'}
              onClick={() => {
                setPasswordShow(!passwordShow);
              }}
            ></div>
          </div>
        )}
        {meta.unitLabel && (
          <div className='unit__box'>
            <span>{meta.unitLabel}</span>
          </div>
        )}
      </div>
      {errorMsg && (
        <div className='error-text'>
          <p>{errorMsg}</p>
        </div>
      )}
      {meta.helperText && (
        <div className='helper-text'>
          <p>{meta.helperText}</p>
        </div>
      )}
    </article>
  );
};
