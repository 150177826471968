import React, { useContext } from 'react';
import { useState } from 'react';
import Handler from '../../Handler';

export const DatePicker = (props) => {
  const { value, onChange, meta, onEnter, min, max } = props;
  const [passwordShow, setPasswordShow] = useState(false);
  const [error, setError] = useState(false);

  let minDate = min ? min.replaceAll('.', '-') : null;
  let maxDate = max ? max.replaceAll('.', '-') : null;

  const onChangeInput = (e) => {
    let new_value = e.target.value;
    onChange(e.target.value, meta.key);
  };

  const buildInputType = () => {
    switch (meta.type) {
      case 'password': {
        if (passwordShow) {
          return 'text';
        } else {
          return 'password';
        }
      }
      case 'number': {
        return 'number';
      }
      default: {
        return 'text';
      }
    }
  };

  const buildInputClassname = () => {
    if (error) {
      return 'error';
    } else {
      return null;
    }
  };

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span>*</span>;
      }
    }
  };
  console.log('picker meta', meta);

  return (
    <article
      className={Handler.BuildArticleClassname('form-date-picker', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>{meta.label}</span>
          {renderRequired()}
        </header>
      )}
      <div className='input__box'>
        <input
          className={buildInputClassname()}
          placeholder={meta.placeholder}
          disabled={meta.disabled || meta.readOnly}
          value={value}
          onChange={onChangeInput}
          type={'date'}
          min={minDate}
          max={maxDate}
          onKeyPress={(e) => {
            // console.log(`Pressed keyCode ${e.key}`);
            if (e.key === 'Enter') {
              // Do code here
              e.preventDefault();
              if (onEnter) {
                onEnter();
              }
            }
          }}
        ></input>
      </div>
    </article>
  );
};
