import React from 'react';
import { Card } from '../../../styles/ComponentStyle';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import Handler from '../../../Handler';
import styled from '@emotion/styled';

const ChartTitleHeader = styled.section`
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  div.col {
    width: 100%;
    &.button-group {
      display: flex;
      justify-content: start;
    }
  }
`;

const ChartTitleWrapper = styled(Card)`
  text-align: left;
  padding: 20px;
  margin-bottom: 10px;
  &.chart {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .price-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 8px;
  }
  .price {
    display: flex;
    align-items: flex-end;
    h3 {
      font-size: 30px;
      span {
        font-size: inherit;
      }
    }
    .unit {
      font-size: 12px;
      padding: 4px 4px 4px 8px;
    }
    .up-down {
      font-size: 14px;
      padding: 4px 8px;
      .up {
        color: #ff5045;
        font-weight: bold;
      }
      .down {
        color: #4579ff;
        font-weight: bold;
      }
    }
  }
`;

export const ChartTitleSkeleton = (props) => {
  const { children } = props;

  return (
    <ChartTitleHeader>
      {children && <div className='col button-group'>{children}</div>}
      <Skeleton width={160} height={40} style={{ marginBottom: 18 }} />
      <ChartTitleWrapper className='col'>
        <div className='date divider'>
          <Skeleton width={80} />
        </div>
        <div className='price-wrapper'>
          <div className='price'>
            <Skeleton width={100} height={36} />
            <div className='up-down'>
              <Skeleton width={30} height={24} />
            </div>
          </div>
          <Skeleton width={60} height={28} />
        </div>
      </ChartTitleWrapper>
      <ChartTitleWrapper className='chart'>
        <CircularProgress />
      </ChartTitleWrapper>
    </ChartTitleHeader>
  );
};

export default ChartTitleSkeleton;
