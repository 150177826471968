import React, { useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContext } from '../contexts';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const SnackbarLayer = (props) => {
  const { snackbar, updateSnackbar } = useContext(SnackbarContext);

  const handleClose = (event, reason) => {
    console.log('handleClose event', event);
    console.log('handleClose reason', reason);

    if (reason === 'clickaway') {
      return;
    }

    updateSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={snackbar.open}
      autoHideDuration={3000}
      onClose={handleClose}
      message=''
    >
      <Alert onClose={handleClose} severity={snackbar.type} sx={{ width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', lineHeight: '20px' }}>{snackbar.message}</div>
      </Alert>
    </Snackbar>
  );
};
