import React, { useRef, useState, useEffect } from 'react';
import ScrollArrow from '../../styles/icon/scroll_arrow.svg';
import { font, fontSize, media } from '../../styles/style';
import styled from '@emotion/styled';

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 400px;
  position: relative;
  .image__box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    filter: ${(props) => (props.blur ? 'blur(20px)' : '')};
    transition: 0.5s all;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .title__box {
    h2,
    p {
      padding: 0 24px;
      color: #fff;
      text-align: center;
      line-height: 1.5;
      word-break: keep-all;
    }
    h2 {
      font-weight: 800;
      font-size: ${fontSize(font.$text_5xl)};
    }
    p {
      padding-top: 24px;
      font-size: ${fontSize(font.$text_xl)};
      font-weight: 300;
    }
    @media only screen and (max-width: ${media.$sm}) {
      h2 {
        font-size: ${fontSize(font.$text_5xl, media.$sm)};
      }
      p {
        font-size: ${fontSize(font.$text_xl, media.$sm)};
      }
    }
    z-index: 1;
  }
  .scroll__box {
    position: absolute;
    bottom: 25px;
    width: 30px;
    cursor: pointer;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-name: bounce;
    img {
      width: 100%;
    }
  }

  @keyframes bounce {
    from {
      bottom: 30px;
    }

    to {
      bottom: 20px;
    }
  }
`;

export const PageTitle = (props) => {
  const { title, description, style, image, preview } = props;
  const [currentImage, setCurrentImage] = useState(preview);
  const [loading, setLoading] = useState(true);

  const titleWrap = useRef();

  const fetchImage = (src) => {
    const loadingImage = new Image();
    loadingImage.src = src;
    loadingImage.onload = () => {
      setCurrentImage(loadingImage.src);
      setLoading(false);
    };
  };

  useEffect(() => {
    fetchImage(image);
  }, []);

  const gotoContainer = () => {
    window.scrollTo({ left: 0, top: titleWrap.current.getBoundingClientRect().bottom - 88, behavior: 'smooth' });
  };
  return (
    <TitleWrapper ref={titleWrap} blur={loading}>
      <div className='image__box'>
        <img src={currentImage} alt='' />
      </div>
      <div className='title__box'>
        <h2>{title}</h2>
        {description && <p>{description}</p>}
      </div>
      <div className='scroll__box' onClick={gotoContainer}>
        <img src={ScrollArrow} alt='아래화살표' />
      </div>
    </TitleWrapper>
  );
};
