import FAQBg from '../styles/images/faq_bg.png';

export const FAQ_TITLE = '자주 찾는 질문';
export const FAQ_BOX_TITLE = '찾는 내용이 없을 경우,\n문의해주시기 바랍니다.';
export const FAQ_BOX_BG = FAQBg;
export const FAQ_PHONE = {
  label: '전화 문의',
  value: '02-6274-3600',
  desc: '(평일 9시 – 18시 / 점심시간 12시-13시)',
};
export const FAQ_EMAIL = {
  label: '이메일 문의',
  button: '이메일 문의하기',
};

export const COMPANY_INFO = [
  { key: 'CEO', value: 'YSH' },
  { key: 'EMAIL', value: 'info@pople.earth' },
  { key: 'CONTACT_PHONE', value: '02-6274-3600' },
  { key: 'BIZ_NUMBER', value: '401-88-02247' },
  { key: 'BIZ_SELLER_NUMBER', value: '2022-서울강남-01536' },
  { key: 'ADDRESS', value: 'COMPANY_ADDRESS' },
];
