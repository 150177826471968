import React, { useEffect, useContext, useState, useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { TablePaging, TableMarketplace, PageTitle, MetaTag } from '../../components';
import { usePagination } from '../../hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { SnackbarContext } from '../../contexts';
import { PAGE_TOP } from '../../constants';
import { SearchBox } from './components';
import API from '../../api';
import Utils from '../../api/Utils';

export const MarketplaceListPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [areaType, setAreaType] = useState('SELECT_ALL');
  const [isForwardTrade, setIsForwardTrade] = useState('SELECT_ALL');
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState('ProjectTitle');
  const [lastSearchBody, setLastSearchBody] = useState(null);
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });

  //event
  const requestSearch = () => {
    requestNewList(0);
  };
  //server
  const requestNewList = (init_page) => {
    let params = null;
    if (init_page === 0) {
      params = {
        page: init_page,
        size: 9,
        searchType: searchType,
        searchValue: search,
      };
      if (isForwardTrade !== 'SELECT_ALL') {
        params.isForwardTrade = isForwardTrade;
      }
      if (areaType !== 'SELECT_ALL') {
        params.areaType = areaType;
      }

      setLastSearchBody({ ...params });
    } else {
      params = {
        ...lastSearchBody,
        page: init_page ? init_page : page,
        size: 9,
      };
    }
    replaceSearchQuery(params);
    API.Marketplace.GetList(params)
      .then((res) => {
        const { content, totalPages } = res.data;
        setLoading(false);
        setList(content);
        setTotalPage(totalPages);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data?.message,
        });
      });
  };

  useEffect(() => {
    if (
      (!lastSearchBody && !location.search) ||
      (location.search && { ...Utils.ParamsToObject(location.search) }['page'] === '0')
    ) {
      requestNewList(0);
    }
    setMetaObject({
      title: t('BUY_CREDITS'),
      description: t('BUY_CREDITS_DESCRIPTION'),
      keywords: '팝플이란, 팝플',
      image: PAGE_TOP.CREDIT.image,
      canonical: '',
      url: window.location.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.search) {
      const params = {
        ...Utils.ParamsToObject(location.search),
      };
      setPage(Number(params.page));
      setLastSearchBody(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (lastSearchBody) {
      requestNewList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const replaceSearchQuery = (params) => {
    history.replace({
      search: Utils.ObjectToParams({
        ...params,
        page: page,
        size: 9,
      }),
    });
  };

  const rowClick = (slug) => {
    history.push('/marketplace/detail/' + slug);
  };

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <PageTitle
          title={t('BUY_CREDITS')}
          description={t('BUY_CREDITS_DESCRIPTION')}
          image={PAGE_TOP.CREDIT.image}
          preview={PAGE_TOP.CREDIT.preview}
        />
        <article className='content__wrapper'>
          <SearchBox
            area={areaType}
            areaChange={setAreaType}
            forward={isForwardTrade}
            forwardChange={setIsForwardTrade}
            keyword={search}
            keywordChange={setSearch}
            keywordType={searchType}
            keywordTypeChange={setSearchType}
            searchRequest={requestSearch}
          />
          {loading ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            <div className='table__container'>
              <TableMarketplace data={list} rowClick={rowClick}></TableMarketplace>
            </div>
          )}
          <div style={{ height: 40 }}></div>
          <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
        </article>
      </article>
    </>
  );
};
