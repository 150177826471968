import React from 'react';
import Handler from '../../Handler';

export const CheckBox = (props) => {
  const { value, onChange, required, label, meta, noOption } = props;

  const renderRequired = () => {
    if (meta.subLabel) {
      return <em className={meta.required ? 'required' : ''}>{meta.subLabel}</em>;
    }
    if (meta && meta.required) {
      if (meta.classNAme === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span>*</span>;
      }
    }
  };

  return (
    <article
      className={Handler.BuildArticleClassname('form-check-box', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      <section className='ico__box'>
        <div
          onClick={() => {
            onChange(!value, meta.key);
          }}
          className={value ? 'ico check-square-on' : 'ico check-square'}
        ></div>
      </section>
      <section
        className='label__box'
        onClick={() => {
          onChange(!value, meta.key);
        }}
      >
        <span>{meta.label}</span>
        {renderRequired()}
      </section>
    </article>
  );
};
