import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PageTitle, MetaTag } from '../../components';
import { ContentContext } from '../../contexts';
import { FOOTPRINT_TITLE_BG, FOOTPRINT_LIST } from '../../constants';
import { color, media } from '../../styles/style';
import GreyDownArrow from '../../styles/icon/grey_down_arrow.svg';
import styled from '@emotion/styled';

const PhraseContainer = styled.section`
  padding: 60px 0;
  text-align: center;
  p {
    /* margin-top: 60px; */
    font-size: 20px;
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 60px 24px;
    p {
      font-size: 16px;
      word-break: keep-all;
      white-space: initial;
      margin-top: 20px;
    }
  }
`;
const IconContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  @media only screen and (max-width: ${media.$sm}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 24px;
  }
`;

const IconCard = styled.div`
  /* background-color: ${color.$main500}; */
  cursor: pointer;
  /* background-image: url(''); */
  background-size: cover;
  padding: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  img.bgImage {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.6s;
    &:hover {
      filter: blur(20px);
    }
  }
  .icon {
    width: 120px;
    z-index: 1;
    img {
      width: 100%;
    }
  }
  .title {
    p {
      font-size: 16px;
      word-break: break-all;
    }
    font-weight: 600;
    z-index: 1;
  }
  .reduce {
    border-radius: 25px;
    background-color: #fff;
    z-index: 1;
    padding: 5px 25px;
    margin-top: 20px;
    display: flex;
    img {
      margin-right: 10px;
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    width: 100%;
    .title p {
      font-size: 20px;
    }
  }
`;

export const CampaignPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { deviceWidth } = useContext(ContentContext);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });
  useEffect(() => {
    setMetaObject({
      title: t('FOOTPRINT_TITLE'),
      description: t('FOOTPRINT_DESCRIPTION'),
      keywords: '팝플이란, 팝플',
      image: FOOTPRINT_TITLE_BG,
      canonical: '',
      url: window.location.href,
    });
  }, []);

  return (
    <>
      <MetaTag props={metaObject} />

      <article className='basic-page'>
        <PageTitle title={t('FOOTPRINT_TITLE')} description={t('FOOTPRINT_DESCRIPTION')} image={FOOTPRINT_TITLE_BG} />
        <PhraseContainer>
          <h2 className='font-bold text-primary'>{t('FOOTPRINT_PHRASE')}</h2>
          <p className='text-sm leading-8 text-gray-600 mt-6'>{t('FOOTPRINT_PHRASE_DESCRIPTION_01')}</p>
          <p className='mt-4 text-main-800 font-bold'>{t('FOOTPRINT_PHRASE_DESCRIPTION_02')}</p>
        </PhraseContainer>
        <section className='content__wrapper'></section>
        <IconContainer>
          {FOOTPRINT_LIST &&
            FOOTPRINT_LIST.map((item) => {
              return (
                <IconCard key={`footprint-list-${item.key}`}>
                  <img src={item.img} className='bgImage' alt='' />
                  <div className='icon mb-3'>
                    <img src={item.icon} alt='' />
                  </div>
                  <div className='font-bold p-3 bg-slate-50 z-10 w-full rounded-md'>
                    <p>{t(item.key)}</p>
                    <div className="flex justify-center pt-3">
                      <img className='mr-2' src={GreyDownArrow} alt='' />
                      <p className='font-bold text-blue-600 text-xl'>{item.value}</p>
                    </div>
                  </div>
                </IconCard>
              );
            })}
        </IconContainer>
      </article>
    </>
  );
};
