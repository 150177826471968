import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { UserContext, SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';

export const SellDetailPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { userInfo, isPersonal } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [data, setData] = useState(null);
  const { slug } = useParams();
  //   const [calculateList, setCalculateList] = useState(null);

  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  const refreshData = () => {
    API.User.GetSaleDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('refreshData result', result);
        setData(result);
        // setCalculateList(result.calculate);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const renderPurchaseType = (item) => {
    if (item.purchaseType === 'ForwardEnterprise') {
      return (
        <div className='forward-type__box'>
          <span>선도거래</span>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderBuildType = (data) => {
    if (data.type === 'VBANK') {
      return (
        <div className='vank__box'>
          <span style={{ color: '#f41515', fontSize: 14, fontWeight: 'bold' }}>{t('VIRTUAL_ACCOUNT')}</span>
          <div className='detail__list'>
            <div className='detail__item'>
              <header>- {t('BANK_NAME')} :</header>
              <span>{data.vbankName}</span>
            </div>
            <div className='detail__item'>
              <header>- {t('BANK_ACCOUNT_USER')} :</header>
              <span>{data.vbankHolder}</span>
            </div>
            <div className='detail__item'>
              <header>- {t('BANK_ACCOUNT_NUMBER')} :</header>
              <span>{data.vbankNum}</span>
            </div>
          </div>
        </div>
      );
    } else {
      return Handler.buildPaymentTypeText(data.type);
    }
  };

  //   console.log('calculateList', calculateList);
  if (!data || !userInfo) {
    return null;
  }

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header className='custom'>
            <h1>{t('SELL_DETAIL')}</h1>
            <section className='breadcrumb__box'>
              <ul>
                <li
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  Home
                </li>
                <li>{t('MYPAGE')}</li>
                <li
                  onClick={() => {
                    history.push('/mypage/sell_list');
                  }}
                >
                  {t('SELL_LIST')}
                </li>
                <li className='active'>{t('SELL_DETAIL')}</li>
              </ul>
            </section>
          </header>
        </section>
        <section className='forward-purchase-detail__container'>
          <section className='order__box'>
            <header>
              <span>{t('ORDER_INFO')}</span>
            </header>

            <div className='table__box'>
              <ul className='header'>
                <li>{t('ORDER_NUMBER')}</li>
                <li>{t('ORDER_HISTORY')}</li>
              </ul>
              <div className='m-order-number__box'>
                <div className='header'>{t('ORDER_NUMBER')}</div>
                <div className='value'>{data.orderNumber}</div>
              </div>
              <ul className='data'>
                <li>{data.orderNumber}</li>
                <li>
                  <div className='img__box'>
                    {data.project.mainImages && data.project.mainImages.length > 0 && (
                      <img src={data.project.mainImages[0]} alt={data.project.title}></img>
                    )}
                  </div>
                  <div className='title__box'>
                    {renderPurchaseType(data)}
                    <span className='name'>{data.project.title}</span>
                    <span className='id'>{data.project.id}</span>
                    <div className='price'>
                      <span>
                        {Handler.numberWithCommas(data.unitPrice)}
                        {t('WON')}
                      </span>
                      <span>·</span>
                      <span>{Handler.numberWithCommas(data.amount)}VRC</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
          <section className='payment__box'>
            <header>
              <span>{t('PURCHASE_INFO')}</span>
              <div className='status'>{t(Handler.buildPaymentStatusText(data.status))}</div>
            </header>
            <div className='table__box'>
              <ul className='header'>
                <li>{t('PURCHASE_DATE')}</li>
                <li className={data.type}>{t('PAYMENT_TYPE')}</li>
                <li>{t('SALE_PRICE')}</li>
                <li>{t('PURCHASE_AMOUNT')}</li>
                <li></li>
              </ul>
              <ul className='data'>
                <li>{Handler.getYYYYMMDDHHMMByUnix(data.createdAt)}</li>
                <li className={data.type}>{renderBuildType(data)}</li>
                <li>
                  {Handler.numberWithCommas(data.price)}
                  {t('WON')}
                </li>
                <li>
                  {Handler.numberWithCommas(data.price)}
                  {t('WON')}
                </li>
                <li>
                  {/* <div
                    className='button'
                    onClick={() => {
                      window.open(data.receiptUrl, '_blank');
                    }}
                  >
                    영수증 보기
                  </div> */}
                </li>
              </ul>
            </div>
          </section>
          <section className='buyer__box'>
            <header>
              <span>{t('BUYER_INFO')}</span>
            </header>
            <div className='table__box'>
              <ul className='header'>
                <li>{t('NAME')}</li>
                <li>{t('BUY_REASON')}</li>
                <li>{t('CONTACT')}</li>
              </ul>
              <ul className='data'>
                <li>{data.user.name}</li>
                <li>{data.reason}</li>
                <li>{data.user.phone}</li>
              </ul>
            </div>
          </section>
          {isPersonal && (
            <section className='calculate__box'>
              <header>
                <span>{t('PROFIT_SETTLEMENT')}</span>
                <span className='desc'>구매한 사업에서 크레딧이 발행되면 결제 순서에 따라 자동으로 상쇄됩니다.</span>
              </header>
              <div className='table__box'>
                <ul className='header'>
                  <li>{t('SALE_DATE')}</li>
                  <li>{t('PROFIT_SETTLEMENT_STATUS')}</li>
                  <li>{t('PROFIT_SETTLEMENT_ACCOUNT')}</li>
                </ul>
                {/* {calculateList && calculateList.length === 0 && <span className='empty__box'>데이터가 없습니다.</span>}
                {calculateList && calculateList.length > 0 && (
                  <div className='data__wrapper'>
                    <ul className='data'>
                      {calculateList.map((item, index) => {
                        return (
                          <li>
                            <div className='date'>
                              <span>{Handler.getYYYYMMDDByUnix(item.createdAt)}</span>
                              <span className='ton'>{Handler.numberWithCommas(item.amount)}톤 판매</span>
                            </div>
                            <div className='status'>
                              <b>정산완료</b>
                              <span>{Handler.getYYYYMMDDByUnix(item.createdAt)}</span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    <div className='button__box'>
                      <div
                        className='button'
                        onClick={() => {
                          history.push('/mypage/modify');
                        }}
                      >
                        정산계좌 확인
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </section>
          )}
          <section className='button-list__box'>
            <div
              className='button'
              onClick={() => {
                history.push('/mypage/sell_list');
              }}
            >
              {t('LIST')}
            </div>
          </section>
        </section>
      </article>
    </article>
  );
};
