import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePagination } from '../../hooks';
import { ContentContext, LangContext } from '../../contexts';
import { PageTitle, SectionTitle, MetaTag } from '../../components';
import { color, media } from '../../styles/style';
import { SnackbarContext } from '../../contexts';
import { PAGE_TOP, NEWS_MAIN_LIST, NEWS_OTHER_LIST } from '../../constants';
import styled from '@emotion/styled';
import GreyArrow from '../../styles/icon/grey_arrow.svg';
import Handler from '../../Handler';
import API from '../../api';
import dayjs from 'dayjs';

const NewsMain = styled.div`
  padding: 140px 0;
  h2 {
    text-align: center;
    padding-bottom: 60px;
  }
  .news__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 40px;
    column-gap: 20px;
    .news__card {
      cursor: pointer;
      .card_image {
        width: 400px;
        height: 180px;
        overflow: hidden;
        border-radius: 10px;
        position: relative;
        border: 1px solid #eee;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .title {
        font-weight: 600;
        font-size: 20px;
        padding-top: 8px;
        word-break: keep-all;
      }
      .date {
        font-size: 14px;
        color: ${color.$black_800};
      }
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 60px 0;
    .news__container {
      padding: 0 24px;
      width: 100%;
      grid-template-columns: 1fr;
      .news__card {
        .card_image {
          height: 180px;
          width: 100%;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
`;

const NewsOther = styled.div`
  padding-bottom: 140px;
  width: 100%;
  @media only screen and (max-width: ${media.$sm}) {
    padding-bottom: 60px;
    background-color: #eee;
    h2 {
      padding-top: 40px;
      text-align: center;
    }
  }
  .news__container {
    width: 100%;
    .news__card {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      .content {
        margin-right: 200px;
        max-width: 60%;
        .title {
          font-weight: 600;
          font-size: 20px;
          padding: 8px 0;
          word-break: keep-all;
        }
        .date {
          padding-top: 8px;
          font-size: 14px;
          color: ${color.$black_800};
        }
        .description {
          color: ${color.$black_800};
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-word;

          display: -webkit-box;
          -webkit-line-clamp: 3; // 원하는 라인수
          -webkit-box-orient: vertical;
        }
      }
      .card_image {
        width: 300px;
        height: 160px;
        overflow: hidden;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .mobile_news__container {
    padding: 24px;
    .mobile_news__card {
      display: flex;
      flex-direction: column;
      background: #fff;
      margin-bottom: 24px;
      border-radius: 10px;
      .content {
        padding: 24px;
        .title {
          font-weight: 600;
          font-size: 20px;
          padding: 8px 0;
          word-break: keep-all;
        }
        .date {
          padding-top: 8px;
          font-size: 14px;
          color: ${color.$black_800};
        }
        .description {
          color: ${color.$black_800};
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-word;

          display: -webkit-box;
          -webkit-line-clamp: 3; // 원하는 라인수
          -webkit-box-orient: vertical;
        }
      }
      .card_image {
        height: 160px;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
        position: relative;
        img {
          height: auto;
          width: 100%;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          object-fit: contain;
        }
      }
    }
  }
`;

export const NewsListPage = (props) => {
  const { t } = useTranslation();
  const { deviceWidth } = useContext(ContentContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const [areaType, setAreaType] = useState('SELECT_ALL');
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState('Title');
  const [lastSearchBody, setLastSearchBody] = useState(null);
  const [mainList, setMainList] = useState(null);
  const [otherlist, setOtherList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });

  const gotoNewsPage = (link) => {
    const openWindow = window.open('about:blank');
    openWindow.location.href = link;
  };

  useEffect(() => {
    setMetaObject({
      title: t('POPLE_NEWS'),
      description: t('POPLE_NEWS_DESCRIPTION'),
      keywords: '팝플이란, 팝플',
      image: PAGE_TOP.NEWS.image,
      canonical: '',
      url: window.location.href,
    });
  }, []);

  useEffect(() => {
    requestNewList(0);
  }, []);

  useEffect(() => {
    if (lastSearchBody) {
      requestNewList();
    }
  }, [page]);

  const requestNewList = (init_page) => {
    let params = null;

    if (init_page) {
      params = {
        page: init_page ? init_page : page,
        limit: Handler.VARIABLES.PAGE_LIMIT,
        searchType: searchType,
        searchValue: search,
      };

      setLastSearchBody({ ...params });
    } else {
      params = {
        ...lastSearchBody,
        page: init_page ? init_page : page,
        limit: Handler.VARIABLES.PAGE_LIMIT,
      };
    }
    API.News.GetList(params)
      .then((res) => {
        const { mainList, otherList } = res.data;
        setMainList(mainList);
        setOtherList(otherList.content);
        setTotalPage(otherList.totalPages);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data?.message,
        });
      });
  };

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <PageTitle
          title={t('POPLE_NEWS')}
          description={t('POPLE_NEWS_DESCRIPTION')}
          image={PAGE_TOP.NEWS.image}
          preview={PAGE_TOP.NEWS.preview}
        />
        <article className='content__wrapper'>
          <NewsMain>
            <SectionTitle title={t('MAIN_NEWS')} />
            <div className='news__container'>
              {mainList &&
                mainList.map((news, idx) => {
                  return (
                    <div key={`news-main-list-${idx}`} className='news__card' onClick={() => gotoNewsPage(news.link)}>
                      <div className='card_image'>
                        <img src={news.image} alt='' />
                      </div>
                      <div className='content'>
                        <p className='title'>{isKorean ? news.title : news.titleEn}</p>
                        <p className='date'>{dayjs(news.pressDate).format('YYYY.MM.DD')}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </NewsMain>
          <NewsOther>
            <SectionTitle title={t('OTHER_NEWS')} />
            {deviceWidth > 1400 ? (
              <div className='news__container'>
                {otherlist &&
                  otherlist.map((news) => {
                    return (
                      <div
                        key={`news-sub-list-${news.title}`}
                        className='news__card'
                        onClick={() => gotoNewsPage(news.link)}
                      >
                        <div className='content'>
                          <p className='date'>{dayjs(news.pressDate).format('YYYY.MM.DD')}</p>
                          <p className='title'>{isKorean ? news.title : news.titleEn}</p>
                          <p className='description'>{isKorean ? news.content : news.contentEn}</p>
                          <div>
                            <img src={GreyArrow} alt='' />
                          </div>
                        </div>
                        <div className='card_image'>
                          <img src={news.image} alt='' />
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className='mobile_news__container'>
                {otherlist &&
                  otherlist.map((news) => {
                    return (
                      <div
                        key={`news-other-list-${news.title}`}
                        className='mobile_news__card'
                        onClick={() => gotoNewsPage(news.link)}
                      >
                        <div className='card_image'>
                          <img src={news.image} alt='' />
                        </div>
                        <div className='content'>
                          <p className='date'>{dayjs(news.pressDate).format('YYYY.MM.DD')}</p>
                          <p className='title'>{isKorean ? news.title : news.titleEn}</p>
                          <p className='description'>{isKorean ? news.content : news.contentEn}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </NewsOther>
        </article>
      </article>
    </>
  );
};
