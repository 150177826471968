import React from 'react';
import { PageTitle } from '../../components';
import { DOCUMENT_TITLE, DOCUMENT_TITLE_DESC, DOCUMENT_TITLE_BG } from '../../constants';

export const DocumentPage = (props) => {

  return (
    <article className='purchase__container'>
      <PageTitle title={DOCUMENT_TITLE} description={DOCUMENT_TITLE_DESC} image={DOCUMENT_TITLE_BG} />
      <div className='normal__box'></div>
    </article>
  );
};
