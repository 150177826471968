import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { media } from '../../../styles/style';
import { SectionTitle } from '../../../components';
import { PARTNER_LIST } from '../../../constants/main.constants';

const PartnerContainer = styled.section`
  padding: 140px 0;
  width: 100%;
  text-align: center;
  h2 {
    padding: 20px 0 45px;
  }
  p {
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 60px 24px;
  }
`;

const PartnerLogoContainer = styled.article`
  display: grid;
  grid-template-columns: repeat(4, 230px);
  gap: 60px;
  align-items: center;
  justify-content: center;
  max-width: ${media.$wrapper_max_width};
  margin: auto;
  .logo-box {
    height: 80px;
    width: 100%;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 24px;
  }
`;

export const Partners = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <PartnerContainer>
      <SectionTitle title={t('PARTNERS')} data-aos='fade-up' />
      <PartnerLogoContainer>
        {PARTNER_LIST.map((logo, idx) => (
          <div key={`partner-list-${idx}`} className='logo-box' data-aos='fade-up'>
            <img src={logo} alt='' />
          </div>
        ))}
      </PartnerLogoContainer>
    </PartnerContainer>
  );
};
