// SEOMetaTag.js
import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

const KakaoMap = styled.div`
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 300px;
`;

export const Map = (props) => {
  const { location, title } = props;
  console.log('project location ------>', location);
  const mapRef = useRef(null);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_KEY}&libraries=services&autoload=false`;
    document.head.appendChild(script);

    script.onload = () => {
      if (mapRef.current) {
        window.kakao.maps.load(() => {
          var options = {
            //지도를 생성할 때 필요한 기본 옵션
            center: new window.kakao.maps.LatLng(37.5189257, 127.0345934),

            level: 3, //지도의 레벨(확대, 축소 정도)
          };
          var map = new window.kakao.maps.Map(mapRef.current, options);
          var geocoder = new window.kakao.maps.services.Geocoder();

          const addr = (location.daum && location.daum.address) || location.detail;

          // 주소로 좌표를 검색합니다
          geocoder.addressSearch(addr, function (result, status) {
            // 정상적으로 검색이 완료됐으면
            if (status === window.kakao.maps.services.Status.OK) {
              var coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);

              // 결과값으로 받은 위치를 마커로 표시합니다
              var marker = new window.kakao.maps.Marker({
                map: map,
                position: coords,
              });

              // 인포윈도우로 장소에 대한 설명을 표시합니다
              var infowindow = new window.kakao.maps.InfoWindow({
                content: `<div style="width:150px;text-align:center;padding:6px 0;">${title}</div>`,
              });
              infowindow.open(map, marker);

              // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
              map.setCenter(coords);
            }
          });
        });
      }
    };
    return () => script.remove();
  }, []);
  // props로 content 내용을 불러올 예정임
  if (location.daum) return <KakaoMap id='map' ref={mapRef} style={{ width: '340px', height: '300px' }}></KakaoMap>;
  return <></>;
};
