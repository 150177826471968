import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Icon } from '@mui/material'
import { ArrowDropDown } from "@mui/icons-material"
import { color, font, media, fontSize } from '../../../styles/style';
import { OutLineButton } from '../../../components';
import ReduceIcon01 from '../../../styles/icon/img-email.png';
import ReduceIcon02 from '../../../styles/icon/img-plane.png';
import ReduceIcon03 from '../../../styles/icon/img-shower.png';

import { SECTION_04_BG } from '../../../constants/main.constants';

const ReductionContainer = styled.section`
  padding: 140px 0;
  /* background-image: url(${SECTION_04_BG}); */
  width: 100%;
  text-align: center;
  h2 {
    /* color: ${color.$white}; */
    font-size: ${fontSize(font.$text_5xl)};
    font-weight: ${font.bold};
    padding: 20px 0 45px;
  }
  p {
    font-size: ${fontSize(font.$text_base)};
  }
  @media only screen and (max-width: ${media.$sm}) {
    display: block;
    h2 {
      font-size: ${fontSize(font.$text_5xl, media.$sm)};
      word-break: keep-all;
      white-space: initial;
      margin: 0 24px;
    }
    p {
      font-size: ${fontSize(font.$text_base, media.$sm)};
    }
  }
`;

const ReductionItemContainer = styled.article`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  max-width: 900px;
  width: 100%;
  margin: auto;
  padding-top: 2rem;
  @media only screen and (max-width: ${media.$sm}) {
    display: block;
  }
`;

const ReductionItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 80px;
  p {
    /* color: #fff; */
    font-weight: 800;
  }
  .img-wrapper {
    border-radius: 15rem;
    height: 150px;
    width: 150px;
    overflow: hidden;
    position: relative;
    margin-bottom: 2rem;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
      right:0;
      bottom:0;
      transition: all 0.6s;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  .reduce-info {
    margin-top: 20px;
    background: #fff;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 4px 25px;
    p {
      color: #333;
      font-weight: 400;
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
`;

export const Reduction = (props) => {
  const history = useHistory();
  const { t } = useTranslation()
  const LIST = [
    { title: t('MAIN_SECTION_04_EMAIL'), desc: '14.6kg', icon: ReduceIcon01 },
    { title: t('MAIN_SECTION_04_FLIGHT'), desc: '1.68t', icon: ReduceIcon02 },
    { title: t('MAIN_SECTION_04_SHOWER'), desc: '7kg', icon: ReduceIcon03 },
  ];

  const gotoMore = () => {
    history.push('/campaign');
  };
  return (
    <ReductionContainer className='bg-slate-50'>
      <h2 data-aos='fade-up'>{t('MAIN_SECTION_04_TITLE')}</h2>
      <ReductionItemContainer>
        {LIST &&
          LIST.map((item) => (
            <ReductionItem key={`list-reduction-${item.title}`}>
              <div className='img-wrapper' data-aos='fade-up'>
                <img src={item.icon} alt='' />
              </div>
              <p data-aos='fade-up'>{item.title}</p>
              <div className='reduce-info' data-aos='fade-up'>
                <Icon component={ArrowDropDown} color={color.$main500}></Icon>
                <p>{item.desc}</p>
              </div>
            </ReductionItem>
          ))}
      </ReductionItemContainer>
      <OutLineButton
        aos='fade-up'
        label={t('MORE')}
        disabled={false}
        color={color.$main500}
        onClick={gotoMore}
      ></OutLineButton>
    </ReductionContainer>
  );
};