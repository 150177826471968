import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'market/market_place';

const GetMain = (params) => {
  const url = BASE_URL + 'market/main' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetContributors = (params) => {
  const url = BASE_URL + 'market/contributors' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetList = (params) => {
  const url = BASE_URL + PATH + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetDetail = (slug) => {
  const url = BASE_URL + PATH + `/${slug}`;
  return AxiosInstance.get(url);
};

const Marketplace = {
  GetMain: GetMain,
  GetList: GetList,
  GetDetail: GetDetail,
  GetContributors: GetContributors,
};

export default Marketplace;
