import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ToastViewer, ModalAccount, ModalPurchase, ModalCertification, MetaTag } from '../../components';
import { GoogleMapWrapper } from '../../components/basic/GoogleMap';
import Popover from '@mui/material/Popover';
import { useHistory, useParams } from 'react-router-dom';
import { UserContext, SnackbarContext, ContentContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import BackArrow from '../../styles/icon/icon_back.svg';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Social } from '../../components';
import { ReactInicis } from '@hellojh/react-inicis';
import { modalStyle, modalMobileStyle } from '../../styles/style';
import { useLabel } from '../../hooks';

export const MarketplaceDetailPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { ERROR } = useLabel();
  const { userInfo, isPersonal } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { slug } = useParams();
  const [project, setProject] = useState(null);
  const [projectDoc, setProjectDoc] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [purchaseType, setPurchaseType] = useState(null);
  const [accountOpen, setAccountOpen] = useState(false);
  const [isPurchase, setIsPurchase] = useState(0);
  const [payData, setPayData] = useState(null);
  const [certificationOpen, setCertificationOpen] = useState(false);
  const [certificationData, setCertificationData] = useState(false);
  const [address, setAddress] = useState(null);
  const { deviceWidth } = useContext(ContentContext);
  const [mobilePurchaseOpen, setMobilePurchaseOpen] = useState(false);
  const TAB_LIST = [t('PROJECT_INTRO'), t('COMPANY_INTRO')];
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플',
    keywords: '팝플',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });

  useEffect(() => {
    document.body.addEventListener('scroll', listener);
    return () => {
      document.body.removeEventListener('scroll', listener);
    };
  });

  const listener = (e) => {
    let project_el = document.getElementById('project-box-el');
    let company_el = document.getElementById('company-box-el');
    let contributor_el = document.getElementById('contributor-box-el');
    if (project_el && company_el && contributor_el) {
      let project_el_top = project_el.getBoundingClientRect().top;
      let company_el_top = company_el.getBoundingClientRect().top;
      let contributor_top = contributor_el.getBoundingClientRect().top;

      if (project_el_top > 0 && company_el_top > 0 && contributor_top > 0) {
        setTabIndex(0);
      } else if (project_el_top < 56 && company_el_top > 56) {
        setTabIndex(0);
      } else if (company_el_top < 56 && contributor_top > 56) {
        setTabIndex(1);
      } else {
        setTabIndex(2);
      }
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'simple-popover' : undefined;
  const goBack = () => history.go(-1);

  useEffect(() => {
    if (slug) {
      refreshData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (project) {
      setMetaObject({
        title: project.title,
        description: project.introProject && Handler.genPlaneText(project.introProject),
        keywords: project.title,
        image: project.mainImages[0],
        canonical: 'marketplace/detail/' + slug,
        url: window.location.href,
      });
    }
  }, [project, slug]);

  useEffect(() => {
    console.log('payData', payData);
    if (payData) {
      setIsPurchase(isPurchase + 1);
    } else {
      setIsPurchase(isPurchase);
    }
  }, [payData, isPurchase]);

  const requestModal = (type) => {
    if (!userInfo) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: ERROR.LOGIN,
      });
      history.push('/user/login');
      return;
    }

    if (type === 'forward') {
      if (project.forwardType === 'ForwardPersonal' && !isPersonal) {
        updateSnackbar({
          type: 'error',
          open: true,
          message: t('ONLY_FOR_INDIVIDUAL'),
        });
        return;
      }

      if (project.forwardType === 'ForwardEnterprise' && isPersonal) {
        updateSnackbar({
          type: 'error',
          open: true,
          message: t('ONLY_FOR_COMPANY'),
        });
        return;
      }

      //선도구매일때만 계좌정보 입력
      if (isPersonal) {
        if (userInfo.account) {
          if (!userInfo.account.account || !userInfo.account.bank || !userInfo.account.name) {
            updateSnackbar({
              type: 'error',
              open: true,
              message: t('INFO_FOR_PROFIT'),
            });
            setAccountOpen(true);
            return;
          }
        } else {
          updateSnackbar({
            type: 'error',
            open: true,
            message: t('INFO_FOR_PROFIT'),
          });
          setAccountOpen(true);
          return;
        }
      }
    }

    setPurchaseType(type);
    setOpen(true);
  };

  const requestConfirm = (data) => {
    console.log('requestConfirm', data);
    console.log('userInfo!!', userInfo);

    let message = '';
    if (!data.amount) message = t('CHECK_AMOUNT');
    else if (data.reason === 'none') message = t('CHECK_PURCHASE_REASON');
    else if (data.reason === '직접입력' && !data.reasonCustom) message = t('FILL_PURCHASE_REASON');
    if (message) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: message,
      });
      return;
    }

    switch (data.confirmType) {
      case 'consulting': {
        API.Payment.PostForwardOrder({
          projectSeq: data.project.seq,
          amount: data.amount,
          reason: data.reason === '직접입력' ? data.reasonCustom : data.reason,
        })
          .then((res) => {
            updateSnackbar({
              type: 'success',
              open: true,
              message: '상담 요청 성공',
            });
            setOpen(false);
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      case 'payment': {
        if (data.type === 'normal') {
          if (!data.isPresent) {
            //선물
          } else {
            console.log('work');
            setPayData({
              productName: 'flower',
              productPrice: '2000',
              buyerName: 'sjh',
              buyerTel: '01024707427',
              buyerEmail: 'test@test.com',
              payStatus: 0,
              returnUrl: 'https://www.dev.pople.kr/marketplace/purchase_return',
              closeUrl: 'https://www.dev.pople.kr/marketplace/purchase_close',
              telStatus: 1,
            });
          }
        }

        break;
      }
      default: {
        break;
      }
    }
  };

  const refreshData = () => {
    API.Marketplace.GetDetail(slug)
      .then((res) => {
        let result = res.data;
        setProject(result.project);
        setProjectDoc(result.projectDoc);
        setSelectedImage(result.project.mainImages[0]);
      })
      .catch((err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  useEffect(() => {
    if (projectDoc && projectDoc.location) {
      if (projectDoc.location.daum) {
        if (projectDoc.location.daum?.address_name) {
          setAddress(projectDoc.location.daum.address_name);
        } else {
          setAddress(projectDoc.location.daum.address);
        }
      } else if (projectDoc.location.addr1) {
        setAddress(projectDoc.location.addr1.formatted_address);
      } else if (projectDoc.location.detail) {
        setAddress(projectDoc.location.detail);
      }
    }
  }, [projectDoc]);

  if (!project || !projectDoc) {
    return null;
  }

  const renderPurchaseBox = () => {
    const renderTradeTypeText = (type) => {
      if (type === 'forward') {
        if (['ForwardPersonal', 'ForwardEnterprise'].includes(project.forwardType)) {
          const label = project.forwardType === 'ForwardEnterprise' ? t('COMPANY') : t('INDIVIDUAL');
          return (
            <span>
              <em>{label}</em> {t('PRE_PURCHASE')}
            </span>
          );
        } else {
          return '';
        }
      } else if (type === 'normal') {
        return <span>{t('ORDINARY_PURCHASE')}</span>;
      }
    };

    const buildAmountValue = (type) => {
      return Handler.numberWithCommas(type === 'forward' ? project.forwardAmount : project.reductionNow);
    };

    const buildStatusClassName = (type) => {
      if (project.saleStatus === 'Closed' || project.saleStatus === 'SoldOut') {
        return 'status__box SoldOut';
      }
      if (type === 'forward') {
        return project.forwardAmount > 0 ? 'status__box' : 'status__box SoldOut';
      } else if (type === 'normal') {
        return project.reductionNow > 0
          ? 'status__box'
          : project.reductionSum === 0
          ? 'status__box ready'
          : 'status__box SoldOut';
      }
    };

    const buildStatusText = (type) => {
      let label = t('ON_SALE');
      if (type === 'forward') {
        /* 선도거래 */
        if (project.forwardAmount > 0) {
          if (project.saleStatus === 'Closed') label = '판매종료';
          else if (project.saleStatus === 'SoldOut') label = t('SOLD_OUT');
          else if (project.saleStatus === 'Ready') label = t('PREPARING');
        } else {
          label = t('SOLD_OUT');
        }
      } else if (type === 'normal') {
        /* 일반거래 */
        if (project.reductionNow > 0) {
          if (project.saleStatus === 'Closed') label = '판매종료';
          else if (project.saleStatus === 'SoldOut') label = t('SOLD_OUT');
          else if (project.saleStatus === 'Ready') label = t('PREPARING');
        } else {
          label = project.reductionSum === 0 ? t('PREPARING') : t('SOLD_OUT');
        }
      }
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            background: '#faf9ff',
            padding: '0px 10px',
            boxSizing: 'border-box',
            borderRadius: 4,
          }}
        >
          {['Closed', 'SoldOut'].includes(project.saleStatus) && (
            <div className='ico complete-check' style={{ marginRight: 4 }}></div>
          )}
          <span>{label}</span>
        </div>
      );
    };

    const buildButtonClassName = (type) => {
      if (project.saleStatus === 'Closed' || project.saleStatus === 'SoldOut') {
        return 'button__box disabled';
      }
      if (type === 'forward') {
        if (project.forwardAmount > 0) {
          return project.saleStatus === 'Ready' ? 'button__box disabled' : 'button__box';
        } else {
          return 'button__box disabled';
        }
      } else if (type === 'normal') {
        if (project.reductionNow > 0) {
          return project.saleStatus === 'Ready' ? 'button__box disabled' : 'button__box';
        } else {
          return 'button__box disabled';
        }
      }
    };

    const buildButtonText = (type) => {
      if (type === 'forward') {
        return t('PRE_PURCHASE');
      } else if (type === 'normal') {
        return t('BUY_CREDIT');
      }
    };

    return (
      <article className='purchase__container'>
        {project.forwardType && (
          <div className='forward__box'>
            <header>
              <div className='type'>
                {renderTradeTypeText('forward')}
                <Tooltip
                  title={
                    <div style={{ padding: '10px' }}>
                      {t('PRE_PURCHASE_IS')} <br />
                      <br />
                      {t('PRE_PURCHASEMENT_DESCRIPTION')}
                    </div>
                  }
                >
                  <div className='ico info'></div>
                </Tooltip>
              </div>
              <div className='amount'>
                <em>{buildAmountValue('forward')}</em>
                <span>{t('_LEFT')}</span>
              </div>
            </header>
            <section className='action__box'>
              <div className='ton__box'>
                <h1>
                  {Handler.numberWithCommas(project.unitPrice)} {t('WON')}
                  <em>/ VRC</em>
                </h1>
                <div className={buildStatusClassName('forward')}>
                  <span>{buildStatusText('forward')}</span>
                </div>
              </div>
              <div
                className={buildButtonClassName('forward')}
                onClick={() => {
                  requestModal('forward');
                }}
              >
                {buildButtonText('forward')}
              </div>
            </section>
          </div>
        )}
        <div className='normal__box'>
          <header>
            <div className='type'>
              {renderTradeTypeText('normal')}{' '}
              <Tooltip
                title={
                  <div style={{ padding: '10px' }}>
                    {t('ORDINARY_PURCHASE_IS')} <br />
                    <br />
                    {t('ORDINARY_PURCHASE_DESCRIPTION')}
                  </div>
                }
              >
                <div className='ico info'></div>
              </Tooltip>
            </div>
            <div className='amount'>
              <em>{buildAmountValue('normal')}</em>
              <span>{t('_LEFT')}</span>
            </div>
          </header>
          <section className='action__box'>
            <div className='ton__box'>
              <h1>
                {Handler.numberWithCommas(project.unitPrice)} {t('WON')}
                <em>/ VRC</em>
              </h1>
              <div className={buildStatusClassName('normal')}>
                <span>{buildStatusText('normal')}</span>
              </div>
            </div>
            <div className='vrc-meaning'>
              <p>
                <span className='key'>{t('VRC')}</span>
                <span className='value'>{t('VRC_VALUE')}</span>
                {t('VRC_MEANING')}
              </p>
            </div>
            <div
              className={buildButtonClassName('normal')}
              onClick={() => {
                console.log('request modal');
                requestModal('normal');
              }}
            >
              {buildButtonText('normal')}
            </div>
          </section>
        </div>
      </article>
    );
  };

  console.log('project', project);
  console.log('projectDoc', projectDoc);
  return (
    <article className='basic-page'>
      <MetaTag props={metaObject} />
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header onClick={goBack}>
            <img src={BackArrow} alt='' />
          </header>
          <div className='right__box'>
            <article className='form-small-breadcrumbs'>
              <ul>
                <li>
                  <span>Home</span>
                </li>
                <li
                  onClick={() => {
                    history.push('/marketplace/list');
                  }}
                >
                  <span>{t('MARKETPLACE')}</span>
                </li>
                <li className='on'>
                  <span>{t('DETAIL')}</span>
                </li>
              </ul>
            </article>
          </div>
        </section>
        <section className='title__container'>
          <div className='badge__box'>
            <p>{project.id}</p>
            <div className='badge__item area'>{t(Handler.ENUM.AREA_TYPE(project.methodology.area))}</div>
            <div className='badge__item sub_area'>{t(Handler.ENUM.AREA_TYPE(project.methodology.subArea))}</div>
          </div>
          <article className='title__wrapper'>
            <div className='title__box'>
              <h2 className='font-bold'>{project.title}</h2>
            </div>
            <div className='share__container'>
              {' '}
              <div className='icon_share' aria-describedby={id} onClick={handleClick}></div>
              <Popover
                id={id}
                open={popoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Social props={metaObject} />
              </Popover>
            </div>
          </article>
        </section>
        <article className='puchase__container'>
          <section className='main__box'>
            <div className='img__box'>
              <img src={selectedImage} alt=''></img>
              {project.saleStatus === 'SoldOut' && (
                <div className='complte__box'>
                  <div className='ico complete-check'></div>
                  <span>{t('SOLD_OUT')}</span>
                </div>
              )}
              <div className='img-list__box'>
                <ul>
                  {project.mainImages.map((item, index) => {
                    return (
                      <li
                        className={selectedImage === item ? 'on' : ''}
                        key={'main-image' + index}
                        onClick={() => {
                          setSelectedImage(item);
                        }}
                      >
                        <img src={item} alt={'main-image' + index}></img>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <section className='m-sub__box'>
              <div className='price__box'>
                <h1>
                  {Handler.numberWithCommas(project.salePrice)} {t('WON')}
                  <em>/ VRC</em>
                </h1>
              </div>
              <div className='date__box'>
                <span>
                  {project.startDate} ~ {project.endDate}
                </span>
              </div>
              {/* {renderPurchaseBox(project)} */}
            </section>
            <div className='tab__box'>
              <ul>
                {TAB_LIST.map((item, index) => {
                  return (
                    <li
                      key={`tab-list-${index}`}
                      className={tabIndex === index ? 'on' : ''}
                      onClick={() => {
                        setTabIndex(index);
                        let project_el = document.getElementById('project-box-el');
                        let company_el = document.getElementById('company-box-el');
                        let contributor_el = document.getElementById('contributor-box-el');
                        if (index === 0) {
                          project_el.scrollIntoView({
                            behavior: 'smooth',
                          });
                        } else if (index === 1) {
                          company_el.scrollIntoView({
                            behavior: 'smooth',
                          });
                        } else if (index === 2) {
                          contributor_el.scrollIntoView({
                            behavior: 'smooth',
                          });
                        }
                      }}
                    >
                      <span>{item}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className='project__box' id='project-box-el'>
              <header>
                <h2 className='font-bold'>{TAB_LIST[0]}</h2>
              </header>
              <ToastViewer value={project.introProject} meta={{}}></ToastViewer>
              <div className='project__info'>
                <ul>
                  <li>
                    <span>{t('BIZ_AREA')}</span>
                    <span>{t(Handler.ENUM.AREA_TYPE(project.methodology.area))}</span>
                  </li>
                  <li>
                    <span>{t('PROJECT_TYPE')}</span>
                    <span>{t(Handler.ENUM.AREA_TYPE(project.methodology.subArea))}</span>
                  </li>
                  <li>
                    <span>{t('REDUCTION_PERIOD')}</span>
                    <span>
                      {project.startDate} ~ {project.endDate}
                    </span>
                  </li>
                  {projectDoc && projectDoc.location && address && (
                    <>
                      <li>
                        <span>{t('LOCATION')}</span>
                        <span>{address}</span>
                      </li>
                      <li className='map-container'>
                        <GoogleMapWrapper address={address}></GoogleMapWrapper>
                      </li>
                    </>
                  )}
                  {/* <li>
                    <span>위치</span>
                    <span>{project.area}</span>
                  </li>
                  <li>
                    <span>발급기관</span>
                    <span>{project.area}</span>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className='project__box' id='company-box-el'>
              <header>
                <h2 className='font-bold'>{TAB_LIST[1]}</h2>
              </header>
              <ToastViewer value={project.introCompany} meta={{}}></ToastViewer>
            </div>
          </section>
          <section className='sub__box'>{renderPurchaseBox(project)}</section>
        </article>
        <article
          className={
            mobilePurchaseOpen ? 'mobile-purchase-button__container open' : 'mobile-purchase-button__container'
          }
        >
          <div className='purchase__box'>
            <header>
              <div
                onClick={() => {
                  setMobilePurchaseOpen(false);
                }}
                className='ico chevron-down'
              ></div>
            </header>
            <div className='list__box'>{renderPurchaseBox(project)}</div>
          </div>
          <div className='button__box'>
            <Button
              label={t('BUY')}
              onClick={() => {
                setMobilePurchaseOpen(true);
              }}
            ></Button>
          </div>
        </article>
      </article>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={deviceWidth > 1136 ? modalStyle : modalMobileStyle}>
          <ModalPurchase
            type={purchaseType}
            project={project}
            onClose={() => {
              setOpen(false);
            }}
            onConfirm={requestConfirm}
            onCertification={(data) => {
              setCertificationData(data);
              setCertificationOpen(true);
            }}
          ></ModalPurchase>
        </Box>
      </Modal>
      <Modal
        open={accountOpen}
        onClose={() => {
          setAccountOpen(false);
        }}
      >
        <Box sx={deviceWidth > 1136 ? modalStyle : modalMobileStyle}>
          <ModalAccount
            onClose={() => {
              setAccountOpen(false);
            }}
          ></ModalAccount>
        </Box>
      </Modal>
      <Modal
        open={certificationOpen}
        onClose={() => {
          setCertificationOpen(false);
        }}
      >
        <Box sx={deviceWidth > 1136 ? modalStyle : modalMobileStyle}>
          <ModalCertification
            data={certificationData}
            onClose={() => {
              setCertificationOpen(false);
            }}
          ></ModalCertification>
        </Box>
      </Modal>
      {process.env.REACT_APP_ENV === 'production' && payData && (
        <ReactInicis payData={payData} isPurchase={isPurchase} />
      )}
      {process.env.REACT_APP_ENV !== 'production' && payData && (
        <ReactInicis payData={payData} isPurchase={isPurchase} isTest />
      )}
    </article>
  );
};
