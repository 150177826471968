import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { color, font, fontSize, media } from '../../../styles/style';
import { ContentContext, LangContext } from '../../../contexts';
import { OutLineButton } from '../../../components';
import {
  MAIN_GRAPH,
  MAIN_GRAPH_EN,
  MOBILE_MAIN_GRAPH,
  MOBILE_MAIN_GRAPH_EN
} from '../../../constants/main.constants';

const GraphContainer = styled.section`
  padding: 140px 0;
  span.title-tag {
    font-weight: ${font.bold};
    font-size: ${fontSize(font.$text_lg)};
  }
  h2 {
    color: ${color.$main500};
    font-size: ${fontSize(font.$text_5xl)};
    font-weight: ${font.bold};
    line-height: ${font.$leading_02};
    white-space: pre-wrap;
    padding: 20px 0 45px;
  }
  p {
    font-size: ${fontSize(font.$text_base)};
  }
  .graph-image {
    height: 500px;
    padding: 60px 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  article {
    align-items: flex-start !important;
  }
  @media only screen and (max-width: ${media.$xl}) {
    padding-right: 40px;
    padding-left: 40px;
    h2,
    p {
      word-break: keep-all;
    }
    h2 {
      font-size: ${fontSize(font.$text_5xl, media.$xl)};
    }
    p {
      font-size: ${fontSize(font.$text_base, media.$xl)};
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 60px 0;
    h2,
    p {
      word-break: keep-all;
      white-space: initial;
    }
    h2 {
      font-size: ${fontSize(font.$text_5xl, media.$sm)};
    }
    p {
      font-size: ${fontSize(font.$text_base, media.$sm)};
    }
    padding-right: 25px;
    padding-left: 25px;
    .graph-image {
      height: 300px;
      padding: 20px;
      margin-bottom: 20px;
    }
  }
`;

export const Graph = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { deviceWidth } = useContext(ContentContext);
  const { isKorean } = useContext(LangContext);

  const gotoMore = () => {
    history.push('/marketplace/intro');
  };
  return (
    <GraphContainer className='w-full bg-slate-50'>
      <article className='w-full m-auto min-w-screen-xl max-w-screen-xl'>
        <span className='title-tag block' data-aos='fade-up'>
          {t('POPLE_IS')}
        </span>
        <h2 data-aos='fade-up'>{t('MAIN_SECTION_02_TITLE')}</h2>
        <p data-aos='fade-up'>{t('MAIN_SECTION_02_DESCRIPTION')}</p>
      </article>
      <div className='graph-image' data-aos='fade-up'>
        {deviceWidth > 640 ? <img src={isKorean ? MAIN_GRAPH : MAIN_GRAPH_EN} alt='' /> : <img src={isKorean ? MOBILE_MAIN_GRAPH : MOBILE_MAIN_GRAPH_EN} alt='' />}
      </div>
      <OutLineButton
        data-aos='fade-up'
        label={t('MORE')}
        disabled={false}
        color={color.$main500}
        onClick={gotoMore}
      ></OutLineButton>
    </GraphContainer>
  );
};
