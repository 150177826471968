import styled from '@emotion/styled';
import { color, font, fontSize, media } from './style';

export const PopleAccordion = styled.div`
  h2 {
    text-align: center;
    padding-bottom: 80px;
  }
  .MuiAccordion-root {
    box-shadow: none;
    background: transparent;
    position: unset;
    &.Mui-expanded {
      /* margin: 0; */
    }
    margin: 20px 0;
    &.Mui-expanded:first-of-type {
      margin: 20px 0;
    }
  }
  .MuiAccordionSummary-root {
    border: 1px solid ${color.$main300};
    background-color: ${color.$white};
    border-radius: 15px;
    width: 100%;
    padding: 10px 20px;
    &.Mui-expanded {
      min-height: unset;
    }
    &:hover {
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    }
    span {
      color: ${color.$main500};
      font-size: ${font.$title_03};
    }
  }
  .MuiAccordionSummary-content {
    margin: 20px;
    &.Mui-expanded {
      margin: 20px;
    }
    .title > p.date {
      font-size: ${font.$body_04};
      color: ${color.$black_500};
      margin-bottom: 10px;
    }
    /* &.Mui-expanded:first-of-type {
      margin: 0;
    } */
  }
  .MuiCollapse-root {
    .MuiCollapse-wrapper {
      border: 1px solid ${color.$main300};
      background-color: ${color.$white};
      border-radius: 15px;
      margin: 20px 0;
    }
  }
  .MuiAccordionDetails-root {
    padding: 40px 40px;
    p,
    li {
      font-size: ${font.$body_01};
      word-break: keep-all;
    }
    ul {
      margin: 10px 0;
      list-style: disc;
      li {
        line-height: 1.5;
        list-style: inherit;
        margin-left: 16px;
      }
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    width: 100%;
    .MuiAccordion-root {
      margin: 20px;
      &.Mui-expanded {
        margin: 20px;
      }
      &.Mui-expanded:first-of-type {
        margin: 20px;
      }
    }
    .MuiAccordionDetails-root {
      padding: 20px;
      .tui-editor-contents {
        p {
          font-size: 16px;
        }
      }
    }
    .MuiAccordionSummary-root {
      border-radius: 10px;
      span {
        font-size: ${fontSize(font.$text_lg, media.$sm)};
        white-space: initial;
      }
    }
    .MuiAccordionSummary-content {
      margin: 0;
      &.Mui-expanded {
        margin: 0;
      }
    }
  }
`;

export const Card = styled.div`
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.08);
  padding: 20px;
  /* padding: 30px; */
  .card-header {
    display: grid;
    grid-template-columns: 1fr 32px;
    justify-content: space-between;
    padding-bottom: 20px;
    flex-wrap: wrap;
    .icon {
      width: 32px;
      height: 32px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
      }
    }
  }
  .title {
  }
  .date {
    color: #888;
    font-size: 14px;
    padding: 4px 0;
    &.divider {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 14px;
        top: 6px;
        background: #ddd;
        margin-left: 8px;
      }
    }
  }
  .percent {
    padding: 2px 4px;
    margin: 8px 0;
    font-weight: bold;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    max-width: 70px;
    font-size: 14px;
    text-align: center;
    &.down {
      background-color: #e0e9ff;
      color: #4579ff;
    }
    &.up {
      background-color: #ffdede;
      color: #ff5045;
    }
  }
`;

export const ChartContainer = styled.section`
  padding-bottom: 60px;
  text-align: left;
  max-width: ${media.$wrapper_max_width};
  width: 100%;
  /* min-width: ${media.$wrapper_min_width}; */
  p {
    font-size: 16px;
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 20px 16px;
    p {
      font-size: 16px;
      word-break: keep-all;
      margin-top: 20px;
    }
  }
`;

export const ChartHeader = styled.article`
  padding: 20px;
  p {
    margin: 10px 0;
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 0;
  }
`;

export const ChartWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  flex-wrap: wrap;
  margin: auto;
  padding: 20px;
  @media only screen and (max-width: ${media.$md}) {
    display: flex;
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 0;
  }
`;

export const RenewableChartWrapper = styled.section`
  display: block;
  padding: 20px;
`;

export const ChartItem = styled.section`
h2 {
  padding: 10px 10px;
  font-size: 24px;
  text-align: left;
  height: 60px;
  @media only screen and (max-width: ${media.$sm}) {
    font-size: 20px;
    height: 45px;
  }
}
@media only screen and (max-width: ${media.$sm}) {
  margin-bottom: 40px;
}
`;

export const TabBox = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: solid 1px ${color.$black_ddd};
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #fff;
  z-index: 10;
  ul {
    display: flex;
    li {
      cursor: pointer;
      min-width: 200px;
      height: 48px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2px;
      span {
        color: $--grey-500;
      }
      &:last-child {
        margin-right: 0px;
      }

      &.on {
        // background: $--main-500;
        // box-shadow: inset 0 1em gold;
        border-bottom: 3px solid ${color.$main500};
        font-weight: 800;
        span {
          color: ${color.$main500};
        }
      }
    }
  }
`;
