import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'methodology/';

const GetList = (params) => {
  // const url = BASE_URL + PATH + Utils.ObjectToParams(params);
  const url = 'https://api-dev.pople.credit/api/v1/credit/' + PATH + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetHistoryList = (slug, params) => {
  const url = BASE_URL + PATH + slug + '/history' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const Methodology = {
  GetList: GetList,
  GetHistoryList: GetHistoryList,
};

export default Methodology;
