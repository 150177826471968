import React from 'react';
import Handler from '../../Handler';
import { Button } from '../../components';

export const TableMethodologyHistory = (props) => {
  const { data, rowClick, callback } = props;

  return (
    <article className='table-methodology-history'>
      <ul>
        {data && data.length === 0 && (
          <li className='empty'>
            <span>데이터가 없습니다.</span>
          </li>
        )}
        {data &&
          data.map((item, index) => {
            console.log('item', item);

            const {
              area,
              attachedFile,
              createdAt,
              expiredYear,
              groupSeq,
              id,
              introOne,
              introTwo,
              isValid,
              mainImage,
              seq,
              startDate,
              subArea,
              title,
              version,
              versionNumber,
            } = item;
            return (
              <li>
                <header>
                  <h2>{versionNumber === 0 ? '최초 작성' : `${versionNumber}차 개정`}</h2>
                  <Button
                    onClick={() => {
                      window.open(attachedFile, '_blank');
                    }}
                    style={{ width: '144px', height: '38px' }}
                    type='square'
                    label='파일 다운로드'
                  ></Button>
                </header>
                <ul className='attribute__list'>
                  <li>
                    <header>
                      <span>방법론명</span>
                    </header>
                    <div className='value'>
                      <span>{title}</span>
                    </div>
                  </li>
                  <li>
                    <header>
                      <span>버전</span>
                    </header>
                    <div className='value'>
                      <span>{version}</span>
                    </div>
                  </li>
                  <li>
                    <header>
                      <span>설명</span>
                    </header>
                    <div className='value'>
                      <p>{introOne}</p>
                      <p>{introTwo}</p>
                    </div>
                  </li>
                  <li>
                    <header>
                      <span>방법론 분야</span>
                    </header>
                    <div className='value'>
                      <span>
                        {Handler.ENUM.AREA(area)} {'>'} {Handler.ENUM.SUB_AREA(subArea)}
                      </span>
                    </div>
                  </li>
                  <li>
                    <header>
                      <span>방법론 고유번호</span>
                    </header>
                    <div className='value'>
                      <span>{id}</span>
                    </div>
                  </li>
                  <li>
                    <header>
                      <span>방법론 유효시작일</span>
                    </header>
                    <div className='value'>
                      <span>{Handler.dbDateToYYYYMMDDComma(startDate)}</span>
                    </div>
                  </li>
                  <li>
                    <header>
                      <span>적용 사업 유효기간</span>
                    </header>
                    <div className='value'>
                      <span>{expiredYear}년</span>
                    </div>
                  </li>
                </ul>
              </li>
            );
          })}
      </ul>
    </article>
  );
};
