import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { DialogContext } from '../contexts';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const DialogLayer = (props) => {
  const { dialog, updateDialog } = useContext(DialogContext);

  const closeDialog = () => {
    updateDialog({
      ...dialog,
      open: false,
    });
  };

  const renderIcon = (type) => {
    switch (type) {
      case 'info': {
        return <InfoIcon></InfoIcon>;
      }
      case 'error': {
        return <ErrorIcon style={{ color: 'red' }}></ErrorIcon>;
      }
      case 'success': {
        return <CheckCircleIcon style={{ color: 'green' }}></CheckCircleIcon>;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Dialog
      className='dialog-layer'
      open={dialog.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
    >
      <DialogTitle>
        <div className='title__wrapper'>
          {renderIcon(dialog.type)}
          {dialog.title}
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>{dialog.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='info' onClick={closeDialog}>
          취소
        </Button>
        <Button
          color={dialog.type === 'error' ? 'secondary' : 'primary'}
          onClick={() => {
            closeDialog();
            dialog.onConfirm();
          }}
        >
          진행
        </Button>
      </DialogActions>
    </Dialog>
  );
};
