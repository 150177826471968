import React, { useContext, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { MainLayout, LoginLayout } from './layouts';
import ScrollToTop from './ScrollToTop';
import {
  HomePage,
  ProtectPage,
  NotFoundPage,
  UserSignUpGuidePage,
  UserSignUpPage,
  UserLoginPage,
  UserFindPwPage,
  UserResetPwPage,
  UserSignUpCompletePage,
  MypageCreditListPage,
  MypageInquiryListPage,
  MypageInquiryAddPage,
  MypageInquiryDetailPage,
  MypageInquiryModifyPage,
  MypageModifyPage,
  MypageSignoutPage,
  MypageSignoutCompletePage,
  MypagePurchaseDetailPage,
  CsContactUsPage,
  CsNoticePage,
  CsFaqPage,
  IntroPage,
  AboutPage,
  BrandPage,
  BizPage,
  ProjectPage,
  StandardPage,
  DigitalSolutionPage,
  MethodologyListPage,
  // MethodologyDetailPage,
  NewsListPage,
  NewsDetailPage,
  CreditInfoPage,
  MarketplaceIntroPage,
  MarketplaceDetailPage,
  MarketplaceListPage,
  MarketplacePurchaseRetunPage,
  MarketplacePurchaseClosePage,
  PurchaseCompletePage,
  ContributorsListPage,
  PurchaseListPage,
  ForwardPurchaseListPage,
  ForwardPurchaseDetailPage,
  SellListPage,
  SellDetailPage,
  CalculatePage,
  UserKakaoLoginPage,
  AddInfoPage,
  PricePage,
  CampaignPage,
  CertPage,
  DocumentPage,
} from './pages';

import { UserContext } from './contexts';

const ROUTER_LIST = [
  {
    path: '/',
    Component: HomePage,
    Layout: MainLayout,
    props: {
      exact: true,
    },
  },
  {
    path: '/about',
    Component: AboutPage,
    Layout: MainLayout,
  },
  {
    path: '/brand',
    Component: BrandPage,
    Layout: MainLayout,
  },
  {
    path: '/biz',
    Component: BizPage,
    Layout: MainLayout,
  },
  {
    path: '/project',
    Component: ProjectPage,
    Layout: MainLayout,
  },
  {
    path: '/news/list',
    Component: NewsListPage,
    Layout: MainLayout,
  },
  {
    path: '/standard',
    Component: StandardPage,
    Layout: MainLayout,
  },
  {
    path: '/solution',
    Component: DigitalSolutionPage,
    Layout: MainLayout,
  },
  // {
  //   path: '/methodology/detail/:slug',
  //   Component: MethodologyDetailPage,
  //   Layout: MainLayout,
  // },
  {
    path: '/methodology/list',
    Component: MethodologyListPage,
    Layout: MainLayout,
  },
  {
    path: '/credit',
    Component: CreditInfoPage,
    Layout: MainLayout,
  },
  {
    path: '/news/detail/:slug',
    Component: NewsDetailPage,
    Layout: MainLayout,
  },
  {
    path: '/marketplace/intro',
    Component: MarketplaceIntroPage,
    Layout: MainLayout,
  },
  {
    path: '/marketplace/list',
    Component: MarketplaceListPage,
    Layout: MainLayout,
  },
  {
    path: '/marketplace/detail/:slug',
    Component: MarketplaceDetailPage,
    Layout: MainLayout,
  },
  {
    path: '/marketplace/complete/:slug',
    Component: PurchaseCompletePage,
    Layout: MainLayout,
  },
  {
    path: '/marketplace/purchase_return',
    Component: MarketplacePurchaseRetunPage,
    Layout: MainLayout,
  },
  {
    path: '/marketplace/purchase_close',
    Component: MarketplacePurchaseClosePage,
    Layout: MainLayout,
  },
  {
    path: '/contributors/list',
    Component: ContributorsListPage,
    Layout: MainLayout,
  },
  //sub
  {
    path: '/price',
    Component: PricePage,
    Layout: MainLayout,
  },
  {
    path: '/campaign',
    Component: CampaignPage,
    Layout: MainLayout,
  },
  {
    path: '/cert',
    Component: CertPage,
    Layout: MainLayout,
  },
  {
    path: '/documents',
    Component: DocumentPage,
    Layout: MainLayout,
  },
  {
    path: '/user/signup_complete',
    Component: UserSignUpCompletePage,
    Layout: LoginLayout,
  },
  {
    path: '/user/signup_guide',
    Component: UserSignUpGuidePage,
    Layout: LoginLayout,
  },
  {
    path: '/user/signup',
    Component: UserSignUpPage,
    Layout: LoginLayout,
  },
  {
    path: '/user/login',
    Component: UserLoginPage,
    Layout: LoginLayout,
  },
  {
    path: '/user/find_pw',
    Component: UserFindPwPage,
    Layout: LoginLayout,
  },
  {
    path: '/user/reset_pw',
    Component: UserResetPwPage,
    Layout: LoginLayout,
  },
  {
    path: '/mypage/purchase_list',
    Component: PurchaseListPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/purchase_detail/:slug',
    Component: MypagePurchaseDetailPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/forward_purchase_list',
    Component: ForwardPurchaseListPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/forward_purchase_detail/:slug',
    Component: ForwardPurchaseDetailPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/sell_list',
    Component: SellListPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/sell_detail/:slug',
    Component: SellDetailPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/calculate',
    Component: CalculatePage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/credit_list',
    Component: MypageCreditListPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/inquiry_list',
    Component: MypageInquiryListPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/inquiry_add',
    Component: MypageInquiryAddPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/inquiry_detail/:slug',
    Component: MypageInquiryDetailPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/inquiry_modify/:slug',
    Component: MypageInquiryModifyPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/modify',
    Component: MypageModifyPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/signout',
    Component: MypageSignoutPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/signout_complete',
    Component: MypageSignoutCompletePage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/cs/contact_us',
    Component: CsContactUsPage,
    Layout: MainLayout,
  },
  {
    path: '/cs/notice',
    Component: CsNoticePage,
    Layout: MainLayout,
  },
  {
    path: '/cs/faq',
    Component: CsFaqPage,
    Layout: MainLayout,
  },
  {
    path: '/kakao_login',
    Component: UserKakaoLoginPage,
    Layout: LoginLayout,
  },
  {
    path: '/add_info',
    Component: AddInfoPage,
    Layout: LoginLayout,
    protect: false,
  },
  {
    path: '/protect',
    Layout: MainLayout,
    Component: ProtectPage,
    protect: true,
  },
  {
    path: '*',
    Layout: LoginLayout,
    Component: NotFoundPage,
  },
];

const Routers = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        {ROUTER_LIST.map((item, index) => {
          const { Layout, Component, path, protect, props } = item;
          if (protect) {
            return (
              <PrivateRoute key={'route-' + index} path={path} {...props}>
                <Layout>
                  <Component></Component>
                </Layout>
              </PrivateRoute>
            );
          } else {
            return (
              <Route key={'route-' + index} path={path} {...props}>
                <Layout>
                  <Component></Component>
                </Layout>
              </Route>
            );
          }
        })}
      </Switch>
    </Router>
  );
};

function PrivateRoute({ children, ...rest }) {
  const { accessToken } = useContext(UserContext);

  //accessToken validation;
  let isValidation = false;
  if (accessToken) {
    isValidation = true;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isValidation ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default Routers;
