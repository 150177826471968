import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Breadcrumbs,
  TextArea,
  Button,
  RadioBox,
  CheckBox,
} from "../../components";
import { useForm, useLabel } from '../../hooks';
import Handler from '../../Handler';
import { SnackbarContext } from '../../contexts';
import API from '../../api';
import { t } from 'i18next';

export const MypageSignoutPage = (props) => {
  const history = useHistory();
  const { ERROR } = useLabel();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      scope: '기타',
      reason: '',
    },
    {
      scope: {
        key: 'scope',
        style: {},
        label: '탈퇴사유',
        menus: [
          {
            text: '사용 빈도 낮음',
            value: '사용 빈도 낮음',
          },
          {
            text: '서비스/시스템 불만',
            value: '서비스/시스템 불만',
          },
          {
            text: '고객응대 불편',
            value: '고객응대 불편',
          },
          {
            text: '기타',
            value: '기타',
          },
        ],
        className: 'column',
      },
      reason: {
        key: 'reason',
        label: '탈퇴 사유',
        maxSize: 500,
        style: {},
        required: true,
      },
    },
  );
  const [aggree, setAggree] = useState(false);

  console.log('formData', formData);

  const requsetSignout = () => {
    if (!aggree) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '유의사항에 동의해주세요.',
      });
    }
    let body = Handler.formToBody(formData, formMeta);
    console.log('requsetSignout body', body);

    if (body) {
      API.User.PostSignout(body).then(
        (res) => {
          let result = res.data;
          console.log('User PostSignout result', result);
          history.push('/mypage/signout_complete');
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: ERROR['REQUIRED_INFO'],
      });
    }
  };

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>{t('RESIGN_MEMBERSHIP')}</h1>
          </header>
          <div className='right__box'>
            <Breadcrumbs type={'small'}></Breadcrumbs>
          </div>
        </section>
        <section className='m-headding-text__container'>
          <header>
            <h2>{t('RESIGN_MEMBERSHIP')}</h2>
          </header>
        </section>
        <section className='user-signout-form__container'>
          <div className='grid__box'>
            <ul className='form__list'>
              <article className='guide__box'>
                <h2>회원 탈퇴를 진행하시겠습니까?</h2>
                <span>운영자의 ‘승인’ 후 회원 탈퇴가 진행됩니다.</span>
              </article>
              <RadioBox value={formData.scope} onChange={formChange} meta={formMeta.scope}></RadioBox>
              <TextArea value={formData.reason} onChange={formChange} meta={formMeta.reason}></TextArea>
            </ul>
            <div className='info__box'>
              <h3>탈퇴 시 유의사항</h3>
              <ul>
                <li>등록/진행중인 사업은 ‘사업 취소’ 신청 후 승인해주시기 바랍니다.</li>
                <li>
                  발행된 크레딧이 있는 경우, 자동으로 ‘구매자 이름’으로 크레딧은 생쇄됩니다.
                  <br />
                  ＊상쇄 인증서는 ID로 등록된 메일로 보내드립니다.
                </li>
                <li>
                  선도구매에 참여한 경우, 해당 크레딧은 자동으로 ‘구매자 이름’으로 상쇄되며 선도구매 계약은 종료됩니다.
                  <br />
                  ＊상쇄 인증서는 ID로 등록된 메일로 보내드리며 추후 정산대금은 지불되지 않습니다.
                </li>
                <li>사업 판매자는 계약이 유효한 선도거래가 있는 경우 계약이행사까지 탈퇴가 보류될 수 있 습니다.</li>
                <li>탈퇴일로부터 30일 이내에 같은 아이디로 재가입이 불가능합니다.</li>
                <li>
                  모든 데이터는 삭제되며, 복구가 불가능합니다. <br /> 단, 개인정보처리방침 제 6조 (개인정보의 보유기간
                  및 파기)에 따른 특별한 경우, 한시적 으로 개인정보를 보존합니다.
                </li>
                <li>탈퇴 처리중에는 취소가 불가능합니다.</li>
              </ul>
            </div>
          </div>
        </section>

        <section className='button__container column'>
          <CheckBox
            value={aggree}
            onChange={(value, key) => {
              setAggree(value);
            }}
            meta={{
              label: `'탈퇴 시 유의사항'을 확인하였으며, 동의합니다.`,
              style: {
                marginBottom: 36,
              },
              className: 'square',
            }}
          ></CheckBox>
          <Button
            disabled={!aggree}
            style={{ width: '360px' }}
            onClick={() => {
              requsetSignout();
            }}
            label='회원탈퇴 신청'
          ></Button>
        </section>
      </article>
    </article>
  );
};
