import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, TextInput, TextArea, Button, FileMultipleUploader, RadioBox } from '../../components';
import { useForm } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';

export const MypageInquiryAddPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      type: 'Member',
      title: '',
      content: '',
      fileArray: [],
    },
    {
      type: {
        key: 'type',
        style: {
          gridColumn: '1/3',
        },
        label: '분류',
        menus: [
          {
            text: '회원 가입/탈퇴',
            value: 'Member',
          },
          {
            text: '방법론',
            value: 'Methodology',
          },
          {
            text: '사업 관리',
            value: 'Project',
          },
          {
            text: '감축량 인증',
            value: 'ReductionCert',
          },
          {
            text: 'ZC크레딧',
            value: 'ZCCredit',
          },
          {
            text: '기타',
            value: 'Etc',
          },
        ],
        className: 'board',
      },
      title: {
        key: 'title',
        label: '제목',
        required: true,
        style: {
          gridColumn: '1/3',
        },
        className: 'board',
      },
      content: {
        required: true,
        key: 'content',
        label: '상담 내용',
        maxSize: 500,
        style: {
          gridColumn: '1/3',
        },
        className: 'board',
      },
      fileArray: {
        key: 'fileArray',
        type: 'file',
        label: '첨부파일',
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
        className: 'board',
      },
    },
  );

  const requsetSubmit = () => {
    console.log('requsetSubmit');

    let body = Handler.formToBody(formData, formMeta);
    if (body) {
      API.User.PostInquiry({ ...body }).then(
        (res) => {
          let result = res.data;
          console.log('User PostInquiry result', result);
          history.push('/request_complete?type=mypage');
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: t('REQUIRED_INFO'),
      });
    }
  };

  return (
    <article className='basic-page'>
      <Breadcrumbs content='credit' title={'1:1 상담 문의'}></Breadcrumbs>
      <article className='content__wrapper'>
        <section className='form__container'>
          <ul className='grid board'>
            <RadioBox value={formData.type} onChange={formChange} meta={formMeta.type}></RadioBox>
            <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
            <TextArea value={formData.content} onChange={formChange} meta={formMeta.content}></TextArea>
            <FileMultipleUploader
              value={formData.fileArray}
              onChange={formChange}
              meta={formMeta.fileArray}
            ></FileMultipleUploader>
          </ul>
        </section>
        <section className='button__container' style={{ marginTop: 0 }}>
          <Button style={{ width: '240px' }} onClick={requsetSubmit} label={t('CONTACT_US')}></Button>
        </section>
      </article>
    </article>
  );
};
