import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'market/payment/';

/* 결제 선도거래 요청 진행 */
const PostForwardOrder = (body) => {
  const url = BASE_URL + PATH + 'forward-order';
  return AxiosInstance.post(url, body);
};

/* 결제요청 진행 */
const PostOrder = (body) => {
  const url = BASE_URL + PATH + 'order';
  return AxiosInstance.post(url, body);
};

/* 세금계산서 발행 */
const PostTaxbill = (body) => {
  const url = BASE_URL + PATH + 'tax-bill';
  return AxiosInstance.post(url, body);
};

/* 크레딧 구매 후 구매정보 */
const GetPaymentOrderInfo = (params) => {
  // const url = BASE_URL + PATH + 'order-info/' + slug;
  const url = BASE_URL + PATH + 'order' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

/* 유저 구매 선물하기 메일 재전송 */
const PutEmailResend = (slug) => {
  const url = BASE_URL + 'market/user/payment/' + slug + '/email-resend';
  return AxiosInstance.put(url);
};

const Payment = {
  PostForwardOrder: PostForwardOrder,
  PostOrder: PostOrder,
  PostTaxbill: PostTaxbill,
  PutEmailResend: PutEmailResend,
  GetPaymentOrderInfo: GetPaymentOrderInfo,
};

export default Payment;
