import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'market/';

/* 공지 목록 조회 */
const GetNoticeList = (params) => {
  const url = BASE_URL + PATH + 'notice' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

/* 공지 상세 조회 */
const GetNoticeDetail = (seq) => {
  const url = BASE_URL + PATH + 'notice/' + seq;
  return AxiosInstance.get(url);
};

/* FAQ 목록 조회 */
const GetFaqList = (params) => {
  const url = BASE_URL + PATH + 'faq' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

/* FAQ 상세 조회 */
const GetFaqDetail = (seq) => {
  const url = BASE_URL + PATH + 'faq/' + seq;
  return AxiosInstance.get(url);
};

/* FAQ 카테고리 조회 */
const GetFaqInfo = () => {
  const url = BASE_URL + PATH + 'faq/category';
  return AxiosInstance.get(url);
};

/* 상담문의 등록 */
const PostContact = (body) => {
  const url = BASE_URL + PATH + 'contact';
  return AxiosInstance.post(url, body);
};

const CS = {
  GetNoticeList: GetNoticeList,
  GetNoticeDetail: GetNoticeDetail,
  GetFaqList: GetFaqList,
  GetFaqDetail: GetFaqDetail,
  GetFaqInfo: GetFaqInfo,
  PostContact: PostContact,
};

export default CS;
