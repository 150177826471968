import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, SelectBox, Button, TablePaging, MetaTag, DropdownInput } from '../../components';
import { useLabel, usePagination } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { UserContext, SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import queryString from 'query-string';
import { genMypageRoutes } from '../../constants';
import { MypageNavList } from './components/MypageNavList';

export const SellListPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const searchObject = queryString.parse(history.location.search);
  const { ERROR } = useLabel();
  const { userInfo, isPersonal } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 4);
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState('Title');
  const [paymentStatus, setPaymentStatus] = useState('SELECT_ALL');
  const [isForwardTrade, setIsForwardTrade] = useState('SELECT_ALL');
  const [lastSearchBody, setLastSearchBody] = useState(null);
  const [userMenu, setUserMenu] = useState(null);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });
  useEffect(() => {
    setMetaObject({
      title: '마이페이지 - 판매목록',
      description: '크레딧 판매목록',
      keywords: '팝플이란, 팝플',
      image: '',
      canonical: '',
      url: window.location.href,
    });
  }, []);

  console.log('searchObject', searchObject);

  useEffect(() => {
    requestNewList(0);
  }, []);

  useEffect(() => {
    if (userInfo) {
      setUserMenu(genMypageRoutes(isPersonal));
    }
  }, [userInfo, isPersonal]);

  //event
  const requestSearch = () => {
    requestNewList(0);
  };

  const requestNewList = (init_page) => {
    let params = null;
    if (init_page === 0) {
      params = {
        page: init_page ? init_page : page,
        limit: 5,
        searchType: 'Title',
        searchValue: search,
      };
      if (paymentStatus !== 'SELECT_ALL') {
        params.paymentStatus = paymentStatus;
      }
      if (isForwardTrade !== 'SELECT_ALL') {
        params.isForwardTrade = isForwardTrade;
      }
      setLastSearchBody({ ...params });
    } else {
      params = {
        ...lastSearchBody,
        page: init_page ? init_page : page,
        limit: 5,
      };
    }
    console.log('requestNewList params', params);

    API.User.GetSaleList(params)
      .then((res) => {
        let result = res.data;
        console.log('Marketplace result', result);
        setList(result.content);
        setTotalPage(result.totalPages);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  useEffect(() => {
    console.log('page ------------_>', page);
    console.log('lastSearchBody ------------_>', lastSearchBody);
    if (lastSearchBody) {
      requestNewList(page);
    }
  }, [page]);

  const renderPurchaseType = (item) => {
    if (item.purchaseType === 'ForwardEnterprise' || item.purchaseType === 'ForwardPersonal') {
      return (
        <div className='forward-type__box'>
          <span>선도거래</span>
        </div>
      );
    } else {
      return null;
    }
  };

  if (!userInfo) {
    return null;
  }

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <div className='content__wrapper'>
          <MypageNavList userMenu={userMenu} />
          <section className='headding-text__container'>
            <header>
              <h1>{t('SELL_LIST')}</h1>
            </header>
            <div className='right__box'>
              <Breadcrumbs type={'small'}></Breadcrumbs>
            </div>
          </section>
          <section className='sell-list__container'>
            <div className='serach__box'>
              <DropdownInput
                value={search}
                menuValue={searchType}
                onChange={(value, key) => {
                  setSearch(value);
                }}
                onMenuChange={(value, key) => {
                  console.log('menu', value);
                  setSearchType(value);
                }}
                onEnter={() => {
                  requestSearch();
                }}
                meta={{
                  style: { width: '446px' },
                  placeholder: t('KEYWORD'),
                  menus: [
                    {
                      value: 'Title',
                      label: t('BIZ_TITLE'),
                    },
                    {
                      value: 'Id',
                      label: t('BIZ_ID'),
                    },
                    {
                      value: 'Name',
                      label: t('NAME'),
                    },
                  ],
                }}
              ></DropdownInput>
              <Button
                style={{ width: '80px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  requestSearch();
                }}
                label={t('SEARCH')}
              ></Button>
            </div>
            <div className='filter__box'>
              <div className='select__box'>
                <SelectBox
                  value={paymentStatus}
                  onChange={(value) => {
                    setPaymentStatus(value);
                  }}
                  meta={{
                    key: 'paymentStatus',
                    style: {
                      width: 165,
                    },
                    type: 'round',
                    menus: [
                      {
                        label: t('ALL_PAYMENT_STATUS'),
                        value: 'SELECT_ALL',
                      },
                      {
                        label: t('PAYMENT_COMPLETE'),
                        value: 'Complete',
                      },
                      {
                        label: t('DEPOSIT_COMPLETE'),
                        value: 'DepositComplete',
                      },
                      {
                        label: t('DEPOSIT_WAITING'),
                        value: 'DepositReady',
                      },
                      {
                        label: t('DEPOSIT_CANCEL'),
                        value: 'DepositCancel',
                      },
                    ],
                  }}
                ></SelectBox>
                <SelectBox
                  value={isForwardTrade}
                  onChange={(value) => {
                    setIsForwardTrade(value);
                  }}
                  meta={{
                    type: 'round',
                    key: 'isForwardTrade',
                    style: {
                      width: 165,
                    },
                    menus: [
                      {
                        label: t('ALL_PURCHASEMENT'),
                        value: 'SELECT_ALL',
                      },
                      {
                        label: t('PRE_PURCHASEMENT'),
                        value: 'true',
                      },
                      {
                        label: t('ORDINARY_PURCHASEMENT'),
                        value: 'false',
                      },
                    ],
                  }}
                ></SelectBox>
              </div>
            </div>
            <div className='list__box'>
              <ul>
                {list && list.length === 0 && (
                  <div className='empty'>
                    <div className='ico no-data'></div>
                    <span>{ERROR['NO_DATA_FOUND']}</span>
                  </div>
                )}
                {list &&
                  list.map((item, index) => {
                    return (
                      <li>
                        <section className='info__box'>
                          <div className='status__box'>
                            <div className='status'>
                              <span className={`status ${item.status}`}>
                                {t(Handler.buildPaymentStatusText(item.status))}
                              </span>
                              <div className='date'>{Handler.getYYYYMMDDHHMMByUnix(item.createdAt)}</div>
                            </div>
                            <div className='order'>
                              {renderPurchaseType(item)}
                              <span>
                                {t('ORDER_NUMBER')} : {item.orderNumber}
                              </span>
                            </div>
                          </div>
                          <div className='caption__box'>
                            <div className='img__box'>
                              {item.project.mainImages && item.project.mainImages.length > 0 && (
                                <img src={item.project.mainImages[0]} alt={item.project.title} />
                              )}
                            </div>
                            <div className='title__box'>
                              {renderPurchaseType(item)}
                              <span className='name'>{item.project.title}</span>
                              <span className='id'>{item.project.id}</span>
                              <div className='buyer'>
                                <span>
                                  {t('BUYER_INFO')} : {item.buyerName}
                                </span>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className='option__box'>
                          <div className='price__box'>
                            <div className='total__box'>
                              <header>{t('ORDER_PRICE')}</header>
                              <span>
                                {Handler.numberWithCommas(item.price)}
                                {t('WON')}
                              </span>
                            </div>
                            <div className='ton__box'>
                              <span>
                                {Handler.numberWithCommas(item.unitPrice)}
                                {t('WON')}
                              </span>
                              <span>·</span>
                              <span>{Handler.numberWithCommas(item.amount)}VRC</span>
                            </div>
                          </div>
                          <div className='button__box'>
                            <div
                              className='button'
                              onClick={() => {
                                history.push('/mypage/sell_detail/' + item.seq);
                              }}
                            >
                              {t('ORDER_DETAIL')}
                            </div>
                          </div>
                        </section>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </section>
          <div style={{ height: 36 }}></div>
          <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
        </div>
      </article>
    </>
  );
};
