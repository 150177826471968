import React, { useEffect, useContext } from 'react';
import { Breadcrumbs, TextInput, TextArea, Button, FileMultipleUploader, RadioBox } from '../../components';
import { useForm } from '../../hooks';
import { useHistory, useParams } from 'react-router-dom';
import { SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';

export const MypageInquiryModifyPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      type: 'Member',
      title: '',
      content: '',
      fileArray: [],
    },
    {
      type: {
        key: 'type',
        style: {
          gridColumn: '1/3',
        },
        label: '분류',
        menus: [
          {
            text: '회원 가입/탈퇴',
            value: 'Member',
          },
          {
            text: '방법론',
            value: 'Methodology',
          },
          {
            text: '사업 관리',
            value: 'Project',
          },
          {
            text: '감축량 인증',
            value: 'ReductionCert',
          },
          {
            text: 'ZC크레딧',
            value: 'ZCCredit',
          },
          {
            text: '기타',
            value: 'Etc',
          },
        ],
        className: 'board',
      },
      title: {
        key: 'title',
        label: '제목',
        required: true,
        style: {
          gridColumn: '1/3',
        },
        className: 'board',
      },
      content: {
        required: true,
        key: 'content',
        label: '상담 내용',
        maxSize: 500,
        style: {
          gridColumn: '1/3',
        },
        className: 'board',
      },
      fileArray: {
        key: 'fileArray',
        type: 'file',
        label: '첨부파일',
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
        className: 'board',
      },
    },
  );

  useEffect(() => {
    if (slug) {
      API.User.GetInquiryDetail(slug).then(
        (res) => {
          let result = res.data;
          console.log('refreshData result', result);
          setFormData({
            type: result.type,
            title: result.title,
            content: result.content,
            fileArray: result.files,
          });
        },
        (err) => {
          console.log('err', err);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
          history.push('/');
        },
      );
    }
  }, [slug]);

  const requsetSubmit = () => {
    console.log('requsetSubmit');

    let body = Handler.formToBody(formData, formMeta);
    if (body) {
      API.User.PutInquiry(slug, { ...body }).then(
        (res) => {
          let result = res.data;
          console.log('User PostInquiry result', result);
          history.push('/mypage/inquiry_detail/' + slug);
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인해주세요.',
      });
    }
  };

  return (
    <article className='basic-page'>
      <Breadcrumbs content='credit' title={'1:1 상담 문의 수정'}></Breadcrumbs>
      <article className='content__wrapper'>
        <section className='form__container'>
          <ul className='grid board'>
            <RadioBox value={formData.type} onChange={formChange} meta={formMeta.type}></RadioBox>
            <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
            <TextArea value={formData.content} onChange={formChange} meta={formMeta.content}></TextArea>
            <FileMultipleUploader
              value={formData.fileArray}
              onChange={formChange}
              meta={formMeta.fileArray}
            ></FileMultipleUploader>
          </ul>
        </section>
        <section className='button__container' style={{ marginTop: 0 }}>
          <Button style={{ width: '240px' }} onClick={requsetSubmit} label='수정하기'></Button>
        </section>
      </article>
    </article>
  );
};
