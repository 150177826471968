import React from 'react';
import styled from '@emotion/styled';
import { color } from '../../styles/style';
import { useState } from 'react';
import { useEffect } from 'react';

const RadioGroup = styled.article`
  display: ${props => props.display ? props.display : 'block'};
`;

const RadioBoxWrapper = styled.div`
  width: 100%;
  label {
    display: block;
    padding: 10px;
    border: 1px solid ${color.$black_ccc};
    border-radius: 10px;
    text-align: center;
    color: #888;
    margin: 5px;
    cursor: pointer;
  }
  input {
    display: none;
    height: auto;
  }
  input:checked + label {
    border: 1px solid ${color.$main500};
    color: ${color.$main500};
    font-weight: 700;
  }
`;

export const ButtonRadioBox = (props) => {
  const { radioList, display, onChange } = props;
  const [radioValue, setRadioValue] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (radioList?.length > 0) {
      setOptions(radioList);
      setRadioValue(radioList[0].value);
    }
  }, []);

  const onRadioChange = (e) => {
    const val = e.target.value
    setRadioValue(val)
    onChange(val)
  }


  return (
    <RadioGroup display={display}>
      {options.length > 0 &&
        options.map((el) => {
          return (
            <RadioBoxWrapper key={`radio-${el.id}`} className='check_box'>
              <input
                type='radio'
                name={el.group}
                id={el.id}
                value={el.value}
                checked={el.value === radioValue}
                onChange={(e) => onRadioChange(e)}
              />
              <label htmlFor={el.id}>{el.name}</label>
            </RadioBoxWrapper>
          );
        })}
    </RadioGroup>
  );
};
