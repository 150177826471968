import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { color, media } from '../../styles/style';
import { CERT_TITLE, CERT_TITLE_DESC, CERT_TITLE_BG, CERT_IMAGE } from '../../constants';
import { PageTitle, SectionTitle, MetaTag, Button } from '../../components';

const CertWrapper = styled.section`
  padding-top: 140px;
  text-align: center;
  margin: auto;
  h2 {
    padding: 20px;
  }
  .image-box {
    max-width: 720px;
    border: 1px solid ${color.$black_ddd};
    margin: 60px 20px 20px;
    img {
      width: 100%;
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 60px 24px;
    p {
      white-space: initial;
    }
  }
`;

const CertButtonWrapper = styled.section`
  display: flex;
  padding: 60px;
  justify-content: center;
  width: 100%;
  article {
    &:first-of-type {
      margin-right: 10px;
    }
    &:last-of-type {
      margin-left: 10px;
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 40px 0 0;
    flex-direction: column;
    align-items: center;
    article.basic-button {
      margin: 10px 0 0 0;
      &:first-of-type {
        button {
          background-color: #fff;
          border: 2px solid ${color.$main500};
          color: ${color.$main500};
          font-weight: 600;
        }
      }
    }
  }
`;

export const CertPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation()
  const [metaObject, setMetaObject] = useState({
    title: '아카이빙',
    description: '아카이빙',
    keywords: '아카이빙',
    imgsrc: '',
    canonical: 'result',
    url: window.location.href,
  });

  const genCertImage = () => {
    history.push('/mypage/purchase_list');
  };

  useEffect(() => {
    setMetaObject({
      title: t('POPLE_CERTIFICATION'),
      description: t('POPLE_CERTIFICATION_DESCRIPTION'),
      keywords: '아카이빙',
      image: CERT_TITLE_BG,
      canonical: 'result',
      url: window.location.href,
    });
  }, []);

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='purchase__container'>
        <PageTitle title={t('POPLE_CERTIFICATION')} description={t('POPLE_CERTIFICATION_DESCRIPTION')} image={CERT_TITLE_BG} />
        <CertWrapper className='content__wrapper'>
          <SectionTitle title={t('POPLE_CERTIFICATION_INTRO')} />
          <p>{t('POPLE_CERTIFICATION_INTRO_DESCRIPTION')}</p>
          <div className='image-box'>
            <img src={CERT_IMAGE} alt='' />
          </div>
          <CertButtonWrapper className='flex'>
            <Button
              style={{ width: 200, height: 50, fontWeight: 800 }}
              label={t('BUY_CREDITS')}
              onClick={() => history.push('/marketplace/list')}
            />
            <Button
              style={{ width: 200, height: 50, fontWeight: 800 }}
              label={t('GET_CERTIFICATE')}
              onClick={() => history.push('/mypage/purchase_list')}
            />
          </CertButtonWrapper>
        </CertWrapper>
      </article>
    </>
  );
};
