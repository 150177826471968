import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import ValueIcon01 from '../../../styles/icon/value-01.png';
import ValueIcon02 from '../../../styles/icon/value-02.png';
import ValueIcon03 from '../../../styles/icon/value-03.png';
import { East } from "@mui/icons-material"
import {
  SECTION_03_BG,
} from '../../../constants/main.constants';
import { useScrollFadeIn } from '../../../hooks';
import { color, font, fontSize, media } from '../../../styles/style';

const GuidLineContainer = styled.section`
  background-image: url(${SECTION_03_BG});
  background-size: cover;
  padding: 140px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: ${media.$sm}) {
    padding: 60px 24px;
  }
`;

const TitleWrapper = styled.div`
  h2,
  p {
    color: #fff;
    text-align: center;
  }
  h2 {
    font-size: ${fontSize(font.$text_5xl)};
    font-weight: ${font.bold};
  }
  p {
    padding: 40px 0 60px;
    font-size: ${fontSize(font.$text_base)};
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 0 24px;
    h2 {
      font-size: ${fontSize(font.$text_5xl, media.$sm)};
      word-break: keep-all;
    }
    p {
      font-size: ${fontSize(font.$text_base, media.$sm)};
      padding: 20px 0 30px;
      word-break: keep-all;
    }
  }
`;
const GuideItemContainer = styled.div`
  width: 100%;
  max-width: 940px;
  @media only screen and (max-width: ${media.$sm}) {
    display: block;
  }
`;

const GuideItems = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  width: inherit;
  justify-content: space-between;
  cursor: pointer;
  margin: 0 auto 25px;
  width: 100%;
  .left {
    display: flex;
    align-items: center;
    .icon {
      padding: 0 10px;
      width: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      color: ${color.$black_400};
      font-size: ${fontSize(font.$text_xl)};
      font-weight: 600;
    }
  }
  .right {
    padding: 0 20px;
    img {
      width: 3rem;
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 14px;
    border-radius: 10px;
    width: 100%;
    .left {
      flex-wrap: wrap;
      .icon {
        padding: 0 8px;
        width: 75px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title-wrap {
        width: 70%;
        p {
          font-size: ${fontSize(font.$text_base, media.$sm)};
          word-break: keep-all;
        }
      }
    }
    .right {
      padding: 0 10px;
      img {
        width: 1rem;
      }
    }
  }
`;

export const GuideLine = (props) => {
  const history = useHistory();
  const { t } = useTranslation()

  const LIST = [
    { title: t('MAIN_SECTION_03_STANDARD'), icon: ValueIcon01, path: '/standard' },
    { title: t('MAIN_SECTION_03_METHODOLOGY'), icon: ValueIcon02, path: '/cs/contact_us' },
    { title: t('MAIN_SECTION_03_SOLUTION'), icon: ValueIcon03, path: '/solution' },
  ];

  return ( 
    <GuidLineContainer>
      <TitleWrapper>
        <h2 className='text-white' {...useScrollFadeIn('up', 0.8)}>
          {t('MAIN_SECTION_03_TITLE')}
        </h2>
        <p className='text-white' {...useScrollFadeIn('up', 0.8)}>
        {t('MAIN_SECTION_03_DESCRIPTION')}
        </p>
      </TitleWrapper>
      <GuideItemContainer>
        {LIST &&
          LIST.map((item) => (
            <GuideItems key={`guide-list-item-${item.title}`} data-aos='fade-up' onClick={() => history.push(item.path)}>
              <div className='left'>
                <div className='icon'>
                  <img src={item.icon} className='' alt='' />
                </div>
                <div className='title-wrap'>
                  <p>{item.title}</p>
                </div>
              </div>
              <div className='right'>
                <East color="info" />
              </div>
            </GuideItems>
          ))}
      </GuideItemContainer>
    </GuidLineContainer>
  );
};
