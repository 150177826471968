import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle, SectionTitle, MetaTag } from '../../components';
import { PAGE_TOP, STANDARD_LIST } from '../../constants';
import styled from '@emotion/styled';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { color, font, fontSize, media } from '../../styles/style';
import { PopleAccordion } from '../../styles/ComponentStyle';

const StandardContainer = styled.article`
  background-color: ${color.$fill_300_light};
`;

const StandardAccordion = styled(PopleAccordion)`
  padding: 140px 0;
  .MuiAccordionSummary-root {
      span {
        color: #333 !important;
        font-weight: bold;
      }
    }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 60px 0;
    article h2 {
      padding: 40px;
    }
    .MuiAccordionSummary-root {
      padding: 8px 10px;
      span {
        font-size: ${fontSize(font.$text_xl, media.$sm)};
      }
    }
    .MuiAccordionSummary-content {
      margin: 10px;
      &.Mui-expanded {
        margin: 10px;
      }
    }
    .MuiAccordionDetails-root {
      padding: 20px;
      p,
      li {
        font-size: ${fontSize(font.$text_base, media.$sm)};
      }
    }
  }
`;

export const StandardPage = (props) => {
  const { t } = useTranslation();
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });
  useEffect(() => {
    setMetaObject({
      title: t('POPLE_STANDARD'),
      description: t('POPLE_STANDARD_DESCRIPTION'),
      keywords: '팝플이란, 팝플',
      image: PAGE_TOP.STANDARD.image,
      canonical: '',
      url: window.location.href,
    });
  }, []);
  return (
    <>
      <MetaTag props={metaObject} />
      <StandardContainer className='basic-page'>
        <PageTitle
          title={t('POPLE_STANDARD')}
          description={t('POPLE_STANDARD_DESCRIPTION')}
          image={PAGE_TOP.STANDARD.image}
          preview={PAGE_TOP.STANDARD.preview}
        />
        <section className='content__wrapper'>
          <StandardAccordion>
            {/* <SectionTitle title={t('POPLE_STANDARD_SUMMARY')} /> */}
            {STANDARD_LIST &&
              STANDARD_LIST.map((item, index) => {
                return (
                  <Accordion key={index + 'standard'}>
                    <AccordionSummary expandIcon={<div className='ico arrow-down'></div>}>
                      <div className='title__box'>
                        <span className='text-gray-800'>{t(item.title)}</span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t(item.content)}</p>
                      {item.sublist && (
                        <ul>
                          {item.sublist.filter(e => t(e) !== '').map((data, idx) => 
                           <li key={`sublist-list-${idx}`}>{t(data)}</li>)}
                        </ul>
                      )}
                      {item.content2 && <p>{t(item.content2)}</p>}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </StandardAccordion>
        </section>
      </StandardContainer>
    </>
  );
};
