import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'market/common/';

const PostFile = (formData) => {
  const url = BASE_URL + PATH + 'file';
  const headers = {
    'accept': 'application/json',
    'content-type': 'multipart/form-data',
  };
  return AxiosInstance.post(url, formData, headers);
};

const DeleteFile = (body) => {
  const url = BASE_URL + PATH + 'delete_file';
  return AxiosInstance.post(url, body);
};

const GetBusinessType = () => {
  const url = BASE_URL + PATH + 'business_type';
  return AxiosInstance.get(url);
};

const GetMethodology = () => {
  const url = BASE_URL + 'credit/methodology/common';
  return AxiosInstance.get(url);
};

const GetMonitoring = () => {
  const url = BASE_URL + 'credit/monitoring-evidence/common';
  return AxiosInstance.get(url);
};

const Common = {
  PostFile: PostFile,
  DeleteFile: DeleteFile,
  GetBusinessType: GetBusinessType,
  GetMethodology: GetMethodology,
  GetMonitoring: GetMonitoring,
};

export default Common;
