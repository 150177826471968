import React, { useState, useEffect, useContext } from 'react';
import { UserContext, SnackbarContext } from '../contexts';
import Utils from '../api/Utils';
import API from '../api';

export const UserProvider = (props) => {
  const { children } = props;
  const [accessToken, setAccessToken] = useState(Utils.GetStorage('accessToken'));
  const [refreshToken, setRefreshToken] = useState(Utils.GetStorage('refreshToken'));
  const { updateSnackbar } = useContext(SnackbarContext);
  const [userInfo, setUserInfo] = useState(null);
  const [isPersonal, setIsPersonal] = useState(false);

  const clearToken = () => {
    Utils.RemoveStorage('accessToken');
    Utils.RemoveStorage('refreshToken');
    Utils.RemoveStorage('keeping');
    setAccessToken(null);
    setRefreshToken(null);

    if (userInfo?.joinedType === 'Google') {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 !== null) {
        auth2
          .signOut()
          .then(
            auth2.disconnect().then(() => {
              console.log('success');
            }),
          )
          .catch((e) => console.log(e));
      }
    }
  };

  const updateAccessToken = (value) => {
    Utils.SetStorage('accessToken', value);
    setAccessToken(value);
  };

  const updateRefreshToken = (value) => {
    Utils.SetStorage('refreshToken', value);
    setRefreshToken(value);
  };

  const updateUserInfo = (value) => {
    setUserInfo(value);
    setIsPersonal(value?.userType === 'Personal');
  };

  const refreshUserInfo = () => {
    if (accessToken) {
      API.User.Get()
        .then((res) => {
          let result = res.data;
          console.log('User Info', result);
          updateUserInfo(result);
        })
        .catch((err) => {
          clearToken();
          updateUserInfo(null);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err?.data?.message,
          });
        });
    } else {
      clearToken();
      updateUserInfo(null);
    }
  };

  useEffect(() => {
    const accessToken = Utils.GetStorage('accessToken');

    if (accessToken) {
      refreshUserInfo();
    } else {
      clearToken();
      setUserInfo(null);
    }
  }, [accessToken]);

  return (
    <UserContext.Provider
      value={{
        accessToken: accessToken,
        refreshToken: refreshToken,
        updateAccessToken: updateAccessToken,
        updateRefreshToken: updateRefreshToken,
        clearToken: clearToken,
        userInfo: userInfo,
        isPersonal: isPersonal,
        updateUserInfo: updateUserInfo,
        refreshUserInfo: refreshUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
