import React from 'react';
import { Button } from '../../components';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

export const MypageCreditListPage = (props) => {
  const history = useHistory();
  const serachObject = queryString.parse(history.location.search);

  const requsetSubmit = () => {
    console.log('requsetSubmit');
  };
  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='complete-guide__container'>
          <div className='img__box'>
            <div className='illust'></div>
          </div>
        </section>
        <section className='guide-heading__container'>
          <h1>
            팝플크레딧 판매 목록은 <em>마켓플레이스</em>에서 확인할 수 있습니다.
          </h1>
        </section>
        <section className='button__container'>
          <Button style={{ width: '240px' }} onClick={requsetSubmit} label='마켓플레이스로 이동'></Button>
        </section>
      </article>
    </article>
  );
};
