import styled from '@emotion/styled';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLabel } from '../../hooks';

const SearchBox = styled(Autocomplete)`
  display: block;
  position: relative;
  z-index: 1;
  &.none {
    display: none;
  }
  .search-input {
    box-sizing: border-box;
    border: 1px solid #ccc;
    width: 100%;
    height: 50px;
    padding: 0 12px;
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
  }
`;

export const AddressSearch = (props) => {
  const { onSearch } = props;
  const { t } = useTranslation();
  const { PLACEHOLDER } = useLabel();
  const ref = useRef(null);
  const [libraries] = useState(['places']);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    // googleMapsApiKey: process.env.prod ? process.env.REACT_APP_GOOGLE_MAP_KEY : '',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });

  const onAutoCompleteLoad = useCallback((auto) => {
    console.log('autocomplete loaded');
  }, []);

  const findPlace = useCallback(() => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: ref.current.value }, (results, status) => {
      if (status === 'OK') {
        onSearch(results[0]);
      }
    });
  }, [onSearch]);

  useEffect(() => {
    return () => {
      const pac = document.querySelectorAll('.pac-container');
      pac.forEach((e) => {
        e.remove();
      });
    };
  }, []);

  return isLoaded ? (
    <SearchBox onPlaceChanged={findPlace} onLoad={onAutoCompleteLoad} style={{ zIndex: 9999 }}>
      <input type='text' ref={ref} placeholder={PLACEHOLDER['ADDRESS_PLACEHOLDER']} className='search-input' />
    </SearchBox>
  ) : (
    <></>
  );
};

export default React.memo(AddressSearch);
