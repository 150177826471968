import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput, Button, CheckBox, MetaTag } from '../../components';
import Handler from '../../Handler';
import { useForm } from '../../hooks';
import { SnackbarContext, UserContext } from '../../contexts';
import API from '../../api';
import Utils from '../../api/Utils';
import { useHistory } from 'react-router-dom';
import GoogleLogin from 'react-google-login';

export const UserLoginPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { updateAccessToken, updateRefreshToken, userInfo } = useContext(UserContext);
  const [formData, formMeta, formChange] = useForm(
    {
      email: '',
      password: '',
      keeping: false,
    },
    {
      email: {
        key: 'email',
        style: {
          marginBottom: 12,
        },
        className: 'round',
        required: true,
        keepInfo: true,
        placeholder: t('EMAIL_PLACEHOLDER'),
      },
      password: {
        key: 'password',
        type: 'password',
        style: {},
        className: 'round',
        required: true,
        keepInfo: true,
        placeholder: t('PASSWORD_PLACEHOLDER'),
      },
      keeping: {
        key: 'keeping',
        style: {},
        label: t('LOGGED_IN'),
      },
    },
  );
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });

  useEffect(() => {
    setMetaObject({
      title: t('LOGIN'),
      description: '로그인 하시고 팝플의 서비스를 이용하세요',
      keywords: '팝플이란, 팝플',
      image: '',
      canonical: '',
      url: window.location.href,
    });
    // const auth2 = window.gapi.auth2.getAuthInstance();

    // if (auth2 !== null) {
    //   auth2
    //     .signOut()
    //     .then(
    //       auth2.disconnect().then(() => {
    //         console.log('success');
    //       }),
    //     )
    //     .catch((e) => console.log(e));
    // }
  }, []);

  useEffect(() => {
    if (userInfo) {
      history.push((!userInfo.name || !userInfo.phone) ? '/add_info' : '/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const requsetLogin = () => {
    let body = Handler.formToBody(formData, formMeta);
    if (body) {
      API.User.Signin(body).then(
        (res) => {
          const { accessToken, refreshToken, initPassword } = res.data;
          updateAccessToken(accessToken);
          updateRefreshToken(refreshToken);
          Utils.SetStorage('keeping', formData.keeping);
          if (initPassword) {
            updateSnackbar({
              type: 'warning',
              open: true,
              message: '비밀번호 재설정 후 사용해주세요.',
            });
            history.push('/user/reset_pw');
          } else {
            history.push('/');
          }
        },
      ).catch((err) => {
        if (err.data.message === 'Invalid user status - Certification') {
          updateSnackbar({
            type: 'error',
            open: true,
            message: '이메일 인증이 처리되지 않았습니다.',
          });
        } else if (err.data.message === 'Invalid user status - ApproveRequired') {
          updateSnackbar({
            type: 'error',
            open: true,
            message: '관리자 승인이 처리되지 않았습니다.',
          });
        } else if (err.data.message === 'Invalid user status - Disabled') {
          updateSnackbar({
            type: 'error',
            open: true,
            message: '사용 불가능한 유저 아이디입니다.',
          });
        } else if (err.data.message === 'Not found user') {
          updateSnackbar({
            type: 'error',
            open: true,
            message: '유효하지 않은 이메일입니다.',
          });
        } else if (err.data.message === 'Invalid password') {
          updateSnackbar({
            type: 'error',
            open: true,
            message: '비밀번호를 확인하세요.',
          });
        } else {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        }
      })
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인해주세요.',
      });
    }
  };

  const responseGoogle = (e) => {
    API.User.PostSigninGoogle({
      googleToken: e.tokenId,
      keeping: true,
    }).then((res) => {
        const { seq, tokenRes } = res.data;
        if (tokenRes) {
          updateAccessToken(tokenRes.accessToken);
          updateRefreshToken(tokenRes.refreshToken);
          Utils.SetStorage('keeping', formData.keeping);
        } else {
          history.push('/add_info?seq=' + seq);
        }
      }).catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      })
  };

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <article className='content__wrapper'>
          <section className='headding-text__container'>
            <header>
              <h1>{t('LOGIN')}</h1>
            </header>
          </section>
          <section className='m-headding-text__container'>
            <header>
              <h2>{t('LOGIN')}</h2>
            </header>
          </section>
          <section className='login-form__container'>
            <TextInput
              value={formData.email}
              onChange={formChange}
              meta={formMeta.email}
              onEnter={requsetLogin}
            ></TextInput>
            <TextInput
              value={formData.password}
              onChange={formChange}
              meta={formMeta.password}
              onEnter={requsetLogin}
            ></TextInput>
            <Button
              style={{ width: '100%', marginTop: 28 }}
              onClick={() => {
                requsetLogin();
              }}
              label={t('LOGIN')}
            ></Button>
            <div className='option__box'>
              <CheckBox value={formData.keeping} onChange={formChange} meta={formMeta.keeping}></CheckBox>
              <ul>
                <li
                  onClick={() => {
                    history.push('/user/find_pw');
                  }}
                >
                  <span>{t('FIND_PASSWORD')}</span>
                </li>
                <li
                  onClick={() => {
                    history.push('/user/signup_guide');
                  }}
                >
                  <span>{t('SIGN_UP')}</span>
                </li>
              </ul>
            </div>
            <section className='sns__box'>
              <ul>
                <li
                  className='kakao'
                  onClick={(e) => {
                    e.preventDefault();
                    const REST_API_KEY = process.env.REACT_APP_KAKAO_REST_KEY;
                    const REDIRECT_URI = window.location.origin + '/kakao_login';
                    window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;
                  }}
                >
                  <div className='ico small-kakao'></div>

                  <span>Kakao</span>
                </li>
                <GoogleLogin
                  clientId='1006780416021-r5a256ddio6npc2k5kv8jmmcr134qjot.apps.googleusercontent.com'
                  render={(renderProps) => (
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        renderProps.onClick();
                      }}
                      className='google'
                    >
                      <div className='ico small-google'></div>
                      <span>Google</span>
                    </li>
                  )}
                  onSuccess={responseGoogle}
                  onFailure={(e) => {
                    if (e.error === 'popup_closed_by_user') {
                      console.log('popup_closed_by_user');
                    } else {
                      updateSnackbar({
                        type: 'error',
                        open: true,
                        message: e.error,
                      });
                    }
                  }}
                  cookiePolicy={'single_host_origin'}
                  isSignedIn={false}
                />
              </ul>
            </section>
          </section>
        </article>
      </article>
    </>
  );
};
