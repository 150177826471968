import CertBg from '../styles/images/title/cert_title_bg.png';
import DocumentBg from '../styles/images/title/documents_title_bg.png';
import CertImage from '../styles/images/pople_cert.png';

export const CERT_TITLE = '팝플 인증서';
export const CERT_TITLE_BG = CertBg;
export const CERT_TITLE_DESC = '인증서를 발급받고 선한 영향력을 전파해 보세요.';

export const CERT_SECTION_TITLE = `${CERT_TITLE} 소개`;
export const CERT_SECTION_TITLE_DESC =
  '마켓플레이스에서 크레딧 구매 후 인증서를 발급받을 수 있습니다.\n발급받은 인증서는 다운로드 및 공유하기 기능을 통해 SNS에 공유하여 지속가능한 지구를 위한 행동 참여를 유도할 수 있습니다.';

export const CERT_IS = `${CERT_TITLE} 소개`;
export const CERT_IS_DESC =
  '마켓플레이스에서 크레딧 구매 후 인증서를 발급받을 수 있습니다.\n발급받은 인증서는 다운로드 및 공유하기 기능을 통해 SNS에 공유하여 지속가능한 지구를 위한 행동 참여를 유도할 수 있습니다.';
export const CERT_IMAGE = CertImage;
export const DOCUMENT_TITLE = '자료실';
export const DOCUMENT_TITLE_DESC = '팝플 자료실입니다.';
export const DOCUMENT_PHRASE = '팝플의 다양한 자료를 만나보세요.';
export const DOCUMENT_TITLE_BG = DocumentBg;
