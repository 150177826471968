import Test from './resources/Test';
import Common from './resources/Common';
import User from './resources/User';
import CS from './resources/CS';
import Chart from './resources/Chart';
import Methodology from './resources/Methodology';
// import Project from './resources/Project';
// import ReductionCert from './resources/ReductionCert';
// import Credit from './resources/Credit';
// import Public from './resources/Public';
import Marketplace from './resources/Marketplace';
import Payment from './resources/Payment';
import News from './resources/News';

const API = {
  Test: Test,
  Common: Common,
  User: User,
  CS: CS,
  Methodology: Methodology,
  // Project: Project,
  // ReductionCert: ReductionCert,
  // Credit: Credit,
  // Public: Public,
  Chart: Chart, 
  Marketplace: Marketplace,
  Payment: Payment,
  News: News
};

export default API;
