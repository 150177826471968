import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Button, TablePaging, BoardAccordion, TextInput, MetaTag } from '../../components';
import { usePagination } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { LangContext, SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import queryString from 'query-string';

export const CsNoticePage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);
  const [search, setSearch] = useState('');
  const serachObject = queryString.parse(history.location.search);
  const initSeq = serachObject.init;
  const [reload, setReload] = useState(true);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });
  useEffect(() => {
    setMetaObject({
      title: t('NOTICE'),
      description: t('NOTICE'),
      keywords: '팝플이란, 팝플',
      image: '',
      canonical: '',
      url: window.location.href,
    });
  }, []);

  useEffect(() => {
    if (!isKorean) history.go(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isKorean]);

  const requestSearch = () => {
    requestNewList(0);
  };

  const requestNewList = (init_page) => {
    setReload(true);
    console.log({
      page: init_page ? init_page : page,
      limit: Handler.VARIABLES.PAGE_LIMIT,
      searchType: 'Title',
      searchValue: search,
    });
    API.CS.GetNoticeList({
      page: init_page === 0 ? init_page : page,
      limit: Handler.VARIABLES.PAGE_LIMIT,
      searchType: 'Title',
      searchValue: search,
    })
      .then((res) => {
        const { content, totalPages } = res.data;
        setList(content);
        setTotalPage(totalPages);
        setReload(false);
      })
      .catch((err) => {
        setReload(false);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  useEffect(() => {
    console.log('page ------------>', page);
    requestNewList(page ? page : 0);
  }, [page]);

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <article className='content__wrapper'>
          <section className='headding-text__container'>
            <header>
              <h1>{t('NOTICE')}</h1>
            </header>
            <div className='right__box'>
              <Breadcrumbs type={'small'}></Breadcrumbs>
            </div>
          </section>
          <section className='m-headding-text__container'>
            <header>
              <h2>{t('NOTICE')}</h2>
            </header>
          </section>
          <section className='notice__container'>
            <div className='serach__box'>
              <TextInput
                value={search}
                onChange={(value, key) => {
                  setSearch(value);
                }}
                onEnter={() => {
                  requestSearch();
                }}
                meta={{
                  style: { width: '446px' },
                  placeholder: t('KEYWORD'),
                  className: 'round',
                }}
              ></TextInput>
              <Button
                style={{ width: '80px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  requestSearch();
                }}
                label={t('SEARCH')}
              ></Button>
            </div>
            <div className='board__box'>
              {!reload && <BoardAccordion data={list} type={'notice'} init={initSeq}></BoardAccordion>}
            </div>
          </section>
          <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
        </article>
      </article>
    </>
  );
};
