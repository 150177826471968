import React, { useContext } from 'react';
import Handler from '../../Handler';
import { UserContext } from '../../contexts';

export const TableCalculate = (props) => {
  const { data, rowClick, callback } = props;
  const { userInfo, isPersonal } = useContext(UserContext);


  console.log('TableCalculate', data);
  const renderPurchaseType = (item) => {
    if (item.purchaseType === 'ForwardEnterprise' || item.purchaseType === 'ForwardPersonal') {
      return (
        <div className='forward-type__box'>
          <span>선도거래</span>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <article className='table-calculate'>
      {data && data.length == 0 && <span className='empty'>데이터가 없습니다.</span>}
      {data && data.length > 0 && (
        <ul>
          {data.map((item, index) => {
            return (
              <li>
                <div className='date__box'>
                  <span>{Handler.getYYYYMMDDByUnix(item.createdAt)}</span>
                </div>
                <div className='title__box'>
                  {renderPurchaseType(item)}
                  <div className='project-title'>{item.project.title}</div>
                  <div className='project-id'>{item.project.id}</div>
                </div>
                <div className='result__box'>
                  <div className='credit__box'>
                    <header>{isPersonal ? '정산된 크레딧수' : '상쇄크레딧(톤)'}</header>
                    <div className='value'>
                      {isPersonal
                        ? Handler.numberWithCommas(item.forwardComplete) + '크레딧'
                        : Handler.numberWithCommas(item.amount) + '톤'}
                    </div>
                  </div>
                  <div className='price__box'>
                    <header>{isPersonal ? '수익' : '정산금액'}</header>
                    <div className='value'>
                      {isPersonal ? Handler.numberWithCommas(item.revenue) : Handler.numberWithCommas(item.calcPrice)}원
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </article>
  );
};
