import React from 'react';
import { PageTitle } from '../../components';

export const ProjectPage = (props) => {

  return (
    <article className='basic-page'>
      <PageTitle title='테스트' description='테스트' image='' />
      <section className='content__wrapper'></section>
    </article>
  );
};
