import React from 'react';
import styled from '@emotion/styled';
import { SectionTitle } from '../../../components';
import { font, media } from '../../../styles/style';
const VisionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 40px;
  margin: 60px 0;
  height: 360px;
  h3.catch {
    padding-top: 20px;
  }
  .vision-image {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    overflow: hidden;
    border-radius: 20px;
  }
  @media only screen and (max-width: ${media.$sm}) {
    display: block;
    padding: 24px;
    margin: 20px 0;
    .vision-image {
      height: 180px;
    }
  }
`;

const Content = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: baseline; */
  p {
    font-size: ${font.$title3};
    line-height: ${font.$leading_02};
    white-space: pre-wrap;
    padding-top: 20px;
  }
  @media only screen and (max-width: ${media.$sm}) {
    p {
      white-space: initial;
      word-break: keep-all;
      margin-bottom: 20px;
    }
  }
`;

const BrandVisionCard = (props) => {
  const { title, description, catchphrase, image_left, image } = props;
  return (
    <VisionWrapper>
      {image_left && (
        <div className='vision-image' image={image}>
          <img src={image} alt='' />
        </div>
      )}
      <Content>
        <SectionTitle title={title} />
        {catchphrase && <h3 className='catch'>{catchphrase}</h3>}
        {description && <p>{description}</p>}
      </Content>
      {!image_left && (
        <div className='vision-image' image={image}>
          <img src={image} alt='' />
        </div>
      )}
    </VisionWrapper>
  );
};

export default BrandVisionCard;
