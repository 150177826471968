import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UserContext, SnackbarContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import { Button } from '../../components';
import { color, font, fontSize } from '../../styles/style';

import styled from '@emotion/styled';
import { t } from 'i18next';

const PhraseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  .title {
    padding: 10px 0 40px;
    p,
    h2 {
      font-size: ${fontSize(font.$text_4xl)};
      text-align: center;
    }
    p.desc {
      font-size: 20px;
      color: ${color.$main500};
      word-break: keep-all;
      padding: 20px 20px 0 20px;
    }
    p.info {
      font-size: 16px;
      margin: 8px 9;
    }
  }
`;

const RECIEPT_TABLE = styled.table`
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
  tr {
    &:first-of-type {
      th,
      td {
        padding: 1rem 1rem 0.5rem;
      }
    }
    &:last-of-type {
      th,
      td {
        padding: 0.5rem 1rem 1rem;
      }
    }
  }
  th,
  td {
    padding: 0.5rem 1rem;
    span.primary {
      color: ${color.$main500};
      font-weight: 700;
    }
  }
  th {
    color: ${color.$black_aaa};
    font-weight: 400;
    text-align: left;
  }
`;

export const PurchaseCompletePage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [show, setShow] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    getOrderInfo(slug);
    // if (orderInfo) {
    //   setShow(true);
    // } else {
    //   setShow(false);
    // }
  }, [slug]);

  const getOrderInfo = (slug) => {
    const params = { merchant_uid: slug };
    API.Payment.GetPaymentOrderInfo(params).then(
      (res) => {
        console.log('TEST ------------------>');
        console.log(res);
        setOrderInfo(res.data);
        setShow(true);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  return (
    <>
      {show && (
        <article className='basic-page'>
          <article className='content__wrapper'>
            <PhraseWrapper>
              <div className='ico circle-check'>{/* <img src={} alt="" /> */}</div>
              <div className='title'>
                <p>{t('BUY_CREDIT_COMPLETE_KEY')}</p>
                <h2>{t('BUY_CREDIT_COMPLETE_VALUE')}</h2>
                {userInfo && (
                  <p className='desc'>
                    {userInfo.userType === 'Integrated' ? '' : userInfo.name + ' 님의 '}
                    {orderInfo.paymentType !== 'TAXBILL'
                      ? t('BUY_CREDIT_COMPLETE_DESCRIPTION')
                      : t('TAXBILL_ISSUANCE_MSG')}
                  </p>
                )}
                {orderInfo.paymentType === 'TAXBILL' && <p className='info'>※ {t('TAXBILL_NOTICE')}</p>}
              </div>
            </PhraseWrapper>
            <RECIEPT_TABLE>
              <tbody>
                <tr>
                  <th>{t('ORDER_NUMBER')}</th>
                  <td>{slug}</td>
                </tr>
                <tr>
                  <th>{t('PROJECT')}</th>
                  <td>{orderInfo.title}</td>
                </tr>
                <tr>
                  <th>{t('PROJECT_PERIOD')}</th>
                  <td>
                    {orderInfo.startDate} ~{orderInfo.endDate}
                  </td>
                </tr>
                <tr>
                  <th>{t('BUY_REASON')}</th>
                  <td>{orderInfo.reason}</td>
                </tr>
                <tr>
                  <th>{t('BUY_AMOUNT')}</th>
                  <td>
                    <span className='primary'>{orderInfo.amount} VRC</span>
                  </td>
                </tr>
                <tr>
                  <th>{t('PURCHASE_AMOUNT')}</th>
                  <td>
                    <span className='primary'>
                      {Handler.numberWithCommas(orderInfo.realPrice)} {t('WON')}
                    </span>
                    <span>
                      ({Handler.numberWithCommas(orderInfo.unitPrice)}
                      {t('WON')} * {orderInfo.amount}VRC)
                    </span>
                  </td>
                </tr>
              </tbody>
            </RECIEPT_TABLE>
            <Button
              radius={25}
              style={{ width: 200, height: 50, fontWeight: 800, borderRadius: '25px' }}
              label={orderInfo.paymentType !== 'TAXBILL' ? t('PURCHASE_HISTORY') : t('PURCHASE_HISTORY')}
              onClick={() => history.push('/mypage/purchase_list')}
            />
          </article>
        </article>
      )}
    </>
  );
};
