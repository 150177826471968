import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const renderTypeText = (value) => {
  switch (value) {
    case 'Member': {
      return '회원 가입/탈퇴';
    }
    case 'Methodology': {
      return '방법론';
    }
    case 'Project': {
      return '사업 관리';
    }
    case 'ReductionCert': {
      return '감축량 인증';
    }
    case 'ZCCredit': {
      return 'ZC크레딧';
    }
    case 'Etc': {
      return '기타';
    }
    default: {
      return value;
    }
  }
};

const renderStatusText = (value) => {
  switch (value) {
    case 'Inquiry': {
      return '답변대기';
    }
    case 'Complete': {
      return '답변완료';
    }
    default: {
      return value;
    }
  }
};

export const TableInquiry = (props) => {
  const { data, rowClick, callback } = props;

  console.log('TableInquiry', data);

  return (
    <article className='table-inquiry'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center' style={{ minWidth: 100 }}>
                번호
              </TableCell>
              <TableCell align='center' style={{ minWidth: 100 }}>
                분류
              </TableCell>
              <TableCell align='center' style={{ minWidth: 600 }}>
                제목
              </TableCell>
              <TableCell align='center' style={{ minWidth: 100 }}>
                일자
              </TableCell>
              <TableCell align='center' style={{ minWidth: 100 }}>
                상태
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow
                  onClick={() => {
                    rowClick(index);
                  }}
                >
                  <TableCell align='center'>{row.seq}</TableCell>
                  <TableCell align='center'>{renderTypeText(row.type)}</TableCell>
                  <TableCell align='left'>{row.title}</TableCell>
                  <TableCell align='center'>{row.inquiryDate}</TableCell>
                  <TableCell align='center' className={row.status}>
                    {renderStatusText(row.status)}
                  </TableCell>
                </TableRow>
              ))}
            {data && data.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>데이터가 없습니다.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};
