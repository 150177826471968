import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { ContentContext } from '../contexts';

export const ContentProvider = (props) => {
  const { children } = props;
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  const updateDimensions = debounce(() => {
    setDeviceWidth(window.innerWidth);
  }, [500]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return <ContentContext.Provider value={{ deviceWidth: deviceWidth }}>{children}</ContentContext.Provider>;
};
