import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import API from '../../api';
import { SnackbarContext } from '../../contexts';
import { Button } from '../../components';
import Handler from '../../Handler';

const acceptFIleList = (type) => {
  if (type === 'image') {
    return 'image/gif, image/jpeg, image/png, image/gif';
  } else if (type === 'sound') {
    return 'audio/mp3';
  } else if (type === 'video') {
    return 'video/mp4';
  } else {
    return '*';
  }
};

const renderPreview = (value, meta) => {
  const fileName = value ? value.split('/')[value.split('/').length - 1] : '';

  if (!value) {
    return <div className='empty__box'></div>;
  } else {
    if (meta.type === 'image') {
      return (
        <div className='img__box'>
          <img
            onClick={() => {
              window.open(value, '_blank');
            }}
            src={value}
            alt='meta'
          ></img>
        </div>
      );
    } else {
      return (
        <div className='file__box'>
          <span
            onClick={() => {
              window.open(value, '_blank');
            }}
          >
            {fileName}
          </span>
        </div>
      );
    }
  }
};

export const FileUploader = (props) => {
  const { value, onChange, meta } = props;
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('path', meta.path);
      setLoading(true);
      API.Common.PostFile(formData)
        .then((res) => {
          console.log('res', res);
          setLoading(false);
          onChange(res.data.cloudUrl, meta.key);
          removeFileInput();
        })
        .catch((err) => {
          console.log('err', err);
          setLoading(false);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
          removeFileInput();
        });
    }
  }, [file]);

  const removeFileInput = () => {
    document.getElementById(meta.key + '-file-uploader-input').value = '';
    setFile(null);
  };

  const removeFileS3 = () => {
    onChange(null, meta.key);
    removeFileInput();
    // console.log('value', value);
    // let body = {
    //   url: value,
    // };
    // console.log('removeFile', body);

    // API.Common.DeleteFile(body).then(
    //   (res) => {
    //     console.log('file remove res', res);
    //     onChange(null, meta.key);
    //     removeFileInput();
    //   },
    //   (err) => {
    //     console.log('file remove err', err);
    //     onChange(null, meta.key);
    //     removeFileInput();
    //   },
    // );
  };

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span>*</span>;
      }
    }
  };

  return (
    <article
      className={Handler.BuildArticleClassname('form-file-uploader', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>{meta.label}</span>
          {renderRequired()}
        </header>
      )}
      <div className='input__wrapper'>
        <div className='preview__box'>
          {value && (
            <div className='close__box'>
              {meta.disabled || meta.readOnly ? null : (
                <ClearIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    removeFileS3();
                  }}
                ></ClearIcon>
              )}
            </div>
          )}

          {renderPreview(value, meta)}
        </div>

        {!meta.disabled && !meta.readOnly && (
          <div className='add__box'>
            <Button
              loading={loading}
              type='info-square'
              style={{ width: '85px', height: '50px' }}
              onClick={() => {
                document.getElementById(meta.key + '-file-uploader-input').click();
              }}
              label={t('FILE_CHOOSE')}
            ></Button>
            {meta.helperText && (
              <div className='helper-text'>
                <span>{meta.helperText}</span>
              </div>
            )}
          </div>
        )}
      </div>

      <div className='input__box'>
        <input
          type='file'
          accept={acceptFIleList(meta.type)}
          id={meta.key + '-file-uploader-input'}
          onChange={(e) => {
            setFile(e.target.files[0]);
          }}
        ></input>
      </div>
    </article>
  );
};
