import React from 'react';
import Handler from '../../Handler';

export const RadioBox = (props) => {
  const { value, onChange, meta } = props;

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.classNAme === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span>*</span>;
      }
    }
  };

  return (
    <article
      className={Handler.BuildArticleClassname('form-radio-box', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>
            {meta.label}
            {renderRequired()}
          </span>
          {meta.subLabel && <span className='sub-label'>{meta.subLabel}</span>}
        </header>
      )}
      <ul>
        {meta.menus &&
          meta.menus.map((item, index) => {
            return (
              <li
                onClick={() => {
                  if (meta.dsiabeld || meta.readOnly) {
                  } else {
                    onChange(item.value, meta.key);
                  }
                }}
              >
                <div className='ico__box'>
                  <div className={value === item.value ? 'ico check on' : 'ico check'}></div>
                </div>
                <div className='text__box'>{item.text}</div>
              </li>
            );
          })}
      </ul>
    </article>
  );
};
