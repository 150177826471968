import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput, Button } from '../../components';
import Handler from '../../Handler';
import { useForm, useLabel } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import API from '../../api';
import { useHistory } from 'react-router-dom';

export const UserFindPwPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { ERROR } = useLabel();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange] = useForm(
    {
      email: '',
      name: '',
    },
    {
      email: {
        key: 'email',
        style: {},
        className: 'round',
        required: true,
        placeholder: t('EMAIL'),
      },
      name: {
        key: 'name',
        style: {
          marginBottom: 12,
        },
        className: 'round',
        required: true,
        placeholder: t('NAME'),
      },
    },
  );

  const requestFindPassword = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('request find password', body);

    if (body) {
      console.log('body', body);

      API.User.FindPassword(body).then(
        (res) => {
          let result = res.data;
          console.log('User FindPassword result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: t('FIND_PW_RELOGIN'),
          });
          history.push('/user/login');
        },
        (err) => {
          if (err.data.message === 'Invalid user name') {
            updateSnackbar({
              type: 'error',
              open: true,
              message: t('INVALID_NAME'),
            });
          } else if (err.data.message === 'Not found user from email key') {
            updateSnackbar({
              type: 'error',
              open: true,
              message: t('INVALID_EMAIL'),
            });
          } else {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          }
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: ERROR['REQUIRED_INFO'],
      });
    }
  };

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>{t('FIND_PW')}</h1>
          </header>
        </section>
        <section className='m-headding-text__container'>
          <header>
            <h2>{t('FIND_PW')}</h2>
          </header>
        </section>
        <section className='login-form__container'>
          <div className='guide__text'>
            <span>{t('FIND_PW_MSG')}</span>
          </div>
          <TextInput
            value={formData.name}
            onChange={formChange}
            meta={formMeta.name}
            onEnter={requestFindPassword}
          ></TextInput>
          <TextInput
            value={formData.email}
            onChange={formChange}
            meta={formMeta.email}
            onEnter={requestFindPassword}
          ></TextInput>

          <Button
            style={{ width: '100%', marginTop: 28 }}
            onClick={() => {
              requestFindPassword();
            }}
            label={t('FIND_PW')}
          ></Button>
        </section>
      </article>
    </article>
  );
};
