import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle, SectionTitle, MetaTag } from '../../components';
import styled from '@emotion/styled';
import { color, media, font, fontSize } from '../../styles/style';
import {
  PAGE_TOP,
  DIGITAL_MRV_API_LIST,
  DIGITAL_MRV_LIST,
  DIGITAL_MRV_PROCESS,
  DIGITAL_MRV_PROCESS_LIST,
  DIGITAL_MRV_STRENGTH_LIST,
} from '../../constants';
import { ContentContext } from '../../contexts';
import RoundArrow from '../../styles/icon/round_arrow.svg';
import CheckIcon from '../../styles/icon/icon_check.svg';

const AboutDigitalMRV = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 140px 0;
  white-space: pre-wrap;
  h3 {
    font-size: ${fontSize(font.$text_5xl)};
    font-weight: 800;
    line-height: 1.5;
    padding-top: 20px;
    span {
      font-size: inherit;
      margin-left: 0.5rem;
      border-bottom: 4px solid ${color.$main500};
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    display: block;
    padding: 60px 24px;
    h3 {
      font-size: ${fontSize(font.$text_4xl, media.$sm)};
      word-break: keep-all;
      white-space: initial;
    }
  }
`;

const DigitalMRVContainer = styled.article`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 140px;
  height: 300px;
  width: 100%;
  max-width: 1200px;
  position: relative;
  @media only screen and (max-width: ${media.$sm}) {
    /* display: block; */
    padding-bottom: 60px;
    height: unset;
    flex-direction: column;
  }
`;

const DigitalMRVCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 340px;
  justify-content: space-between;
  text-align: center;
  z-index: 1;
  h4 {
    margin: 10px 0;
  }
  .img {
    width: 180px;
    img {
      width: 100%;
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    margin-bottom: 40px;
    .img {
      width: 120px;
    }
  }
`;

const DigitalMRVStrength = styled.section`
  padding: 100px 0 140px;
  width: 100%;
  max-width: 1200px;
  h2 {
    text-align: center;
  }
  .strength-container {
    padding-top: 40px;
    p {
      position: relative;
      &:before {
        position: absolute;
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        top: 2px;
        left: -30px;
        background-image: url(${CheckIcon});
        background-size: cover;
      }
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 0 24px;
    .strength-container {
      padding: 40px 0;
      p {
        margin-left: 20px;
        font-size: ${fontSize(font.$text_sm)};
      }
    }
  }
`;

const DigitalMRVApiSection = styled.section`
  width: 100%;
  background-color: ${color.$fill_300_light};
  text-align: center;
  padding: 140px 0;
  .item-container {
    display: flex;
    justify-content: center;
  }
  h3 {
    font-size: ${fontSize(font.$text_4xl)};
    white-space: pre-wrap;
    padding-top: 20px;
  }
  @media only screen and (max-width: ${media.$sm}) {
    min-width: unset;
    padding: 60px 24px;
    h3 {
      font-size: ${fontSize(font.$text_4xl, media.$sm)};
      word-break: keep-all;
    }
    .item-container {
      flex-direction: row;
      align-items: center;
      padding: 0 24px;
      .item-wrapper {
        p {
          white-space: initial;
          word-break: keep-all;
        }
      }
    }
  }
`;

const DigitalMRVApiItem = styled.div`
  border-radius: 50%;
  border: 4px solid ${color.$main500};
  width: 300px;
  height: 300px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 60px;
  h4 {
    color: ${color.$main500};
    white-space: pre-wrap;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 20px;
    margin: 20px;
    width: 140px;
    height: 140px;
    border: 2px solid ${color.$main500};
    h4 {
      font-size: 18px;
    }
    h4,
    p {
      word-break: keep-all;
    }
  }
`;

const DigitalMRVProcess = styled.section`
  padding: 140px 0;
  max-width: ${media.$wrapper_max_width};
  min-width: ${media.$wrapper_min_width};
  .item-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 60px 24px;
    min-width: unset;
    width: 100%;
    .item-container {
      grid-template-columns: repeat(1, 1fr);
      padding-top: 20px;
    }
  }
`;

const ProcessItem = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: relative;
  div.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 230px;
    h4,
    p {
      line-height: 1.5;
    }
    h4 {
      color: ${color.$main500};
      font-size: ${font.$body_02};
      white-space: pre-wrap;
      text-align: center;
      margin-bottom: 10px;
    }
    p {
      text-align: center;
      word-break: keep-all;
    }
  }
  div.img {
    width: 25px;
    display: flex;
    align-items: flex-start;
    img {
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    display: block;
    margin-bottom: 20px;
    div.content {
      display: grid;
      grid-template-columns: 140px 1fr;
      align-items: center;
      width: 100%;
      margin: 20px 0;
      .icon-box {
        width: 140px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .image-box {
          width: 100px;
          img {
            width: 100%;
            margin: auto;
          }
        }
      }
    }
    .img {
      position: absolute;
      left: 20%;
      transform: rotate(90deg);
    }
  }
`;

export const DigitalSolutionPage = (props) => {
  const { t } = useTranslation();
  const { deviceWidth } = useContext(ContentContext);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });
  useEffect(() => {
    setMetaObject({
      title: t('DIGITAL_MRV'),
      description: t('DIGITAL_MRV_DESCRIPTION'),
      keywords: '팝플이란, 팝플',
      image: PAGE_TOP.SOLUTION.image,
      canonical: '',
      url: window.location.href,
    });
  }, []);
  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <PageTitle
          title={t('DIGITAL_MRV')}
          description={t('DIGITAL_MRV_DESCRIPTION')}
          image={PAGE_TOP.SOLUTION.image}
          preview={PAGE_TOP.SOLUTION.preview}
        />
        <section className='content__wrapper'>
          <AboutDigitalMRV>
            <div>
              <SectionTitle title={t('DIGITAL_MRV_INTRO')}></SectionTitle>
              <h3>{t('DIGITAL_MRV_INTRO_DESCRIPTION')}</h3>
            </div>
          </AboutDigitalMRV>
        </section>
        <DigitalMRVContainer>
          {DIGITAL_MRV_LIST &&
            DIGITAL_MRV_LIST.map((item) => {
              return (
                <div className='flex flex-col items-center text-center w-2/6 h-full z-10 px-4'>
                  <div className='w-32 mb-5'>
                    <img className='w-full' src={item.icon} alt='' />
                  </div>
                  <h4 className='font-bold pb-4'>{t(item.key)}</h4>
                  <p>{t(item.value)}</p>
                </div>
              );
            })}
        </DigitalMRVContainer>
        <DigitalMRVStrength>
          <div className='bg-slate-50 p-10 pl-16 rounded-lg'>  
            <h2 className='font-bold'>{t('DIGITAL_MRV_STRENGTH')}</h2>
            <div className='strength-container'>
              {DIGITAL_MRV_STRENGTH_LIST &&
                DIGITAL_MRV_STRENGTH_LIST.map((item) => {
                  return <p className='text-md leading-6 mb-2'>{t(item)}</p>;
                })}
            </div>
          </div>
        </DigitalMRVStrength>
        <DigitalMRVApiSection>
          <div className='max-w-4xl m-auto'>
            <h2 className='font-bold text-primary'>{t('DATA_COLLECTION_INTERFACE_SYSTEM')}</h2>
            <h3>{t('DATA_COLLECTION_INTERFACE_SYSTEM_DESCRIPTION')}</h3>
          </div>
          <div className='item-container'>
            {DIGITAL_MRV_API_LIST &&
              DIGITAL_MRV_API_LIST.map((item) => {
                return deviceWidth > 640 ? (
                  <DigitalMRVApiItem>
                    <h4 className='font-bold text-primary'>{t(item.key)}</h4>
                    <p>{t(item.value)}</p>
                  </DigitalMRVApiItem>
                ) : (
                  <div className='item-wrapper'>
                    <DigitalMRVApiItem>
                    <h4 className='font-bold text-primary'>{t(item.key)}</h4>
                    </DigitalMRVApiItem>
                    <p>{t(item.value)}</p>
                  </div>
                );
              })}
          </div>
        </DigitalMRVApiSection>
        <DigitalMRVProcess>
          <SectionTitle title={t('DIGITAL_MRV_PROCESS')} style={{ textAlign: 'center' }}></SectionTitle>
          <div className='item-container'>
            {DIGITAL_MRV_PROCESS_LIST &&
              DIGITAL_MRV_PROCESS_LIST.map((process, index) => {
                return (
                  <ProcessItem>
                    <div className='content'>
                      <div className='icon-box'>
                        <div className='image-box'>
                          <img src={process.icon} alt='' />
                        </div>
                        <h4>
                          0{index + 1}. {t(process.key)}
                        </h4>
                      </div>
                      <p>{t(process.value)}</p>
                    </div>
                    {index < 4 && (
                      <div className='img'>
                        <img src={RoundArrow} alt='' />
                      </div>
                    )}
                  </ProcessItem>
                );
              })}
          </div>
        </DigitalMRVProcess>
      </article>
    </>
  );
};
