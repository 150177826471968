import Process01 from '../styles/icon/market-01.png';
import Process02 from '../styles/icon/market-02.png';
import Process03 from '../styles/icon/market-03.png';
// import Process04 from '../styles/icon/market-04.svg';
import Process04 from '../styles/icon/market-04.png';
import MoreStandard from '../styles/icon/more_standard.svg';
import MoreFaq from '../styles/icon/more_faq.svg';

import { BIZ_ICON_LIST as SubAreaIcons } from './about.constants';

export const MARKET_SLOGAN = {
  black: [
    'MARKETPLACE_SLOGAN_BLACK_01',
    'MARKETPLACE_SLOGAN_BLACK_02',
  ],
  primary: 'MARKETPLACE_SLOGAN_PRIMARY',
};
export const MARKET_INTRO = 'MARKETPLACE_WORKS';
export const MARKET_INTRO_DESC =
  'MARKETPLACE_WORKS_DESCRIPTION';

export const MARKET_VRC_MEANING = {
  key: 'VRC',
  value: ' - Verified Removal(Reduction) Credit - ',
  desc: '는\n팝플 레지스트리에 등록되어 발행된 팝플 크레딧입니다.\n1VRC는 1톤의 탄소 배출 제거/감축량(이산화탄소상당량)을 나타냅니다.',
};
export const MARKET_INTRO_LIST = [
  {
    label: 'CARBON_REDUCTION_COMPANY',
    title: 'METHODOLOGY_BIZ_APPLY',
    desc: 'CARBON_REDUCTION_COMPANY_DESCRIPTION',
    icon: Process01,
    path: '',
  },
  {
    label: 'POPLE',
    title: 'CREDIT_ISSUANCE',
    desc: 'CREDIT_ISSUANCE_DESCRIPTION',
    icon: Process02,
  },
  {
    label: 'CARBON_REDUCTION_COMPANY',
    title: 'MARKETPLACE_REGISTRATION',
    desc: 'MARKETPLACE_REGISTRATION_DESCRIPTION',
    icon: Process03,
  },
  {
    label: 'PERSON_COMPANY',
    title: 'BUY_CREDIT',
    desc: 'BUY_CREDIT_DESCRIPTION',
    icon: Process04,
  },
];
export const MARKET_PROCESS_LIST = Array(6).fill().map((_,idx) => ({
  key: `MARKET_PROCESS_ITEM_0${idx + 1}_TITLE`,
  value: `MARKET_PROCESS_ITEM_0${idx + 1}_DESCRIPTION`,
}))

export const POPLE_MORE_LIST = [
  {
    title: 'POPLE_STANDARD',
    desc: 'POPLE_VALUE_PRINCIPLE',
    path: '/standard',
    icon: MoreStandard,
  },
  // {
  //   title: '다양한 자료',
  //   desc: '탄소 관련 자료',
  //   path: '/documents',
  //   icon: MoreInfo,
  // },
  {
    title: 'CARBON_FAQ',
    desc: 'CARBON_FAQ_DESCRIPTION',
    path: '/cs/faq',
    icon: MoreFaq,
  },
];

export const SUB_AREA_ICONS = SubAreaIcons;

