import Light from '../styles/icon/Light.png';
import Pioneering from '../styles/icon/Pioneering.png';
import Rigorous from '../styles/icon/Rigorous.png';
import Transparent from '../styles/icon/Transparent.png';

import BrandColorMotif from '../styles/images/brand_color_motif.png';
import BrandColorMotifEn from '../styles/images/brand_color_motif_en.png';
import BrandKeyword from '../styles/images/brand_keyword.svg';
import BrandKeywordBg from '../styles/images/brand_keyword_bg.png';
import BrandKeywordEn from '../styles/images/brand_keyword_en.svg';
import BrandKeywordMobile from '../styles/images_m/brand_keyword.png';

import Agriculture from '../styles/icon/icon_agriculture.svg';
import Biochar from '../styles/icon/icon_biochar.svg';
import Ccs from '../styles/icon/icon_ccs.svg';
import ChangeEnergy from '../styles/icon/icon_change_energy.svg';
import Chemical from '../styles/icon/icon_chemical.svg';
import Dac from '../styles/icon/icon_dac.svg';
import Emobility from '../styles/icon/icon_emobility.svg';
import Forest from '../styles/icon/icon_forest.svg';
import Recycle from '../styles/icon/icon_recycle.svg';
import Renewable from '../styles/icon/icon_renewable.svg';
import ReplaceElement from '../styles/icon/icon_replace_element.svg';
import Soil from '../styles/icon/icon_soil.svg';

import IconConsulting from '../styles/icon/biz_consulting.svg';
import IconMarkeplace from '../styles/icon/biz_marketplace.svg';
import IconRegistry from '../styles/icon/biz_registry.svg';

import PopleLogoImages from '../styles/icon/logo_images.png';

import PopleLogo01 from '../styles/icon/pople_logo_01.svg';
import PopleLogo02 from '../styles/icon/pople_logo_02.svg';
import PopleLogo03 from '../styles/icon/pople_logo_03.svg';
import PopleLogo04 from '../styles/icon/pople_logo_04.svg';
import PopleLogo05 from '../styles/icon/pople_logo_05.svg';
import PopleLogo06 from '../styles/icon/pople_logo_06.svg';
import PopleLogo07 from '../styles/icon/pople_logo_07.svg';
import PopleLogo08 from '../styles/icon/pople_logo_08.svg';


/* 팝플이란 */
export const POPLE_MEANING = '팝플의미';
export const POPLE_MEANING_DESC_01 = '팝플(POPLE)은\nPromise for Our Planet의\n약자로';
export const POPLE_MEANING_DESC_span = "'우리의 지구를 위한 약속'";
export const POPLE_MEANING_DESC_02 = '\n이라는 의미를 지니고 있습니다.';

export const BRAND_KEYWORD = '브랜드 키워드';
export const BRAND_KEYWORD_GRAPH = BrandKeyword;
export const BRAND_KEYWORD_GRAPH_EN = BrandKeywordEn;
export const BRAND_KEYWORD_BG = BrandKeywordBg;
export const BRAND_KEYWORD_MOBILE = BrandKeywordMobile;

export const BRAND_LOGO_STORY = '로고 스토리';
export const BRAND_LOGO_STORY_DESC =
  '글자들이 서로 유기적으로 이어진 팝플의 로고는\n우리가 함께 기후위기 대응에 행동하면서\n세상을 변화시키겠다는 의지를 담고있습니다.';
export const BRAND_LOGO_STORY_IMAGE = PopleLogoImages;
export const BRAND_COLOR_MOTIF = '컬러 모티프';
export const BRAND_COLOR_MOTIF_DESC =
  '팝플 로고의 보라색(purple)은 회복을 의미합니다.\n지속가능한 지구를 위해 우리의 책임 있는 행동들이 모이면\n탄소중립(NET-ZERO)은 실현 가능합니다.';
export const BRAND_COLOR_MOTIF_IMAGE = BrandColorMotif;
export const BRAND_COLOR_MOTIF_IMAGE_EN = BrandColorMotifEn;
export const BRAND_COLOR_SYSTEM = '컬러 시스템';
export const BRAND_COLOR_SYSTEM_LIST = [
  { name: 'POPLE Purple', hex: 'HEX: #5D38E5', rgb: 'RGB: R93 G56 B229', cmyk: 'CMYK: C79 M80 Y0 K0' },
  { name: 'Variation 1', hex: 'HEX: #DED6FF', rgb: 'RGB: R222 G214 B255', cmyk: 'CMYK: C16 M18 Y0 K0' },
  { name: 'Variation 2', hex: 'HEX: #FAF9FF', rgb: 'RGB: R250 G249 B255', cmyk: 'CMYK: C2 M2 Y0 K5' },
];
export const BRAND_LOGO_GUIDE = '로고 사용 가이드';
export const BRAND_LOGO_GUIDE_DESC =
  '팝플 로고는 팝플의 정체성을 보여주는 디자인입니다.\n로고는 사전 동의 후  규정에 따라 사용 가능하며,\n로고의 변경은 아래 로고들에서 비율에 맞게 크기 조정만 가능합니다.';
export const BRAND_LOGO_GUIDE_BUTTON = '로고 사용 문의하기';
export const BRAND_LOGO_GUIDE_LIST = [
  {
    title: 'LOGO_TYPE_DEFAULT',
    desc: 'LOGO_TYPE_DEFAULT_DESCRIPTION',
    icon: [PopleLogo01],
    mobile: true,
  },
  {
    title: 'LOGO_TYPE_SLOGAN_MIX',
    desc: 'LOGO_TYPE_SLOGAN_MIX_DESCRIPTION',
    icon: [PopleLogo02, PopleLogo03],
    mobile: true,
  },
  {
    title: 'LOGO_TYPE_SOURCE',
    desc: 'LOGO_TYPE_SOURCE_DESCRIPTION',
    icon: [PopleLogo04, PopleLogo05, PopleLogo06],
  },
  {
    title: 'LOGO_TYPE_ETC',
    desc: 'LOGO_TYPE_ETC_DESCRIPTION',
    icon: [PopleLogo07, PopleLogo08],
    icon_desc: [
      { key: 'LOGO_SYMBOL', value: 'LOGO_SYMBOL_DESCRIPTION' },
      { key: 'LOGO_CERT', value: 'LOGO_CERT_DESCRIPTION' },
    ],
    mobile: true,
  },
];

export const BELIEF = '브랜드 빌리프';
export const BELIEF_DESC_01 = '폭염, 잦은 산불과 폭우 등 기후변화로 인한 현상이\n우리에게 가까이 다가와있습니다.';
export const BELIEF_DESC_02 = '지속가능한 지구를 위한 1.5도의 약속.\n지금이 바로 책임있는 행동을 할 마지막 기회입니다.';
export const BELIEF_DESC_03 = '팝플은 작은 행동들이 모여 세상을 변화시킬 수 있다고 생각합니다.';
export const BELIEF_DESC_04 = '우리가 함께 탄소를 감축하고 제거하고';
export const BELIEF_DESC_05 = '발생하는 탄소를 상쇄';
export const BELIEF_DESC_06 = '한다면 탄소 중립, 어렵지 않습니다.';
export const BELIEF_DESC_07 = '그 책임은 무거워도 작은 행동을 즐기면서 한다면';
export const BELIEF_DESC_08 = '탄소 중립 시대는 앞당겨지고 1.5도의 약속은 지킬 수 있습니다.';
export const BELIEF_SPAN_01 = '(Reduce & Remove)';
export const BELIEF_SPAN_02 = '(Offset)';
export const BELIEF_SPAN_03 = '(Enjoy)';
export const MISSION = '브랜드 미션';
export const MISSION_DESC =
  '팝플은 책임있는 행동을 하는 모두를 위한 플랫폼입니다.\n팝플의 진보적이고 혁신적인 기술로\n우리의 영향력을 검증하고, 극대화하는데 기여합니다.';
export const VISION = '브랜드 비전';
export const VISION_PHRASE = 'We create Net-Zero Culture';
export const VISION_DESC = `우리는 지속가능한 지구를 위해 행동하는\n개인, 기업들과 새로운 탄소 중립 문화를 만듭니다.`;
export const OUR_VALUE = '우리의 가치';
export const OUR_VALUE_DICT = [
  {
    title: 'Pioneering',
    desc: 'OUR_VALUE_PIONEERING_DESCRIPTION',
    image: Pioneering,
  },
  { title: 'Rigorous', desc: 'OUR_VALUE_RIGOROUS_DESCRIPTION', image: Rigorous },
  {
    title: 'Transparent',
    desc: 'OUR_VALUE_TRANSPARENT_DESCRIPTION',
    image: Transparent,
  },
  {
    title: 'Innovative',
    desc: 'OUR_VALUE_INNOVATIVE_DESCRIPTION',
    image: Light,
  },
];
export const KEYWORD = '브랜드 키워드';

/* 브랜드 아이덴티티 */

/* 사업소개 */
export const BIZ_PHRASE = '함께하면 불가능한 일도 가능합니다.';
export const BIZ_PHRASE_DESC =
  '탄소 중립, 멀리 있지 않습니다.\n발생량을 감축하고 제거하고 상쇄하면\n탄소 중립은 곧 현실이 됩니다.\n팝플은 이 모든 과정을 함께합니다.';
export const BIZ_ABOUT_NETZERO = '탄소 중립이란';

export const BIZ_SERVICE_LIST = [
  {
    type: 'out',
    icon: IconRegistry,
    title: 'BIZ_SERVICE_REGISTRY',
    desc: 'BIZ_SERVICE_REGISTRY_DESCRIPTION',
    path: 'https://www.pople.credit/',
  },
  {
    type: 'in',
    icon: IconConsulting,
    title: 'BIZ_SERVICE_CONSULTING',
    desc: 'BIZ_SERVICE_CONSULTING_DESCRIPTION',
    path: '/solution',
  },
  {
    type: 'in',
    icon: IconMarkeplace,
    title: 'BIZ_SERVICE_MARKETPLACE',
    desc: 'BIZ_SERVICE_MARKETPLACE_DESCRIPTION',
    path: '/marketplace/list',
  },
];

export const BIZ_CATEGORY = [
  { key: 'Removal', value: '탄소제거' },
  { key: 'Reduction', value: '탄소감축' },
];

export const BIZ_ICON_LIST = [
  { key: 'Forestry', name: 'FORESTRY', icon: Forest },
  { key: 'BioChar', name: 'BIOCHAR', icon: Biochar },
  { key: 'Soil', name: 'SOIL', icon: Soil },
  { key: 'DAC', name: 'DAC', icon: Dac },
  { key: 'RenewableEnergy', name: 'RENEWABLEENERGY', icon: Renewable },
  { key: 'EMobility', name: 'EMOBILITY', icon: Emobility },
  { key: 'Recycle', name: 'RECYCLE', icon: Recycle },
  { key: 'ReplaceElement', name: 'REPLACEELEMENT', icon: ReplaceElement },
  { key: 'EnergyConversion', name: 'ENERGYCONVERSION', icon: ChangeEnergy },
  { key: 'Chemical', name: 'CHEMICAL', icon: Chemical },
  { key: 'Agriculture', name: 'AGRICULTURE', icon: Agriculture },
  { key: 'CCS', name: 'CCS', icon: Ccs },
  { key: 'Service', name: 'SERVICE', icon: Ccs },
  { key: 'Etc', name: 'ETC', icon: Ccs },
];

export const subAreaIcon = (subArea) => {
  const el = BIZ_ICON_LIST.find((el) => el.key === subArea);
  return el && el.icon;
};
