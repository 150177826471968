import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalCertification } from '../../components';
import { useHistory, useParams } from 'react-router-dom';
import { UserContext, SnackbarContext, DialogContext, ContentContext } from '../../contexts';
import Handler from '../../Handler';
import API from '../../api';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const certification_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  borderRadius: 0,
};

const m_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  boxSizing: 'border-box',
};

export const ForwardPurchaseDetailPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { userInfo, isPersonal } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [data, setData] = useState(null);
  const { slug } = useParams();
  const [calculateList, setCalculateList] = useState(null);
  const { deviceWidth } = useContext(ContentContext);

  const [certificationOpen, setCertificationOpen] = useState(false);
  const [certificationData, setCertificationData] = useState(false);
  useEffect(() => {
    if (slug) {
      refreshData();
    }
  }, [slug]);

  const refreshData = () => {
    API.User.GetForwardPaymentDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('refreshData result', result);
        setData(result);
        setCalculateList(result.calculate);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const renderBuildType = (data) => {
    if (data.type === 'VBANK') {
      return (
        <div className='vank__box'>
          <span style={{ color: '#f41515', fontSize: 14, fontWeight: 'bold' }}>가상계좌</span>
          <div className='detail__list'>
            <div className='detail__item'>
              <header>- 은행명 :</header>
              <span>{data.vbankName}</span>
            </div>
            <div className='detail__item'>
              <header>- 예금주 :</header>
              <span>{data.vbankHolder}</span>
            </div>
            <div className='detail__item'>
              <header>- 계좌번호 :</header>
              <span>{data.vbankNum}</span>
            </div>
          </div>
        </div>
      );
    } else {
      return Handler.buildPaymentTypeText(data.type);
    }
  };

  console.log('calculateList', calculateList);
  if (!data || !userInfo) {
    return null;
  }

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header className='custom'>
            <h1>선도구매 상세</h1>
            <section className='breadcrumb__box'>
              <ul>
                <li
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  Home
                </li>
                <li>마이페이지</li>
                <li
                  onClick={() => {
                    history.push('/mypage/forward_purchase_list');
                  }}
                >
                  선도구매 목록
                </li>
                <li className='active'>선도구매 상세</li>
              </ul>
            </section>
          </header>
        </section>
        <section className='forward-purchase-detail__container'>
          <section className='order__box'>
            <header>
              <span>주문정보</span>
            </header>
            <div className='table__box'>
              <ul className='header'>
                <li>주문번호</li>
                <li>주문내역</li>
              </ul>
              <div className='m-order-number__box'>
                <div className='header'>주문번호</div>
                <div className='value'>{data.orderNumber}</div>
              </div>
              <ul className='data'>
                <li>{data.orderNumber}</li>
                <li>
                  <div className='img__box'>
                    <img
                      src={
                        data.project.mainImages && data.project.mainImages.length > 0
                          ? data.project.mainImages[0]
                          : null
                      }
                      alt='project-main'
                    />
                  </div>
                  <div className='title__box'>
                    <span className='name'>{data.project.title}</span>
                    <span className='id'>{data.project.id}</span>
                    <div className='price'>
                      <span>{Handler.numberWithCommas(Math.floor(data.price / data.amount))}원</span>
                      <span>·</span>
                      <span>{Handler.numberWithCommas(data.amount)}VRC</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
          <section className='payment__box'>
            <header>
              <span>결제정보</span>
              <div className='status'>{t(Handler.buildPaymentStatusText(data.status))}</div>
            </header>
            <div className='table__box'>
              <ul className='header'>
                <li>결제일시</li>
                <li className={data.type}>결제수단</li>
                <li>판매 금액</li>
                <li>결제금액</li>
                <li>영수증</li>
              </ul>
              <ul className='data'>
                <li>{Handler.getYYYYMMDDHHMMByUnix(data.createdAt)}</li>
                <li className={data.type}>{renderBuildType(data)}</li>
                <li>{Handler.numberWithCommas(data.price)}원</li>
                <li>{Handler.numberWithCommas(data.price)}원</li>
                <li>
                  {data.receiptUrl && (
                    <div
                      className='button'
                      onClick={() => {
                        window.open(data.receiptUrl, '_blank');
                      }}
                    >
                      영수증 보기
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </section>
          {/* <section className='m-receipt__box'>
            <div className='button'>영수증 보기</div>
          </section> */}
          <section className='buyer__box'>
            <header>
              <span>구매자정보</span>
            </header>
            <div className='table__box'>
              <ul className='header'>
                <li>이름</li>
                <li>구매 사유</li>
                <li>연락처</li>
              </ul>
              <ul className='data'>
                <li>{data.user.name}</li>
                <li>{data.reason}</li>
                <li>{data.user.phone}</li>
              </ul>
            </div>
          </section>
          {isPersonal && (
            <section className='calculate__box'>
              <header>
                <span>수익정산</span>
                <span className='desc'>
                  {`구매한 사업에서 크레딧(톤)이 판매되면 결제 순서에 따라 정산됩니다. 정산된 금액은 '마이페이지 > 투자/출금'에서 출금 요청 가능합니다.`}
                </span>
              </header>
              <div className='table__box'>
                <ul className='header'>
                  <li>판매 날짜</li>
                  <li>정산상태</li>
                  <li>정산계좌</li>
                </ul>
                {calculateList && calculateList.length === 0 && <span className='empty__box'>데이터가 없습니다.</span>}
                {calculateList && calculateList.length > 0 && (
                  <div className='data__wrapper'>
                    <ul className='data'>
                      {calculateList.map((item, index) => {
                        return (
                          <li>
                            <div className='date'>
                              <span>{Handler.getYYYYMMDDByUnix(item.createdAt)}</span>
                              <span className='ton'>{Handler.numberWithCommas(item.amount)}톤 판매</span>
                            </div>
                            <div className='status'>
                              <b>정산완료</b>
                              <span>{Handler.getYYYYMMDDByUnix(item.createdAt)}</span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    <div className='button__box'>
                      <div
                        className='button'
                        onClick={() => {
                          history.push('/mypage/modify');
                        }}
                      >
                        정산계좌 확인
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='m-table__box'>
                {calculateList && calculateList.length === 0 && <span className='empty__box'>데이터가 없습니다.</span>}
                {calculateList && calculateList.length > 0 && (
                  <ul className='data'>
                    {calculateList.map((item, index) => {
                      return (
                        <li>
                          <div className='date'>
                            <header>판매 날짜</header>
                            <div className='value'>
                              <span>{Handler.getYYYYMMDDByUnix(item.createdAt)}</span>
                              <span className='ton'>{Handler.numberWithCommas(item.amount)}톤 판매</span>
                            </div>
                          </div>
                          <div className='complete'>
                            <header>정산 상태</header>
                            <div className='value'>
                              <b>정산완료</b>
                              <span className='ton'>{Handler.getYYYYMMDDByUnix(item.createdAt)}</span>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              <div className='m-account__box'>
                <div
                  className='button'
                  onClick={() => {
                    history.push('/mypage/modify');
                  }}
                >
                  정산계좌 확인
                </div>
              </div>
            </section>
          )}
          {userInfo.userType !== 'Personal' && (
            <section className='calculate__box company'>
              <header>
                <span>정산 결과</span>
                <span className='desc'>구매한 사업에서 크레딧이 발행되면 결제 순서에 따라 자동으로 상쇄됩니다.</span>
              </header>
              <div className='summary__box'>
                <ul className='header'>
                  <li>상쇄 완료</li>
                  <li>상쇄 대기</li>
                </ul>
                <ul className='data'>
                  <li>{Handler.numberWithCommas(data.forwardComplete)}VRC</li>
                  <li>{Handler.numberWithCommas(data.forwardReady)}VRC</li>
                </ul>
              </div>
              <div className='table__box'>
                <ul className='header'>
                  <li>상쇄일자</li>
                  <li>크레딧 발행 수</li>
                  <li>상쇄 인증서</li>
                </ul>
                {calculateList && calculateList.length === 0 && <span className='empty__box'>데이터가 없습니다.</span>}
                {calculateList && calculateList.length > 0 && (
                  <div className='data__wrapper'>
                    <ul className='data'>
                      {calculateList.map((item, index) => {
                        return (
                          <li>
                            <div className='date'>
                              <span>{Handler.getYYYYMMDDByUnix(item.createdAt)}</span>
                            </div>
                            <div className='amount'>
                              <span>{Handler.numberWithCommas(item.amount)}VRC</span>
                            </div>
                            <div className='cert'>
                              <div
                                className='button'
                                onClick={() => {
                                  setCertificationData(item.cert);
                                  setCertificationOpen(true);
                                }}
                              >
                                상쇄 인증서
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
              <div className='m-table__box company'>
                {calculateList && calculateList.length === 0 && <span className='empty__box'>데이터가 없습니다.</span>}
                {calculateList && calculateList.length > 0 && (
                  <ul className='data'>
                    {calculateList.map((item, index) => {
                      return (
                        <li>
                          <div className='complete'>
                            <header>상쇄일자</header>
                            <div className='value'>
                              <span>{Handler.getYYYYMMDDByUnix(item.createdAt)}</span>
                            </div>
                          </div>
                          <div className='complete'>
                            <header>크레딧 발행 수</header>
                            <div className='value'>
                              <span>{Handler.numberWithCommas(item.amount)}VRC</span>
                            </div>
                          </div>

                          <div className='complete'>
                            <header>상쇄 인증서</header>
                            <div className='value'>
                              <div
                                className='button'
                                onClick={() => {
                                  setCertificationData(item.cert);
                                  setCertificationOpen(true);
                                }}
                              >
                                상쇄 인증서
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </section>
          )}
          <section className='button-list__box'>
            <div
              className='button'
              onClick={() => {
                history.push('/mypage/forward_purchase_list');
              }}
            >
              목록
            </div>
          </section>
        </section>
      </article>
      <Modal
        open={certificationOpen}
        onClose={() => {
          setCertificationOpen(false);
        }}
      >
        <Box sx={deviceWidth > 1136 ? certification_style : m_style}>
          <ModalCertification
            data={certificationData}
            onClose={() => {
              setCertificationOpen(false);
            }}
          ></ModalCertification>
        </Box>
      </Modal>
    </article>
  );
};
