import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'market/news';

const GetList = (params) => {
  const url = BASE_URL + PATH + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetDetail = (slug) => {
  const url = BASE_URL + PATH + `/${slug}`;
  return AxiosInstance.get(url);
};

const News = {
  GetList: GetList,
  GetDetail: GetDetail,
};

export default News;
