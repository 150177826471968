import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button as MuiButton } from '@mui/material';
import { ButtonFooter, MetaTag, LazyLoadImage } from '../../components';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ContentContext } from '../../contexts';
import { color, font, fontSize, media } from '../../styles/style';
import styled from '@emotion/styled';
import { Earth, Graph, GuideLine, Reduction, Partners } from './components';
import { MAIN_IMAGES } from '../../constants/main.constants';

const MainContainer = styled.section`
  width: 100%;
  height: 100%;
  min-height: 780px;
  position: relative;
  display: flex !important;
  justify-content: center;
  @media only screen and (max-width: ${media.$lg}) {
    height: 100vh;
  }
  .slider-container {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    .slick-track {
      display: flex;
    }
    .slick-slide > div {
      height: 100%;
      min-height: 780px;
      position: relative;
      display: flex !important;
      justify-content: center;
      position: relative;
      @media only screen and (max-width: ${media.$lg}) {
        height: 100vh;
      }
    }
  }

  .title-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: ${media.$wrapper_max_width};
    min-width: ${media.$wrapper_min_width};
    /* padding: 0 10vw; */
    z-index: 1;
    h1.page-title {
      font-size: ${fontSize(font.$text_5xl)};
      font-weight: ${font.bold};
      text-align: left;
      color: ${color.$white};
      white-space: pre-wrap;
      text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
    }
    p.page-title-desc {
      font-size: ${fontSize(font.$text_xl)};
      font-weight: ${font.light};
      color: ${color.$white};
      text-align: left;
      padding: 60px 0;
      text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
    }
    .main-logo-white {
      margin-top: 20px;
    }
    .test {
      display: flex;
      border: 2px solid #fff;
      margin-top: 30px;
      padding: 10px;
      article {
        margin-right: 20px;
      }
    }
    @media only screen and (max-width: ${media.$xl}) {
      padding: 24px;
      min-width: unset;
      max-width: unset;
      align-items: center;
      h1.page-title,
      p.page-title-desc {
        text-align: center;
        word-break: keep-all;
      }
    }
  }
`;

export const HomePage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });
  useEffect(() => {
    setMetaObject({
      title: t('MAIN_PAGE_TITLE'),
      description: t('MAIN_PAGE_DESCRIPTION'),
      keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
      imgsrc: '',
      canonical: '',
      url: window.location.href,
    });
  }, []);
  const earth_settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 2000,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const IMAGE_SLIDES = MAIN_IMAGES;

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='home-page'>
        <MainContainer>
          <div className='slider-container'>
            <Slider {...earth_settings}>
              {IMAGE_SLIDES &&
                IMAGE_SLIDES.map((el, idx) => {
                  return <LazyLoadImage key={`image+${idx}`} image={el.image} preview={el.preview} />;
                })}
            </Slider>
          </div>
          <div className='title-box'>
            <h1 className='page-title' data-aos='fade-up'>
              {t('MAIN_PAGE_TITLE')}
            </h1>
            <div className='ico main-logo-white' data-aos='fade-up' data-aos-delay='100'></div>
            <p className='page-title-desc' data-aos='fade-up' data-aos-delay='130'>
              {t('MAIN_PAGE_DESCRIPTION')}
            </p>
            <div data-aos='fade-up' data-aos-delay='120'>
              <MuiButton
                radius={25}
                style={{ width: 200, height: 50, fontWeight: 800, borderRadius: '25px', fontFamily: 'Pretendard' }}
                className='font-bold text-md'
                variant='contained'
                size='large'
                onClick={() => history.push('/marketplace/list')}
              >
                {t('BUY_CREDITS')}
              </MuiButton>
            </div>
          </div>
        </MainContainer>
        <Earth />
        <Graph />
        <GuideLine />
        <Reduction />
        <Partners />
        <ButtonFooter />
      </article>
    </>
  );
};
