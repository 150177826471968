import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DaumPostcode from 'react-daum-postcode';
import Modal from '@mui/material/Modal';
import { Button } from '../../components';
import Box from '@mui/material/Box';
import _ from 'lodash';
import Handler from '../../Handler';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 2,
};

export const Address = (props) => {
  const { value, addressDetail, zonecode, onChange, meta } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleComplete = (daum_data) => {
    let new_value = _.clone(value);
    new_value.daum = daum_data;
    onChange(new_value, meta.key);
    setOpen(false);
  };

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span>*</span>;
      }
    }
  };

  return (
    <article
      className={Handler.BuildArticleClassname('form-address', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>{meta.label}</span>
          {renderRequired()}
        </header>
      )}
      <section className='address-value__box'>
        {meta && meta.type === 'content' && (
          <Button
            disabled={meta.disabled}
            style={{ width: '96px', height: '50px', marginRight: 8 }}
            onClick={() => {
              if (!meta.readOnly) {
                setOpen(true);
              }
            }}
            label={t('SEARCH')}
            type='secondary-square'
          ></Button>
        )}

        <div className='zonecode'>
          <input readOnly value={value.daum ? value.daum.zonecode : ''}></input>
          {meta && meta.type === 'mypage' && (
            <Button
              disabled={meta.disabled}
              style={{ width: '80px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                setOpen(true);
              }}
              label={t('SEARCH')}
              type='info-square'
            ></Button>
          )}
        </div>
        <div className='address'>
          <input readOnly value={value.daum ? value.daum.roadAddress : ''}></input>
        </div>
        <div className='detail'>
          <input
            disabled={meta.disabled}
            value={value.detail}
            placeholder={t('ADDRESS_DETAIL')}
            onChange={(e) => {
              let new_value = _.clone(value);
              new_value.detail = e.target.value;
              onChange(new_value, meta.key);
            }}
          ></input>
        </div>
      </section>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={style}>
          <DaumPostcode onComplete={handleComplete} />
        </Box>
      </Modal>
    </article>
  );
};
