import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Breadcrumbs, Button, TablePaging, BoardAccordion, TextInput, MetaTag } from '../../components';
import { usePagination } from '../../hooks';
import { LangContext, SnackbarContext } from '../../contexts';
import { FAQ_BOX_BG, FAQ_PHONE } from '../../constants';
import Handler from '../../Handler';
import API from '../../api';
import { color, media } from '../../styles/style';

const FaqContactWrapper = styled.section`
  background-image: url(${FAQ_BOX_BG});
  background-size: cover;
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    font-weight: 800;
    font-size: 48px;
    color: #fff;
  }
  .contact-box {
    background-color: rgba(295, 295, 295, 0.95);
    border-radius: 15px;
    padding: 70px 0;
    margin-top: 40px;
    width: 100%;
    max-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    div.box {
      flex: 1 1;
      max-width: 100%;
      &:first-of-type {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        p {
          font-weight: 600;
          font-size: 28px;
        }
      }
      &:last-of-type {
        .contact-item {
          &:first-of-type {
            padding-bottom: 40px;
          }
          .label {
            font-weight: 800;
            &:before {
              content: '•';
              padding-right: 10px;
            }
          }
          .value {
            font-size: 24px;
          }
          .button {
            margin-top: 20px;
            padding: 15px;
            text-align: center;
            color: #fff;
            font-weight: 600;
            border-radius: 25px;
            background-color: ${color.$main500};
            max-width: 160px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 24px;
    h2 {
      font-size: 24px;
    }
    .contact-box {
      padding: 40px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      div.box {
        &:first-of-type {
          padding: 20px;
          p {
            font-size: 24px;
          }
        }
        .contact-item {
          .button {
            margin: auto;
          }
        }
      }
    }
  }
`;

export const CsFaqPage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 10);
  const [search, setSearch] = useState('');
  const [categoryList, setCategoryList] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('전체');
  const [reload, setReload] = useState(true);
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });
  useEffect(() => {
    setMetaObject({
      title: t('FAQ_LONG'),
      description: t('FAQ_LONG'),
      keywords: '팝플이란, 팝플',
      image: '',
      canonical: '',
      url: window.location.href,
    });
  }, []);

  useEffect(() => {
    if (!isKorean) history.go(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isKorean]);

  useEffect(() => {
    API.CS.GetFaqInfo().then(
      (res) => {
        let result = res.data;
        console.log('GetFaqInfo result', result);
        let target_list = [];
        for (let i in result) {
          let target = result[i];
          if (target.site === 'Market') {
            target_list.push(target);
          }
        }
        setCategoryList([
          {
            category: '전체',
          },
          ...target_list,
        ]);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  }, []);

  useEffect(() => {
    if (page) {
      requestNewList();
    }
  }, [page]);

  useEffect(() => {
    requestNewList(0);
  }, [selectedCategory]);

  const requsetSearch = () => {
    requestNewList(0);
  };

  const requestNewList = (init_page) => {
    setReload(true);

    let body = {
      page: init_page === 0 ? init_page : page,
      limit: Handler.VARIABLES.PAGE_LIMIT,
      searchType: 'Title',
      searchValue: search,
    };

    if (selectedCategory !== '전체') {
      body.category = selectedCategory;
    }
    API.CS.GetFaqList(body)
      .then((res) => {
        let result = res.data;
        console.log('result', result);
        setList(result.content);
        setTotalPage(result.totalPages);
        setReload(false);
      })
      .catch((err) => {
        setReload(false);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  return (
    <>
      <MetaTag props={metaObject} />
      <article className='basic-page'>
        <FaqContactWrapper>
          <h2>{t('FAQ_LONG')}</h2>
          <div className='contact-box'>
            <div className='box'>
              <p>{t('FAQ_LONG_CONTACT')}</p>
            </div>
            <div className='box'>
              <div className='contact-item'>
                <p className='label'>{t('CONTACT_PHONE')}</p>
                <p className='value'>{FAQ_PHONE.value}</p>
                <p className='desc'>{t('CONTACT_PHONE_TIME')}</p>
              </div>
              <div className='contact-item'>
                <p className='label'>{t('CONTACT_EMAIL')}</p>
                <div className='button'>
                  <a href='mailto:info@pople.earth'>{t('CONTACT_EMAIL_LABEL')}</a>
                </div>
              </div>
            </div>
          </div>
        </FaqContactWrapper>
        <article className='content__wrapper'>
          <section className='headding-text__container'>
            <header>
              <h1>FAQ</h1>
            </header>
            <div className='right__box'>
              <Breadcrumbs type={'small'}></Breadcrumbs>
            </div>
          </section>
          <section className='m-headding-text__container'>
            <header>
              <h2>FAQ</h2>
            </header>
          </section>
          <section className='faq__container'>
            <div className='option__box'>
              <section className='serach__box'>
                <TextInput
                  value={search}
                  onChange={(value, key) => {
                    setSearch(value);
                  }}
                  onEnter={() => {
                    requsetSearch();
                  }}
                  meta={{
                    style: { width: '446px' },
                    placeholder: t('KEYWORD'),
                    className: 'round',
                  }}
                ></TextInput>
                <Button
                  style={{ width: '80px', height: '50px', marginLeft: 12 }}
                  onClick={() => {
                    requsetSearch();
                  }}
                  label={t('SEARCH')}
                ></Button>
              </section>
              <section className='category__box'>
                <ul>
                  {categoryList &&
                    categoryList.map((item, index) => {
                      return (
                        <li
                          className={item.category === selectedCategory ? 'on' : ''}
                          onClick={() => {
                            setSelectedCategory(item.category);
                          }}
                        >
                          <span>{item.category}</span>
                        </li>
                      );
                    })}
                </ul>
              </section>
            </div>
            <div className='board__box'>{!reload && <BoardAccordion data={list} type={'faq'}></BoardAccordion>}</div>
          </section>
          <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
        </article>
      </article>
    </>
  );
};
