import React from 'react';
import { Card } from '../../../styles/ComponentStyle';
import Handler from '../../../Handler';
import styled from '@emotion/styled';

const ChartTitleHeader = styled.section`
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  div.col {
    width: 100%;
    &.button-group {
      display: flex;
      justify-content: start;
    }
  }
`

const ChartTitleWrapper = styled(Card)`
  text-align: left;
  padding: 20px;
  margin-bottom: 10px;
  .price-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 8px;
  }
  .price {
    display: flex;
    align-items: flex-end;
    h3 {
      font-size: 30px;
      span {
        font-size: inherit;
      }
    }
    .unit {
      font-size: 12px;
      padding: 4px 4px 4px 8px;
    }
    .up-down {
      font-size: 14px;
      padding: 4px 8px;
      .up {
        color: #ff5045;
        font-weight: bold;
      }
      .down {
        color: #4579ff;
        font-weight: bold;
      }
    }
  }
`;

export const ChartTitle = (props) => {
  const {
    // name = 'KAU',
    date = '2022-01-01',
    price = 30000,
    head = false,
    change = 0,
    rate = 0,
    currency = '₩',
    unit = '',
    children
  } = props;

  return (
    <ChartTitleHeader>
    {children && <div  className='col button-group'>{children}</div>}
    <ChartTitleWrapper className='col'>
      <div className='date divider'>{date}</div>
      <div className='price-wrapper'>
        <div className='price'>
          <h3 className='font-bold'>
            {head && <span>{Handler.curDict[currency]}</span>}
            {Handler.numberWithCommas(price)}
            {!head && <span>{Handler.curDict[currency]}</span>}
          </h3>
          {unit && <div className="unit">
            / {unit}
          </div>}
          <div className='up-down'>
            {change > 0 ? <div className='up'>▲{Handler.numberWithCommas(change)}</div> : <div className='down'>▼{Handler.numberWithCommas(change)}</div>}
          </div>
        </div>
        <div className={rate < 0 ? 'percent down' : 'percent up'}>{rate > 0 ? '+' : ''}{rate}%</div>
      </div>
    </ChartTitleWrapper>
    </ChartTitleHeader>
  );
};

export default ChartTitle;
