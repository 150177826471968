import React, { useContext, useState } from 'react';
import { TextInput, Button} from '../../components';
import { UserContext, SnackbarContext } from '../../contexts';
import API from '../../api';

export const ModalAccount = (props) => {
  const { project, type, onConfirm, onClose } = props;
  const { userInfo, refreshUserInfo } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [bank, setBank] = useState('');
  const [account, setAccount] = useState('');
  const [name, setName] = useState('');

  const requsetSave = () => {
    console.log('requsetSave');
    if (bank && account && name) {
      API.User.PutAccount({
        bank: bank,
        account: account,
        name: name,
      }).then(
        (res) => {
          let result = res.data;
          console.log('result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '추가정보가 저장되었습니다.',
          });
          onClose();
          refreshUserInfo();
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인해주세요.',
      });
    }
  };

  return (
    <article className='account-modal'>
      <header>
        <div className='ico logo-small'></div>
        <h4>추가정보</h4>
        <div
          className='ico close'
          onClick={() => {
            onClose();
          }}
        ></div>
      </header>
      <section className='form__list'>
        <TextInput
          value={bank}
          onChange={(value) => {
            console.log('value', value);
            setBank(value);
          }}
          meta={{
            key: 'bank',
            label: '은행명',
            style: {},
            className: 'point',
            required: true,
          }}
        ></TextInput>
        <TextInput
          value={account}
          onChange={(value) => {
            setAccount(value);
          }}
          meta={{
            key: 'account',
            label: '계좌번호',
            placeholder: `계좌번호 '-' 없이 숫자만 입력`,
            style: {},
            className: 'point',
            required: true,
          }}
        ></TextInput>
        <TextInput
          value={name}
          onChange={(value) => {
            setName(value);
          }}
          meta={{
            key: 'name',
            label: '예금주',
            style: {},
            className: 'point',
            required: true,
          }}
        ></TextInput>
      </section>
      <section className='button__box'>
        <div className='button__item'>
          <Button onClick={onClose} type='info' style={{ width: '100%', height: '50px' }} label='취소'></Button>
        </div>
        <div className='button__item'>
          <Button onClick={requsetSave} style={{ width: '100%', height: '50px' }} label='저장'></Button>
        </div>
      </section>
    </article>
  );
};
