import React from 'react';
import {
  CHART_ICON
} from '../../../constants';
import { Card } from '../../../styles/ComponentStyle';
import Handler from '../../../Handler';
import ChartCardSkeleton from './ChartCardSkeleton';
import styled from '@emotion/styled';
import { media } from '../../../styles/style';

const ChartInfo = styled(Card)`
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .date {
    color: #888;
    font-size: 14px;
  }
  .price > h3 {
    font-size: 28px;
    span {
      font-size: inherit;
    }
  }
  @media only screen and (max-width: ${media.$lg}) {
    &:first-of-type {
      margin-top: 20px;
    }
  }
`;

export const ChartCard = (props) => {
  const { config, price, loading } = props;

  return (
    <ChartInfo>
      {loading || !config ? (
        <ChartCardSkeleton />
      ) : (
        <>
          <div className='price-wrapper'>
            <div className='card-header'>
              <div className='title'>
                <h3>{config.chart_name}</h3>
                <div className='date'>{config.date}</div>
              </div>
              <div className='icon'>
                <img src={CHART_ICON(config.chart_name)} alt='' />
              </div>
            </div>
          </div>
          <div>
            <div className={config.change_rate < 0 ? 'percent down' : 'percent up'}>
              {config.change_rate > 0 ? '+' : ''}
              {config.change_rate}%
            </div>
            <div className='price'>
              <h3 className='font-bold'>
                {config.currency !== 'KRW' && <span>{Handler.curDict[config.currency]}</span>}
                {Handler.numberWithCommas(price)}
                {config.currency === 'KRW' && <span>{Handler.curDict[config.currency]}</span>}
              </h3>
            </div>
          </div>
        </>
      )}
    </ChartInfo>
  );
};

export default ChartCard;
