import React, { useContext } from 'react';
import i18n, { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts';

const URL_MAPPING = [
  {
    path: '/',
    text: 'Home',
    active: true,
  },
  {
    path: '/mypage',
    text: 'MYPAGE',
    active: false,
  },
  {
    path: '/mypage/inquiry_list',
    text: '1:1 상담 내역',
    active: true,
  },
  {
    path: '/mypage/inquiry_detail',
    text: '1:1 상담 내역',
    active: false,
  },
  {
    path: '/mypage/inquiry_add',
    text: '1:1 상담 문의',
    active: false,
  },
  {
    path: '/mypage/inquiry_modify',
    text: '1:1 상담 문의 수정',
    active: false,
  },
  {
    path: '/mypage/modify',
    text: 'MODIFY',
    active: true,
  },
  {
    path: '/mypage/signout',
    text: '회원탈퇴',
    active: true,
  },
  {
    path: '/mypage/signout_complete',
    text: '회원탈퇴',
    active: true,
  },
  {
    path: '/mypage/purchase_list',
    text: 'PURCHASEMENT_LIST',
    active: true,
  },
  {
    path: '/mypage/forward_purchase_list',
    text: '선도구매 목록',
    active: true,
  },
  {
    path: '/mypage/sell_list',
    text: 'SELL_LIST',
    active: true,
  },
  {
    path: '/mypage/calculate',
    text: '투자/출금',
    active: true,
  },
  {
    path: '/cs',
    text: 'CS_CENTER',
    active: false,
  },
  {
    path: '/cs/contact_us',
    text: 'Contact us',
    active: true,
  },
  {
    path: '/cs/notice',
    text: 'NOTICE',
    active: true,
  },
  {
    path: '/cs/faq',
    text: 'FAQ',
    active: true,
  },
];

const getPathText = (value) => {
  for (let i in URL_MAPPING) {
    let target = URL_MAPPING[i];
    if (value === target.path) {
      return target.text;
    }
  }
  return null;
};

const getActive = (value) => {
  for (let i in URL_MAPPING) {
    let target = URL_MAPPING[i];
    if (value === target.path) {
      return target.active;
    }
  }
  return false;
};

const renderBreadcrumbs = (history, userInfo, t) => {
  let pathArray = history.location.pathname.split('/');
  let URL_LIST = [];
  console.log('pathArray', pathArray);
  for (let i in pathArray) {
    let path_value = '';

    //remove slug용
    if (Number(i) === pathArray.length - 1 && Number.isInteger(Number(pathArray[i]))) {
      break;
    }
    for (let j = 0; j <= i; j++) {
      if (Number(j) === Number(i)) {
        if (Number(j) === 0) {
          path_value += '/';
        } else {
          path_value += pathArray[j];
        }
      } else {
        path_value += pathArray[j] + '/';
      }
    }
    URL_LIST.push({
      path: path_value,
      text: getPathText(path_value),
      active: getActive(path_value),
    });
  }

  return URL_LIST.map((item, index) => {
    const buildClassname = () => {
      if (history.location.pathname === item.path) {
        return 'on';
      }
    };

    const renderText = (item, userInfo) => {
      if (item.text === '투자/출금') {
        if (!userInfo) {
          return '';
        }
        return userInfo.userType !== 'Personal' ? '정산/출금' : item.text;
      }
      return item.text;
    };

    return (
      <li
        key={item.text}
        className={buildClassname()}
        onClick={() => {
          if (item.active) {
            if (item.custom_url) {
              history.push(item.custom_url);
            } else {
              history.push(item.path);
            }
          }
        }}
      >
        <span>{t(renderText(item, userInfo))}</span>
      </li>
    );
  });
};

export const Breadcrumbs = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { title, type, content } = props;
  const { userInfo } = useContext(UserContext);

  if (type && type === 'small') {
    return (
      <article className='form-small-breadcrumbs'>
        <ul>{renderBreadcrumbs(history, userInfo, t)}</ul>
      </article>
    );
  } else {
    return (
      <article className={`form-breadcrumbs ${content || ''}`}>
        <div className='content__wraperr'>
          <section className='breadcrumbs__box'>
            <ul>{renderBreadcrumbs(history, false, t)}</ul>
          </section>
          <section className='title__box'>
            <h1>{title}</h1>
          </section>
        </div>
      </article>
    );
  }
};
