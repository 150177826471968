import React, { useEffect, useContext, useState, useMemo } from 'react';
import { CheckBox, SelectBox, Button, ButtonRadioBox } from '../../components';
import Handler from '../../Handler';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { UserContext, SnackbarContext, ContentContext } from '../../contexts';
import API from '../../api';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import { useLabel } from '../../hooks';

export const ModalPurchase = (props) => {
  const history = useHistory();
  const { VALIDATION, PURCHASE_WAY, PURCHASE_CAUTIOUS, PURCHASE_AGREE, PURCHASE_REASON_LIST, PURCHASE_GIFT } =
    useLabel();
  const { project, type, onConfirm, onClose, onCertification } = props;
  const { userInfo, isPersonal } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [amount, setAmount] = useState(0);
  const [amountView, setAmountView] = useState(0);
  const [reason, setReason] = useState('none');
  const [reasonCustom, setReasonCustom] = useState(null);
  const [calculatedPrice, setCalculatedPrice] = useState(0);
  const [isPresent, setIsPresent] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [payType, setPayType] = useState('');
  const [addr, setAddr] = useState('');
  const [postcode, setPostcode] = useState('');
  const [isAgree1, setIsAgree1] = useState(false);
  const [isAgree2, setIsAgree2] = useState(false);
  const { deviceWidth } = useContext(ContentContext);
  const [merchantUid, setMerchantUid] = useState(null);
  const [paymentOrderId, setPaymentOrderId] = useState(null);
  const [realPrice, setRealPrice] = useState(null);
  const [payMethod, setPayMethod] = useState('card');
  // const { IMP } = window;

  const radioOptions = [
    { group: PURCHASE_WAY['TITLE'], name: PURCHASE_WAY['NORMAL'], id: 'Normal', value: 'Normal' },
    { group: PURCHASE_WAY['TITLE'], name: PURCHASE_WAY['DONATION'], id: 'Donation', value: 'Donation' },
    { group: PURCHASE_WAY['TITLE'], name: PURCHASE_WAY['PRESENT'], id: 'Present', value: 'Present' },
  ];

  console.log('ModalPurchase type', type);
  console.log('ModalPurchase project', project);
  console.log('ModalPurchase userInfo', userInfo);
  console.log('ModalPurchase calculatedPrice', calculatedPrice);

  // userInfo에서 기업 - 선도거래면 30% -> API콜
  // userInfo에서 개인 - 선도거래면 슬라이딩해서 가격 설정 -> 이니시스
  // 일반거래는 모두 동일 -> 이니시스
  useEffect(() => {
    if (amount) {
      setAmountView(Handler.numberWithCommas(amount));
      setCalculatedPrice(project.salePrice * amount);
    } else {
      setAmountView(0);
      setCalculatedPrice(0);
    }
  }, [amount, project]);

  useEffect(() => {
    if (!isPresent) {
      setName('');
      setEmail('');
    }
  }, [isPresent]);

  useEffect(() => {
    setIsPresent(['Present', 'Donation'].includes(payType));
  }, [payType]);

  useEffect(() => {
    if (reason && reason !== '직접입력') {
      setReasonCustom(null);
    }
  }, [reason]);

  useEffect(() => {
    if (userInfo) {
      const address = userInfo.address;
      if (!!address && address.hasOwnProperty('daum')) {
        setAddr(address.daum.address);
        setPostcode(address.daum.zonecode);
      }
      if (!!address && address.hasOwnProperty('addr1')) {
        setAddr(address.addr1.formatted_address + ' ' + address.addr2);
        const post = address.addr1.address_components.find((e) => e.types[0] === 'postal_code');
        setPostcode(post.long_name);
      }
    }
  }, [userInfo]);

  const isForwardEnterprise = useMemo(() => {
    return project.forwardType && project.forwardType === 'ForwardEnterprise';
  }, [project]);

  const onClickPayment = async () => {
    /* 1. 가맹점 식별하기 */
    const { IMP } = window;
    const portOneKey =
      process.env.REACT_APP_ENV === 'production'
        ? process.env.REACT_APP_PORTONE_PROD_KEY
        : process.env.REACT_APP_PORTONE_DEV_KEY;
    IMP.init(portOneKey);

    const valid = validation();
    if (!valid) return;

    let order_body = {
      projectSeq: project.seq,
      amount: amount,
      purchaseType: isPersonal ? 'NormalPersonal' : 'NormalEnterprise',
      paymentType: payMethod,
      reason: reason === '직접입력' || reason === 'Enter directly' ? reasonCustom : reason,
    };

    if (isPresent) {
      order_body.presentType = payType;
      order_body.presentName = name;
      order_body.presentEmail = email;
    }

    await API.Payment.PostOrder({
      ...order_body,
    })
      .then((res) => {
        setPaymentOrderId(res.data.orderInfo.paymentOrder.seq);
        setMerchantUid(res.data.merchantUid);
        setRealPrice(res.data.realPrice);

        /* 세금계산서 발행일 경우 */
        if (payMethod === 'taxbill') {
          const taxbill_body = {
            buyerName: userInfo.name,
            projectName: project.title,
            merchantUid: res.data.merchantUid,
          };
          postTaxbill(taxbill_body);
        } else {
          /* 2. 결제 데이터 정의하기 */
          const data = {
            pg: process.env.REACT_APP_ENV === 'production' ? 'tosspayments' : 'tosspayments.iamporttest_3',
            pay_method: payMethod,
            merchant_uid: res.data.merchantUid,
            name: '탄소 크레딧',
            amount: res.data.realPrice,
            buyer_email: userInfo.email,
            buyer_name: userInfo.name,
            buyer_tel: userInfo.phone,
            buyer_addr: addr,
            buyer_postcode: postcode,
            m_redirect_url: window.location.host + '/marketplace/complete/' + merchantUid + '?type=' + payMethod,
          };
          /* 4. 결제 창 호출하기 */
          IMP.request_pay(data, payCallback);
        }
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  const payCallback = async (response) => {
    const { merchant_uid, error_code, error_msg } = response;
    if (error_code) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: error_msg,
      });
      onClose();
      return;
    } else {
      await API.Payment.GetPaymentOrderInfo({ merchant_uid: merchant_uid })
        .then((res) => {
          history.push({
            pathname: '/marketplace/complete/' + merchant_uid,
            search: '?type=' + payMethod,
          });
          updateSnackbar({
            type: 'success',
            open: true,
            message: '결제가 성공하였습니다.',
          });
          onClose();
        })
        .catch((err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: `${t('PURCHASE_FAILURE')} : ${error_msg}`,
          });
          onClose();
        });
    }
  };

  const onRadioChange = (e) => setPayType(e);

  const validation = () => {
    let message = '';
    if (isPresent && (!name || !email)) message = '이름과 이메일을 확인해주세요.';
    else if (calculatedPrice < 1) message = VALIDATION['CHECK_AMOUNT'];
    else if (reason === 'none') message = VALIDATION['FILL_REASON'];
    else if ((reason === '직접입력' || reason === 'Enter directly') && !reasonCustom)
      message = VALIDATION['FILL_REASON'];
    else if (!isForwardEnterprise || type === 'normal') {
      if (!isAgree1 || !isAgree2) message = VALIDATION['CHECK_PRECAUTION'];
    }
    if (message) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: message,
      });
      return false;
    }
    return true;
  };

  const renderWon = () => {
    if (isForwardEnterprise && type !== 'normal') {
      return <span style={{ color: '#f41515' }}>총 계약 수량의 30% 결제</span>;
    }
    return (
      <span>
        {Handler.numberWithCommas(calculatedPrice)}
        {t('WON')}
      </span>
    );
  };

  const renderCaution = () => {
    if (isForwardEnterprise) {
      if (type === 'normal') {
        return (
          <section className='caution__box'>
            <Accordion>
              <AccordionSummary expandIcon={<div className='ico arrow-down'></div>}>
                <h3>{t('PURCHASE_NOTICE')}</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div className='text__box'>
                  <span>{t('PURCHASE_CAUTIOUS'[0])}</span>
                  <em>* 탄소 상쇄 인증서는 '마이페이지' &gt; '구매목록' 에서 확인하시기 바랍니다.</em>
                  <span>'선물하기'를 선택하신 경우, 입력하신 이메일로 '탄소 상쇄 인증서'가 발송됩니다.</span>
                </div>
              </AccordionDetails>
            </Accordion>
          </section>
        );
      }
      return null;
    } else {
      return (
        <section className='caution__box'>
          <Accordion>
            <AccordionSummary expandIcon={<div className='ico arrow-down'></div>}>
              <h3>{t('PURCHASE_NOTICE')}</h3>
            </AccordionSummary>
            <AccordionDetails>
              {type === 'normal' && (
                <div className='text__box'>
                  {PURCHASE_CAUTIOUS.map((_) => {
                    return <span>{_}</span>;
                  })}
                </div>
              )}
              {type === 'forward' && (
                <div className='text__box'>
                  <span>사업개발자가 추후 크레딧 발행 시 발행양과 구매 순서에 따라 정산금이 지급됩니다.</span>
                  <span className='underline'>
                    사업개발자의 크레딧 발행양에 따라 정산금 지급 시점은 최소 1년에서 유효기간 종료일까지 소요될 수
                    있습니다.
                  </span>
                  <em>* 정산 내역은 '마이페이지' &gt; '정산/출금' 에서 확인하시기 바랍니다.</em>
                  <span>
                    선도구매 후 '탄소 상쇄 인증서'는 사업개발자가 추후 크레딧 발행 시 발행양과 구매 순서에 따라
                    발행됩니다.
                  </span>
                  <span className='underline'>
                    사업개발자의 크레딧 발행양에 따라 '탄소 상쇄 인증서'는 여러 차례에 나누어 발급될 수 있습니다.
                  </span>
                  <em>* 탄소 상쇄 인증서는 '마이페이지' &gt; '구매목록' 에서 확인하시기 바랍니다.</em>
                  <span>거래단위 당 정산 금액이 5만원 이하일 경우, 원천징수세액이 없습니다.(분리과세)</span>
                  <em>* 상세내용은 '고객센터' &gt; 'FAQ'를 확인하시기 바랍니다.</em>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        </section>
      );
    }
  };

  const renderForwardCaution = () => {
    if (type === 'forward') {
      return isForwardEnterprise ? (
        <section className='forward-caution__box'>
          <h3>기업 고객님께 드리는 선도구매 혜택!</h3>
          <span>지금은 구매하시는 수량에 대해 30%만 결제하세요!</span>
          <span>이후에는 동일한 상품 금액으로 70%에 대한 구매우선권을 보장받을 수 있습니다.</span>
        </section>
      ) : (
        <section className='forward-caution__box'>
          <span>
            {userInfo.name}님께서 선도 구매하신 금액으로 사업개발자는 사업 유효기간({project.methodology.expiredYear}
            년) 내 크레딧을 생성할 수 있습니다. 생성된 크레딧이 판매된 이후 정산됩니다.
          </span>
        </section>
      );
    }
    return null;
  };

  const renderTerms = () => {
    if (isForwardEnterprise && type !== 'normal') {
      return null;
    }
    return (
      <section className='terms__box'>
        <CheckBox
          meta={{
            label: PURCHASE_AGREE[0],
            style: {},
            className: 'square',
          }}
          value={isAgree1}
          onChange={(new_value, key) => {
            console.log('isPresent', new_value);
            setIsAgree1(new_value);
          }}
        ></CheckBox>
        <CheckBox
          meta={{
            label: PURCHASE_AGREE[1],
            style: {},
            className: 'square',
          }}
          value={isAgree2}
          onChange={(new_value, key) => {
            console.log('isPresent', new_value);
            setIsAgree2(new_value);
          }}
        ></CheckBox>
      </section>
    );
  };

  const postTaxbill = (taxbill_body) => {
    API.Payment.PostTaxbill({
      ...taxbill_body,
    })
      .then((res) => {
        updateSnackbar({
          type: 'success',
          open: true,
          message: '세금계산서 발행 신청이 완료되었습니다.',
        });
        history.push({
          pathname: '/marketplace/complete/' + res.data.paymentOrder.merchantUid,
          search: '?type=' + payMethod,
        });
      })
      .catch((err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  const handleAddAmount = (num) => {
    let new_amount = amount + num;
    let max_number = type === 'forward' ? project.forwardAmount : project.reductionNow;
    // console.log('new_amount', new_amount);
    if (new_amount <= max_number) {
      setAmount(new_amount);
    }
  };

  const renderPayMethodBox = () => {
    if (type === 'normal') {
      return (
        <div className='reason__box'>
          <header>
            <span>{t('PAYMENT_METHOD')}</span>
          </header>
          <div className='select__box'>
            <SelectBox
              value={payMethod}
              onChange={(value) => {
                setPayMethod(value);
              }}
              meta={{
                key: 'reason',
                style: {
                  // width: 350,
                },
                menus: [
                  {
                    label: t('CREDIT_CARD'),
                    value: 'card',
                  },
                  // {
                  //   label: t('TRANSFER'),
                  //   value: 'trans',
                  // },
                  {
                    label: t('VIRTUAL_ACCOUNT'),
                    value: 'vbank',
                  },
                  {
                    label: t('TAXBILL'),
                    value: 'taxbill',
                  },
                ],
              }}
            ></SelectBox>
          </div>
        </div>
      );
    } else if (type === 'forward') {
      if (isForwardEnterprise) {
        return null;
      } else {
        return (
          <div className='reason__box'>
            <header>
              <span>결제 수단</span>
            </header>
            <div className='select__box'>
              <SelectBox
                value={payMethod}
                onChange={(value) => {
                  setPayMethod(value);
                }}
                meta={{
                  key: 'reason',
                  style: {
                    width: 350,
                  },
                  menus: [
                    {
                      label: '신용카드',
                      value: 'card',
                    },
                    {
                      label: '계좌이체',
                      value: 'trans',
                    },
                    {
                      label: '가상계좌',
                      value: 'vbank',
                    },
                  ],
                }}
              ></SelectBox>
            </div>
          </div>
        );
      }
    }
  };

  if (!project) {
    return null;
  }

  return (
    <article className='purchase-modal'>
      <header>
        <div className='ico logo-small'></div>
        <h4>{type === 'normal' ? '일반거래' : '선도거래'}</h4>
        <div
          className='ico close'
          onClick={() => {
            onClose();
          }}
        ></div>
      </header>
      <section className='project__box'>
        <div className='img__box'>
          <img src={project.mainImages[0]} alt={project.title} />
        </div>
        <div className='caption__box'>
          <div className='id__box'>
            <span>{project.id}</span>
          </div>
          <div className='title__box'>
            <h3>{project.title}</h3>
          </div>
          <div className='price__box'>
            <span>
              {Handler.numberWithCommas(project.salePrice)}
              {t('WON')} / VRC
            </span>
          </div>
          <div className='date__box'>
            <span>
              {project.startDate} ~ {project.endDate}
            </span>
          </div>
        </div>
      </section>
      <section className='info__box'>
        <div className='amount__box'>
          <div className='slider__box'>
            <header>
              <span>{t('AMOUNT')}</span>
            </header>
          </div>
          <div className='view__box'>
            <input
              value={amountView}
              onChange={(e) => {
                console.log('e', e.target.value);
                if (isNaN(Number(e.target.value.replace(',', '')))) {
                  console.log('none');
                } else {
                  console.log('number');
                  let new_amount = Number(e.target.value.replace(',', ''));
                  let max_number = type === 'forward' ? project.forwardAmount : project.reductionNow;
                  console.log('new_amount', new_amount);
                  console.log('max_number', max_number);

                  if (new_amount <= max_number) {
                    setAmount(new_amount);
                  }
                }
              }}
            ></input>
            <span>{t('TONNE_SHORT')}</span>
          </div>
        </div>
        <div className='amount__button__box'>
          {[1, 10, 100].map((el, idx) => {
            return (
              <div>
                <Button
                  onClick={() => handleAddAmount(el)}
                  radius='10px'
                  style={{ width: '60px', height: '40px' }}
                  label={'+' + el + t('TONNE_SHORT')}
                ></Button>
              </div>
            );
          })}
          <div>
            <Button
              onClick={() => setAmount(0)}
              radius='10px'
              type='secondary'
              style={{ width: '60px', height: '40px' }}
              label={t('INIT')}
            ></Button>
          </div>
        </div>
        <div className='reason__box'>
          <header>
            <span>{t('PURCHASE_REASON')}</span>
          </header>
          <div className='select__box'>
            <SelectBox
              value={reason}
              onChange={(value) => {
                setReason(value);
              }}
              meta={{
                key: 'reason',
                style: {
                  // width: 350,
                },
                menus: PURCHASE_REASON_LIST.map((el) => ({
                  label: el,
                  value: el,
                })),
              }}
            ></SelectBox>
            <div className='desc'>
              <span>{t('PURCHASE_REASON_NOTICE')}</span>
            </div>
            {(reason === '직접입력' || reason === 'Enter directly') && (
              <div className='custom__box'>
                <input
                  value={reasonCustom}
                  onChange={(e) => {
                    setReasonCustom(e.target.value);
                  }}
                ></input>
              </div>
            )}
          </div>
        </div>
        {type === 'normal' && (
          <>
            <div className='present__box'>
              <div>{PURCHASE_WAY['TITLE']}</div>
              <div className='present__select__box'>
                <ButtonRadioBox radioList={radioOptions} display='flex' onChange={onRadioChange} />
              </div>
            </div>
            {(payType === 'Present' || payType === 'Donation') && (
              <div className='wrapper'>
                <div className='content'>
                  <div className='email__box'>
                    <input
                      value={name}
                      placeholder={payType === 'Present' ? '받는 분 이름' : '기부할 기업명'}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className='email__box'>
                    <input
                      value={email}
                      placeholder={payType === 'Present' ? '받는 분 이메일' : '기업 담당자 이메일'}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
                <div className='desc__box'>
                  {PURCHASE_GIFT.map((el) => {
                    return <span>{el}</span>;
                  })}
                </div>
              </div>
            )}
          </>
        )}
        {renderPayMethodBox()}
        <div className='price__box'>
          <header>
            <span>{t('PURCHASE_AMOUNT')}</span>
          </header>
          <div className={deviceWidth > 1136 ? 'value__box' : 'value__box mobile'}>{renderWon()}</div>
        </div>
      </section>
      {renderCaution()}
      {renderForwardCaution()}
      {renderTerms()}
      <section className='button__box'>
        <div className='button__item'>
          <Button onClick={onClose} type='info' style={{ width: '100%', height: '50px' }} label={t('CANCEL')}></Button>
        </div>
        <div className='button__item'>
          <Button
            onClick={onClickPayment}
            type=''
            style={{ width: '200px', height: '50px' }}
            label={payMethod === 'taxbill' ? '발행' : t('PAYMENT')}
          ></Button>
        </div>
      </section>
    </article>
  );
};
