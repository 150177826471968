import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'market/charts';

const GetChart = (params) => {
  const url = BASE_URL + PATH + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const Chart = {
  GetChart: GetChart,
};

export default Chart;
