import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs, Button } from '../../components';
import { UserContext } from '../../contexts';
import { useLabel } from '../../hooks';

export const MypageSignoutCompletePage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { userInfo } = useContext(UserContext);
  const { USER_WITHDRAWAL } = useLabel();

  if (!userInfo) {
    return null;
  }
  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>{t('RESIGN_MEMBERSHIP')}</h1>
          </header>
          <div className='right__box'>
            <Breadcrumbs type={'small'}></Breadcrumbs>
          </div>
        </section>
        <section className='m-headding-text__container'>
          <header>
            <h2>{t('RESIGN_MEMBERSHIP')}</h2>
          </header>
        </section>
        <section className='user-form__container signout' style={{ alignItems: 'center' }}>
          <h1 style={{ color: '#5d3be5', marginTop: 120 }}>{USER_WITHDRAWAL['SUCCESS']}</h1>
          <h2 style={{ marginTop: 16, fontWeight: 'normal' }}>{USER_WITHDRAWAL['APPROVE']}</h2>
          <div
            style={{
              marginTop: 36,
              marginBottom: 16,
              height: 64,
              background: '#f7f7f9',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h2 style={{ color: '#5d3be5', fontWeight: 'bold' }}>{userInfo.email}</h2>
          </div>
          <span style={{ color: '#161616' }}>가입일 : {userInfo.confirmDate}</span>
        </section>
        <section className='button__container'>
          <Button
            style={{ width: '180px' }}
            onClick={() => {
              history.push('/');
            }}
            label={t('GOTO_MAIN')}
          ></Button>
        </section>
      </article>
    </article>
  );
};
