import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import BrandBeliefImage from '../../../styles/images/brand_belief.png';
import BrandBeliefImageEn from '../../../styles/images/brand_belief_en.png';
import { color, media } from '../../../styles/style';
import { LangContext } from '../../../contexts';
const BrandWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 40% 60%;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 60px;
  @media only screen and (max-width: ${media.$sm}) {
    display: block;
    padding: 24px;
    margin-top: 10px;
  }
`;
const GraphicArea = styled.div`
  background-color: ${color.$main500};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  img {
    width: 90%;
    padding-left: 20px;
  }
  @media only screen and (max-width: ${media.$sm}) {
    border-radius: 20px 20px 0 0;
    overflow: hidden;
  }
`;
const DescriptionArea = styled.div`
  /* background-color: ${color.$main100}; */
  padding: 90px 60px;
  p {
    white-space: pre-wrap;
    line-height: 1.5;
    padding-bottom: 2rem;
    &.no-padding-bottom {
      padding: 0;
    }
    span {
      font-size: inherit;
      color: ${color.$main500};
      &.black {
        color: ${color.$mono_000_light};
      }
    }
  }
  @media only screen and (max-width: ${media.$sm}) {
    padding: 24px;
    p {
      white-space: initial;
      word-break: keep-all;
    }
  }
`;

const BrandBelief = (props) => {
  const { t } = useTranslation()
  const { isKorean } = useContext(LangContext);

  return (
    <BrandWrapper>
      <GraphicArea>
        <img src={isKorean ? BrandBeliefImage : BrandBeliefImageEn} alt='' />
      </GraphicArea>
      <DescriptionArea className='bg-slate-50'>
        <p>{t('BRAND_BELIEF_DESCRIPTION_01')}</p>
        <p>{t('BRAND_BELIEF_DESCRIPTION_02')}</p>
        <p className='no-padding-bottom'>{t('BRAND_BELIEF_DESCRIPTION_03')}</p>
        <p className='no-padding-bottom'>
        {t('BRAND_BELIEF_DESCRIPTION_04')}
          <span>{t('BRAND_BELIEF_SPAN_01')}</span>
        </p>
        <p>
        {t('BRAND_BELIEF_DESCRIPTION_05')}
        <span>{t('BRAND_BELIEF_SPAN_02')}</span>
          <span className='black'>{t('BRAND_BELIEF_DESCRIPTION_06')}</span>
        </p>
        <p className='no-padding-bottom'>
        {t('BRAND_BELIEF_DESCRIPTION_07')}
        <span>{t('BRAND_BELIEF_SPAN_03')}</span>
        </p>
        <p className='no-padding-bottom'>{t('BRAND_BELIEF_DESCRIPTION_08')}</p>
      </DescriptionArea>
    </BrandWrapper>
  );
};

export default BrandBelief;
