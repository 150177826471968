import Utils from '../Utils';
import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';

const PATH = 'test/';

const Get = (params) => {
  const url = BASE_URL + PATH + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetDetail = (slug) => {
  const url = BASE_URL + PATH + +slug;
  return AxiosInstance.get(url);
};

const Put = (slug, body) => {
  const url = BASE_URL + PATH + 'inquiry/' + slug;
  return AxiosInstance.put(url, body);
};

const Delete = (slug) => {
  const url = BASE_URL + PATH + 'inquiry/' + slug;
  return AxiosInstance.delete(url);
};

const Post = (body) => {
  const url = BASE_URL + PATH;
  return AxiosInstance.post(url, body);
};

const Test = {};

export default Test;
